import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { getPlacesLocality } from '../../../services/placesApi'
import { PlaceBo } from '../../../utils/myAdfactoryApi/swaggerApi'

interface IApiPlacesState {
  places: PlaceBo[]
  loading: string
  error: any
  suggestions: PlaceBo[] | null
}

export const initialState: IApiPlacesState = {
  places: [],
  loading: 'idle',
  error: null,
  suggestions: null,
}

// Slice
const apiPlacesSlice = createSlice({
  name: 'apiPlaces',
  initialState,
  reducers: {
    setSuggestions(state, action) {
      state.suggestions = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        state.suggestions = null
        state.places = []
        state.loading = 'idle'
        state.error = null
      })
      .addCase(getPlacesLocality.pending, (state) => {
        state.loading = 'pending'
        state.suggestions = null
      })
      .addCase(getPlacesLocality.fulfilled, (state, action: any) => {
        state.loading = 'idle'
        state.places = action.payload
      })
      .addCase(getPlacesLocality.rejected, (state, action: any) => {
        state.loading = 'idle'
        state.error = action.error
      })
  },
})

// Selectors
type ApiPlacesSliceName = (typeof apiPlacesSlice)['name']
export type PageUsersStateType = Record<ApiPlacesSliceName, IApiPlacesState>

export const suggestionsSelector = (state: PageUsersStateType) =>
  state.apiPlaces.suggestions
export const placesSelector = (state: PageUsersStateType) =>
  state.apiPlaces.places

// Actions
export const { setSuggestions } = apiPlacesSlice.actions

// The reducer
export default apiPlacesSlice.reducer
