import React from 'react'
import { formatNumbers } from '../../../../utils/tsUtils'

import ResultTableLoad from './ResultTableLoad'

const ResultTable = ({ loading, totalEmails, productionFee, totalBudget, showSL, showLI }
    : { loading : string, totalEmails: number, productionFee: number | undefined, totalBudget: number, showSL:boolean, showLI: boolean}) => {
  if (loading === 'pending') {
    return <ResultTableLoad />
  }

  if (!showLI && !showSL) return null
  return (
    <div className="tableResult">
      <>
        <div className="rowAlign">
          <span>Volume total: {' '}</span>
          <p className="totalEmails">
            {formatNumbers(Math.round(totalEmails))} contacts
          </p>
        </div>
        <div className="wrapperTotalBudget">
          <div className="rowAlign">
            <span>Frais de production: {' '}</span>
            <p className="fraisProd">{productionFee} €</p>
          </div>
          <div className="rowAlign">
            <span>Budget total: {' '}</span>
            <p className="totalBudget">{formatNumbers(parseFloat(totalBudget.toFixed(2)))} €</p>
          </div>
        </div>
      </>

    </div>
  )
}

export default ResultTable
