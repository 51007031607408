import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BodyList from '../../../components/Lists/BodyList/BodyList'
import FooterList from '../../../components/Lists/FooterList/FooterList'
import HeaderList from '../../../components/Lists/HeaderList/HeaderList'
import { countSearchSelector } from '../../../redux/reducers/Page/Counts/pageCounts.reducer'
import {
  currentMarketSelector,
  tabListCountSelector,
  userProfileSelector,
} from '../../../redux/reducers/UserConfig/userConfig.reducer'
import { getAllCounts, getMyCounts } from '../../../services/countApi'
import { TypeProfil } from '../../../utils/myAdfactoryApi/swaggerApi'
import { ITEMS_PER_PAGE, choicesCounts } from '../../../utils/variables'

const MyCounts = (): React.ReactElement => {
  const dispatch = useDispatch()
  const userProfile = useSelector(userProfileSelector)
  const currentMarket = useSelector(currentMarketSelector)
  const tabListCount = useSelector(tabListCountSelector)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortBy, setSortBy] = useState<{ [key: string]: string }>({
    ClientId: 'desc',
    ProductName: 'desc',
    TotalBudget: 'desc',
    CreatedDate: 'desc',
    MafId: 'desc',
  })
  const [col, setCol] = useState<string>('CreatedDate')
  const countSearch: string = useSelector(countSearchSelector)

  const dispatchCounts = (page: number) => {
    if (tabListCount === 'myCounts') {
      dispatch(
        getMyCounts({
          page: page,
          itemsPerPage: ITEMS_PER_PAGE,
          clientId: userProfile.id,
          order: col.charAt(0).toUpperCase() + col.slice(1),
          orderDirection: sortBy[col],
          market: currentMarket,
          searchCount: countSearch,
        })
      )
    } else {
      const itemsPerPage = ITEMS_PER_PAGE
      const clientId = undefined
      const productName = undefined
      const market = currentMarket
      const clientName = undefined
      dispatch(
        getAllCounts({
          page,
          itemsPerPage,
          clientId,
          productName,
          market,
          clientName,
          order: col.charAt(0).toUpperCase() + col.slice(1),
          orderDirection: sortBy[col],
          searchCount: countSearch,
        })
      )
    }
  }
  const handleClick = (page: number) => {
    setCurrentPage(page)
    dispatchCounts(page)
  }
  const buttonObj = {
    haveButton: true,
    btnLabel: 'Nouveau comptage',
  }

  useEffect(() => {
    dispatchCounts(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMarket])

  return (
    <section className="listsPage" data-testid="listsPage">
      <div className="listsContentWrapper">
        {userProfile.typeProfil === TypeProfil.AdfactoryOperations ? (
          <HeaderList
            setCurrentPage={setCurrentPage}
            buttonObj={buttonObj}
            title="Liste des Comptages"
            choices={choicesCounts}
            userProfile={userProfile}
          />
        ) : (
          <HeaderList
            setCurrentPage={setCurrentPage}
            buttonObj={buttonObj}
            title="Liste des Comptages"
            choices={[choicesCounts[0]]}
            userProfile={userProfile}
          />
        )}
        <BodyList
          currentPage={currentPage}
          setSortBy={setSortBy}
          setCol={setCol}
          sortBy={sortBy}
          col={col}
          mode={tabListCount}
        />
        <FooterList
          currentPage={currentPage}
          onClick={handleClick}
          itemsPerPage={ITEMS_PER_PAGE}
          mode={tabListCount}
        />
      </div>
    </section>
  )
}

export default MyCounts
