/**
 * This custom hook is used to get async data from BO
 * getCountByIdAsync()
 */
import { useDispatch } from 'react-redux'

import { useMode } from '../../useMode'
import { useEffectOnce } from '../../useEffectOnce'

import { cleanState } from '../../../redux/reducers/VedettePlus/vedettePlus.reducer'

import { getCountByIdAsync } from '../../../services/countApi'

import { ModesType } from '../../../types/Modes.type'

export const useProductVedettePlusData = (id: string | undefined) => {
  const { mode } = useMode()
  const dispatch: any = useDispatch()

  // Async data
  useEffectOnce(() => {
    const onMount = () => {
      if (id && mode !== ModesType.New) {
        dispatch(getCountByIdAsync(id))
      }
    }
    onMount()

    return () => {
      const onUnmount = () => {
        dispatch(cleanState())
      }
      onUnmount()
    }
  })
}
