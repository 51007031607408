import React from 'react'
import type { PlaceBo } from '../../../../utils/myAdfactoryApi/swaggerApi'

type TagProps = {
  item: PlaceBo,
  removeItemsToMyList: (value: PlaceBo) => void,
}

const Tag = ({ item, removeItemsToMyList }: TagProps): React.ReactElement => (
  <div className="tag" data-testid="tagTest">
    <p>
      {item.name ? `${item.name} (${item.code})` : item.code }
    </p>
    <span
      id="remove"
      onClick={() => {
        removeItemsToMyList(item)
      }}
    >
      &#215;
    </span>
  </div>
)

export default Tag
