import { createSlice, current } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import { getCountByIdAsync } from '../../../services/countApi'
import {
  processCountForVedettePlusAsync,
  recordCountForVedettePlusAsync,
} from '../../../services/vedettePlusApi'

import { initialStateType } from '../../../types/vedetteplus.type'
import { formatCityTags, formatCityTagsWithRadius } from '../../../utils/format'
import {
  ForecastResult,
  MAFProduct,
  PlaceBo,
} from '../../../utils/myAdfactoryApi/swaggerApi'

const defineDefaultDates = () => {
  const defaultStartDate = new Date()
  const defaultEndDate = new Date()
  defaultStartDate.setDate(defaultStartDate.getDate() + 1)
  defaultEndDate.setDate(defaultEndDate.getDate() + 30)
  return [defaultStartDate, defaultEndDate]
}

export const initialState: initialStateType = {
  canSave: false,
  countNameSaved: null,
  errors: null,
  excludedLocalities: [],
  data: {
    targetings: {
      SellersNew: {
        label: 'Acquereurs neuf',
        order: 2,
        broadcastMediums: ['SELOGERNEUF'],
        checked: [true],
      },
    },
    periodicity: 'OneShot',
    dates: defineDefaultDates(),
    logos: ['SELOGERNEUF'],
  },
  loading: 'idle',
  res: null,
  radius: 0,
  listToDisplay: [],
}

const setConfigDataFromGetCount = (state: initialStateType) => {
  // Locations
  const locations = state?.res?.countData.locality

  // Dates
  const dates = []
  if (state?.res?.countData.vedettePlusReq?.startDate)
    dates.push(state.res.countData.vedettePlusReq.startDate)
  if (state?.res?.countData.vedettePlusReq?.endDate)
    dates.push(state.res.countData.vedettePlusReq.endDate)

  state.data = { ...state.data, locations, dates }
}

const defineDefaultForecastsForPendingProcessCount = (
  state: initialStateType
) => {
  const defaultForecasts = state?.data?.locations?.map((row: PlaceBo) => {
    return {
      code: row.code,
      name: row.name,
      available: 0,
      budget: undefined, // important!
      purchased: 0,
      sold: 0,
      total: 0,
    }
  })
  state.res = {
    ...state.res,
    countData: {
      forecasts: defaultForecasts,
      mafProduct: MAFProduct.VedettePlus,
      totalBudget: 0,
    },
  }
}

const setExcludedLocalities = (state: initialStateType) => {
  const data = state.data ? current(state.data) : {}
  state.excludedLocalities =
    data?.locations?.filter((location: PlaceBo) =>
      state?.res?.countData?.excludePostalCode?.includes(location.code)
    ) ?? []

  // remove excluded Locatities to data?.locations
  if (state?.data) {
    state.data.locations =
      data?.locations?.filter(
        (location: PlaceBo) =>
          !state?.res?.countData?.excludePostalCode?.includes(location.code)
      ) ?? []
  }

  // remove excluded Locatities to res?.countData?.locality
  if (state?.res?.countData?.locality) {
    state.res.countData.locality =
      state.res.countData.locality?.filter(
        (location: PlaceBo) =>
          !state?.res?.countData?.excludePostalCode?.includes(location.code)
      ) ?? []
  }

  // Clean localityNameByZipCode by adding only not excluded ones before record.
  if (state?.res?.countData?.forecasts) {
    const localityNameByZipCode: any = {}
    state.res.countData.forecasts?.forEach((forecast: ForecastResult) => {
      if (
        forecast.code &&
        state?.res?.countData?.localityNameByZipCode?.[forecast.code]
      ) {
        localityNameByZipCode[forecast.code] =
          state.res.countData.localityNameByZipCode[forecast.code]
      }
    })
    state.res.countData.localityNameByZipCode = localityNameByZipCode
  }
}

const setForecastsTotalBudget = (state: initialStateType) => {
  if (state.res) {
    const { forecasts } = state.res.countData
    const totalBudget = forecasts?.reduce(
      (acc, value) => acc + (value.budget ?? 0),
      0
    )
    state.res.countData = {
      ...state.res.countData,
      totalBudget,
    }
  }
}

const cleanResToDisplayInProgressComponent = (state: initialStateType) => {
  if (state.res) {
    state.res.countData = {}
  }
}

// Slice
const vedettePlusSlice = createSlice({
  name: 'vedettePlus',
  initialState,
  reducers: {
    cleanState() {
      return initialState
    },
    setCanSave(state, action) {
      state.canSave = action.payload
    },
    setData(state, action) {
      state.data = action.payload
      cleanResToDisplayInProgressComponent(state)
    },
    setRadius(state, action) {
      state.radius = action.payload.radius
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(getCountByIdAsync.pending, (state: initialStateType) => {
        state.loading = 'pending'
      })
      .addCase(
        getCountByIdAsync.fulfilled,
        (state: initialStateType, action: any) => {
          // getCountByIdAsync is used by all the products. We need to check to product name from BO
          if (action.payload?.mafProduct === MAFProduct.VedettePlus) {
            state.loading = 'idle'
            state.res = action.payload
            state.countNameSaved = action.payload.countData.countName ?? null
            state.errors = null
            setConfigDataFromGetCount(state)
          }
          if (state.res) {
            state.radius = action.payload.countData.vedettePlusReq?.radius

            const postalCode =
              action.payload.countData.vedettePlusReq?.postalCodes[0]
            const localityByZipCode =
              action.payload.countData.localityNameByZipCode

            if (state.radius > 0) {
              state.listToDisplay = formatCityTagsWithRadius(
                postalCode,
                localityByZipCode[postalCode]
              )
            } else {
              state.listToDisplay = formatCityTags(localityByZipCode ?? {})
            }
          }
        }
      )
      .addCase(
        getCountByIdAsync.rejected,
        (state: initialStateType, action) => {
          state.loading = 'idle'
          state.res = null
          state.countNameSaved = null
          state.excludedLocalities = []
          state.errors = action.error
        }
      )
      .addCase(
        processCountForVedettePlusAsync.pending,
        (state: initialStateType) => {
          state.loading = 'pending'
          state.canSave = false
          state.errors = null
          state.excludedLocalities = []
          defineDefaultForecastsForPendingProcessCount(state)
        }
      )
      .addCase(processCountForVedettePlusAsync.fulfilled, (state, action) => {
        state.loading = 'idle'
        state.canSave = true
        const res = state.res ? current(state.res) : {}
        state.res = { ...res, countData: action.payload }

        const postalCode = action.meta?.arg?.postalCodes?.toString() ?? ''
        const localityByZipCode = action.payload?.localityNameByZipCode

        if (action.meta.arg?.radius && action.meta.arg?.radius > 0) {
          state.listToDisplay = formatCityTagsWithRadius(
            postalCode,
            localityByZipCode?.[postalCode]
          )
        } else {
          state.listToDisplay = formatCityTags(localityByZipCode ?? {})
        }

        setExcludedLocalities(state)
        setForecastsTotalBudget(state)

        // update data.locations with data from processCount result
        const data = state.data ? current(state.data) : {}
        if (state?.res?.countData?.locality)
          state.data = { ...data, locations: state.res.countData.locality }
      })
      .addCase(processCountForVedettePlusAsync.rejected, (state, action) => {
        state.loading = 'idle'
        state.res = null
        state.errors = action.error
      })
      .addCase(recordCountForVedettePlusAsync.fulfilled, (state) => {
        state.canSave = false
      })
  },
})

// Selectors
type VedettePlusSliceName = (typeof vedettePlusSlice)['name']
export type VedettePlusStateType = Record<VedettePlusSliceName, any>

export const vedettePlusDataSelector = (state: VedettePlusStateType) =>
  state.vedettePlus.data
export const vedettePlusResSelector = (state: VedettePlusStateType) =>
  state.vedettePlus.res
export const vedettePlusErrorsSelector = (state: VedettePlusStateType) =>
  state.vedettePlus.errors
export const vedettePlusExcludedLocalitiesSelector = (
  state: VedettePlusStateType
) => state.vedettePlus.excludedLocalities
export const vedettePlusLoadingSelector = (state: VedettePlusStateType) =>
  state.vedettePlus.loading
export const vedettePlusCanSaveSelector = (state: VedettePlusStateType) =>
  state.vedettePlus.canSave
export const vedettePlusCountNameSavedSelector = (
  state: VedettePlusStateType
) => state.vedettePlus.countNameSaved
export const vedettePlusRadiusSelector = (state: VedettePlusStateType) =>
  state.vedettePlus.radius
export const vedettePlusListToDisplaySelector = (state: VedettePlusStateType) =>
  state.vedettePlus.listToDisplay

// Actions
export const { cleanState, setCanSave, setData, setRadius } =
  vedettePlusSlice.actions

// The reducer
export default vedettePlusSlice.reducer
