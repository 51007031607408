import styled, { css } from 'styled-components'

type SiteTableNbMailsProps = {
  $isNotEditable?: boolean,
}

type SiteTableNbMailsInputProps = {
  $error?: boolean,
}

type SiteTableBudgetProps = {
  $isNotEditable?: boolean,
  $error?: boolean,
}

type SiteTableNbMailsTextProps = {
  $isNotEditable?: boolean,
}

export const SiteTableRow = styled.div`
  display: grid;
  grid-template-columns: 32.5% 17.5% 25% 25%;

  > div {
    padding: 1em .8em;
    border-top: ${(props) => `1px solid ${props.theme.global.colorPalette.greyLighter}`};
    border-left: ${(props) => `1px solid ${props.theme.global.colorPalette.greyLighter}`};

    &:nth-child(4n) {
      border-right: ${(props) => `1px solid ${props.theme.global.colorPalette.darkerSaphir}`};
    }

    &:nth-child(3n) {
      border-left: ${(props) => `1px solid ${props.theme.global.colorPalette.darkerSaphir}`};
    }

    p {
      span {
        font-style: italic;
        font-size: .85em;
      }
    }
  }

  &:first-child {
    > div {
      &:nth-child(4n) {
        border-top: ${(props) => `1px solid ${props.theme.global.colorPalette.darkerSaphir}`};
      }

      &:nth-child(3n) {
        border-top: ${(props) => `1px solid ${props.theme.global.colorPalette.darkerSaphir}`};
      }
    }
  }

  &:last-child {
    > div {
      &:nth-child(1) {
        border-bottom-left-radius: .3em;
      }

      border-bottom: ${(props) => `1px solid ${props.theme.global.colorPalette.greyLighter}`};
    }
  }

  @keyframes LoaderAnimation {
    0% {
      background-position: -15em 0;
    }

    100% {
      background-position: 15em 0;
    }
  }
`
export const SiteTableBroadcasts = styled.div`
  display: flex;
`

export const SiteTableNbMails = styled.div<SiteTableNbMailsProps>`
  ${(props: any) => props.$isNotEditable && css`
    display: flex;
    justify-content: flex-end;
    border-left: ${(p) => `1px solid ${p.theme.global.colorPalette.darkerSaphir}`};
  `}
`

export const SiteTableNbMailsInput = styled.div<SiteTableNbMailsInputProps>`
  display: flex;
  justify-content: flex-end;
  margin-top: .5em;
  height: 2.5em;

  input[type="text"] {
    padding: 0 1em 0 1em;
    width: 7.75em;
    height: 100%;
    border-bottom-right-radius: 0;
    outline: none !important;
    border: ${(props) => `1px solid ${props.theme.global.colorPalette.greyLight}`};
    border-right: none;
    border-radius: .3em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    padding-right: 2.5em;
    border: ${(props) => `1px solid ${props.theme.global.colorPalette.greyLight}`};
    border-radius: .3em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
  
  ${(props: any) => props.$error && css`
    input[type="text"] {
      border: ${(p) => `1px solid ${p.theme.global.colorPalette.error}`} ;
      border-right: none;
    }

    span {
      border: ${(p) => `1px solid ${p.theme.global.colorPalette.error}`} ;
      border-left: none;
    }
  `}
`

export const SiteTableBudget = styled.div<SiteTableBudgetProps>`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  ${(props: any) => props.$isNotEditable && css`
    border-left: ${(p) => `1px solid ${p.theme.global.colorPalette.greyLighter}`};
    border-right: ${(p) => `1px solid ${p.theme.global.colorPalette.darkerSaphir}`};
  `}

  ${(props: any) => props.$error && css`
    p {
      color: ${(p) => p.theme.global.colorPalette.error};
    }
  `}
`

export const SiteTableNbMailsText = styled.div<SiteTableNbMailsTextProps>`
  span {
    margin-left: .2em;
  }
`

export const ErrorNbMails = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 1em;
  color: ${(props) => props.theme.global.colorPalette.error};
  font-weight: 500;
`
