import { Button, Icon, TextField } from '@ui-library/core'
import {
  ChevronDownLined,
  ChevronUpLined,
  LoaderRegular,
} from '@ui-library/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import AutocompleteMultiple from '../../../Autocomplete/AutocompleteMultiple/AutocompleteMultiple'
import BroadcastMedium from '../../../BroadcastMedium/BroadcastMedium'
import DatePicker from '../../../DatePicker/DatePicker'

import {
  performanceDataSelector,
  performanceListToDisplaySelector,
  performanceLoadingSelector,
  performanceRadiusSelector,
  performanceResSelector,
  setRadius,
} from '../../../../redux/reducers/Performance/performance.reducer'

import { useProductPerformanceHeader } from '../../../../hooks/products/performance/useProductPerformanceHeader'

import { respondTo } from '../../../../theme/mixins/respondTo'
import { productNamesType } from '../../../../types/productNames.types'

const PerformanceHeader = (): React.ReactElement => {
  const dispatch = useDispatch()

  const data = useSelector(performanceDataSelector)
  const res = useSelector(performanceResSelector)
  const loading = useSelector(performanceLoadingSelector)
  const listToDisplay = useSelector(performanceListToDisplaySelector)
  const radius = useSelector(performanceRadiusSelector)

  const [reactivateEstimateButton, setReactivateEstimateButton] =
    React.useState(false)

  const {
    openListBroadcast,
    showErrorLocalities,
    showErrorDatePicker,
    stateBroadcast,
    handleBroadcastMediumClick,
    handleChangeLocations,
    handleChangeDates,
    handleRadiusChange,
    handleProcess,
    toggleListBroadcastMedium,
  } = useProductPerformanceHeader()

  return (
    <PerformanceHeaderWrapper data-testid="performanceHeader">
      <PerformanceHeaderContainer>
        <h2>
          Configuration du comptage{' '}
          <span>{productNamesType.DisplayPerformance}</span>
        </h2>
        <FormWrapper>
          <FormContainer>
            <BroadcastDiv data-testid="broadcastSelect">
              <LabelField>Contexte(s) de diffusion</LabelField>
              <span
                onClick={() => toggleListBroadcastMedium()}
                aria-hidden="true"
              >
                <TextField
                  disabled={loading === 'pending'}
                  errorMessage="Sélectionnez au moins une valeur"
                  iconColor="#929292"
                  onChange={() => true}
                  placeholder="Ex: SeLoger,Logic..."
                  state={stateBroadcast}
                  suffix={openListBroadcast ? ChevronUpLined : ChevronDownLined}
                  value={data?.inputTargetingsValue ?? ''}
                />
              </span>
              {openListBroadcast && (
                <BroadcastMedium
                  closeDropdown={toggleListBroadcastMedium}
                  info="Sélectionnez un ou plusieurs sites où vous souhaitez afficher le display."
                  onClick={handleBroadcastMediumClick}
                  targetings={data?.targetings ?? null}
                  targetRequiredText="Veuillez sélectionner au moins un type de projet"
                />
              )}
            </BroadcastDiv>
            <LocalitiesDiv
              onClick={() => toggleListBroadcastMedium(true)}
              data-testid="localitiesSelect"
            >
              <AutocompleteMultiple
                maximumItems={50}
                catchmentZone={undefined}
                label="Localité(s) recherchée(s)"
                placeholder="Ajouter une localité (ville, quartier, département...)"
                completingWord="localités"
                titleList="Localité(s)"
                otherSuggestionsText="Un autre lieu ?"
                autocompleteCallback={(locations) =>
                  handleChangeLocations(locations)
                }
                persistRadius={(value) => {
                  dispatch(setRadius({ radius: value }))
                }}
                onRadiusChange={(_: boolean, isZero?: boolean) => {
                  handleRadiusChange(isZero)
                  setReactivateEstimateButton(true)
                }}
                error={showErrorLocalities}
                noResultFound="Aucun résultat ne correspond à votre recherche"
                textError="Sélectionnez au moins une localité"
                initDataLocality={listToDisplay ?? data?.locations ?? undefined}
                data-testid="AutocompleteDisplay"
                disabled={loading === 'pending'}
                radius={radius}
              />
            </LocalitiesDiv>
            <DatepickerDiv onClick={() => toggleListBroadcastMedium(true)}>
              <LabelField>Périodicité</LabelField>
              <DatepickerContainer>
                <DatePicker
                  action={(dates: any) => handleChangeDates(dates)}
                  defaultValue={data?.dates}
                  showErrorDatePicker={showErrorDatePicker}
                  modeSelectRange
                  resetDate={!data?.dates}
                />
              </DatepickerContainer>
            </DatepickerDiv>
            <EstimateButton onClick={() => toggleListBroadcastMedium(true)}>
              <Button
                tag="button"
                size="medium"
                kind="primary"
                variant="normal"
                onClick={() => {
                  handleProcess()
                  setReactivateEstimateButton(false)
                }}
                disabled={
                  res?.countData &&
                  Object.keys(res.countData).length > 0 &&
                  !reactivateEstimateButton
                }
              >
                {loading === 'pending' ? (
                  <Icon icon={LoaderRegular} style={{ display: 'flex' }} />
                ) : (
                  'Estimer'
                )}
              </Button>
            </EstimateButton>
          </FormContainer>
        </FormWrapper>
      </PerformanceHeaderContainer>
    </PerformanceHeaderWrapper>
  )
}

const PerformanceHeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: ${(props) => props.theme.global.myAdFactory.zIndex.form};
  height: 10em;
  width: 100%;
  background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
`

const PerformanceHeaderContainer = styled.div`
  margin: 2.5em 2.5em 0 2.5em;

  h2 {
    font-size: 1.15em;
    font-weight: 700;

    span {
      font-weight: 400;
    }
  }
`

const FormWrapper = styled.div`
  position: relative;
  height: 9.5em;
  margin-top: 1em;
  width: 100%;
  background-color: ${(props) => props.theme.global.colorPalette.white};
  border-radius: 0.3em;
  padding: 1em 1.5em 2.75em 1.5em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
`

const FormContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
`

const BroadcastDiv = styled.div`
  position: relative;
  width: 31%;
  margin-right: 2%;

  ${respondTo.lg`
    width: 25%;
  `}

  input {
    font-weight: 600;

    &:disabled {
      cursor: wait;
      background: transparent;
    }
  }

  [data-testid='gsl.uilib.TextField.message'] {
    margin-top: 0.4em;
    font-size: 0.85em;
    font-weight: 600;
  }

  [data-testid='gsl.uilib.TextField.input'] {
    &:disabled {
      background: transparent;
      border-color: ${(props) => props.theme.global.colorPalette.greyLight};
      cursor: wait;
    }
  }
`

const LocalitiesDiv = styled.div`
  margin-right: 2%;

  ${respondTo.md`
    width: 33%;
  `}

  ${respondTo.lg`
    width: 45%;
  `}
`

const DatepickerDiv = styled.div`
  position: relative;

  ${respondTo.md`
    width: 31%;
  `}

  ${respondTo.lg`
    width: 25%;
  `}

  input {
    font-weight: 600;
  }

  [data-testid='gsl.uilib.TextField.message'] {
    margin-top: 0.4em;
    font-size: 0.85em;
    font-weight: 600;
  }
`

const DatepickerContainer = styled.div`
  width: 100%;
  height: 39%;
  margin-right: 1.5em;

  p {
    display: inline-block;
    margin-top: 0.25em;
    color: ${(props) => props.theme.global.colorPalette.error};
    line-height: 1.12em;
  }
`

const LabelField = styled.span`
  display: block;
  margin-bottom: 0.3em;
  font-size: 0.85em;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const EstimateButton = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 10.75em;
  bottom: 0;
  transform: translateY(50%);

  button {
    width: 100%;
  }
`

export default PerformanceHeader
