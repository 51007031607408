import React from 'react'
import { Button, Icon } from '@ui-library/core'
import { BinRegular, CloseRegular } from '@ui-library/icons'
import { useDispatch } from 'react-redux'
import { deleteCount } from '../../../services/countApi'
import Overlay from '../Overlay'

type OverlayDeleteCountProps = {
  closeOverlay: () => void
  idMaf: number
}

const OverlayDeleteCount = ({
  closeOverlay,
  idMaf,
}: OverlayDeleteCountProps) => {
  const dispatch = useDispatch()

  const deleteCountById = () => {
    dispatch(deleteCount(idMaf))
    closeOverlay()
  }

  return (
    <Overlay closeOverlay={() => closeOverlay()}>
      <div data-testid="overlayDeleteCount" className="overlayFirstConnection">
        <div className="wrapperOverlayFirstConnection">
          <Button
            onClick={() => {
              closeOverlay()
            }}
            className="closeOverlay"
            kind="tertiary"
            size="medium"
            variant="text"
            tag="button"
          >
            <Icon icon={CloseRegular} />
          </Button>

          <div
            data-testid="contentOverlayDeleteCount"
            className="contentOverlayDeleteCount"
          >
            <Icon icon={BinRegular} size="40px" className="binRegular" />
            <h2>Êtes vous certain ?</h2>
            <p>Voulez-vous supprimer le comptage MAF-{idMaf} ?</p>
          </div>
          <hr />
          <div
            data-testid="buttonsDeleteOverlay"
            className="buttonsDeleteOverlay"
          >
            <Button
              className="cancelButton"
              kind="secondary"
              onClick={() => {
                closeOverlay()
              }}
              size="medium"
              tag="button"
              variant="normal"
            >
              Annuler
            </Button>
            <Button
              className="deleteButton"
              tag="button"
              kind="primary"
              size="medium"
              variant="normal"
              onClick={() => deleteCountById()}
            >
              Supprimer
            </Button>
          </div>
        </div>
      </div>
    </Overlay>
  )
}

export default OverlayDeleteCount
