import { useDispatch, useSelector } from 'react-redux'

import { masterDisplayDataSelector, masterDisplayResSelector, setRes } from '../../../redux/reducers/MasterDisplay/masterDisplay.reducer'

import { ForecastResult } from '../../../utils/myAdfactoryApi/swaggerApi'

import { MASTER_DISPLAY_CPM } from '../../../utils/variables'

export const useProductMasterDisplayCount = () => {
  const dispatch: any = useDispatch()

  const data = useSelector(masterDisplayDataSelector)
  const res = useSelector(masterDisplayResSelector)

  const offers = ((res?.countData?.totalBudget ?? 0) / MASTER_DISPLAY_CPM) * 1000

  const forecastsForTable = res?.countData?.forecasts ?? Object.entries(data?.locations ?? {}).map((l) => {
    if (l?.[1]) {
      return {
        ...l[1],
        available: 0,
        budget: 0,
        purchased: 0,
        sold: 0,
        total: 0,
      }
    }
    return undefined
  })

  const forescastResultSplitByPostalCodes: ForecastResult[] = forecastsForTable?.map((f: ForecastResult) => {
    const updatedF: ForecastResult & { offer?: number } = { ...f }
    updatedF.offer = f?.budget ? (f.budget / MASTER_DISPLAY_CPM) * 1000 : 0
    return updatedF
  })

  const calculateTotalBudget = (forecasts: ForecastResult[]) => {
    let totalBudg = 0
    forecasts.forEach((forecast: ForecastResult) => {
      totalBudg += forecast.budget ?? 0
    })
    return totalBudg
  }

  const calculateTotalImpressions = (forecasts: ForecastResult[]) => {
    let totalImp = 0
    forecasts.forEach((forecast: ForecastResult) => {
      totalImp += forecast.purchased ?? 0
    })
    return totalImp
  }

  const handleBudgetChange = (d: ForecastResult) => {
    const copyForecast = [...forecastsForTable]
    const index = copyForecast.findIndex((forecast: ForecastResult) => forecast.code === d.code)

    // First update target forecast with the new values.
    if (copyForecast && index !== -1) copyForecast[index] = d

    const totalBudget = calculateTotalBudget(copyForecast)
    const totalImpPurchased = calculateTotalImpressions(copyForecast)

    const updatedRes = {
      ...res,
      countData: {
        ...res.countData,
        forecasts: copyForecast,
        totalBudget,
        totalImpPurchased,
      },
    }
    dispatch(setRes(updatedRes))
  }

  return {
    offers,
    forescastResultSplitByPostalCodes,
    handleBudgetChange,
  }
}
