import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormatStyled, InputWrapper, SpanCoverage, SpanMax, StrongBudget, TableStyled, ThBlue, TotalAndBudget } from '../common/styled'

import Loader from '../../Loader/Loader'

import { performanceCanSaveSelector, setCanSave } from '../../../redux/reducers/Performance/performance.reducer'

import { PERFORMANCE_PRODUCTS_FORMATS } from '../../../utils/variables'
import { PerformanceForecastResult } from '../../../utils/myAdfactoryApi/swaggerApi'
import { formatNumbers } from '../../../utils/tsUtils'

type PrintByFormatProps = {
  formatsResult: any[],
  cpcs: { [key: string]: number } | undefined,
  isProposal?: boolean,
  loading: string,
  totalBudget: number,
  totalOffer: number,
  onChange?: (data: any) => void,
}

export const defineAvailableImp = (fr: PerformanceForecastResult) => Math.round(fr?.available ?? 0)
export const definePurchasedClicks = (fr: PerformanceForecastResult) => Math.round(fr?.purchased ?? 0)
export const defineCpc = (fr: PerformanceForecastResult, cpcs: { [key: string]: number } | undefined) => {
  if (fr.code && cpcs && cpcs[fr.code]) {
    return cpcs[fr.code]
  }
  return 0
}

const InputBudget = ({ data, onChange, cpc }: { data: any, onChange: (data: any) => void, cpc: number }): React.ReactElement | null => {
  const dispatch = useDispatch()
  const canSave = useSelector(performanceCanSaveSelector)

  const [budgetValue, setBudgetValue] = useState(data.budget)
  const maximumBudget: number = Math.round((data?.click ?? 0) * cpc)
  const isDisabled = maximumBudget === 0

  const handleChange = (event: any) => {
    if (!canSave) dispatch(setCanSave(true))

    const valueWithoutSpacing = event.target.value.replace(/\D+/g, '')
    if (!Number.isNaN(valueWithoutSpacing)) {
      if (valueWithoutSpacing === '') {
        return setBudgetValue(0)
      }

      if (valueWithoutSpacing > maximumBudget) {
        return false
      }

      return setBudgetValue(parseInt(valueWithoutSpacing))
    }
    return false
  }

  /**
   * WHEN PURCHASED INPUT IS USED, SET BUDGET INPUT WITH NEW VALUE FROM DATA
   */
  useEffect(() => {
    setBudgetValue(data.budget)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.purchased])

  /**
   * IF BUGDET INPUT IS USED, CALCULATE THE NEW PURCHASED VALUE OF DATA AND UPDATE
   */
  useEffect(() => {
    let purchasedClicks = Math.round(budgetValue / cpc)
    // Ugly Fix: first loading (diff between click value from back-office and multiple calculation in front with rounded values)
    purchasedClicks = data.click - purchasedClicks === 1 ? data.click : purchasedClicks
    const broadcastData = {
      ...data,
      purchased: purchasedClicks,
      budget: budgetValue,
    }
    onChange(broadcastData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetValue])

  return (
    <div className={isDisabled ? 'isDisabled' : ''}>
      <InputWrapper>
        <input
          disabled={isDisabled}
          type="text"
          value={formatNumbers(budgetValue)}
          onChange={handleChange}
        />
        <span>€</span>
      </InputWrapper>
      <SpanMax>Max. {formatNumbers(maximumBudget)}€</SpanMax>
    </div>
  )
}

const InputClicks = ({ data, onChange, cpc }: { data: any, onChange: (data: any) => void, cpc: number }): React.ReactElement | null => {
  const dispatch = useDispatch()
  const canSave = useSelector(performanceCanSaveSelector)
  const [clickValue, setClickValue] = useState(Math.min(data.purchased, data.click))
  const isDisabled = data.click === 0

  const handleChange = (event: any) => {
    if (!canSave) dispatch(setCanSave(true))

    const valueWithoutSpacing = event.target.value.replace(/\D+/g, '')
    if (!Number.isNaN(valueWithoutSpacing)) {
      if (valueWithoutSpacing === '') {
        return setClickValue(0)
      }

      if (valueWithoutSpacing > data.click) {
        return false
      }

      return setClickValue(parseInt(valueWithoutSpacing))
    }
    return false
  }

  /**
   * WHEN BUDGET INPUT IS USED, SET PUSCHASED INPUT WITH NEW VALUE FROM DATA
   */
  useEffect(() => {
    setClickValue(Math.min(data.purchased, data.click))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.budget])

  /**
   * IF PUSCHASED INPUT IS USED, CALCULATE THE NEW BUDGET VALUE OF DATA AND UPDATE
   */
  useEffect(() => {
    const budget = Math.round(clickValue * cpc)
    const broadcastData = {
      ...data,
      purchased: clickValue,
      budget,
    }
    onChange(broadcastData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickValue])

  return (
    <div className={isDisabled ? 'isDisabled' : ''}>
      <InputWrapper>
        <input
          disabled={isDisabled}
          type="text"
          value={formatNumbers(clickValue)}
          onChange={handleChange}
        />
        <span>Clics</span>
      </InputWrapper>
    </div>
  )
}

const ClicksByFormatTable = ({
  formatsResult,
  cpcs,
  isProposal = false,
  loading,
  totalBudget,
  totalOffer,
  onChange = (() => true),
}: PrintByFormatProps): React.ReactElement => {
  const isPending = loading === 'pending'

  return (
    <TableStyled data-testid="ClicksByFormatTable">
      <thead>
        <tr>
          <th scope="col">
            Type de format
          </th>

          {!isProposal && <th scope="col">Déjà vendues <span>(sur la période)</span></th>}
          {!isProposal && <th scope="col">Disponibles <span>(sur la période)</span></th>}
          <ThBlue scope="col">Clics estimés proposés <span>(sur la période)</span></ThBlue>
          <ThBlue scope="col">Budgets <span>(hors taxe)</span></ThBlue>
        </tr>
      </thead>
      <tbody>
        {formatsResult?.length > 0 && (
          formatsResult.map((fr: PerformanceForecastResult) => {
            const availableImp = defineAvailableImp(fr)
            const purchasedClicks = definePurchasedClicks(fr)
            const cpc = defineCpc(fr, cpcs)

            // If table is used in a proposal page, we want to hide the "zero" lines
            if (isProposal && fr.budget === 0) {
              return null
            }

            return (
              <tr key={`row-${fr.code}`}>
                <td>
                  <FormatStyled>
                    <img src={fr.code && PERFORMANCE_PRODUCTS_FORMATS[fr.code]?.svg} width="50" alt="masthead" />
                    {fr.name}
                  </FormatStyled>
                </td>
                {!isProposal && (
                  <>
                    <td>
                      {isPending ? (
                        <Loader maxWidth={100} height={20} />
                      ) : (
                        <>{formatNumbers(fr.sold ?? 0)} Clics</>
                      )}
                    </td>
                    <td>
                      {isPending ? (
                        <>
                          <Loader maxWidth={100} height={20} />
                          <SpanCoverage><Loader maxWidth={150} height={15} /></SpanCoverage>
                        </>
                      ) : (
                        <>
                          {fr?.click} Clics
                          {fr?.available && fr?.total ? <SpanCoverage>Soit {formatNumbers(availableImp)} imps. ou {Math.round((fr.available / fr.total) * 100) || 0}% de couverture</SpanCoverage> : ''}
                        </>
                      )}
                    </td>
                  </>
                )}
                <td data-testid={`inputClick-${fr.code}`}>
                  {isPending ? (
                    <div style={{ marginBottom: '23px' }}>
                      <Loader maxWidth={300} height={35} style={{ marginBottom: '5px' }} />
                    </div>
                  ) : (
                    <>
                      {!isProposal ? (
                        <div style={{ marginBottom: '23px' }}>
                          <InputClicks data={fr} onChange={onChange} cpc={cpc} />
                        </div>
                      ) : (
                        <StrongBudget>{formatNumbers(purchasedClicks)} Clics</StrongBudget>
                      )}
                    </>
                  )}
                </td>
                <td data-testid={`inputBudget-${fr.code}`}>
                  {(isPending || fr.budget === undefined) ? (
                    <>
                      <Loader maxWidth={300} height={35} style={{ marginBottom: '5px' }} />
                      <Loader maxWidth={80} height={20} />
                    </>
                  ) : (
                    <>
                      {!isProposal ? (
                        <InputBudget data={fr} onChange={onChange} cpc={cpc} />
                      ) : (
                        <StrongBudget>{fr.budget} €</StrongBudget>
                      )}
                    </>
                  )}
                </td>
              </tr>
            )
          })
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={!isProposal ? 3 : 1} style={{ textAlign: 'right' }}>
            <strong>Total du comptage :</strong>
          </td>
          <td>
            {isPending ? (
              <Loader maxWidth={80} height={20} />
            ) : (
              <TotalAndBudget $hideBudget={false}>
                <strong>{formatNumbers(totalOffer)} Clics</strong>
              </TotalAndBudget>
            )}
          </td>
          <td>
            {isPending ? (
              <Loader maxWidth={80} height={20} style={{ marginLeft: 'auto' }} />
            ) : (
              <StrongBudget>{formatNumbers(totalBudget)} €</StrongBudget>
            )}
          </td>
        </tr>
      </tfoot>
    </TableStyled>
  )
}

export default ClicksByFormatTable
