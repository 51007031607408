import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import MasterDisplayHeader from '../../../components/Products/MasterDisplay/MasterDisplayHeader/MasterDisplayHeader'
import MasterDisplayCount from '../../../components/Products/MasterDisplay/MasterDisplayCount/MasterDisplayCount'

import { masterDisplayErrorsSelector } from '../../../redux/reducers/MasterDisplay/masterDisplay.reducer'

import { useProductMasterDisplayData } from '../../../hooks/products/masterDisplay/useProductMasterDisplayData'

type DisplayWrapperProps = {
  children: React.ReactNode,
  dataTestId: string,
}

const MasterDisplayWrapper = ({ children, dataTestId }: DisplayWrapperProps): React.ReactElement => (
  <MasterDisplayWrapperStyled data-testid={dataTestId}>
    <MasterDisplayHeader />
    {children}
  </MasterDisplayWrapperStyled>
)

const MasterDisplayErrorComp = (): React.ReactElement => (
  <MasterDisplayWrapper dataTestId="masterDisplayError">
    <MasterDisplayError>
      <p>Une erreur est survenue</p>
    </MasterDisplayError>
  </MasterDisplayWrapper>
)

const MasterDisplay = (): React.ReactElement => {
  const displayErrors: any = useSelector(masterDisplayErrorsSelector)

  useProductMasterDisplayData(undefined)

  if (displayErrors) {
    return <MasterDisplayErrorComp />
  }

  return (
    <MasterDisplayWrapper dataTestId="masterDisplay">
      <MasterDisplayCount />
    </MasterDisplayWrapper>
  )
}

// Styled component
const MasterDisplayWrapperStyled = styled.div`
  padding-top: 14em;
  background-color: ${(props) => props.theme.global.colorPalette.white};
`

const MasterDisplayError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

export default MasterDisplay
