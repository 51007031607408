import React from 'react'
import { useSelector } from 'react-redux'
import { DocumentLined, CalendarLined, DesktopLined, FillsRegular } from '@ui-library/icons'

import ResumeCard from '../../../ResumeCard/ResumeCard'

import { performanceDataSelector, performanceLoadingSelector, performanceResSelector } from '../../../../redux/reducers/Performance/performance.reducer'

import { formatZipCodeWithManyLocalityNames } from '../../../../utils/format'
import { formatNumbers } from '../../../../utils/tsUtils'
import { formatDate } from '../../../../utils/date'

import { ForecastResult } from '../../../../utils/myAdfactoryApi/swaggerApi'
import { BudgetInformationType, TableInformationType } from '../../../../types/ResumeCard.type'
import { productNamesType } from '../../../../types/productNames.types'

type SummaryCountsProps = {
  isProposal?: boolean,
}

const PerformanceSummaryCounts = ({ isProposal = false }: SummaryCountsProps): React.ReactElement => {
  const data = useSelector(performanceDataSelector)
  const res = useSelector(performanceResSelector)
  const loading = useSelector(performanceLoadingSelector)

  const logos = data?.logos ?? []
  const productName = productNamesType.DisplayPerformance

  const locations: ForecastResult[] | undefined = formatZipCodeWithManyLocalityNames(data?.locations ?? [], res?.countData?.localityNameByZipCode ?? {})

  const periodicity: string = data && data?.dates?.length > 0 && `Du ${formatDate(new Date(data.dates[0]))} au ${formatDate(new Date(data.dates[1]))}`

  let projectType: string = ''
  if (data?.targetings) {
    projectType = Object.values(data.targetings).map((t: any) => {
      if (t.checked.includes(true)) return t.label
      return null
    }).filter((t: any) => t).join(', ')
  }

  const budgetInformation: BudgetInformationType = {
    totalBudget: formatNumbers(res?.countData.totalBudget ?? 0),
    complementary: `pour ${formatNumbers(res?.countData.totalImpPurchased ?? 0)} clics`,
  }

  const formatToDisplay: string = 'Masthead et Pavé'

  const tableInformation: TableInformationType = [{
    title: 'Appareils',
    icon: DesktopLined,
    description: 'Desktop, tablette, mobile',
  },
  {
    title: 'Périodicité',
    icon: CalendarLined,
    description: periodicity,
  },
  {
    title: 'Format',
    icon: FillsRegular,
    description: formatToDisplay,
  }]

  if (projectType) {
    tableInformation.splice(1, 0, {
      title: 'Type de projet',
      icon: DocumentLined,
      description: projectType,
    })
  }

  return (
    <ResumeCard
      testId="displayContentSummary"
      loading={loading}
      broadcastMediums={logos}
      budgetInformation={budgetInformation}
      productName={productName}
      tableInformation={tableInformation}
      locations={locations}
      isProposal={isProposal}
    />
  )
}

export default PerformanceSummaryCounts
