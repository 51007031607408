import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  expert360DataSelector,
  expert360RadiusSelector,
  setData,
} from '../../../redux/reducers/Expert360/expert360.reducer'

import { processCountAsync } from '../../../services/expert360Api'

import { SelectElementType } from '../../../types/select.type'
import { PlaceBo } from '../../../utils/myAdfactoryApi/swaggerApi'

export const useProductExpert360Header = () => {
  const dispatch = useDispatch()

  const data = useSelector(expert360DataSelector)
  const radius = useSelector(expert360RadiusSelector)

  const [errorBroadcastMediums, setErrorBroadcastMediums] =
    useState<boolean>(false)
  const [errorProjectTypes, setErrorProjectTypes] = useState<boolean>(false)
  const [showErrorLocalities, setShowErrorLocalities] = useState<boolean>(false)

  const promiseHandleEstimate = useRef<any>(undefined)

  const handleBroadcastMediums = (bm: SelectElementType[]) => {
    const values = bm.map((v: SelectElementType) => v.value)
    const broadcastMediums = data?.broadcastMediums?.map(
      (b: SelectElementType) => {
        const updatedB = { ...b }
        updatedB.checked = false
        if (values.includes(b.value)) {
          updatedB.checked = true
        }
        return updatedB
      }
    )

    const updatedData = { ...data, broadcastMediums }
    setErrorBroadcastMediums(false)
    dispatch(setData(updatedData))
  }

  const handleProjectTypes = (pts: SelectElementType[]) => {
    const values = pts.map((pt: SelectElementType) => pt.value)
    const projectTypes = data?.projectTypes?.map((p: SelectElementType) => {
      const updatedP = { ...p }
      updatedP.checked = false
      if (values.includes(p.value)) {
        updatedP.checked = true
      }
      return updatedP
    })

    const updatedData = { ...data, projectTypes }
    setErrorProjectTypes(false)
    dispatch(setData(updatedData))
  }

  const handleChangeLocations = (locations: PlaceBo[]) => {
    const updatedData = { ...data, locations }
    setShowErrorLocalities(false)
    dispatch(setData(updatedData))
  }

  const handleRadiusChange = (radiusIsZero = false) => {
    const updatedData = { ...data }
    // If radius is 0, we need to remove all localities but the one selected
    // Somehow when the radius is 0, the localities are not updated
    radiusIsZero && (updatedData.locations = [updatedData.locations[0]])
    dispatch(setData(updatedData))
  }

  const handleConfigErrors = () => {
    const errors = {
      locations: false,
      broadcastMediums: false,
      projectTypes: false,
    }

    // Config need to have at least one location
    if (!data?.locations || data?.locations?.length === 0)
      errors.locations = true
    if (
      !data?.broadcastMediums ||
      data?.broadcastMediums?.filter((b: SelectElementType) => b.checked)
        ?.length === 0
    )
      errors.broadcastMediums = true
    if (
      !data?.projectTypes ||
      data?.projectTypes?.filter((p: SelectElementType) => p.checked)
        ?.length === 0
    )
      errors.projectTypes = true

    return errors
  }

  const handleErrors = (errors: any) => {
    if (errors.locations) setShowErrorLocalities(true)
    if (errors.broadcastMediums) setErrorBroadcastMediums(true)
    if (errors.projectTypes) setErrorProjectTypes(true)

    if (errors.locations || errors.broadcastMediums || errors.projectTypes)
      return true
    return false
  }

  const handleProcess = () => {
    const configErrors = handleConfigErrors()
    const errors = handleErrors(configErrors)

    if (!errors) {
      promiseHandleEstimate.current = dispatch(
        processCountAsync({
          broadcastMediums:
            data?.broadcastMediums
              ?.filter((b: any) => b.checked)
              .map((b: any) => b.value) ?? undefined,
          clientId: data?.clientId,
          periodicity:
            data?.periodicity
              ?.filter((p: any) => p.checked)
              .map((p: any) => p.value)?.[0] ?? undefined,
          postalCodes:
            data?.locations?.map((locality: any) => locality.code) ?? undefined,
          projectTypes:
            data?.projectTypes
              ?.filter((pt: any) => pt.checked)
              .map((pt: any) => pt.value) ?? undefined,
          radius,
        })
      )
    }
  }

  /**
   * On Unmount, abort processCount if pending
   */
  useEffect(
    () =>
      // Abord api calls in pending...
      () =>
        promiseHandleEstimate.current?.abort(),
    [promiseHandleEstimate]
  )

  return {
    errorBroadcastMediums,
    errorProjectTypes,
    showErrorLocalities,
    handleConfigErrors,
    handleBroadcastMediums,
    handleChangeLocations,
    handleProjectTypes,
    handleRadiusChange,
    handleErrors,
    handleProcess,
  }
}
