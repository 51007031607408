import { createAsyncThunk } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import { hideNotification } from '../redux/reducers/Notifications/notifications.reducer'
import { ModesType } from '../types/Modes.type'
import { getMyAdFactoryClass } from '../utils/myAdfactoryApi/getMyAdFactory.utils'
import {
  MyAdFactoryClass,
  Xp360Req,
  Xp360Resp,
} from '../utils/myAdfactoryApi/swaggerApi'

export const fetchCatchmentZoneClient = createAsyncThunk(
  'expert360/fetchCatchmentZoneClient',
  async ({ clientId }: { clientId: string }, getState) => {
    const response = await getMyAdFactoryClass(getState).catchmentZone(clientId)
    return response
  }
)

export const getClientsList = async ({
  clientId,
  state,
}: {
  clientId: string
  state: string
}) => {
  const myAdFactoryClass = new MyAdFactoryClass(state)
  const response = await myAdFactoryClass.clientsAll(clientId)
  return response
}

export const processCountAsync = createAsyncThunk(
  'expert360/ProcessCount',
  async (
    {
      broadcastMediums,
      clientId,
      periodicity,
      postalCodes,
      projectTypes,
      // eslint-disable-next-line no-unused-vars
      mode,
      // eslint-disable-next-line no-unused-vars
      previous,
      market,
      radius,
    }: Xp360Req & { previous?: any; mode?: ModesType },
    getState
  ) => {
    const body: Xp360Req = {
      broadcastMediums,
      clientId,
      market,
      periodicity: periodicity,
      postalCodes,
      projectTypes,
      radius,
    }

    const response = await getMyAdFactoryClass(getState).processCount4(body)
    return response
  }
)

export const recordCountForExpert360Async = createAsyncThunk(
  'expert360/RecordCount',
  async (
    {
      adjustableOffer,
      brandContentAdjustableOffer,
      brandContentCommentary,
      client,
      displayBudget,
      externalURL,
      intensity,
      locality,
      localityNameByZipCode,
      mafId,
      mafProduct,
      mode,
      print,
      retargetingBudget,
      totalBudget,
      totalImpPurchased,
      xp360Req,
    }: Xp360Resp & { mode: ModesType; mafId: number },
    getState
  ) => {
    const body: Xp360Resp = {
      adjustableOffer,
      brandContentAdjustableOffer,
      brandContentCommentary,
      client,
      displayBudget,
      externalURL,
      intensity,
      locality,
      localityNameByZipCode,
      mafProduct,
      print,
      retargetingBudget,
      totalBudget,
      totalImpPurchased,
      xp360Req,
    }
    let response = null
    if (mode === ModesType.Edit) {
      response = await getMyAdFactoryClass(getState).expert360(mafId, body)
    } else {
      response = await getMyAdFactoryClass(getState).recordCount4(body)
    }

    setTimeout(() => {
      getState.dispatch(hideNotification())
    }, 5000)
    return response
  }
)
