import { createAsyncThunk } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import { hideNotification } from '../redux/reducers/Notifications/notifications.reducer'

import { ModesType } from '../types/Modes.type'
import { getMyAdFactoryClass } from '../utils/myAdfactoryApi/getMyAdFactory.utils'
import { EmailingReq, EmailingResp } from '../utils/myAdfactoryApi/swaggerApi'

export const getSelectableValuesAsync = createAsyncThunk(
  'emailing/GetSelectableValues',
  async (currentMarket: string, getState) => {
    const response =
      await getMyAdFactoryClass(getState).getSelectableValues2(currentMarket)
    return response
  }
)

export const processCountAsync = createAsyncThunk(
  'emailing/ProcessCount',
  async (
    {
      baseComptage,
      targetings,
      locationZipCode,
      productTypes,
      price,
      numberOfPieces,
      dateSent,
      market,
      radius,
    }: EmailingReq,
    getState
  ) => {
    const body: EmailingReq = {
      baseComptage,
      targetings,
      locationZipCode,
      productTypes,
      price,
      numberOfPieces,
      dateSent,
      market,
      radius,
    }
    const response = await getMyAdFactoryClass(getState).processCount3(body)
    return response
  }
)

export const recordCountAsync = createAsyncThunk(
  'emailing/RecordCount',
  async (
    {
      countName,
      mafProduct,
      emailingNeufReq,
      localityNameByZipCode,
      bySiteGroup,
      productionFee,
      allEmailsAvailable,
      mode,
      mafId,
    }: EmailingResp & { mode: ModesType; mafId: number },
    getState
  ) => {
    const body: EmailingResp = {
      countName,
      mafProduct,
      emailingNeufReq,
      localityNameByZipCode,
      bySiteGroup,
      productionFee,
      allEmailsAvailable,
    }
    let response = null

    if (mode === ModesType.Edit) {
      response = await getMyAdFactoryClass(getState).emailing(mafId, body)
    } else {
      response = await getMyAdFactoryClass(getState).recordCount3(body)
    }
    setTimeout(() => {
      getState.dispatch(hideNotification())
    }, 5000)
    return response
  }
)
