import React from 'react'
import styled from 'styled-components'

import LogosBroadcastMediums from '../../../Logo/LogosBroadcastMediums/LogosBroadcastMediums'
import { SiteTableBroadcasts, SiteTableBudget, SiteTableNbMails, SiteTableNbMailsInput, SiteTableRow } from './styled'

import { respondTo } from '../../../../theme/mixins/respondTo'

import { BroadcastMedium } from '../../../../utils/myAdfactoryApi/swaggerApi'

const SiteTableLoad = ({ sites, ligne } : {sites : BroadcastMedium[] | undefined, ligne: string }): React.ReactElement => {
  let logos
  if (ligne === 'seloger') {
    logos = sites?.filter((site) => site === BroadcastMedium.SELOGER || site === BroadcastMedium.SELOGERNEUF)
  }
  if (ligne === 'logicimmo') {
    logos = sites?.filter((site) => site === BroadcastMedium.LOGICIMMO || site === BroadcastMedium.LOGICIMMONEUF)
  }
  return (
    <SiteTableRow>
      <SiteTableBroadcasts>
        <LogosBroadcastMediums sites={logos} />
      </SiteTableBroadcasts>
      <div data-testid="loadingBarsAvailable">
        <FirstBarLoading />
        <SecondBarLoading data-testid="secondBarLoading" />
      </div>
      <SiteTableNbMails>
        <SiteTableNbMailsInput>
          <LoadingBarInput data-testid="loadingBarInput" />
        </SiteTableNbMailsInput>
      </SiteTableNbMails>
      <SiteTableBudget>
        <LoadingBarsBudget data-testid="loadingBarsBudget" />
      </SiteTableBudget>
    </SiteTableRow>
  )
}

const FirstBarLoading = styled.div`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: lazyLoadTableCustom;
  animation-timing-function: linear;
  background: ${(props) => `1px solid ${props.theme.global.colorPalette.lighterSaphir}`} ;
  background-size: 8em 1.4em;
  background-image: ${(props) => `linear-gradient(
    to right,
    ${props.theme.global.colorPalette.lighterSaphir} 0%,
    #f7f8ff 20%,
    ${props.theme.global.colorPalette.lighterSaphir} 40%,
    ${props.theme.global.colorPalette.lighterSaphir} 100%
  );`} ;
  width: 4.313em;
  height: 1.375em;
  border-radius: .25em;
  margin-bottom: .25em;
`

const SecondBarLoading = styled.div`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: lazyLoadTableCustom;
  animation-timing-function: linear;
  background: ${(props) => `1px solid ${props.theme.global.colorPalette.lighterSaphir}`} ;
  background-size: 8em 1.4em;
  background-image: ${(props) => `linear-gradient(
    to right,
    ${props.theme.global.colorPalette.lighterSaphir} 0%,
    #f7f8ff 20%,
    ${props.theme.global.colorPalette.lighterSaphir} 40%,
    ${props.theme.global.colorPalette.lighterSaphir} 100%
  );`} ;
  width: 6em;
  height: 1em;
  border-radius: .25em;

  ${respondTo.md`
    width: 7.5em;
  `}
`

const LoadingBarInput = styled.div`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: lazyLoadTableCustom;
  animation-timing-function: linear;
  background: ${(props) => props.theme.global.colorPalette.lighterSaphir} ;
  background-size: 15.75em 2.69em;
  background-image: ${(props) => `linear-gradient(
    to right,
    ${props.theme.global.colorPalette.lighterSaphir} 0%,
    #f7f8ff 20%,
    ${props.theme.global.colorPalette.lighterSaphir} 40%,
    ${props.theme.global.colorPalette.lighterSaphir} 100%
  );`};
  width: 15.75em;
  height: 2.7em;
  border-radius: .25em;
  margin-bottom: 1.375em;
`

const LoadingBarsBudget = styled.div`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: lazyLoadTableCustom;
  animation-timing-function: linear;
  background: ${(props) => props.theme.global.colorPalette.lighterSaphir} ;
  background-size: 15.75em 2.69em;
  background-image: ${(props) => `linear-gradient(
    to right,
    ${props.theme.global.colorPalette.lighterSaphir} 0%,
    #f7f8ff 20%,
    ${props.theme.global.colorPalette.lighterSaphir} 40%,
    ${props.theme.global.colorPalette.lighterSaphir} 100%
  );`};
  width: 15.75em;
  height: 2.7em;
  border-radius: .25em;
  margin-bottom: 1.375em;
`

export default SiteTableLoad
