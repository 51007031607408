export enum productNamesType {
  Expert360 = 'Expert 360°',
  MasterDisplay = 'Master Display',
  VedettePlus = 'Vedette +',
  EmailingOptin = 'Emailing - Optin',
  EmailingAlerte = 'Emailing - Alerte',
  EmailingOptinConstruire = 'Emailing - Optin',
  EmailingAlerteConstruire = 'Emailing - Alerte',
  MasterDisplayNeuf = 'Display - Impression',
  DisplayLuxury = 'Display - Luxury',
  DisplayConstruire = 'Display - Impression',
  DisplayBureauxEtCommerce = 'Display - Impression',
  DisplayBureauxCommerce = 'Display - Impression',
  DisplayPerformance = 'Display - Performance'
}
