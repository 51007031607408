import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import EmailingHeader from '../../../components/Products/Emailing/EmailingHeader/EmailingHeader'
import EmailingCount from '../../../components/Products/Emailing/EmailingCount/EmailingCount'

import { emailingErrorsSelector } from '../../../redux/reducers/Emailing/emailing.reducer'

import { useProductEmailingData } from '../../../hooks/products/emailing/useProductEmailingData'

type EmailingWrapperProps = {
  children: React.ReactNode,
  dataTestId: string,
}

const EmailingWrapper = ({ children, dataTestId }: EmailingWrapperProps): React.ReactElement => (
  <EmailingWrapperStyled data-testid={dataTestId}>
    <EmailingHeader />
    {children}
  </EmailingWrapperStyled>
)

const EmailingErrorComp = (): React.ReactElement => (
  <EmailingWrapper dataTestId="emailingError">
    <EmailingError>
      <p>Une erreur est survenue</p>
    </EmailingError>
  </EmailingWrapper>
)

const Emailing = (): React.ReactElement => {
  const { id }: { id: string } = useParams()
  const emailingErrors: any = useSelector(emailingErrorsSelector)

  useProductEmailingData(id)

  if (emailingErrors) {
    return <EmailingErrorComp />
  }

  return (
    <EmailingWrapper dataTestId="emailing">
      <EmailingCount />
    </EmailingWrapper>
  )
}

// Styled component
const EmailingWrapperStyled = styled.div`
  padding-top: 14em;
  background-color: ${(props) => props.theme.global.colorPalette.white};
`

const EmailingError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

export default Emailing
