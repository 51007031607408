import React from 'react'
import styled, { css } from 'styled-components'

type InputProps = {
  label: string,
  content: string[] | string,
  noBorder: boolean,
}

const Input = ({ label, content, noBorder }: InputProps): React.ReactElement => (
  <InputStyled $border={!noBorder}>
    <span>{label}</span>
    <InputContent>
      <p>{content}</p>
    </InputContent>
  </InputStyled>
)

type InputStyledProps = {
  $border?: boolean,
}

const InputStyled = styled.div<InputStyledProps>`
  ${(props: any) => props.$doubleViewHeight && css`
  border-bottom: ${(p) => `1px solid ${p.theme.global.colorPalette.greyLight}`};
  `}

  > span{
    font-size: 0.875em;
    font-weight: 600;
  }
`

const InputContent = styled.div`
  margin-top: 0.375em;
  margin-bottom: 0.8em;

  p   {
    width: 100%;
    font-size: 1em;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space : nowrap;
    overflow : hidden;
}
`

export default Input
