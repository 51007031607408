export const getEnv = (): string => {
  const hostname = window?.location?.hostname
  switch (hostname) {
    case 'myadfactory.seloger.com':
      return 'production'
    case 'myadfactory-stage.dignp.com':
      return 'stage'
    case 'myadfactory-int1.dignp.com':
      return 'int1'
    case 'myadfactory-int2.dignp.com':
      return 'int2'
    case 'myadfactory-int3.dignp.com':
      return 'int3'
    default:
      return 'localhost'
  }
}

export const getBackendHost = () => {
  const hostname = window?.location?.hostname
  let backendHost
  if (hostname === 'myadfactory.seloger.com') {
    backendHost =
      'https://myadfactory-api.production.myadfactory.svc.groupe-seloger.com'
  } else if (hostname === 'myadfactory-stage.dignp.com') {
    backendHost = 'https://myadfactory-api.staging.myadfactory.eks.dignp.com'
  } else if (
    hostname === 'myadfactory-int1.dignp.com' ||
    hostname === 'myadfactory-int2.dignp.com' ||
    hostname === 'myadfactory-int3.dignp.com'
  ) {
    backendHost =
      'https://myadfactory-api.integration.myadfactory.eks.dignp.com'
  } else {
    backendHost =
      process.env.REACT_APP_BACKEND_HOST ??
      'https://myadfactory-api.staging.myadfactory.eks.dignp.com'
  }
  return backendHost
}
