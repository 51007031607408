import React from 'react'
import { BudgetLoadingSpan, DescriptionLoading, HeaderIntensity, IntensityInfoUl, Expert360IntensityCardStyled } from '../styled'

const Expert360IntensityCardLoading = (): React.ReactElement => (
  <Expert360IntensityCardStyled $available={false}>
    <HeaderIntensity>
      <BudgetLoadingSpan />
    </HeaderIntensity>
    <IntensityInfoUl data-testid="intensityInfo">
      <li>
        <DescriptionLoading data-testid="descriptionLoading" />
      </li>
      <li>
        <DescriptionLoading data-testid="descriptionLoading" />
      </li>
      <li>
        <DescriptionLoading data-testid="descriptionLoading" />
      </li>
    </IntensityInfoUl>
  </Expert360IntensityCardStyled>
)

export default Expert360IntensityCardLoading
