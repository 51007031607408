import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { AddLined, EuroLined } from '@ui-library/icons'

import Range from '@ui-library/core/Range'

import { emailingDataSelector, emailingResSelector, setData } from '../../redux/reducers/Emailing/emailing.reducer'

interface IRangePriceProps {
  mode: string,
  rangeTitle?: string,
  suiteTitle?: string,
  isRangeUpdated: boolean,
  setIsRangeUpdated: (value: boolean) => void,
  isResetClicked?: boolean,
  setIsResetClicked: (value: boolean) => void,
}

const RangeComponent = ({ mode, rangeTitle, suiteTitle, isRangeUpdated, setIsRangeUpdated, isResetClicked, setIsResetClicked } : IRangePriceProps): React.ReactElement => {
  const dispatch = useDispatch()
  const data = useSelector(emailingDataSelector)
  const res = useSelector(emailingResSelector)

  const DEFAULT_VARIABLES: any = {
    price: {
      min: data?.options?.minPrice,
      max: data?.options?.maxPrice,
    },
    nbPiece: {
      min: data?.options?.minNbPieces,
      max: data?.options?.maxNbPieces,
    },
  }

  const { min } = DEFAULT_VARIABLES[mode]
  const { max } = DEFAULT_VARIABLES[mode]

  const setOptionsMin = (value: any) => {
    if (isRangeUpdated) {
      if (mode === 'price') {
        dispatch(setData({
          ...data,
          options: {
            ...data?.options ?? {},
            minPrice: value,
          },
        }))
      } else {
        dispatch(setData({
          ...data,
          options: {
            ...data?.options ?? {},
            minNbPieces: value,
          },
        }))
      }
    }
  }

  const setOptionsMax = (value: any) => {
    if (isRangeUpdated) {
      if (mode === 'price') {
        dispatch(setData({
          ...data,
          options: {
            ...data?.options ?? {},
            maxPrice: value,
          },
        }))
      } else {
        dispatch(setData({
          ...data,
          options: {
            ...data?.options ?? {},
            maxNbPieces: value,
          },
        }))
      }
    }
  }

  /**
   * SETTING DEFAULT VALUES
   */
  useEffect(() => {
    if (mode === 'price') {
      const newMin = !isRangeUpdated && res?.countData?.emailingNeufReq?.price ? res?.countData?.emailingNeufReq?.price?.min : data?.options.minPrice
      const newMax = !isRangeUpdated && res?.countData?.emailingNeufReq?.price ? res?.countData?.emailingNeufReq?.price?.max : data?.options.maxPrice
      setOptionsMin(newMin)
      setOptionsMax(newMax)
    } else {
      const newMin = !isRangeUpdated && res?.countData?.emailingNeufReq?.numberOfPieces ? res?.countData?.emailingNeufReq?.numberOfPieces?.min : data?.options.minNbPieces
      const newMax = !isRangeUpdated && res?.countData?.emailingNeufReq?.numberOfPieces ? res?.countData?.emailingNeufReq?.numberOfPieces?.max : data?.options.maxNbPieces
      setOptionsMin(newMin)
      setOptionsMax(newMax)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Reset
   */
  useEffect(() => {
    if (isResetClicked) {
      if (mode === 'price') {
        setOptionsMin(DEFAULT_VARIABLES.price.min)
        setOptionsMax(DEFAULT_VARIABLES.price.max)
      } else {
        setOptionsMin(DEFAULT_VARIABLES.nbPiece.min)
        setOptionsMax(DEFAULT_VARIABLES.nbPiece.max)
      }
      setIsResetClicked(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetClicked, setIsResetClicked])

  const classPlus = () => {
    if (max === 5) return AddLined
    return undefined
  }

  return (
    <RangeComponentWrapper>
      <RangeComponentTitle>{rangeTitle} <span>{suiteTitle}</span></RangeComponentTitle>
      <Range
        inputsSuffix={mode === 'price' ? EuroLined : classPlus()}
        min={1}
        currentMin={min}
        onMinChange={(v : number) => {
          setIsRangeUpdated(true)
          setOptionsMin(v)
        }}
        max={mode === 'price' ? 10000000 : 5}
        currentMax={max}
        onMaxChange={(v : number) => {
          setIsRangeUpdated(true)
          setOptionsMax(v)
        }}
        className={`range${mode}`}
        gap={mode === 'price' ? 100000 : 1}
        inputMinLabel={mode === 'price' ? 'Min' : 'Nb pièces min'}
        inputMaxLabel={mode === 'price' ? 'Max' : 'Nb pièces max'}
      />
    </RangeComponentWrapper>
  )
}

const RangeComponentWrapper = styled.div`
  margin: 0 0 2em;
`

const RangeComponentTitle = styled.p`
  margin-bottom: 0.813em;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875em;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 0.875em;
    color: ${(props) => props.theme.global.colorPalette.grey};
  }
`

export default RangeComponent
