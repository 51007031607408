import { Icon, useClickOutside } from '@ui-library/core'
import { PinFilled } from '@ui-library/icons'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  AutoComplete,
  AutoCompleteDisplayInput,
  AutoCompleteInput,
  AutoCompleteLabel,
  AutoCompleteList,
  AutoCompletePlaceholder,
  AutoCompletePlaceholderStrong,
  AutoCompleteWrapperInput,
  AutoCompleteWrapperPlaceholder,
  MyList,
  ResultList,
  Shadow,
  ShadowMask,
  SpanIcon,
  TextError,
  TitleCategoryList,
} from '../styled'
import InfosMessage from './InfosMessage/InfosMessage'
import ItemList from './ItemList/ItemList'
import ItemListLoader from './ItemList/ItemListLoader'
import Tag from './Tag/Tag'

import useDebounce from '../../../hooks/useDebounce'

import { getPlacesLocality } from '../../../services/placesApi'
import { PlaceBo } from '../../../utils/myAdfactoryApi/swaggerApi'
import RadiusExtendLocality from './RadiusExtendLocality/RadiusExtendLocality'

type AutocompleteMultipleProps = {
  catchmentZone: PlaceBo[] | undefined
  maximumItems: number
  label: string
  placeholder: string
  completingWord: string
  titleList: string
  otherSuggestionsText: string
  error: boolean
  textError: string
  noResultFound: string
  autocompleteCallback: (value: Array<PlaceBo>) => void
  persistRadius: (value: number) => void
  onRadiusChange?: (changed: boolean, isZero: boolean) => void
  initDataLocality: PlaceBo[] | undefined
  disabled?: boolean
  forceUpdate?: boolean
  radius: number
}

export const lowercaseLocality = (localityName: string) =>
  localityName
    .toLowerCase()
    .replace(/-/g, ' ')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

export const SharedMultipleLocalities = (
  inputValue: string,
  locality: PlaceBo[],
  results: PlaceBo[]
) => {
  const isPostalCode = !Number.isNaN(parseInt(inputValue))
  const inputLocality = lowercaseLocality(inputValue)
  const localityFilter = locality.filter(
    (currentValue) => currentValue.code === results[0].code
  )

  let r = ''
  let breakLoop = false

  if (results?.length > 0) {
    results.forEach((result) => {
      const lowercaseResults = lowercaseLocality(result.name)
      if (
        localityFilter.length >= 2 &&
        !isPostalCode &&
        inputLocality === lowercaseResults &&
        !breakLoop
      ) {
        r = `Le code postal de ${result.name} (${result.code}) regroupe plusieurs communes. 
          La sélection de ${result.name} sélectionnera automatiquement toutes les communes de ce code postal.`
        breakLoop = true
      }
    })
  }
  return r
}

export const copyPasteLocalities = (
  zipcodes: string,
  regex: RegExp,
  myList: PlaceBo[]
) => {
  const zipCodeFromInput = zipcodes
    .split(regex)
    .filter((s: string) => s && s.length <= 5 && !Number.isNaN(Number(s)))
  const pasteLocalities = zipCodeFromInput.map((code: string) => {
    return { code, id: '', name: '' }
  })

  const mergedLocalitiesList: PlaceBo[] = [...myList, ...pasteLocalities]
  // remove duplicates zipcode
  const updatedLocalitiesList = mergedLocalitiesList.filter(
    (value: PlaceBo, index: number, a: PlaceBo[]) =>
      a.findIndex((nextValue) => nextValue.code === value.code) === index
  )

  return updatedLocalitiesList
}

const AutocompleteMultiple = ({
  catchmentZone,
  maximumItems,
  label,
  placeholder,
  completingWord,
  titleList,
  otherSuggestionsText,
  error,
  textError,
  noResultFound,
  autocompleteCallback,
  persistRadius,
  onRadiusChange,
  initDataLocality,
  disabled = false,
  forceUpdate = false,
  radius,
}: AutocompleteMultipleProps) => {
  const autocompleteListRef = useRef<HTMLInputElement | null>(null)
  const [suggestions, setSuggestions] = useState<PlaceBo[] | null>(null)
  const [errorAutocomplete, setErrorAutocomplete] = useState<boolean>(false)
  const [errorTooManyItems, setErrorTooManyItems] = useState<boolean>(false)
  const [errorTooManyItemsTimeout, setErrorTooManyItemsTimeout] =
    useState<boolean>(false)
  const [isAutocompleteFocus, setIsAutocompleteFocus] = useState<boolean>(false)
  const [myList, setMyList] = useState<PlaceBo[]>([])
  const [isAutocompleteListOpen, setIsAutocompleteListOpen] =
    useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [isMouseDownOnInput, setIsMouseDownOnInput] = useState<boolean>(false)
  const [infosMessage, setInfosMessage] = useState<string>('')
  const MAX_ITEMS = maximumItems
  const previousInitDataLocality = useRef<PlaceBo[] | undefined>(undefined)

  // Regex separators: ',', ';', ' ', '|'
  const copyPasteSeparatorsRegex: RegExp = /[| ,;]/

  const dispatch = useDispatch()
  //-----------------------------------------------------------------------
  // Fetch data with debounce method
  const debouncedQuery = useDebounce(inputValue, 200)

  useEffect(() => {
    if (initDataLocality?.length === 0 || !initDataLocality) setMyList([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate])

  useEffect(() => {
    if (
      initDataLocality &&
      initDataLocality.length > 0 &&
      JSON.stringify(previousInitDataLocality.current) !==
        JSON.stringify(initDataLocality)
    ) {
      previousInitDataLocality.current = initDataLocality
      setMyList(initDataLocality)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initDataLocality])

  useEffect(() => {
    if (debouncedQuery && debouncedQuery !== undefined) {
      setInfosMessage('')

      const debouncedQueryContainsSeparators: boolean =
        copyPasteSeparatorsRegex.test(debouncedQuery)
      if (debouncedQueryContainsSeparators) {
        // ZipCodes copy/paste
        const pasteLocalitiesArray = copyPasteLocalities(
          debouncedQuery,
          copyPasteSeparatorsRegex,
          myList
        )
        setMyList(pasteLocalitiesArray)
        autocompleteCallback(pasteLocalitiesArray)
        setInputValue('')
      } else if (debouncedQuery.length >= 2) {
        // Default way to find localities
        dispatch(getPlacesLocality({ locality: debouncedQuery }))?.then(
          (results: any) => {
            const localitiesResults = results?.payload
            const getPlacesError = results?.error // when the payload is undefined and error object is set on getPlacesLocality

            if (getPlacesError) {
              setSuggestions([])
            } else if (
              !Number.isNaN(parseInt(debouncedQuery)) &&
              localitiesResults.length > 1 &&
              localitiesResults[0].code !== undefined
            ) {
              setSuggestions(
                [
                  {
                    id: `touteslesvilles${localitiesResults[0].code}`,
                    code: localitiesResults[0].code,
                    name: 'Toutes les villes',
                  },
                ].concat(localitiesResults)
              )
            } else {
              setSuggestions(localitiesResults)
            }

            const checkNotEmptyArray =
              Array.isArray(localitiesResults) && localitiesResults.length
            checkNotEmptyArray &&
              dispatch(
                getPlacesLocality({ locality: localitiesResults[0].code })
              ).then((localitiesShared: any) => {
                const PostalCodeCommunales = localitiesShared.payload
                const multipleLocalities = SharedMultipleLocalities(
                  inputValue,
                  PostalCodeCommunales,
                  localitiesResults
                )
                setInfosMessage(multipleLocalities)
              })
          }
        )
      }
    } else {
      setSuggestions(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery, getPlacesLocality])

  //-----------------------------------------------------------------------
  // Check value of inputValue and myList, to close autocomplete if needed

  useEffect(() => {
    if (inputValue.length < 2 && myList.length === 0) {
      closeAutocompleteList()
    }
  }, [inputValue, myList, isAutocompleteListOpen])

  //-----------------------------------------------------------------------
  // Update state autocomplete error depending on props

  useEffect(() => {
    if (error) {
      setErrorAutocomplete(true)
    } else {
      setErrorAutocomplete(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const defaultItemsToMyList: any = catchmentZone?.map(
    (catchmentAreas: PlaceBo) => {
      return {
        code: catchmentAreas.code,
        name: catchmentAreas.name,
      }
    }
  )

  useEffect(() => {
    if (catchmentZone !== undefined) {
      autocompleteCallback(defaultItemsToMyList)
      setMyList(defaultItemsToMyList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catchmentZone])

  //-----------------------------------------------------------------------
  // Add EventListener/function to track mouseClick and set

  const mouseDownOnInput = () => {
    setIsMouseDownOnInput(true)
  }
  const mouseUpOnDocument = () => {
    setIsMouseDownOnInput(false)
  }

  useEffect(() => {
    const autocompleteRef = autocompleteListRef.current
    if (autocompleteListRef && autocompleteRef) {
      autocompleteRef?.addEventListener('mousedown', mouseDownOnInput)
      document.addEventListener('mouseup', mouseUpOnDocument)
    }

    return () => {
      autocompleteRef?.removeEventListener('mousedown', mouseDownOnInput)
      document.removeEventListener('mouseup', mouseUpOnDocument)
    }
  }, [])

  //-----------------------------------------------------------------------
  // Input's interaction method

  const inputFocus = () => {
    setIsAutocompleteFocus(true)
    if (myList.length !== 0 || inputValue.length >= 2) {
      openAutocompleteList()
    }
    !isAutocompleteListOpen && setInputValue('')
  }

  const inputFocusOut = () => {
    if (!isMouseDownOnInput) {
      setIsAutocompleteFocus(false)
      closeAutocompleteList()
    }
  }

  const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    if (event.target.value.length >= 2) {
      setSuggestions(null)
      openAutocompleteList()
    }
  }

  //-----------------------------------------------------------------------
  // Define Autocomplete Comportment state / Open or close

  const openAutocompleteList = () => {
    setIsAutocompleteListOpen(true)
  }
  const closeAutocompleteList = () => {
    setIsAutocompleteListOpen(false)
  }

  // ----------------------------------------------------------------------
  // Reset locality radius
  const resetLocalityRadius = () => {
    if (persistRadius) {
      persistRadius(0) // Reset radius by calling the parent function with 0
    }
  }

  //-----------------------------------------------------------------------
  // Add Items to MyList

  const addItemsToMyList = (elem: PlaceBo) => {
    if (MAX_ITEMS === 0 || myList.length < MAX_ITEMS) {
      const newMyList = [...myList, elem]
      setMyList(newMyList)
      autocompleteCallback(newMyList)
      setInfosMessage('')
    } else {
      setErrorTooManyItems(true)
      setErrorTooManyItemsTimeout(true)
      setIsAutocompleteFocus(false)
      closeAutocompleteList()
    }

    resetLocalityRadius()
  }

  useEffect(() => {
    setErrorTooManyItemsTimeout(false)
    const timeout = setTimeout(() => setErrorTooManyItems(false), 4000)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorTooManyItemsTimeout])

  //-----------------------------------------------------------------------
  // remove items to MyList

  const removeItemsToMyList = (elem: PlaceBo) => {
    const newMyList = myList.filter((value) => value !== elem)
    setMyList(newMyList)
    autocompleteCallback(newMyList)
    if (newMyList.length === 0 && inputValue.length < 2) {
      setIsAutocompleteFocus(false)
    }
  }

  const removeAllItemsToMyList = () => {
    const newMyList: PlaceBo[] = []
    setMyList(newMyList)
    autocompleteCallback(newMyList)
    resetLocalityRadius()

    if (inputValue.length < 2) {
      setIsAutocompleteFocus(false)
    }
  }

  //-----------------------------------------------------------------------
  // CustomHooks from Ui library / Close Select when user click outside of select

  useClickOutside([autocompleteListRef], inputFocusOut)

  return (
    <AutoComplete data-testid="autocompleteMultiple">
      <AutoCompleteLabel>{label}</AutoCompleteLabel>
      <div ref={autocompleteListRef}>
        <AutoCompleteWrapperInput
          $error={errorAutocomplete || errorTooManyItems}
        >
          <SpanIcon>
            <Icon icon={PinFilled} />
          </SpanIcon>
          <AutoCompleteDisplayInput
            $error={errorAutocomplete || errorTooManyItems}
            $displayInputHide={isAutocompleteFocus}
            $noItemsInMyList={myList.length !== 0}
          >
            {myList.length === 0 && (
              <AutoCompleteWrapperPlaceholder>
                <span>{placeholder}</span>
              </AutoCompleteWrapperPlaceholder>
            )}
            {myList.length === 1 && (
              <AutoCompleteWrapperPlaceholder>
                <AutoCompletePlaceholder>
                  <AutoCompletePlaceholderStrong>
                    {myList[0].code}
                  </AutoCompletePlaceholderStrong>
                </AutoCompletePlaceholder>
                <span>{otherSuggestionsText}</span>
              </AutoCompleteWrapperPlaceholder>
            )}
            {myList.length >= 2 && (
              <AutoCompleteWrapperPlaceholder>
                <AutoCompletePlaceholderStrong>
                  <AutoCompletePlaceholder>
                    {' '}
                    {myList[0].code}{' '}
                  </AutoCompletePlaceholder>{' '}
                  et {myList.length - 1} autres {completingWord}
                </AutoCompletePlaceholderStrong>
                <span> {placeholder}</span>
              </AutoCompleteWrapperPlaceholder>
            )}
          </AutoCompleteDisplayInput>
          <AutoCompleteInput
            data-testid="autocompleteInput"
            type="text"
            onFocus={inputFocus}
            onChange={(e) => inputChange(e)}
            value={inputValue}
            disabled={disabled}
          />
        </AutoCompleteWrapperInput>
        {isAutocompleteListOpen && (
          <AutoCompleteList>
            {inputValue && <InfosMessage messagecontent={infosMessage} />}
            {myList.length !== 0 && (
              <>
                <dl>
                  <TitleCategoryList>
                    <p>Ma recherche</p>
                    <span onClick={() => removeAllItemsToMyList()}>
                      Réinitialiser
                    </span>
                  </TitleCategoryList>
                  <ShadowMask />
                  <Shadow />
                  <MyList>
                    {myList.map((item) => (
                      <Tag
                        key={`myList-${item.code}-${item.id}`}
                        item={item}
                        removeItemsToMyList={(elem: PlaceBo) => {
                          removeItemsToMyList(elem)
                        }}
                      />
                    ))}
                  </MyList>
                </dl>
                {myList.length === 1 && myList[0].code.length === 5 && (
                  <RadiusExtendLocality
                    persistRadiusInReducer={persistRadius}
                    onRadiusChange={onRadiusChange}
                    radius={radius}
                  />
                )}
              </>
            )}
            {inputValue !== null && inputValue.length >= 2 && (
              <dl>
                <TitleCategoryList>
                  <p>{titleList}</p>
                </TitleCategoryList>
                <ShadowMask />
                <Shadow />
                <ResultList>
                  {suggestions !== null && suggestions.length === 0 ? (
                    <p>{noResultFound}</p>
                  ) : (
                    <>
                      {suggestions === undefined || suggestions === null ? (
                        <ItemListLoader />
                      ) : (
                        suggestions.map((item: PlaceBo) => (
                          <ItemList
                            item={item}
                            inputValue={inputValue}
                            myList={myList}
                            addItemsToMyList={(elem: PlaceBo) => {
                              addItemsToMyList(elem)
                            }}
                            key={item.id}
                          />
                        ))
                      )}
                    </>
                  )}
                </ResultList>
              </dl>
            )}
          </AutoCompleteList>
        )}

        <TextError>
          {errorAutocomplete ? textError : null}
          {!errorAutocomplete && errorTooManyItems
            ? `Vous ne pouvez pas sélectionner plus de ${MAX_ITEMS} ${completingWord}.`
            : null}
        </TextError>
      </div>
    </AutoComplete>
  )
}

export default AutocompleteMultiple
