import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button } from '@ui-library/core'

import mapBackground from '../../../assets/images/map_background.jpg'

import ProposalHeader from '../../../components/Header/HeaderProposal/HeaderProposal'
import EmailingSummaryCount from '../../../components/Products/Emailing/EmailingSummaryCount/EmailingSummaryCount'
import ContactsByBroadcastTable from '../../../components/Tables/ContactsByBroadcastTable/ContactsByBroadcastTable'
import Logo from '../../../components/Logo/Logo'

import { emailingDataSelector, emailingErrorsSelector, emailingResSelector } from '../../../redux/reducers/Emailing/emailing.reducer'

import { useProductEmailingData } from '../../../hooks/products/emailing/useProductEmailingData'
import { formatDate } from '../../../utils/date'
import { respondTo } from '../../../theme/mixins/respondTo'

const EmailingProposal = (): React.ReactElement | null => {
  const { id }: { id: string } = useParams()

  const displayErrors: any = useSelector(emailingErrorsSelector)
  const data = useSelector(emailingDataSelector)
  const res = useSelector(emailingResSelector)

  useProductEmailingData(id)

  window.onbeforeprint = () => {
    document.title = `MAF-${res?.mafId}`
  }

  window.onafterprint = () => {
    document.title = 'MyAdFactory - Groupe SeLoger'
  }

  if (res && Object.keys(res).length === 0) return null
  if (res?.countData && Object.keys(res.countData).length === 0) return null

  if (displayErrors) {
    return (
      <div data-testid="proposalEmailingError">
        <ProposalWrapper>
          <ProposalDisplayError>
            <p>Une erreur est survenue</p>
          </ProposalDisplayError>
        </ProposalWrapper>
      </div>
    )
  }

  return (
    <div data-testid="proposalEmailing">
      <ProposalHeader mafId={res?.mafId} />
      <ProposalWrapper>
        <ProposalMap style={{ backgroundImage: `url(${mapBackground})` }} />
        <ProposalContainer>
          <ProposalSummaryBlock>
            <EmailingSummaryCount isProposal />
          </ProposalSummaryBlock>
        </ProposalContainer>
        <ProposalContainer>
          <h1>Bonjour,</h1>
          <p>Vous trouverez ci-dessous le plan média répondant aux besoins évoqués ensemble. Nous sommes à votre écoute pour toute question complémentaire.</p>
          <ProposalResult>
            <ContactsByBroadcastTable countData={res?.countData ?? {}} loading="idle" logos={data?.logos} isProposal />
            <ProposalGenerate>
              <p>Générez un PDF de la proposition commerciale pour le partager à votre client. Vous pouvez également télécharger les spécifications techniques des différents formats d'affichage publicitaire.</p>
              <Button
                testID="pdfButton"
                size="medium"
                kind="primary"
                tag="button"
                variant="normal"
                onClick={() => { window.print() }}
              >
                Télécharger le PDF
              </Button>
            </ProposalGenerate>
            <ProposalFooter>
              <p>Proposition réalisée le {formatDate(new Date(res?.createdDate))} par</p>
              <span><Logo /></span>
            </ProposalFooter>
          </ProposalResult>
        </ProposalContainer>
      </ProposalWrapper>
    </div>
  )
}

const ProposalWrapper = styled.div`
  position: relative;
`

const ProposalMap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 30em;
  background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
  background-size: cover;
`

const ProposalSummaryBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  height: 35em;
  margin-bottom: 3em;

  @media print {
    margin-bottom: 1em;
  }
`

const ProposalContainer = styled.div`
  padding: 0em 6em 2.5em 6em;

  ${respondTo.lg`
    padding: 0em 10em 2.5em 10em;
  `}

  h1 {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: .5em;
  }

  > p {
    margin-bottom: 4em;
  }

  @media print {
    h1 {
      font-size: 1.4em;
    }

    > p {
      margin-bottom: 2em;
    }
  }
`
const ProposalDisplayError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

const ProposalResult = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`

const ProposalGenerate = styled.div`
  margin-top: 4em;
  text-align: center;

  p {
    font-size: 1.2em;
    margin-bottom: 2em;
  }

  button {
    display: block;
    margin: 0 auto;
    margin-bottom: 2em;
  }

  @media print {
    display: none;
  }
`

const ProposalFooter = styled.div`
  margin-top: 4em;
  text-align: center;
  color: ${(props) => props.theme.global.colorPalette.grey};

  > span {
    display: block;
    margin: 1em auto;
    max-width: 150px;
  }
`

export default EmailingProposal
