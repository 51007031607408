import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Icon from '@ui-library/core/Icon'
import { LoaderRegular } from '@ui-library/icons'
import countLoading from '../../../assets/images/bloc-calculette@2x.png'

import TableHeadMyCounts from './TableHead/TableHeadMyCounts'
import TableHeadUsers from './TableHead/TableHeadUsers'
import ActionButtonsMenu from './TableRow/ActionButtonsMenu'
import TableRowMyCounts from './TableRow/TableRowMyCounts'
import TableRowUsers from './TableRow/TableRowUsers'

import {
  allCountsDataSelector,
  loadingApiSelector as loadingApiCountsSelector,
  myCountsDataSelector,
  rowSelectedSelector,
  setShowPopin,
} from '../../../redux/reducers/Page/Counts/pageCounts.reducer'
import {
  loadingApiSelector as loadingApiUsersSelector,
  rowSelectedSelector as rowSelectedUsersSelector,
  usersDataSelector,
} from '../../../redux/reducers/Page/Users/pageUsers.reducer'
import {
  currentMarketSelector,
  userIdSelector,
} from '../../../redux/reducers/UserConfig/userConfig.reducer'

import { getAllCounts, getMyCounts } from '../../../services/countApi'

import { ITEMS_PER_PAGE } from '../../../utils/variables'

import { getAllUsers } from '../../../services/userApi'
import {
  CountBo,
  Market,
  UserBo,
} from '../../../utils/myAdfactoryApi/swaggerApi'
import OverlayDeleteCount from '../../Overlay/OverlayDeleteCount/OverlayDeleteCount'
import OverlayEditProfil from '../../Overlay/OverlayEditUser/OverlayEditUser'

type BodyListProps = {
  col: any
  currentPage: number
  mode: string
  setCol: any
  sortBy: { [key: string]: string }
  setSortBy: Dispatch<SetStateAction<{ [key: string]: string }>>
}

const BodyList = ({
  col,
  currentPage,
  mode,
  setCol,
  sortBy,
  setSortBy,
}: BodyListProps): React.ReactElement => {
  const dispatch = useDispatch()

  const [actionButtonsMenuOpen, setActionButtonsMenuOpen] = useState(false)
  const [overlayEditUser, setOverlayEditUser] = useState(false)
  const [overlayDeleteCount, setOverlayDeleteCount] = useState(false)

  let loadingSelector: any
  let dataSelector: any
  switch (mode) {
    case 'myCounts':
      loadingSelector = loadingApiCountsSelector
      dataSelector = myCountsDataSelector
      break
    case 'allCounts':
      loadingSelector = loadingApiCountsSelector
      dataSelector = allCountsDataSelector
      break
    case 'users':
      loadingSelector = loadingApiUsersSelector
      dataSelector = usersDataSelector
      break
    default:
      break
  }

  const currentMarket: Market = useSelector(currentMarketSelector)
  const loading = useSelector(loadingSelector)
  const dataList: any = useSelector(dataSelector)
  const userId = useSelector(userIdSelector)
  const countsRowSelected = useSelector(rowSelectedSelector)
  const userRowSelected = useSelector(rowSelectedUsersSelector)
  const rowSelected =
    mode === 'myCounts' || mode === 'allCounts'
      ? countsRowSelected
      : userRowSelected

  const getData = (page: number, order?: string, sort?: string) => {
    const itemsPerPage = ITEMS_PER_PAGE
    const clientId = undefined
    const productName = undefined
    const market = currentMarket
    const clientName = undefined

    switch (mode) {
      case 'myCounts':
        dispatch(
          getMyCounts({
            page: page,
            itemsPerPage: ITEMS_PER_PAGE,
            clientId: userId,
            order: order,
            orderDirection: sort,
            market,
            searchCount: '',
          })
        )
        break
      case 'allCounts':
        dispatch(
          getAllCounts({
            page,
            itemsPerPage,
            clientId,
            productName,
            market,
            clientName,
            order,
            orderDirection: sort,
            searchCount: '',
          })
        )
        break
      case 'users':
        dispatch(
          getAllUsers({
            page,
            itemsPerPage: ITEMS_PER_PAGE,
            order: order,
            orderDirection: sort,
            searchUser: '', // We don't use redux userSearchSelector here because we don't need to use its value when we change the page
          })
        )
        break
      default:
        break
    }
  }

  const closePopin = () => {
    dispatch(setShowPopin(false))
    setActionButtonsMenuOpen(false)
  }

  useEffect(() => {
    if (mode === 'users') getData(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, mode])

  if (loading === 'pending') {
    return (
      <Icon
        className="loader"
        icon={LoaderRegular}
        size="2.625em"
        testID="loadingIcon"
      />
    )
  }

  const emptyDataLists = (): React.ReactNode => (
    <div className="countLoading">
      {mode !== 'users' ? (
        <img src={countLoading} alt="Aucun comptage effectue" />
      ) : (
        <img src={countLoading} alt="Aucun utilisateur trouvé" />
      )}
      {mode !== 'users' ? (
        <p>Aucun comptage effectué</p>
      ) : (
        <p>Aucun utilisateur trouvé</p>
      )}
    </div>
  )

  return (
    <div className="listsBodyWrapper" data-testid="bodyList">
      {dataList && dataList.length === 0 ? (
        emptyDataLists()
      ) : (
        <table>
          {(mode === 'myCounts' || mode === 'allCounts') && (
            <TableHeadMyCounts
              setCol={setCol}
              col={col}
              setSortBy={setSortBy}
              sortBy={sortBy}
              getData={getData}
              currentPage={currentPage}
            />
          )}
          {mode === 'users' && (
            <TableHeadUsers
              setCol={setCol}
              col={col}
              setSortBy={setSortBy}
              sortBy={sortBy}
              getData={getData}
              currentPage={currentPage}
            />
          )}
          <tbody>
            {(mode === 'myCounts' || mode === 'allCounts') &&
              loading !== 'pending' &&
              dataList &&
              dataList.length !== 0 &&
              dataList.map((myCount: CountBo) => (
                <TableRowMyCounts
                  myCount={myCount}
                  key={myCount.id}
                  setActionButtonsMenuOpen={setActionButtonsMenuOpen}
                />
              ))}
            {mode === 'users' &&
              loading !== 'pending' &&
              dataList.length !== 0 &&
              dataList.map((user: UserBo) => (
                <TableRowUsers
                  user={user}
                  key={user.id}
                  setActionButtonsMenuOpen={setActionButtonsMenuOpen}
                />
              ))}
          </tbody>
        </table>
      )}
      {actionButtonsMenuOpen && (
        <ActionButtonsMenu
          idMaf={rowSelected.idMaf}
          mafProduct={rowSelected.mafProduct}
          left={rowSelected.x}
          top={rowSelected.y}
          closePopin={closePopin}
          actionType={mode}
          editUser={() => setOverlayEditUser(true)}
          deleteCount={() => setOverlayDeleteCount(true)}
        />
      )}
      {overlayEditUser && (
        <OverlayEditProfil closeOverlay={() => setOverlayEditUser(false)} />
      )}
      {overlayDeleteCount && (
        <OverlayDeleteCount
          closeOverlay={() => setOverlayDeleteCount(false)}
          idMaf={rowSelected.idCount}
        />
      )}
    </div>
  )
}
export default BodyList
