/**
 * This custom hook is used to define the current mode of the count page viewed by the user from the pathname
 * values: 'new', 'edition' or 'preview'
 */
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { ModesType } from '../types/Modes.type'

export const useMode = () => {
  const location = useLocation()

  const defineMode = useCallback(() => {
    if (location?.pathname.includes('/edit')) return ModesType.Edit
    if (location?.pathname.includes('/preview')) return ModesType.Preview
    return ModesType.New
  }, [location])

  const mode = defineMode()

  return {
    mode,
  }
}
