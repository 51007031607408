import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { Icon } from '@ui-library/core'
import { CloseBold } from '@ui-library/icons'

export type BannerProps = {
  animation?: boolean,
  dataTestId?: string,
  children: React.ReactNode,
  style?: React.CSSProperties,
}

const BannerWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  > div {
    width: 100%;
    padding: 1em;
    background: ${(props) => props.theme.global.colorPalette.pastelYellow};
    border-radius: .5em;
    font-size: 1em;
    line-height: 1.5em;
    overflow: hidden;
  }

  a {
    color: ${(props) => props.theme.global.colorPalette.saphir};
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: .3em;
    font-size: 1.2em;
    font-weight: 700;
  }

  ul {
    margin-top: .5em;
    margin-left: 1em;

    li {
      list-style: disc;
      display: list-item;
    }
  }

  &.bannerAnimation-enter {
    max-height: 0;
    overflow: hidden;

    > div {
      transform: scaleY(0);
      padding: 0;

      > div {
        opacity: 0;
      }
    }
  }

  &.bannerAnimation-enter-active {
    max-height: 7em;
    transition: all 800ms;

    > div {
      transform: scaleY(1);
      padding: 1em;
      transition: all 400ms ease-out 400ms;

      > div {
        opacity: 1;
        transition: all 200ms ease-out 800ms;
      }
    }
  }

  &.bannerAnimation-exit {
    max-height: 7em;

    > div {
      transform: scaleY(1);
      padding: 1em;

      > div {
        opacity: 1;
      }
    }
  }

  &.bannerAnimation-exit-active {
    max-height: 0;
    margin-top: 0 !important;
    transition: all 400ms ease 900ms;

    > div {
      transform: scaleY(0);
      padding: 0;
      transition: all 600ms ease 300ms;

      > div {
        opacity: 0;
        transition: opacity 300ms;
      }
    }
  }
`

const BannerClose = styled.nav`
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 1;
  cursor: pointer;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

const Banner = ({ animation = false, dataTestId = 'banner', children, style = {}}: BannerProps): React.ReactElement => {
  // Refs
  const refBanner = useRef(null)

  // States
  const [bannerVisible, setBannerVisible] = React.useState(false)

  const closeBanner = () => {
    setBannerVisible(false)
  }

  useEffect(() => {
    setBannerVisible(true)
  }, [])

  if (animation) {
    return (
      <CSSTransition
        nodeRef={refBanner}
        timeout={1300}
        in={bannerVisible}
        classNames="bannerAnimation"
        unmountOnExit
      >
        {() => (
          <BannerWrapper className="bannerAnimation" ref={refBanner} data-testid={dataTestId} style={style}>
            <div>
              {children}
              <BannerClose onClick={closeBanner} data-testid="bannerClose">
                <Icon icon={CloseBold} size="1em" />
              </BannerClose>
            </div>
          </BannerWrapper>
        )}
      </CSSTransition>
    )
  }
  return (
    <BannerWrapper data-testid={dataTestId} style={style}>
      <div>{children}</div>
    </BannerWrapper>
  )
}

export default Banner
