import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Checkbox, Icon, useClickOutside } from '@ui-library/core'
import { ChevronDownLined } from '@ui-library/icons'

import { SelectElementType } from '../../types/select.type'

export type SelectProps = {
  disabled?: boolean,
  id?: string | null,
  label?: string | null,
  multiple?: boolean,
  off?: boolean,
  options: SelectElementType[],
  placeholder?: string | null,
  theme?: string,
  value: SelectElementType[] | null,
  onChange: (elem: SelectElementType[]) => void,
  showError?: boolean,
  msgError?: string,
}

type SelectContentProps = {
  $active: boolean,
  $disabled: boolean,
  $error: boolean,
  $isOpen: boolean,
  $off: boolean,
  $theme: string,
}

type SelectTextProps = {
  $hasPlaceholder: boolean,
}

type SelectLabelProps = {
  $theme: string,
}

type SelectDropdownProps = {
  $isOpen: boolean,
}

type SelectIconProps = {
  $isOpen: boolean,
  $off: boolean,
}

const SelectStyled = styled.div`
  text-align: left;
`

const SelectLabel = styled.div<SelectLabelProps>`
  margin-bottom: .3em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  font-size: .85em;

  ${(props: any) => props.$theme === 'secondary' && css`
    color: ${props.theme.global.colorPalette.white};
  `}
`

const SelectWrapper = styled.div`
  position: relative;
`

const SelectContent = styled.div<SelectContentProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2em 0 .75em;
  height: 2.5em;
  background: ${(props) => props.theme.global.colorPalette.white};
  border: ${(props) => `1px solid ${props.theme.global.colorPalette.greyLight}`};
  border-radius: .3em;
  user-select: none;

  ${(props: any) => props.$theme === 'secondary' && css`
    background: transparent;
    border-color: ${props.theme.global.colorPalette.white};
    color: ${props.theme.global.colorPalette.white};
  `}

  ${(props: any) => props.$active && css`
    cursor: pointer;
  `}

  ${(props: any) => props.$error && css`
    border-color: ${props.theme.global.colorPalette.error};
    background-color: ${props.theme.global.colorPalette.errorPastel};
  `}

  ${(props: any) => props.$disabled && !props.$off && css`
    cursor: wait;
  `}

  ${(props: any) => props.$isOpen && props.$theme !== 'secondary' && css`
    border-color: ${props.theme.global.colorPalette.saphir};
  `}

  ${(props: any) => props.$off && props.$theme !== 'secondary' && css`
    background-color: ${props.theme.global.colorPalette.greyLighter};
    color: ${props.theme.global.colorPalette.greyLight};
  `}
`

const SelectText = styled.div<SelectTextProps>`
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props: any) => props.$hasPlaceholder && css`
    color: ${props.theme.global.colorPalette.greyLight};
  `}
`

const SelectDropdown = styled.div<SelectDropdownProps>`
  position: absolute;
  top: 100%;
  margin-top: .2em;
  z-index: ${(props) => props.theme.global.myAdFactory.zIndex.dropdown};
  display: none;
  width: 100%;
  background: ${(props) => props.theme.global.colorPalette.white};
  border-radius: .3em;
  overflow: hidden;
  box-shadow: 0 0.1em 0.5em 0 rgba(0, 0, 0, 0.15);

  ul {
    width: 100%;
    user-select: none;

    li {
      position: relative;
      padding: 0 .75em;
      height: 2.5em;
      line-height: 2.5em;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        display: block;
        width: 90%;
        height: 1px;
        background: ${(props) => props.theme.global.colorPalette.lighterSaphir};
      }

      &:last-child {
        &:after {
          height: 0;
        }
      }

      &:hover {
        background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
      }
    }
  }

  ${(props: any) => props.$isOpen && css`
    display: block;
  `}
`

const IconStyled = styled.div<SelectIconProps>`
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  right: .8em;

  svg {
    transition: transform .3s;
    
    ${(props: any) => props.$isOpen && css`
      transform: rotate(-180deg);
      transition: transform .3s;
    `}

    ${(props: any) => props.$off && css`
      display: none;
    `}
  }
`

const SelectError = styled.div`
  margin-top: .4em;
  color: ${(props) => props.theme.global.colorPalette.error};
  font-size: .9em;
  font-weight: 600;
`

const Select = ({
  disabled = false,
  id = null,
  label = null,
  msgError = '',
  multiple = false,
  off = false,
  options,
  placeholder = null,
  showError = false,
  theme = 'primary',
  value = null,
  onChange,
}: SelectProps): React.ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const selectRef = useRef(null)

  const hasPlaceholder: boolean = placeholder !== null && !value
  const currentValue = value?.map((v: SelectElementType) => v.label).join(', ')

  const handleClick = () => !disabled && options?.length > 1 && setIsOpen(!isOpen)
  const handleClose = () => setIsOpen(false)

  const handleChange = (elem: { label: string, value: string }) => {
    if (!multiple) {
      onChange([elem])
    } else {
      const newValue = value ? [...value] : []
      if (newValue.indexOf(elem) === -1) {
        newValue.push(elem)
      } else {
        newValue.splice(newValue.indexOf(elem), 1)
      }
      onChange(newValue)
    }
  }

  useClickOutside([selectRef], handleClose)

  return (
    <SelectStyled data-testid="select">
      {label && <SelectLabel data-testid="selectLabel" $theme={theme}>{label}</SelectLabel>}
      <SelectWrapper ref={selectRef} data-testid={id ? `${id}Select` : 'wrapperSelect'} onClick={handleClick}>
        <SelectContent
          data-testid="selectContent"
          $theme={theme}
          $active={options?.length > 1}
          $error={showError}
          $disabled={disabled}
          $isOpen={isOpen}
          $off={off}
        >
          <SelectText $hasPlaceholder={hasPlaceholder}>
            <span>{currentValue ?? placeholder ?? '-'}</span>
            <IconStyled $isOpen={isOpen} $off={off}>
              <Icon icon={ChevronDownLined} size="1em" style={{ marginLeft: '2em' }} />
            </IconStyled>
          </SelectText>
        </SelectContent>
        {options?.length > 1 && (
          <SelectDropdown data-testid="dropdownSelect" $isOpen={isOpen && !off}>
            <ul>
              {options.map((elem) => (
                <li
                  data-testid={elem?.testId ? `${elem.testId}Option` : null}
                  key={`item-${elem.value}`}
                  onClick={() => handleChange(elem)}
                >
                  {!multiple ? elem.label : (
                    <Checkbox
                      checked={value !== null && value.some((v) => v.value === elem.value)}
                      disabled={elem.disabled}
                      label={elem.label}
                      size="small"
                      variant="normal"
                      onChange={() => handleChange(elem)}
                    />
                  )}
                </li>
              ))}
            </ul>
          </SelectDropdown>
        )}
      </SelectWrapper>
      {showError && <SelectError data-testid="msgError">{msgError}</SelectError>}
    </SelectStyled>
  )
}

export default Select
