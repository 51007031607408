import React, { useEffect, useRef, useState } from 'react'
import Calendar from 'react-calendar'
import styled, { css } from 'styled-components'

import Icon from '@ui-library/core/Icon'
import { Button, useClickOutside } from '@ui-library/core'
import ReloadRegular from '@ui-library/icons/ReloadRegular'
import ChevronLeftRegular from '@ui-library/icons/ChevronLeftRegular'
import ChevronRightRegular from '@ui-library/icons/ChevronRightRegular'

import InputDateSend from './InputDateSend/InputDateSend'

import 'react-calendar/dist/Calendar.css'

interface IDatePickerProps {
  showErrorDatePicker : boolean
  action: Function
  modeSelectRange : boolean
  defaultValue?: Date | Date[] | null
  resetDate?: boolean
}

const DatePicker = ({ showErrorDatePicker, action, modeSelectRange, defaultValue = null, resetDate = false } : IDatePickerProps): React.ReactElement => {
  const today = new Date()
  const startDateCalendar = new Date(today)
  startDateCalendar.setDate(today.getDate() + 1)

  // REFS
  // ------------------------------
  const calendarUserRef = useRef(null)

  // STATES
  // ------------------------------
  const [calendarValue, setCalendarValue] = useState<Date | Date[] | null>(null)
  const [selectedDate, setSelectedDate] = useState<string>('')
  const [visibleCalendar, setVisibleCalendar] = useState<boolean>(false)
  const [keyCalendar, setKeyCalendar] = useState<string>('calendar-01')

  // FUNCTIONS
  // ------------------------------
  const showCalendar = (display: boolean) => {
    setVisibleCalendar(display)
  }

  const closeCalendar = () => {
    showCalendar(false)
  }

  useClickOutside([calendarUserRef], closeCalendar)

  const dateToIsoString = (date : Date): string => {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return new Date(`${year}-${month}-${day}`).toISOString()
  }
  const dateValueSelected = (date : Date): string => {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  /**
   * FOR SINGLE DATE
   */
  const HandleChangeDate = (date : Date, noAction: boolean) => {
    const valueSelected = dateValueSelected(date)
    const dateISOString = dateToIsoString(date)
    setCalendarValue(date)
    showCalendar(false)
    setSelectedDate(valueSelected)

    if (!noAction) {
      action(dateISOString)
    }
  }

  /**
   * FOR RANGE OF DATES
   */
  const HandleChangeDateRange = (rangeDates: Date[], noAction: boolean) => {
    const valueSelected0 = dateValueSelected(rangeDates[0])
    const dateISOString0 = dateToIsoString(rangeDates[0])
    const valueSelected1 = dateValueSelected(rangeDates[1])
    const dateISOString1 = dateToIsoString(rangeDates[1])
    setCalendarValue(rangeDates)
    showCalendar(false)
    setSelectedDate(`${valueSelected0}  ⟶  ${valueSelected1}`)

    if (!noAction) {
      action([dateISOString0, dateISOString1])
    }
  }

  /**
   * TO REINIT THE CALENDAR, EVEN IF RANGE IS NOT ALREADY DEFINED (ONE CLICK),
   * WE NEED TO REMOUNT THE CALENDAR COMPONENT WHEN RESET BUTTON IS CLICKED
   */
  const remountCalendar = () => {
    if (keyCalendar === 'calendar-01') {
      setKeyCalendar('calendar-02')
    } else {
      setKeyCalendar('calendar-01')
    }
  }

  /**
   * RESET CALENDAR
   */
  const handleResetCalendar = (actionValue: string | [] = []) => {
    setCalendarValue(null)
    setSelectedDate('')
    action(actionValue)
    remountCalendar()
  }

  const prevLabel = (<Icon className="calendarButton" icon={ChevronLeftRegular} size="2em" />)
  const nextLabel = (<Icon className="calendarButton" icon={ChevronRightRegular} size="2em" />)

  const formatShortWeekday = (locale : string, date : Date) => ['D', 'L', 'M', 'M', 'J', 'V', 'S'][date.getDay()]

  /**
   * IF COMPONENT HAS DEFAULTVALUE PROP, 2 CASES:
   * - DEFAULTVALUE IS A DATE, FOR SINGLE DATE MODE => USE HANDLECHANGEDATE() FUNCTION
   * - DEFAULTVALUE IS AN ARRAY OF DATES, FOR RANGE DATES MODE => USE HANDLECHANGEDATERANGE() FUNCTION
   */
  useEffect(() => {
    if (defaultValue && !modeSelectRange) {
      HandleChangeDate(new Date(defaultValue as Date), true)
    }
    if (defaultValue && Array.isArray(defaultValue) && defaultValue.length > 0 && modeSelectRange) {
      const datesFormatted = defaultValue.map((dv) => new Date(dv))
      HandleChangeDateRange(datesFormatted, true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  /**
   * RESET DATE FROM PARENT COMPONENT WITH THE RESETDATE PROPS
   */
  useEffect(() => {
    if (resetDate) {
      handleResetCalendar('')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetDate])

  return (
    <>
      <InputDateSend
        updateDate={setSelectedDate}
        selectedDate={selectedDate}
        showCalendar={showCalendar}
        showErrorDatePicker={showErrorDatePicker}
        modeSelectRange={modeSelectRange}
      />
      {visibleCalendar && (
        <WrapperReactCalendar $displayCalendarHide={visibleCalendar} $doubleViewHeight={modeSelectRange} ref={calendarUserRef}>
          <Calendar
            key={keyCalendar}
            data-testid="calendar"
            showDoubleView={modeSelectRange || false}
            selectRange={modeSelectRange || false}
            formatShortWeekday={formatShortWeekday}
            minDate={startDateCalendar}
            onChange={(data: Date | Date[]) => {
              if (modeSelectRange) {
                HandleChangeDateRange(data as Date[], false)
              } else {
                HandleChangeDate(data as Date, false)
              }
            }}
            value={calendarValue as any}
            prevLabel={prevLabel}
            nextLabel={nextLabel}
            next2Label={null}
            prev2Label={null}
          />

          {/** Reinit button only for doubleview version */}
          {modeSelectRange && (
            <DatePickerReinit>
              <Button
                icon={ReloadRegular}
                iconPosition="left"
                kind="secondary"
                size="small"
                tag="a"
                variant="text"
                onClick={() => handleResetCalendar()}
              >
                Réinitaliser
              </Button>
            </DatePickerReinit>
          )}
        </WrapperReactCalendar>
      )}
    </>
  )
}

type WrapperReactCalendarProps = {
  $displayCalendarHide?: boolean,
  $doubleViewHeight?: boolean,
}

const WrapperReactCalendar = styled.div<WrapperReactCalendarProps>`
  ${(props: any) => props.$displayCalendarHide && css`
    position: absolute;
    right: 0;
    z-index: ${(p) => p.theme.global.myAdFactory.zIndex.dropdown};
    display: block;
    margin-top: 0.5em;
    width: 24em;
    min-height: 24em;
    background: ${(p) => p.theme.global.colorPalette.white};
    box-shadow: 0em 0.375em 1.5em rgba(0, 0, 0, 0.16);
    border-radius: 0.5em;

    .react-calendar {
      margin: 0 2em !important;
      width: calc(100% - 4em) !important;
      border: none;
      max-width: none;
    }
  
    .react-calendar__navigation {
      display: flex;
      height: 4em;
      margin: 0.5em 0 0 0;
  
      .react-calendar__navigation__label {
        color: ${(p) => p.theme.global.colorPalette.black};
        font-weight: bold;
        font-size: 1em;
        line-height: 1.375em;
  
        &:hover, &:focus {
          background: transparent;
        }
      }
  
      button {
        .calendarButton { 
          padding: 0.375em;
          border: ${(p) => `1px solid ${p.theme.global.colorPalette.grey}`};
          box-sizing: border-box;
          border-radius: 3.125em;
        }

        &:hover {
          background: transparent;
        }
      }
    }

    .react-calendar__navigation button:disabled {
      background-color: transparent;
      cursor: not-allowed;
    }
    
    .react-calendar__viewContainer {
      .react-calendar__month-view > div {
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
  
      .react-calendar__month-view  {
        width: 100%;

        .react-calendar__month-view__weekdays {
          font-weight: 400;
          width: 100%;
          margin-bottom: 0.15em;
          padding: 0.45em;
          background: ${(p) => p.theme.global.colorPalette.cloud};
  
          abbr {
            border: none !important;
            text-decoration: none !important;
          }
        }

        .react-calendar__month-view__days {
          width: 100%;
  
          .react-calendar__tile {
            height: 3.4em !important;
            max-width: 14.2857% !important;
            max-width: 40px;
            height: 40px;
            border: ${(p) => `1px solid ${p.theme.global.colorPalette.white}`};

            &:hover {
              background: ${(p) => p.theme.global.colorPalette.saphir};
              color: ${(p) => p.theme.global.colorPalette.white};
            }
          }
  
          .react-calendar__tile--active, .react-calendar__tile--hover {
            background: ${(p) => p.theme.global.colorPalette.lightSaphir};
          }
  
          .react-calendar__tile--rangeStart, .react-calendar__tile--rangeEnd {
            background: ${(p) => `${p.theme.global.colorPalette.saphir} !important`};
            border-radius: 2.5em;
          }
  
          .react-calendar__tile:enabled:hover {
            height: 3.4em;
            border-radius: 2.5em;
            max-width: 14.2857% !important;
          }
  
          .react-calendar__tile:disabled {
            background-color: transparent;
            color: ${(p) => p.theme.global.colorPalette.greyLighter};
          }
  
          .react-calendar__month-view__days__day--weekend {
            color: ${(p) => p.theme.global.colorPalette.black};
  
            &.react-calendar__tile--active {
              color: ${(p) => p.theme.global.colorPalette.white};
            }

            &:active {
              color: ${(p) => p.theme.global.colorPalette.white};
            }
          }
        }
      }
    }
  
    .react-calendar__navigation__arrow {
      margin: 0;
      padding: 0;

      &:hover, &:focus {
        background: transparent;
      }

      &.react-calendar__navigation__prev-button {
        text-align: left;
      }

      &.react-calendar__navigation__next-button {
        text-align: right;
      }
    }
  
    .react-calendar__year-view__months__month {
      &:hover {
        background: ${(p) => p.theme.global.colorPalette.lightSaphir};
      }
  
      &.react-calendar__tile--now {
        background: ${(p) => p.theme.global.colorPalette.lightSaphir};
      }
    }
  `}

  ${(props: any) => props.$doubleViewHeight && css`
    width: 47em !important;
    height: 30em !important;
  `}
`

const DatePickerReinit = styled.div`
  text-align: center;
  margin-top: 1em;
  width: 100%;

  div[data-testid="gsl.uilib.Button.Icon"] {
    z-index: 1;
  }
`

export default DatePicker
