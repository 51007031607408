import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Button, Icon, TextField } from '@ui-library/core'
import { Popin } from '@ui-library/core/Popin'
import { CloseBold, CheckBold, LoaderRegular, EyeLined } from '@ui-library/icons'
import { CSSTransition } from 'react-transition-group'

import CodeMaf from '../../Input/CodeMaf/CodeMaf'

import {
  emailingResSelector,
  setRes as setResEmailing,
} from '../../../redux/reducers/Emailing/emailing.reducer'
import {
  notificationVisibleSelector,
  notificationSelector,
} from '../../../redux/reducers/Notifications/notifications.reducer'

import { useMode } from '../../../hooks/useMode'

import {
  saveCountExpert360,
  saveCountMasterDisplay,
  saveCountEmailing,
  saveCountDisplay,
  saveCountPerformance,
  saveCountVedettePlus,
} from '../../../redux/actions/saveCount'

import { DISPLAY_PRODUCTS, EMAILING_PRODUCTS } from '../../../utils/variables'

import { BaseComptage, MAFProduct } from '../../../utils/myAdfactoryApi/swaggerApi'
import { ModesType } from '../../../types/Modes.type'

type CountsFooterProps = {
  canSave: boolean,
  countNameSaved?: string | null,
  loading: string,
  res: any,
}

type NotificationSavedCountsProps = {
  $error: boolean,
}

type IconDivProps = {
  $error: boolean,
}

type ButtonDivProps = {
  $unavailable: boolean,
}

const CountsFooter = ({ canSave, countNameSaved = null, loading, res } : CountsFooterProps): React.ReactElement => {
  const dispatch = useDispatch()
  const { mode } = useMode()
  const history = useHistory()

  const notification = useSelector(notificationSelector)
  const notificationVisible = useSelector(notificationVisibleSelector)

  const [isPopinOpen, setIsPopinOpen] = useState<boolean>(false)
  const [countName, setCountName] = useState<string>('')

  const refCountsFooter = useRef(null)

  // Variables
  const mafId: number = res?.mafId ?? null
  const baseComptage = res?.countData?.emailingNeufReq?.baseComptage ?? null
  const mafProduct: MAFProduct = res?.countData?.mafProduct ?? null
  const isExpert360Product: boolean = res?.countData?.mafProduct === MAFProduct.Expert360
  const isMasterDisplayProduct: boolean = res?.countData?.mafProduct === MAFProduct.MasterDisplay
  const isEmailingProduct: boolean = EMAILING_PRODUCTS.includes(mafProduct)
  const isDisplayProduct: boolean = DISPLAY_PRODUCTS.includes(mafProduct)
  const isPerformanceProduct: boolean = mafProduct === MAFProduct.DisplayPerformance
  const isVedettePlusProduct: boolean = mafProduct === MAFProduct.VedettePlus
  const textSaveButton: string = mode === ModesType.New ? 'Enregistrer le comptage' : 'Enregistrer les modifications'

  const resEmailing = useSelector(emailingResSelector)

  const redirectToEditPageAfterCountCreated = (id: string) => {
    if (mode === ModesType.New) {
      setTimeout(() => {
        // This function is used only for emailing product
        dispatch(setResEmailing({ ...resEmailing, id }))
        history.push(`/count/emailing/${baseComptage === BaseComptage.Alerte ? 'alert' : 'optin'}/edit/${id}`)
      }, 5500)
    }
  }

  const redirectAfterCountCreated = () => {
    if (mode === ModesType.New) {
      setTimeout(() => {
        history.push('/comptage')
      }, 5500)
    }
  }

  const onChangeCountName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (value.length <= 55) {
      setCountName(value)
    }
  }

  const saveCountName = () => {
    setIsPopinOpen(false)
    if (isEmailingProduct) {
      dispatch(saveCountEmailing(countName, mode)).then((data: any) => {
        if (data?.payload?.id) {
          redirectToEditPageAfterCountCreated(data.payload.id)
        }
      })
    }
    if (isDisplayProduct) {
      dispatch(saveCountDisplay(countName, mode)).then((data: null | Promise<any>) => {
        if (data) redirectAfterCountCreated()
      })
    }
    if (isPerformanceProduct) {
      dispatch(saveCountPerformance(countName, mode)).then((data: null | Promise<any>) => {
        if (data) redirectAfterCountCreated()
      })
    }
    if (isVedettePlusProduct) {
      dispatch(saveCountVedettePlus(countName, mode)).then((data: null | Promise<any>) => {
        if (data) redirectAfterCountCreated()
      })
    }
  }

  const saveCounts = () => {
    if (isExpert360Product) {
      dispatch(saveCountExpert360(mode)).then((data: null | Promise<any>) => {
        if (data) redirectAfterCountCreated()
      })
    } else if (isMasterDisplayProduct) {
      dispatch(saveCountMasterDisplay()).then((data: null | Promise<any>) => {
        if (data) redirectAfterCountCreated()
      })
    } else {
      setCountName(countNameSaved ?? '')
      setIsPopinOpen(true)
    }
  }

  const handlePreviewPage = () => {
    let pathname = '/'
    if (isExpert360Product) pathname = `/count/expert360/preview/${res?.id}`
    if (isEmailingProduct) pathname = `/count/emailing/${baseComptage === BaseComptage.Alerte ? 'alert' : 'optin'}/preview/${res?.id}`
    if (isDisplayProduct) pathname = `/count/display/preview/${res?.id}`
    if (isPerformanceProduct) pathname = `/count/performance/preview/${res?.id}`
    if (isVedettePlusProduct) pathname = `/count/vedetteplus/preview/${res?.id}`

    history.push({
      pathname,
      state: {
        from: history.location.pathname,
      },
    })
  }

  return (
    <CountsFooterWrapper data-testid="countsFooter">
      <Popin
        size="small"
        className="countNamePopin"
        open={isPopinOpen}
        onClose={() => { setIsPopinOpen(false) }}
      >
        <h3
          style={{
            marginBottom: '0.5em',
            fontWeight: 700,
            fontSize: '1.5em',
          }}
        >
          Enregistrer le comptage
        </h3>
        <TextField
          testID="popinInputName"
          label="Nom de l'agence"
          placeholder="Exemple : Martin Immobilier"
          value={countName}
          onChange={onChangeCountName}
        />
        <div
          style={{
            columnGap: '1.5em',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1.5em',
          }}
        >
          <Button
            className="button"
            kind="secondary"
            onClick={() => { setIsPopinOpen(false) }}
            size="medium"
            tag="button"
            testID="cancelBtnInPopin"
            variant="normal"
          >
            Annuler
          </Button>
          <Button
            className="button"
            kind="primary"
            onClick={saveCountName}
            size="medium"
            tag="button"
            testID="saveCountBtnInPopin"
            variant="normal"
            disabled={!countName?.length}
          >
            Valider
          </Button>
        </div>
      </Popin>
      <CSSTransition
        nodeRef={refCountsFooter}
        in={notificationVisible}
        timeout={400}
        classNames="visible"
        appear
        unmountOnExit
      >
        <NotificationSavedCounts data-testid="notificationSavedCount" className="visible" ref={refCountsFooter} $error={notification?.statut === 'error'}>
          <IconDiv $error={notification?.statut === 'error'}>
            <Icon icon={notification?.statut === 'error' ? CloseBold : CheckBold} />
          </IconDiv>
          <NotificationSavedCountsContainer>
            <strong>{notification?.title}</strong>
            <NotificationSavedCountsText>
              {notification?.description}
            </NotificationSavedCountsText>
          </NotificationSavedCountsContainer>
        </NotificationSavedCounts>
      </CSSTransition>
      <ButtonDiv $unavailable={loading === 'pending' || !canSave}>
        <Button
          size="medium"
          kind="primary"
          testID={`saveCountBtn${!canSave ? 'Disabled' : ''}`}
          variant="normal"
          className="saveCountBtn"
          tag="button"
          onClick={saveCounts}
        >
          {loading === 'pending' ? (
            <Icon testID="iconLoading" icon={LoaderRegular} style={{ display: 'flex' }} />
          ) : textSaveButton}
        </Button>
        {mode !== 'new' && !canSave && loading !== 'pending' && (
          <>
            <Button
              className="proposalButton"
              iconPosition="left"
              icon={EyeLined}
              kind="secondary"
              size="medium"
              variant="normal"
              onClick={handlePreviewPage}
              tag="button"
              testID="ProposalButton"
            >
              Voir la proposition client
            </Button>
            <CodeMaf idMaf={mafId} />
          </>
        )}
      </ButtonDiv>
    </CountsFooterWrapper>
  )
}

const CountsFooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: ${(props) => props.theme.global.myAdFactory.zIndex.footer};
  display: flex;
  align-items: center;
  padding: 1.5em 2.5em;
  width: 100%;
  background: ${(props) => props.theme.global.colorPalette.white};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .15);
`

const NotificationSavedCounts = styled.div<NotificationSavedCountsProps>`
  position: absolute;
  top: -5.5em;
  display: flex;
  opacity: 1;
  align-items: center;
  background: ${(props) => props.theme.global.colorPalette.validationPastel};
  padding: 1.12em 2em 1.12em .75em;
  border-radius: .4em;

  &.visible-enter {
    opacity: 0;
  }
  &.visible-enter-done {
    opacity: 1;
  }
  &.visible-enter-active {
    opacity: 1;
    transition: opacity 400ms;
  }
  &.visible-exit {
    opacity: 1;
  }
  &.visible-exit-done {
    opacity: 0;
  }
  &.visible-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }

  ${(props) => props.$error && css`
    background: ${props.theme.global.colorPalette.lighterCandy};
  `}
`

const IconDiv = styled.div<IconDivProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  border-radius: 1.75em;
  border: ${(props) => `1px solid ${props.theme.global.colorPalette.validationPastel}`};

  > div {
    width: 65%;
    color: ${(props) => `1px solid ${props.theme.global.colorPalette.validationPastel}`};
  }

  ${(props) => props.$error && css`
    border-color: ${props.theme.global.colorPalette.error};

    > div {
      color: ${props.theme.global.colorPalette.error};
    }
  `}
`

const NotificationSavedCountsContainer = styled.div`
  margin-left: .75em;
  color: ${(props) => props.theme.global.colorPalette.black};
`

const NotificationSavedCountsText = styled.div`
  font-size: 0.85em;
`

const ButtonDiv = styled.div<ButtonDivProps>`
  display: flex;

  ${(props) => props.$unavailable && css`
    .saveCountBtn {
      pointer-events: none;
      opacity: 0.4;
    }
  `}

  .proposalButton {
    margin-left: 1.15em;
    color: ${(props) => props.theme.global.colorPalette.saphir};
    border-color: ${(props) => props.theme.global.colorPalette.saphir};

    &:before {
      background: none;
    }
    &:hover {
      background-color: ${(props) => props.theme.global.colorPalette.saphir} !important;
      color: ${(props) => props.theme.global.colorPalette.white};
    }
  }
`

export default CountsFooter
