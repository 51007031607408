import React from 'react'
import styled, { css } from 'styled-components'

type InfosMessageProps = {
  messagecontent: string,
}

type InfosMessageStyledProps = {
  $hide?: boolean,
}

const InfosMessage = ({ messagecontent }: InfosMessageProps): React.ReactElement => (
  <InfosMessageStyled $hide={messagecontent === ''} data-testid="infosMessageContainer">
    <InfosMessageContent>
      {messagecontent}
    </InfosMessageContent>
  </InfosMessageStyled>
)

const InfosMessageStyled = styled.div<InfosMessageStyledProps>`
  margin-left: 0.563em;
  margin-top: 0.5em;
  height: 100%;
  width: 96%;
  background-color: ${(p) => p.theme.global.colorPalette.pastelYellow};
  border-radius: 0.5em;
  padding: 1em;
  text-align: left;

  ${(props: any) => props.$hide && css`
    display: none;
  `}
`

const InfosMessageContent = styled.span`
  font-size: 1em;
  font-weight: normal;
  width: 100%;
`

export default InfosMessage
