import React from 'react'
import { Button } from '@ui-library/core'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import backgroundImg from '../../assets/images/error404_background.jpg'

const ErrorPage = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url(${backgroundImg});
  background-size: cover;
`
const ErrorPageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ErrorPageTitle = styled.div`
  color: ${(props) => props.theme.global.colorPalette.white};
  font-weight: bold;
  height: 8em;
  display: flex;
  align-items: center;

  h1 {
    font-size: 8.5em;
    font-weight: 700;
  }
`

const ErrorPageDesc = styled.div`
  color: ${(props) => props.theme.global.colorPalette.white};
  font-weight: bold;
  margin: 1.5em 0 2.5em 0;

  p {
    font-size: 2em;
  }
`

const ButtonStyled = styled(Button)`
  padding: 0.85em 2.5em 0.85em 2.5em
  border: none;
  font-weight: 600;
  border: 0;
`

const Error404Page = (): React.ReactElement => {
  const history = useHistory()

  return (
    <ErrorPage data-testid="errorPage">
      <ErrorPageWrapper>
        <ErrorPageTitle>
          <h1>404 !</h1>
        </ErrorPageTitle>
        <ErrorPageDesc>
          <p>Il fait trop noir pour trouver cette page. Désolé.</p>
        </ErrorPageDesc>
        <ButtonStyled
          kind="secondary"
          size="medium"
          tag="button"
          variant="normal"
          onClick={() => {
            history.push('/accueil')
          }}
        >
          Page d'accueil
        </ButtonStyled>
      </ErrorPageWrapper>
    </ErrorPage>
  )
}

export default Error404Page
