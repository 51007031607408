/**
 * This custom hook is used to get async data from BO
 * getSelectableValuesAsync() and/or getCountByIdAsync()
 */
import { useRef } from 'react'
import { useDispatch } from 'react-redux'

import { useEffectOnce } from '../../useEffectOnce'
import { useMode } from '../../useMode'

import { cleanState } from '../../../redux/reducers/Expert360/expert360.reducer'

import { getCountByIdAsync } from '../../../services/countApi'
import { processCountAsync } from '../../../services/expert360Api'

import { ModesType } from '../../../types/Modes.type'

export const useProductExpert360Data = (id: string | undefined) => {
  const { mode } = useMode()
  const dispatch: any = useDispatch()

  const processCountThunk = useRef<any>(undefined)

  // Async data
  useEffectOnce(() => {
    const onMount = () => {
      if (id && mode !== ModesType.New) {
        dispatch(getCountByIdAsync(id)).then((c: any) => {
          if (c.meta.requestStatus === 'fulfilled' && mode === ModesType.Edit) {
            processCountThunk.current = dispatch(
              processCountAsync({
                broadcastMediums:
                  c?.payload?.countData?.xp360Req?.broadcastMediums ?? [],
                clientId: c?.payload?.countData?.xp360Req?.clientId,
                mode,
                periodicity: c?.payload?.countData?.xp360Req?.periodicity,
                postalCodes: c?.payload?.countData?.xp360Req?.postalCodes ?? [],
                projectTypes:
                  c?.payload?.countData?.xp360Req?.projectTypes ?? [],
                previous: {
                  brandContentAdjustableOffer:
                    c?.payload?.countData?.brandContentAdjustableOffer,
                  brandContentCommentary:
                    c?.payload?.countData?.brandContentCommentary,
                  externalURL: c?.payload?.countData?.externalURL,
                  intensities: c?.payload?.countData?.client?.intensity,
                  selectedIntensityId: c?.payload?.countData?.intensity,
                },
                radius: c?.payload?.countData?.xp360Req?.radius,
              })
            )
          }
        })
      }
    }
    onMount()

    return () => {
      const onUnmount = () => {
        // Abord api calls in pending...
        processCountThunk.current?.abort()
        // Clean
        dispatch(cleanState())
      }

      onUnmount()
    }
  })
}
