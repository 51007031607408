import React from 'react'
import styled, { css } from 'styled-components'

import Icon from '@ui-library/core/Icon'
import SortLined from '@ui-library/icons/SortLined'
import SortUpLined from '@ui-library/icons/SortUpLined'

import type { TableHeadProps } from '../../../../types/Tables.type'

const TableHeadMyCounts = ({ col, currentPage, setCol, setSortBy, sortBy, getData }: TableHeadProps): React.ReactElement => {
  const handleSort = (order: string) => {
    const newSortBy = { ...sortBy }
    if (col !== order) {
      // click on another column, sort by the chosen column (desc by default).
      newSortBy[order] = 'desc'
      setCol(order)
    } else {
      // click on the same column, reverse the order direction.
      newSortBy[order] = (sortBy[order] === 'desc') ? 'asc' : 'desc'
    }
    setSortBy(newSortBy)
    // Update counts with the new order.
    getData(currentPage, order, newSortBy[order])
  }

  // Handle sort icon
  const arrowSortIcon = (colonne: string) => {
    // click on another column
    if (colonne !== col) return SortLined
    // click on the same column
    if (sortBy[colonne] === 'asc') return SortUpLined
    return SortLined
  }

  return (
    <thead data-testid="table.Header">
      <tr>
        <ThCellFix data-testid="clientId" $isBlue={col === 'ClientId'} onClick={() => handleSort('ClientId')}>
          RC Client <IconStyled data-testid="clientIdIcon" $isBlue={col === 'ClientId'} icon={arrowSortIcon('ClientId')} size="16px" />
        </ThCellFix>
        <ThBlue data-testid="productName" $isBlue={col === 'ProductName'} onClick={() => handleSort('ProductName')}>
          Produit <IconStyled data-testid="productNameIcon" $isBlue={col === 'ProductName'} icon={arrowSortIcon('ProductName')} size="16px" />
        </ThBlue>
        <th data-testid="locality"> Localité(s) </th>
        <ThBlue data-testid="totalBudget" $isBlue={col === 'TotalBudget'} onClick={() => handleSort('TotalBudget')}>
          Budget(HT) <IconStyled data-testid="totalBudgetIcon" $isBlue={col === 'TotalBudget'} icon={arrowSortIcon('TotalBudget')} size="16px" />
        </ThBlue>
        <ThCellFix data-testid="createdDate" $isBlue={col === 'CreatedDate'} onClick={() => handleSort('CreatedDate')}>
          Créé le <IconStyled data-testid="createdDateIcon" $isBlue={col === 'CreatedDate'} icon={arrowSortIcon('CreatedDate')} size="16px" />
        </ThCellFix>
        <ThCellFix data-testid="mafId" $isBlue={col === 'MafId'} onClick={() => handleSort('MafId')}>
          ID MAF <IconStyled data-testid="idIcon" $isBlue={col === 'MafId'} icon={arrowSortIcon('MafId')} size="16px" />
        </ThCellFix>
      </tr>
    </thead>
  )
}

type ThCellFixProps = {
  $isBlue?: boolean,
}

type ThBlueProps = {
  $isBlue?: boolean,
}

type IconStyledProps = {
  $isBlue?: boolean,
}

const ThCellFix = styled.th<ThCellFixProps>`
  width: 12%;
  color: ${(p) => p.theme.global.colorPalette.black};

  ${(props: any) => props.$isBlue && css`
    color: ${(p) => p.theme.global.colorPalette.saphir};
    font-weight: 600;
  `}
`

const ThBlue = styled.th<ThBlueProps>`
  color: ${(p) => p.theme.global.colorPalette.black};

  ${(props: any) => props.$isBlue && css`
    color: ${(p) => p.theme.global.colorPalette.saphir};
    font-weight: 600;
  `}
`

const IconStyled = styled(Icon)<IconStyledProps>`
  position: relative;
  top: 1px;
  color: ${(p) => p.theme.global.colorPalette.greyLight};

  ${(props: any) => props.$isBlue && css`
    color: ${(p) => p.theme.global.colorPalette.saphir};
  `}
`

export default TableHeadMyCounts
