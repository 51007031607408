import styled, { css } from 'styled-components'
import { respondTo } from '../../theme/mixins/respondTo'

type AutoCompleteWrapperInputProps = {
  $error?: boolean,
}

type AutoCompleteDisplayInputProps = {
  $error?: boolean,
  $displayInputHide?: boolean,
  $noItemsInMyList?: boolean,
  $noIcon?: boolean,
}

type ItemListStyledProps = {
  $isDisabled?: boolean,
}

type IconWrapperProps = {
  $isDisabled?: boolean,
}

export const AutoComplete = styled.div`
  position: relative;

  @keyframes lazyLoadCustom {
    0% {
      background-position: -15em 0;
    }

    100% {
      background-position: 15em 0;
    }
  }
`

export const AutoCompleteLabel = styled.div`
  margin-bottom: 0.3em;
  text-align: left; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  font-size: .85em;
`

export const AutoCompleteWrapperInput = styled.div<AutoCompleteWrapperInputProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.5em;

  ${(props: any) => props.$error && css`
    input {
      background-color: ${(p) => p.theme.global.colorPalette.errorPastel};
      border-color: ${(p) => p.theme.global.colorPalette.error};
    }
  `}
`

export const SpanIcon = styled.span`
  position: absolute;
  left: 0.75em;
  margin-top: 0.2em;
  color: ${(p) => p.theme.global.colorPalette.saphir};
`

export const AutoCompleteDisplayInput = styled.div<AutoCompleteDisplayInputProps>`
  position: absolute;
  display: flex;
  align-items: center;
  width: calc(100% - 2.7em);
  height: calc(100% - 2px);
  margin: 1px 0 1px 2.35em;
  border-radius: 0.3em;
  background: ${(p) => p.theme.global.colorPalette.white};
  outline: none;
  pointer-events: none;
  font-weight: 600;
  cursor: pointer;

  span {
    width: 100%;
  }

  ${(props: any) => props.$error && css`
    background-color: ${(p) => p.theme.global.colorPalette.errorPastel};
  `}

  ${(props: any) => props.$displayInputHide && css`
    display: none;
  `}

  ${(props: any) => props.$noIcon && css`
    margin: 1px 0 1px 1em;
    width:calc(100% - 1.5em);
  `}
`

export const AutoCompletePlaceholderStrong = styled.span`
  color: ${(p) => p.theme.global.colorPalette.black};
`

export const AutoCompleteWrapperPlaceholder = styled.div`
  width: 90%;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const AutoCompletePlaceholder = styled.span`
  max-width: 3.5em;
  padding: 0.25em 0.5em;
  color: ${(p) => p.theme.global.colorPalette.saphir};
  background: ${(p) => p.theme.global.colorPalette.lighterSaphir};
  border-radius: 0.25em;
  margin-left: 0.4em;
  margin-right: 0.4em;
`

export const ClientName = styled.span`
  margin-left: 10px;
`

export const AutoCompleteInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 1em 0 2.35em;
  border-radius: 0.3em;
  border: ${(p) => `1px solid ${p.theme.global.colorPalette.greyLight}`};
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1em;
  font-weight: 600;

  &#inputWithNoIcon {
    padding: 0 1em 0 1em;
  }

  &:focus {
    border: ${(p) => `1px solid ${p.theme.global.colorPalette.saphir}`};
  }

  &:disabled {
    cursor: wait;
  }
`

export const AutoCompleteList = styled.div`
  background: ${(p) => p.theme.global.colorPalette.white};
  position: absolute;
  width: 100%;
  max-height: 14em;
  max-height: 20em;
  overflow: auto;
  border-radius: 0.3em;
  margin-top: 0.25em;
  box-shadow: 0 0.1em 0.5em 0 rgba(0, 0, 0, 0.16);
  z-index: 2;

  ${respondTo.md`
    max-width: none;
  `}
`

export const TitleCategoryList = styled.dt`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;
  padding: 0 1em;
  width: 100%;
  height: 2.75em;
  background: ${(p) => p.theme.global.colorPalette.white};

  p {
    font-weight: 700;
    font-size: 0.875em;

    ${respondTo.md`
      font-size: 1em;
    `}
  }

  span {
    font-size: 0.75em;
    font-weight: 600;
    color: ${(p) => p.theme.global.colorPalette.saphir};
    cursor: pointer;

    ${respondTo.md`
      font-size: 0.875em;
    `}
  }
`

export const ShadowMask = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  margin-top: -2.55em;
  width: 100%;
  height: 2.75em;
  background: ${(p) => p.theme.global.colorPalette.white};
  box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, .15);
`

export const Shadow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: sticky;
  top: -1em;
  z-index: 2;
  margin-top: -2.9em;
  height: 3.25em;
  background: ${(p) => p.theme.global.colorPalette.white};
`

export const MyList = styled.dd`
  display: flex;
  flex-wrap: wrap;
  margin: .25em 1em .4em 1em;

  > div {
    display: flex;
    align-items: center;
    margin-bottom: .5em;
    margin-right: .4em;
    padding: .25em .5em;
    background: ${(p) => p.theme.global.colorPalette.lighterSaphir};
    border-radius: .3em;

    p {
      font-size: .8em;
      font-weight: 600;
      text-align: left;
      color: ${(p) => p.theme.global.colorPalette.saphir};
    }

    #remove {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: .25em;
      font-size: 1.5em;
      color: ${(p) => p.theme.global.colorPalette.saphir};
      height: 0.6em;
    }
  }
`

export const ResultList = styled.div`
  > p {
    padding: 0 1em 1em 1em;
    text-align: left;
  }
`

export const TextError = styled.div`
  margin-top: .4em;
  font-size: .9em;
  font-weight: 600;
  color: ${(p) => p.theme.global.colorPalette.error};
  text-align: left;
`

export const RemoveItem = styled.span`
  position: absolute;
  right: .75em;
  left: auto;
  margin-top: .4em;
  cursor: pointer;
  color: ${(p) => p.theme.global.colorPalette.black};
`

export const NoItem = styled.p`
  padding: 1em;
`

export const AddItem = styled.div`
  display: none;

  p {
    font-size: 0.875em;
    font-weight: 600;
    color: ${(p) => p.theme.global.colorPalette.saphir};
  }
`
export const ItemListStyled = styled.div<ItemListStyledProps>`
  display: flex;
  align-items: center;
  padding: 0 0.75em;
  height: 3em;
  border-bottom: ${(p) => `1px solid ${p.theme.global.colorPalette.greyLighter}`};

  &:hover {
    background: ${(p) => p.theme.global.colorPalette.cloud};
    cursor: pointer;
  }

  &:hover ${AddItem} {
    display: block;
  }

  ${(props: any) => props.$isDisabled && css`
    color: ${(p) => p.theme.global.colorPalette.greyLight};
    font-weight: 500;

    i {
      color: ${(p) => p.theme.global.colorPalette.greyLight};
    }

    &:hover {
      background: none;
    }

    &:hover ${AddItem} {
      display: none;
    }
  `}
`

export const IconWrapper = styled.span<IconWrapperProps>`
  color: ${(p) => p.theme.global.colorPalette.saphir};
  margin-top: 0.2em;

  ${(props: any) => props.$isDisabled && css`
    color: ${(p) => p.theme.global.colorPalette.greyLight};
  `}
`

export const ItemValue = styled.div`
  margin-left: 0.45em;
  width: 100%;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  p {
    font-size: 1em;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    mark {
      background: none;
      font-weight: 600;
    }
  }
`

export const ItemLocation = styled.span`
  display: flex;
  align-items: center;
`

export const ItemLocationTag = styled.span`
  padding: 0.25em 0.5em;
  background: ${(p) => p.theme.global.colorPalette.lighterSaphir};
  display: flex;
  align-items: center;
  border-radius: 0.3em;
  margin-left: 0.4em;

  .highlightLocationTag {
    color: ${(p) => p.theme.global.colorPalette.saphir};
  }
`

export const ItemListLoadingStyled = styled(ItemListStyled)`
  padding: 0 1em;

  &:hover {
    background: none;
  }
`

export const LoaderIcon = styled.i`
  width: 1.1em;
  height: 1em;
  border-radius: 0.25em;
  margin: 0;
  margin-right: 1em;
  animation-duration: 1.4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: lazyLoadCustom;
  animation-timing-function: linear;
  animation-timing-function: linear;
  background: ${(p) => p.theme.global.colorPalette.lighterSaphir};
  background-size: 15em 1.4em;
  background-image: ${(props) => `linear-gradient(
    to right,
    ${props.theme.global.colorPalette.lighterSaphir} 0%,
    #f7f8ff 20%,
    ${props.theme.global.colorPalette.lighterSaphir} 40%,
    ${props.theme.global.colorPalette.lighterSaphir} 100%
  )`};
`

export const LoaderItem = styled.div`
  width: 75%;
  height: 1em;
  border-radius: 0.25em;
  margin: 0;
  animation-duration: 1.4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: lazyLoadCustom;
  animation-timing-function: linear;
  animation-timing-function: linear;
  background: ${(p) => p.theme.global.colorPalette.lighterSaphir};
  background-size: 15em 1.4em;
  background-image: ${(props) => `linear-gradient(
    to right,
    ${props.theme.global.colorPalette.lighterSaphir} 0%,
    #f7f8ff 20%,
    ${props.theme.global.colorPalette.lighterSaphir} 40%,
    ${props.theme.global.colorPalette.lighterSaphir} 100%
  )`};
`
