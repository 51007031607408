import React from 'react'
import styled, { css } from 'styled-components'

type LoaderProps = {
  maxWidth?: number,
  height?: number,
  style?: React.CSSProperties,
}

type LoaderStyledProps = {
  $maxWidth: number,
  $height: number,
}

const LoaderStyled = styled.div<LoaderStyledProps>`
  @keyframes LoaderAnimation {
    0% {
      background-position: -15em 0;
    }

    100% {
      background-position: 15em 0;
    }
  }

  width: 100%;
  border-radius: .3em;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: LoaderAnimation;
  animation-timing-function: linear;
  opacity: .6;
  background: ${(props) => props.theme.global.colorPalette.lighterSaphir};
  background-size: 8em 1.4em;
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.global.colorPalette.lighterSaphir} 0%,
    ${(props) => props.theme.global.colorPalette.cloud} 20%,
    ${(props) => props.theme.global.colorPalette.lighterSaphir} 40%,
    ${(props) => props.theme.global.colorPalette.lighterSaphir} 100%
  );

  ${(props: any) => props.$maxWidth && css`
    max-width: ${props.$maxWidth}px;
    height: ${props.$height}px;
  `}
`

const Loader = ({ maxWidth = 200, height = 30, style }: LoaderProps): React.ReactElement => <LoaderStyled $maxWidth={maxWidth} $height={height} data-testid="loaderBar" style={style} />

export default Loader
