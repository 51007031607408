import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import DisplayHeader from '../../../components/Products/Display/DisplayHeader/DisplayHeader'
import DisplayCount from '../../../components/Products/Display/DisplayCount/DisplayCount'

import { displayErrorsSelector } from '../../../redux/reducers/Display/display.reducer'

import { useProductDisplayData } from '../../../hooks/products/display/useProductDisplayData'

type DisplayWrapperProps = {
  children: React.ReactNode,
  dataTestId: string,
}

const DisplayWrapper = ({ children, dataTestId }: DisplayWrapperProps): React.ReactElement => (
  <DisplayWrapperStyled data-testid={dataTestId}>
    <DisplayHeader />
    {children}
  </DisplayWrapperStyled>
)

const DisplayErrorComp = (): React.ReactElement => (
  <DisplayWrapper dataTestId="displayError">
    <DisplayError>
      <p>Une erreur est survenue</p>
    </DisplayError>
  </DisplayWrapper>
)

const Display = (): React.ReactElement => {
  const { id }: { id: string } = useParams()
  const displayErrors: any = useSelector(displayErrorsSelector)

  useProductDisplayData(id)

  if (displayErrors) {
    return <DisplayErrorComp />
  }

  return (
    <DisplayWrapper dataTestId="display">
      <DisplayCount />
    </DisplayWrapper>
  )
}

// Styled component
const DisplayWrapperStyled = styled.div`
  padding-top: 14em;
  background-color: ${(props) => props.theme.global.colorPalette.white};
`

const DisplayError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

export default Display
