import {
  UserConfigStateType,
  backendHostSelector,
} from '../../redux/reducers/UserConfig/userConfig.reducer'
import { MyAdFactoryClass } from './swaggerApi'

export const getMyAdFactoryClass = ({ getState }: { getState: any }) => {
  const backendHost = (state: UserConfigStateType) => backendHostSelector(state)
  const myAdFactoryClass = new MyAdFactoryClass(
    backendHost(getState() as UserConfigStateType)
  )
  return myAdFactoryClass
}
