import React, { ReactNode, useRef } from 'react'
import { Icon } from '@ui-library/core/Icon'
import { InfoBubble } from '@ui-library/core/InfoBubble'
import CheckCircleFilled from '@ui-library/icons/CheckCircleFilled'
import InfoCircleLined from '@ui-library/icons/InfoCircleLined'
import styled, { css } from 'styled-components'

interface CardSelectProps {
    forbiddenToUncheck?: boolean,
    imgCard?: string,
    iconCard?: ReactNode,
    infoBubbleCard?: string,
    label?: string,
    isChecked: boolean,
    styleInfosBubble?: string,
    type: 'broadcast' | 'product',
    onClickCard: React.MouseEventHandler<HTMLDivElement>,
    onMouseInfosEnter?: React.MouseEventHandler<HTMLDivElement>,
    onMouseInfosLeave?: React.MouseEventHandler<HTMLDivElement>,
}
const CardSelect = (
  {
    forbiddenToUncheck,
    label,
    iconCard,
    infoBubbleCard,
    imgCard,
    isChecked,
    styleInfosBubble,
    type,
    onClickCard,
    onMouseInfosEnter,
    onMouseInfosLeave,
  } : CardSelectProps
) => {
  const anchorRef = useRef(null)

  return (
    <CardSelectStyled $type={type} $forbiddenToUncheck={forbiddenToUncheck} onClick={onClickCard}>
      <CheckCardIcon
        testID={type === 'product' ? `checkCard${label}` : ''}
        icon={CheckCircleFilled}
        size="small"
        color="#4d54c5"
        $displayCheck={isChecked}
        $hideCheck={!isChecked}
      />
      {imgCard === undefined
        ? iconCard
        : <CardSelectStyledImg style={{ backgroundImage: `url(${imgCard})` }} />}
      {infoBubbleCard !== undefined ? (
        <LabelAndInfos>
          {label ?? null}
          <div ref={anchorRef} onMouseEnter={onMouseInfosEnter} onMouseLeave={onMouseInfosLeave}>
            <Icon
              icon={InfoCircleLined}
              size="15px"
              color="#4d54c5"
            />
          </div>
          <InfoBubbleStyled
            anchorRef={anchorRef}
            type="Tooltip"
            orientation="right"
            style={{ display: styleInfosBubble, pointerEvents: 'none' }}
          >
            {infoBubbleCard}
          </InfoBubbleStyled>
        </LabelAndInfos>

      ) : null}
    </CardSelectStyled>
  )
}

type CardSelectStyledProps = {
  $forbiddenToUncheck?: boolean,
  $type?: string,
}

type CheckCardIconProps = {
  $displayCheck?: boolean,
  $hideCheck?: boolean,
}

const CheckCardIcon = styled(Icon)<CheckCardIconProps>`
  position: absolute;
  right: 0.3em;
  top: 0.3em;

  ${(props) => props.$displayCheck && css`
    visibility: visible;
  `}

  ${(props) => props.$hideCheck && css`
    visibility: hidden;
  `}
`

const CardSelectStyled = styled.div<CardSelectStyledProps>`
  ${(props) => props.$type === 'broadcast' && css`
    position: relative;
    margin-right: 1em;
    width: 8.2em;
    height: 3.25em;
    display: flex;
    border-radius: 0.25em;
    box-shadow: 0px 2px 8px 0px #00000030;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      box-shadow: 0px 4px 16px 0px #00000030;
      border: 1px solid #09a3e9;
      cursor: pointer;
    }
  `}

  ${(props) => props.$type === 'product' && css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 4em;
    cursor: pointer;
    background: ${(p) => p.theme.global.colorPalette.white};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    margin-bottom: 2.375em;

    ${CheckCardIcon} {
      position: absolute;
      top: .3em;
      right: .3em;
      width: 1.125em;
      height: 1.125em;
    }
  `}

  ${(props) => props.$forbiddenToUncheck && css`
    &:hover {
      box-shadow: 0px 4px 16px 0px #00000030;
      border: 1px solid #09a3e9;
      cursor: not-allowed;
    }
  `}
`

const CardSelectStyledImg = styled.div`
  width: inherit;
  background-repeat: no-repeat;
  background-position: center; 
`

const InfoBubbleStyled = styled(InfoBubble)`
  width: 12.625em;
  z-index: ${(p) => p.theme.global.myAdFactory.zIndex.max};
  font-style: normal;
  font-size: 0.875em;
  border-radius: 0.25em;
`

const LabelAndInfos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.25em;

  div {
    display: flex;
    align-items: center;
    margin-left: 0.2em;
  }
`

export default CardSelect
