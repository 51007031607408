import { createSlice, current } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import {
  deleteCount,
  getAllCounts,
  getMyCounts,
} from '../../../../services/countApi'
import {
  CountBo,
  MAFProduct,
} from '../../../../utils/myAdfactoryApi/swaggerApi'

interface IPageCountsState {
  loading: string
  error: any
  myCountsData: any
  allCountsData: any
  totalCounts: number | undefined
  rowSelected: {
    x: number
    y: number
    idMaf: string
    mafProduct: MAFProduct | ''
    showPopin: boolean
    idCount: number
  }
  search?: string
}

export const initialState: IPageCountsState = {
  loading: 'idle',
  error: null,
  myCountsData: [],
  allCountsData: [],
  totalCounts: 0,
  rowSelected: {
    x: 0,
    y: 0,
    idMaf: '',
    mafProduct: '',
    showPopin: false,
    idCount: 0,
  },
  search: '',
}

// Slice
const pageCountsSlice = createSlice({
  name: 'pageCounts',
  initialState,
  reducers: {
    setCountsSearch: (state, action) => {
      state.search = action.payload.search
    },
    saveSelectedRow(state, action) {
      state.rowSelected.x = action.payload.x
      state.rowSelected.y = action.payload.y
      state.rowSelected.idMaf = action.payload.idMaf
      state.rowSelected.mafProduct = action.payload.mafProduct
      state.rowSelected.idCount = action.payload.idCount
    },
    setShowPopin(state, action) {
      state.rowSelected.showPopin = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        state.loading = 'idle'
        state.error = null
        state.myCountsData = []
        state.allCountsData = []
        state.totalCounts = 0
        state.rowSelected = {
          x: 0,
          y: 0,
          idMaf: '',
          mafProduct: '',
          showPopin: false,
          idCount: 0,
        }
      })
      .addCase(getMyCounts.pending, (state) => {
        state.loading = 'pending'
        state.myCountsData = []
        state.totalCounts = 0
        state.rowSelected = {
          x: 0,
          y: 0,
          idMaf: '',
          mafProduct: '',
          showPopin: false,
          idCount: 0,
        }
      })
      .addCase(getMyCounts.fulfilled, (state, action: any) => {
        state.rowSelected = {
          x: 0,
          y: 0,
          idMaf: '',
          mafProduct: '',
          showPopin: false,
          idCount: 0,
        }
        state.loading = 'idle'
        state.myCountsData = action.payload
        state.totalCounts = action.payload.total
      })
      .addCase(getMyCounts.rejected, (state, action) => {
        state.loading = 'idle'
        state.error = action.error
      })
      .addCase(getAllCounts.pending, (state) => {
        state.loading = 'pending'
        state.allCountsData = []
        state.totalCounts = 0
        state.rowSelected = {
          x: 0,
          y: 0,
          idMaf: '',
          mafProduct: '',
          showPopin: false,
          idCount: 0,
        }
      })
      .addCase(getAllCounts.fulfilled, (state, action) => {
        state.rowSelected = {
          x: 0,
          y: 0,
          idMaf: '',
          mafProduct: '',
          showPopin: false,
          idCount: 0,
        }
        state.loading = 'idle'
        state.allCountsData = action.payload
        state.totalCounts = action.payload.total
      })
      .addCase(getAllCounts.rejected, (state, action) => {
        state.loading = 'idle'
        state.error = action.error
      })
      .addCase(deleteCount.fulfilled, (state, action: any) => {
        const index = current(state.myCountsData).countBo.findIndex(
          (count: CountBo) => count.id === action.payload.id
        )
        state.myCountsData.countBo.splice(index, 1)
      })
  },
})

// Selectors
type PageCountsSliceName = (typeof pageCountsSlice)['name']
export type PageCountsStateType = Record<PageCountsSliceName, IPageCountsState>

export const myCountsDataSelector = (state: PageCountsStateType) =>
  state.pageCounts.myCountsData.countBo
export const allCountsDataSelector = (state: PageCountsStateType) =>
  state.pageCounts.allCountsData.countBo
export const totalCountSelector = (state: PageCountsStateType) =>
  state.pageCounts.totalCounts
export const loadingApiSelector = (state: PageCountsStateType) =>
  state.pageCounts.loading
export const rowSelectedSelector = (state: PageCountsStateType) =>
  state.pageCounts.rowSelected
export const countSearchSelector = (state: PageCountsStateType) =>
  state.pageCounts.search

// Actions
export const { setCountsSearch, saveSelectedRow, setShowPopin } =
  pageCountsSlice.actions

// The reducer
export default pageCountsSlice.reducer
