import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Icon } from '@ui-library/core'

type NavMenuButtonProps = {
  current: boolean,
  link: string,
  icon: any,
  text: string,
}

type StyledLiProps = {
  $current: boolean,
}

type WrapperLinkProps = {
  $current: boolean,
}

const StyledLi = styled.li<StyledLiProps>`
  background-color: ${(props) => props.theme.global.colorPalette.darkerSaphir};
  transition: background-color .3s;

  &:hover {
    background-color: ${(props) => props.theme.global.myAdFactory.navigation.hoverColor};
    background-image: linear-gradient(
      0deg,
      rgba(0,0,0,0.5) 0%,
      rgba(0,0,0,0.5) 100%);
  }

  ${(props: any) => props.$current && css`
    background-color: ${props.theme.global.colorPalette.white};
    color: ${props.theme.global.colorPalette.darkerSaphir};
  `}
`

const StyledLink = styled(Link)`
  display: block;
  background-color: ${(props) => props.theme.global.colorPalette.darkerSaphir};
  text-decoration: none;
  outline: none;

  &:focus, &:active {
    outline: none;
  }
`

const WrapperLink = styled.div<WrapperLinkProps>`
  position: relative;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.global.colorPalette.white};
  height: 3em;
  border-radius: 0.5em 0 0 0.5em;
  padding-left: 0;
  transition: padding-left .3s;
  background-color: ${(props) => props.theme.global.colorPalette.darkerSaphir};
  transition: padding-left .3s, background-color .3s, color .3s;

  &:before {
    content: "";
    position: absolute;
    border-radius: 0.5em 0 0 0.5em;
    right: 0;
    width: 40%;
    height: 100%;
    opacity: 0;
    background-color: ${(props) => props.theme.global.colorPalette.white};
    transition: all 0.3s;
  }

  &:hover {
    background-color: ${(props) => props.theme.global.myAdFactory.navigation.hoverColor};
    padding-left: 0.5em;
  }

  ${(props: any) => props.$current && css`
    color: ${props.theme.global.colorPalette.darkerSaphir};
    padding-left: 0;

    &:before {
      opacity: 1;
      width: 100%;
      transition: width .3s, opacity .3s;
    }
  `}
`

const ContentLink = styled.div`
  position: absolute;
  display: flex;
  align-items: center;

  span {
    text-decoration: none;
    font-weight: 600;
  }
`

const IconLink = styled.div`
  display: flex;
  margin: 0 1em;
`

const UpperLink = styled.div`
  height: .45em;
  background-color: ${(props) => props.theme.global.colorPalette.darkerSaphir};
  border-radius: 0 0 .5em 0;
`

const LowerLink = styled.div`
  height: 0.45em;
  background-color: ${(props) => props.theme.global.colorPalette.darkerSaphir};
  border-radius: 0 0.5em 0 0;
`

const NavMenuButton = ({ current, link, icon, text }: NavMenuButtonProps): React.ReactElement => (
  <StyledLi $current={current} data-testid="NavMenuButton">
    <UpperLink />
    <StyledLink to={link}>
      <WrapperLink $current={current}>
        <ContentLink>
          <IconLink>
            <Icon icon={icon} size="1.5em" />
          </IconLink>
          <span>{text}</span>
        </ContentLink>
      </WrapperLink>
    </StyledLink>
    <LowerLink />
  </StyledLi>
)

export default NavMenuButton
