import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import mapBackground from '../../../../assets/images/map_background.jpg'

import CountInProgress from '../../../Counts/CountInProgress/CountInProgress'
import DisplaySummaryCounts from '../DisplaySummaryCount/DisplaySummaryCount'
import DisplayTabs from '../DisplayTabs/DisplayTabs'
import PrintsByBroadcastTable from '../../../Tables/PrintsByBroadcastTable/PrintsByBroadcastTable'
import PrintsByFormatTable from '../../../Tables/PrintsByFormatTable/PrintsByFormatTable'
import PrintsByFormatTableForLuxury from '../../../Tables/PrintsByFormatTableForLuxury/PrintsByFormatTableForLuxury'
import CountsFooter from '../../../Counts/CountsFooter/CountsFooter'

import { useProductDisplayCount } from '../../../../hooks/products/display/useProductDisplayCount'

import {
  currentTabSelector,
  displayCanSaveSelector,
  displayCountNameSavedSelector,
  displayLoadingSelector,
  displayResSelector,
} from '../../../../redux/reducers/Display/display.reducer'
import { currentMarketSelector } from '../../../../redux/reducers/UserConfig/userConfig.reducer'

import { DisplayMarketsType } from '../../../../types/display.type'

const DisplayCount = (): React.ReactElement => {
  const {
    displayTabs,
    handleChangeInputs,
    handleChangeTabs,
  } = useProductDisplayCount()

  const res = useSelector(displayResSelector)
  const loading: string = useSelector(displayLoadingSelector)
  const canSave: boolean = useSelector(displayCanSaveSelector)
  const countNameSaved: string | null = useSelector(displayCountNameSavedSelector)
  const currentMarket: any = useSelector(currentMarketSelector)
  const currentTab: string = useSelector(currentTabSelector)

  if (!res || (res?.countData && Object.keys(res.countData).length === 0)) {
    return <CountInProgress dataTestId="displayCount" />
  }

  return (
    <DisplayCountWrapper data-testid="displayCount">
      <DisplayCountMap style={{ backgroundImage: `url(${mapBackground})` }} />
      <DisplayCountSummary>
        <DisplayCountTitle>Résumé du comptage</DisplayCountTitle>
        <DisplayCountSummaryBlock>
          <DisplaySummaryCounts />
        </DisplayCountSummaryBlock>
        {displayTabs && <DisplayTabs currentTab={currentTab} handleChange={handleChangeTabs} /> }
        {currentTab === 'format' && currentMarket !== DisplayMarketsType.Luxe && (
          <PrintsByFormatTable
            cpms={res?.countData?.cpm ?? undefined}
            currentMarket={currentMarket}
            formatsResult={res?.countData?.forecastSplitByPosition ?? []}
            loading={loading}
            totalBudget={res?.countData?.totalBudget ?? 0}
            totalOffer={res?.countData?.totalImpPurchased ?? 0}
            onChange={handleChangeInputs}
          />
        )}
        {currentTab === 'context' && currentMarket !== DisplayMarketsType.Luxe && (
          <PrintsByBroadcastTable
            cpms={res?.countData?.cpm ?? undefined}
            forecastResult={res?.countData?.forecasts ?? []}
            loading={loading}
            totalBudget={res?.countData?.totalBudget ?? 0}
            totalOffer={res?.countData?.totalImpPurchased ?? 0}
            onChange={handleChangeInputs}
          />
        )}
        {currentTab === 'format' && currentMarket === DisplayMarketsType.Luxe && (
          <PrintsByFormatTableForLuxury
            currentMarket={currentMarket}
            formatsResult={res?.countData?.forecastSplitByPosition ?? []}
            cpms={res?.countData?.cpm ?? undefined}
            loading={loading}
            totalBudget={res?.countData?.totalBudget ?? 0}
            totalOffer={res?.countData?.totalImpPurchased ?? 0}
            onChange={handleChangeInputs}
          />
        )}
        <CountsFooter
          canSave={canSave}
          countNameSaved={countNameSaved}
          loading={loading}
          res={res}
        />
      </DisplayCountSummary>
    </DisplayCountWrapper>
  )
}

const DisplayCountWrapper = styled.div`
  position: relative;
`

const DisplayCountMap = styled.div`
  position: absolute;
  left: 0;
  top: 13em;
  width: 100%;
  height: 30em;
  background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
  background-size: cover;
`

const DisplayCountSummary = styled.div`
  position: absolute;
  padding: 8.5em 2.5em 10em 2.5em;
  width: 100%;
`

const DisplayCountTitle = styled.p`
  font-size: 1.5em;
  font-weight: 700;
  color: ${(props) => props.theme.global.colorPalette.black};
`

const DisplayCountSummaryBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  height: 35em;
  margin-bottom: 6em;
`

export default DisplayCount
