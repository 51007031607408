import React from 'react'
import TextField from '@ui-library/core/TextField'
import CalendarRegular from '@ui-library/icons/CalendarRegular'
import { IDateInputProps } from '../../../types/InputDateSend.types'

const InputDate = ({
  showCalendar,
  updateDate,
  selectedDate,
  showErrorDatePicker,
  modeSelectRange,
} : IDateInputProps) => {
  const displayCalendarHide = () => {
    showCalendar(true)
  }

  const handleChangeInput = (event : React.ChangeEvent<HTMLInputElement>) => {
    updateDate(event.target.value)
  }

  let placeholder = "Date d'envoi"
  let errorMessage = 'Sélectionnez une date d\'envoi'
  if (modeSelectRange) {
    placeholder = 'Début       ⟶        Fin'
    errorMessage = 'Sélectionner une periodicité'
  }
  return (
    <>
      <TextField
        className="inputDateSend"
        placeholder={placeholder}
        suffix={CalendarRegular}
        value={selectedDate}
        onChange={handleChangeInput}
        handleSuffixClick={displayCalendarHide}
        onClick={displayCalendarHide}
        state={showErrorDatePicker ? 'error' : 'normal'}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default InputDate
