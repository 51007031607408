import { createSlice, current } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import { getCountByIdAsync } from '../../../services/countApi'
import {
  getSelectableValuesAsync,
  processCountAsync,
  recordCountAsync,
} from '../../../services/emailingApi'

import {
  formatCityTags,
  formatCityTagsWithRadius,
  setInputTargetingsValue,
} from '../../../utils/format'
import { EMAILING_PRODUCTS } from '../../../utils/variables'

import {
  EmailingMarketsType,
  initialStateType,
} from '../../../types/emailing.type'
import { BaseComptage } from '../../../utils/myAdfactoryApi/swaggerApi'
import {
  proposalSelectedEmails,
  proposalTotalBudget,
} from '../../../utils/tsUtils'

export const initialState: initialStateType = {
  base: BaseComptage.Optin,
  canSave: false,
  countNameSaved: null,
  errors: null,
  data: {
    options: {
      productTypes: [],
      minPrice: 1,
      maxPrice: 10000000,
      minNbPieces: 1,
      maxNbPieces: 5,
    },
  },
  loading: 'idle',
  res: null,
  radius: 0,
  listToDisplay: [],
}

const removeLogicImmoBasesIfEmailingAlert = (state: initialStateType) => {
  const targetingsFromDataArray =
    state.data.targetings && Object.entries(state.data.targetings)
  if (state.base === BaseComptage.Alerte) {
    return (
      targetingsFromDataArray &&
      targetingsFromDataArray.length > 0 &&
      targetingsFromDataArray.map((t: any) => {
        const updatedT = [...t]
        updatedT[1].broadcastMediums = updatedT[1].broadcastMediums.filter(
          (b: string) => !b.includes('LOGIC')
        )
        return updatedT
      })
    )
  }
  return state?.data?.targetings
}

const updateInputTargetingsValue = (state: initialStateType) => {
  if (state.data) {
    const targetingsArray =
      state?.data?.targetings && Object.entries(state.data.targetings)
    const filtered = targetingsArray?.filter((t) => t?.[1]?.selected) ?? []
    const targetingsSelected = Object.fromEntries(filtered)
    state.data.inputTargetingsValue =
      setInputTargetingsValue(targetingsSelected)
  }
}

const initDefaultConfig = (state: initialStateType) => {
  // Every broadcasts need to be checked by default
  if (state?.data?.targetings) {
    removeLogicImmoBasesIfEmailingAlert(state)

    const targetingsFromDataArray = Object.entries(state.data.targetings)
    const targetingsFromDataArrayWithCheckedValues =
      targetingsFromDataArray.map((t: any) => {
        const updatedT = [...t]
        updatedT[1].checked = updatedT[1].broadcastMediums.map(() => true)
        updatedT[1].disabled = updatedT[0] === 'PrestigeSellers'
        updatedT[1].selected = false
        // eslint-disable-next-line prefer-destructuring
        updatedT[1].value = updatedT[0]
        return updatedT
      })
    state.data.targetings =
      targetingsFromDataArrayWithCheckedValues &&
      Object.fromEntries(targetingsFromDataArrayWithCheckedValues)
  }
}

const setConfigDataFromGetCount = (state: initialStateType) => {
  const data = state.data && current(state.data)

  // Targetings
  const targetingsFromDataArray =
    data?.targetings && Object.entries(data.targetings)
  const targetingsFromDisplayReqArray =
    state.res?.countData?.emailingNeufReq?.targetings

  const updatedTargetings = targetingsFromDataArray?.map((targeting: any) => {
    const updated = [...targeting]
    const filtered = targetingsFromDisplayReqArray?.filter(
      (t) => t.distributionType === updated[0]
    )

    const filteredBroadcasts = filtered?.[0]?.broadcastMediums
    // Reset default checked statement before setting with values from BO
    const checkedArray = [...updated[1].checked].map(() => true)

    // selected to true
    const selected = filtered && filtered.length > 0

    filteredBroadcasts?.forEach((fb: string) => {
      if (updated[1].broadcastMediums.includes(fb)) {
        const i = updated[1].broadcastMediums.indexOf(fb)
        checkedArray[i] = true
      }
    })

    updated[1] = { ...updated[1], checked: checkedArray, selected }
    return updated
  })

  // Locations
  const localityNameByZipCode = state?.res?.countData?.localityNameByZipCode
  const localityNameByZipCodeArray =
    localityNameByZipCode && Object.entries(localityNameByZipCode)

  const locations = localityNameByZipCodeArray?.map((l) => {
    return {
      id: '',
      code: l?.[0] ?? '',
      name: l?.[1]?.[0] ?? '',
    }
  })

  // Dates
  const date = state?.res?.countData.emailingNeufReq?.dateSent

  // Options
  const options = {
    productTypes:
      state?.res?.countData?.emailingNeufReq?.productTypes
        ?.map((pt: string) => {
          const updatedPt = pt
          if (updatedPt === 'Apartment') return 'Appartement'
          if (updatedPt === 'House') return 'Maison'
          if (updatedPt === 'HouseModel' || updatedPt === 'Plot') return ''
          return updatedPt
        })
        .filter((u: string) => u) ?? data?.options?.productTypes,
    minPrice:
      state?.res?.countData?.emailingNeufReq?.price?.min ??
      data?.options?.minPrice,
    maxPrice:
      state?.res?.countData?.emailingNeufReq?.price?.max ??
      data?.options?.maxPrice,
    minNbPieces:
      state?.res?.countData?.emailingNeufReq?.numberOfPieces?.min ??
      data?.options?.minNbPieces,
    maxNbPieces:
      state?.res?.countData?.emailingNeufReq?.numberOfPieces?.max ??
      data?.options?.maxNbPieces,
  }

  state.data = {
    ...state.data,
    date,
    options,
    locations,
    targetings: updatedTargetings && Object.fromEntries(updatedTargetings),
  }
  updateInputTargetingsValue(state)
  defineLogosFromGetCountToDisplayInSummaryCard(state)
}

const defineDefaultForecastsForPendingProcessCount = (
  market: EmailingMarketsType,
  state: initialStateType
) => {
  state.res = {
    ...state.res,
    countData: {
      bySiteGroup: undefined,
      totalBudget: 0,
    },
  }
}

const cleanResToDisplayInProgressComponent = (state: initialStateType) => {
  if (state.res) {
    state.res.countData = {}
  }
}

const defineLogosToDisplayInSummaryCard = (state: initialStateType) => {
  if (state.data) {
    const targetingsArray =
      state?.data?.targetings && Object.entries(state.data.targetings)
    const filtered = targetingsArray?.filter((t) => t?.[1]?.selected) ?? []
    const targetingsSelected = Object.fromEntries(filtered)

    const logosArray = Object.values(targetingsSelected)?.map(
      (targeting: any) =>
        targeting.checked?.map((c: boolean, index: number) => {
          if (c) return targeting.broadcastMediums[index]
          return null
        })
    )
    const logosArrayFlat = logosArray?.flat() ?? []
    const logosArrayFlatUniq = logosArrayFlat.filter(
      (l: string, index: number) => logosArrayFlat.indexOf(l) === index
    )

    state.data.logos = logosArrayFlatUniq?.filter((l: string) => l)
  }
}

const defineLogosFromGetCountToDisplayInSummaryCard = (
  state: initialStateType
) => {
  if (state?.res?.countData?.bySiteGroup) {
    const bySiteGroupArray = Object.entries(state.res.countData.bySiteGroup)
    const logosArray = bySiteGroupArray.map(
      (sg: any) => sg?.[1]?.respondedBroadcastMediums
    )
    const logosArrayFlat = logosArray?.flat() ?? []
    const logosArrayFlatUniq = logosArrayFlat.filter(
      (l: string, index: number) => logosArrayFlat.indexOf(l) === index
    )

    state.data.logos = logosArrayFlatUniq?.filter((l: string) => l)
  } else {
    defineLogosToDisplayInSummaryCard(state)
  }
}

// Slice
const emailingSlice = createSlice({
  name: 'emailing',
  initialState,
  reducers: {
    cleanState() {
      return initialState
    },
    setBase(state, action) {
      state.base = action.payload
    },
    setCanSave(state, action) {
      state.canSave = action.payload
    },
    setData(state, action) {
      state.data = action.payload
      updateInputTargetingsValue(state)
      cleanResToDisplayInProgressComponent(state)
      defineLogosToDisplayInSummaryCard(state)
    },
    setRes(state, action) {
      state.res = action.payload
    },
    setRadius(state, action) {
      state.radius = action.payload.radius
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(getCountByIdAsync.pending, (state: initialStateType) => {
        state.loading = 'pending'
      })
      .addCase(
        getCountByIdAsync.fulfilled,
        (state: initialStateType, action: any) => {
          // getCountByIdAsync is used by all the products. We need to check to product name from BO
          if (EMAILING_PRODUCTS.includes(action.payload?.mafProduct)) {
            state.loading = 'idle'
            state.res = {
              ...action.payload,
              countData: {
                ...action.payload.countData,
                totalBudget: proposalTotalBudget(action.payload.countData),
                totalEmails: proposalSelectedEmails(action.payload.countData),
              },
            }
            state.base =
              state?.res?.countData?.emailingNeufReq?.baseComptage ??
              BaseComptage.Optin
            state.countNameSaved = action.payload.countData.countName ?? null
            state.errors = null
            setConfigDataFromGetCount(state)
          }
          if (state.res) {
            state.radius = action.payload.countData.emailingNeufReq?.radius
            const postalCode =
              action.payload.countData.emailingNeufReq?.locationZipCode[0]
            const localityByZipCode =
              action.payload.countData.localityNameByZipCode

            if (state.radius > 0) {
              state.listToDisplay = formatCityTagsWithRadius(
                postalCode,
                localityByZipCode[postalCode]
              )
            } else {
              state.listToDisplay = formatCityTags(localityByZipCode ?? {})
            }
          }
        }
      )
      .addCase(
        getCountByIdAsync.rejected,
        (state: initialStateType, action) => {
          state.canSave = false
          state.loading = 'idle'
          state.res = null
          state.countNameSaved = null
          state.errors = action.error
        }
      )
      .addCase(getSelectableValuesAsync.pending, (state: initialStateType) => {
        state.loading = 'pending'
        state.data = initialState.data
      })
      .addCase(
        getSelectableValuesAsync.fulfilled,
        (state: initialStateType, action) => {
          const payload = action?.payload
          state.loading = 'idle'
          state.data.targetings = { ...payload?.targetings }
          state.data.productType = { ...payload?.productType }
          initDefaultConfig(state)
        }
      )
      .addCase(
        getSelectableValuesAsync.rejected,
        (state: initialStateType, action) => {
          state.loading = 'idle'
          state.data = initialState.data
          state.errors = action.error
        }
      )
      .addCase(
        processCountAsync.pending,
        (state: initialStateType, action: any) => {
          const { market } = action.meta.arg
          state.loading = 'pending'
          state.canSave = false
          state.errors = null
          if (market) {
            defineDefaultForecastsForPendingProcessCount(market, state)
          }
        }
      )
      .addCase(processCountAsync.fulfilled, (state, action) => {
        state.loading = 'idle'
        state.canSave = true
        const res = state.res ? current(state.res) : {}
        state.res = { ...res, countData: action.payload }

        const postalCode = action.meta.arg?.locationZipCode?.toString() ?? ''
        const localityByZipCode = action.payload?.localityNameByZipCode

        if (action.meta.arg.radius && action.meta.arg?.radius > 0) {
          state.listToDisplay = formatCityTagsWithRadius(
            postalCode,
            localityByZipCode?.[postalCode]
          )
        } else {
          state.listToDisplay = formatCityTags(localityByZipCode ?? {})
        }

        if (state?.res?.countData) {
          state.res.countData.totalBudget = proposalTotalBudget(
            state.res.countData
          )
          state.res.countData.totalEmails = proposalSelectedEmails(
            state.res.countData
          )
        }
        defineLogosFromGetCountToDisplayInSummaryCard(state)

        // update data.locations with data from processCount result
        const data = state.data ? current(state.data) : {}

        if (state?.res?.countData?.localityNameByZipCode)
          state.data = {
            ...data,
            locations: Object.entries(
              state.res.countData.localityNameByZipCode
            ).map((l) => {
              return {
                id: '',
                code: l?.[0] ?? '',
                name: l?.[1]?.[0] ?? '',
              }
            }),
          }
      })
      .addCase(processCountAsync.rejected, (state, action) => {
        state.loading = 'idle'
        state.res = null
        state.errors = action.error
      })
      .addCase(recordCountAsync.fulfilled, (state) => {
        state.canSave = false
      })
  },
})

// Selectors
type EmailingSliceName = (typeof emailingSlice)['name']
export type EmailingStateType = Record<EmailingSliceName, any>

export const emailingDataSelector = (state: EmailingStateType) =>
  state.emailing.data
export const emailingResSelector = (state: EmailingStateType) =>
  state.emailing.res
export const emailingErrorsSelector = (state: EmailingStateType) =>
  state.emailing.errors
export const emailingLoadingSelector = (state: EmailingStateType) =>
  state.emailing.loading
export const emailingBaseSelector = (state: EmailingStateType) =>
  state.emailing.base
export const emailingCanSaveSelector = (state: EmailingStateType) =>
  state.emailing.canSave
export const emailingCountNameSavedSelector = (state: EmailingStateType) =>
  state.emailing.countNameSaved
export const emailingRadiusSelector = (state: EmailingStateType) =>
  state.emailing.radius
export const emailingListToDisplaySelector = (state: EmailingStateType) =>
  state.emailing.listToDisplay

// Actions
export const { cleanState, setBase, setCanSave, setData, setRes, setRadius } =
  emailingSlice.actions

// The reducer
export default emailingSlice.reducer
