/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { Icon, Tooltip } from '@ui-library/core'
import ThreePointsRegular from '@ui-library/icons/ThreePointsRegular'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  saveSelectedRow,
} from '../../../../redux/reducers/Page/Counts/pageCounts.reducer'
import { productNamesType } from '../../../../types/productNames.types'
import { formatDate } from '../../../../utils/date'
import { formatProductNames } from '../../../../utils/format'
import {
  CountBo,
  DisplayResp,
  EmailingResp,
  ForecastResult,
  MAFProduct,
  MasterDisplayResp,
  PerformanceDisplayResp,
  Xp360Resp,
} from '../../../../utils/myAdfactoryApi/swaggerApi'
import { capitalize } from '../../../../utils/tsUtils'
import {
  DISPLAY_PRODUCTS,
  EMAILING_PRODUCTS,
} from '../../../../utils/variables'

type myCountType = CountBo & {
  countData: EmailingResp &
    MasterDisplayResp &
    Xp360Resp &
    DisplayResp &
    PerformanceDisplayResp
}

type TableRowMyCountsProps = {
  myCount: myCountType
  setActionButtonsMenuOpen: (value: boolean) => void
}

export const defineTotalBudget = (
  myCount: myCountType,
  isEmailing: boolean
) => {
  let totalBudg: number = 0
  if (myCount.countData) {
    if (isEmailing) {
      totalBudg = myCount.countData.productionFee ?? totalBudg
      const includeSeLoger = myCount.countData.bySiteGroup?.SeLoger
      const includeLogicImmo = myCount.countData.bySiteGroup?.LogicImmo

      if (includeSeLoger?.selectedBudget) {
        totalBudg += includeSeLoger.selectedBudget
      }

      if (includeLogicImmo?.selectedBudget) {
        totalBudg += includeLogicImmo.selectedBudget
      }
    } else {
      totalBudg = myCount.countData.totalBudget ?? totalBudg
    }
  }
  totalBudg = Number(totalBudg?.toFixed(2))
  return totalBudg
}

export const getLocalities = (myCount: myCountType) => {
  // Xp360 and Display and DisplayPerformance
  if (
    myCount?.mafProduct === MAFProduct.Expert360 ||
    myCount?.mafProduct === MAFProduct.DisplayPerformance ||
    DISPLAY_PRODUCTS.includes(myCount?.mafProduct)
  ) {
    if (
      DISPLAY_PRODUCTS.includes(myCount?.mafProduct) &&
      myCount?.countData?.displayReq?.isAllFrance
    )
      return [{ code: undefined, name: 'Toute la France' }]

    return myCount?.countData?.locality?.map((loc) => {
      const localityNameByZipCode =
        myCount?.countData?.localityNameByZipCode?.[loc.code]
      return {
        ...loc,
        name:
          localityNameByZipCode && localityNameByZipCode.length > 1
            ? `Toutes les villes (${loc?.code})`
            : loc?.name,
      }
    })
  }

  // Master Display
  if (
    myCount?.mafProduct === MAFProduct.MasterDisplay ||
    myCount?.mafProduct === MAFProduct.VedettePlus
  ) {
    return myCount?.countData?.forecasts
  }

  // Emailing Optin & Alert
  if (EMAILING_PRODUCTS.includes(myCount?.mafProduct)) {
    const localityNameByZipCodeObj =
      myCount?.countData?.localityNameByZipCode &&
      Object.entries(myCount.countData.localityNameByZipCode)
    return localityNameByZipCodeObj?.map((l) => {
      const codesArray = myCount?.countData?.localityNameByZipCode?.[l[0]]
      return {
        code: l?.[0],
        name:
          codesArray && codesArray.length > 1
            ? `Toutes les villes (${l[0]})`
            : l?.[1]?.[0],
      }
    })
  }
  return undefined
}

export const defineTooltipLocality = (
  myCountLocalities: ForecastResult[] | undefined
): string => {
  if (!myCountLocalities || myCountLocalities?.length === 0) {
    return ''
  }

  // Variables
  let localities = myCountLocalities
  let localitiesAsAString: string = ''
  let etc = ''
  const LIMIT_CITIES: number = 20

  // Limit number of items displayed in tooltip if more cities than 20
  if (localities && localities.length > LIMIT_CITIES) {
    localities = localities.slice(0, LIMIT_CITIES)
    etc = '...'
  }

  localities?.forEach((locality: ForecastResult, index: number) => {
    const needToDisplayCodeInParenthesis: boolean =
      !locality?.name?.includes('Toutes les villes')
    const localityCode: string = needToDisplayCodeInParenthesis
      ? ` (${locality.code})`
      : ''
    localitiesAsAString += `${capitalize(locality.name)}${localityCode}${index !== localities.length - 1 ? ', ' : ''}`
  })

  return `${localitiesAsAString}${etc}`
}

const RcClientContent = ({
  myCount,
}: {
  myCount: myCountType
}): React.ReactElement => {
  if (myCount?.countData) {
    if (myCount.mafProduct === MAFProduct.Expert360) {
      return (
        <p className="clientName">
          {myCount.countData.client?.clientName} <br />
          <span>{myCount.countData.client?.clientId}</span>
        </p>
      )
    }

    if (myCount.countData.countName) {
      return <p className="clientName">{myCount.countData.countName}</p>
    }

    return <>__</>
  }

  return <>__</>
}

const TableRowMyCounts = ({
  myCount,
  setActionButtonsMenuOpen,
}: TableRowMyCountsProps): React.ReactElement => {
  const dispatch = useDispatch()

  const [styleTooltip, setStyleTooltip] = useState('none')

  const anchorRef = useRef(null)

  const isExpert360Product: boolean =
    myCount.mafProduct === MAFProduct.Expert360
  const isMasterDisplayProduct: boolean =
    myCount.mafProduct === MAFProduct.MasterDisplay
  const isEmailingOptinProduct: boolean = [
    MAFProduct.EmailingOptin,
    MAFProduct.EmailingOptinConstruire,
  ].includes(myCount.mafProduct)
  const isEmailingAlertProduct: boolean = [
    MAFProduct.EmailingAlerte,
    MAFProduct.EmailingAlerteConstruire,
  ].includes(myCount.mafProduct)
  const isDisplayProduct: boolean = DISPLAY_PRODUCTS.includes(
    myCount.mafProduct
  )
  const isPerformanceProduct: boolean =
    myCount.mafProduct === MAFProduct.DisplayPerformance
  const isVedettePlusProduct: boolean =
    myCount.mafProduct === MAFProduct.VedettePlus
  const isEmailing = isEmailingOptinProduct || isEmailingAlertProduct

  let proposalLink = ''
  if (isExpert360Product)
    proposalLink = `/count/expert360/preview/${myCount.id}`
  if (isMasterDisplayProduct)
    proposalLink = `/count/masterdisplay/preview/${myCount.id}`
  if (isEmailingOptinProduct)
    proposalLink = `/count/emailing/optin/preview/${myCount.id}`
  if (isEmailingAlertProduct)
    proposalLink = `/count/emailing/alert/preview/${myCount.id}`
  if (isDisplayProduct) proposalLink = `/count/display/preview/${myCount.id}`
  if (isPerformanceProduct)
    proposalLink = `/count/performance/preview/${myCount.id}`
  if (isVedettePlusProduct)
    proposalLink = `/count/vedetteplus/preview/${myCount.id}`

  const totalBudg = defineTotalBudget(myCount, isEmailing)
  const myCountLocalities = getLocalities(myCount)
  const tooltipLocality = defineTooltipLocality(myCountLocalities)

  const handleClickCount = (e: React.MouseEvent<HTMLElement>) => {
    setActionButtonsMenuOpen(true)
    dispatch(
      saveSelectedRow({
        x: e.clientX,
        y: e.clientY,
        idMaf: myCount.id,
        mafProduct: myCount.mafProduct,
        idCount: myCount.mafId,
      })
    )
  }

  return (
    <tr className="count">
      <td aria-label="Count">
        <div className="tdWrapper">
          <div className="cellText">
            <RcClientContent myCount={myCount} />
            <Link className="proposalLink" to={proposalLink} />
          </div>
          <span className="divider" />
        </div>
      </td>
      <td>
        <div className="tdWrapper">
          <div className="cellText">
            {formatProductNames(
              myCount.mafProduct as keyof typeof productNamesType
            )}
          </div>
          <span className="divider" />
        </div>
      </td>
      <td>
        <div className="tdWrapper">
          <div
            data-testid="cellLocality"
            ref={anchorRef}
            className="cellText locality"
            onMouseEnter={() => setStyleTooltip('block')}
            onMouseLeave={() => setStyleTooltip('none')}
          >
            <Link className="proposalLink" to={proposalLink}>
              {capitalize(myCountLocalities?.[0]?.name) ?? ''}
              {myCountLocalities && myCountLocalities.length > 1
                ? `, +${myCountLocalities.length - 1}`
                : ''}
            </Link>
            {myCountLocalities && myCountLocalities?.length > 1 ? (
              <Tooltip
                testID="toolTipLocalities"
                style={{
                  display: styleTooltip,
                  width: '16.5em',
                  textAlign: 'center',
                  pointerEvents: 'none',
                }}
                orientation="top"
                anchorRef={anchorRef}
              >
                {tooltipLocality}
              </Tooltip>
            ) : null}
          </div>
          <span className="divider" />
        </div>
      </td>
      <td>
        <div className="tdWrapper">
          <div className="cellText totalBudget">{totalBudg} €</div>
          <span className="divider" />
        </div>
      </td>
      <td>
        <div className="tdWrapper">
          <div className="cellText">
            {formatDate(new Date(myCount.createdDate ?? 0))}
          </div>
          <span className="divider" />
        </div>
      </td>
      <td>MAF-{myCount.mafId}</td>
      {[
        MAFProduct.Expert360,
        MAFProduct.VedettePlus,
        MAFProduct.DisplayPerformance,
        ...EMAILING_PRODUCTS,
        ...DISPLAY_PRODUCTS,
      ].includes(myCount.mafProduct) ? (
        <td
          data-testid="clickCount"
          onClick={handleClickCount}
          aria-label="Actions"
        >
          <div
            data-testid="clickCountDiv"
          >
            <Icon
              icon={ThreePointsRegular}
              size="16px"
              className="threePointsVertical"
            />
          </div>
        </td>
      ) : (
        <td>{' '} </td>
      )}
    </tr>
  )
}
export default TableRowMyCounts
