import { useOktaAuth } from '@okta/okta-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import {
  currentMarketSelector,
  isUserConnectedSelector,
  redirectSelector,
  setUserConnected,
} from '../../redux/reducers/UserConfig/userConfig.reducer'
import { COMPTAGE_PRODUITS } from '../../routes.constants'
import { getUserMe } from '../../services/userApi'
import '../../utils/axiosConfig'
import type { Market } from '../../utils/myAdfactoryApi/swaggerApi'
import BlankLayout from './BlankLayout'
import DefaultLayout from './DefaultLayout'

type PrivateLayoutRouteProps = {
  component: any
  exact?: boolean
  layoutless?: boolean
  onlyFor?: string[] | null
  path: string | string[]
  // Forced to used a double negation construction here, as we only want to
  // restrict a specific route and allow the others
  isNotAccessible?: boolean
}

/**
 * PrivateLayoutRoute, is used to redirect users not connected to login page or to handle redirection for pages reserved to list of markets
 */
const PrivateLayoutRoute = ({
  component: Component,
  ...rest
}: PrivateLayoutRouteProps): React.ReactElement | null => {
  const dispatch = useDispatch()
  const { authState } = useOktaAuth()

  // Selectors
  const isUserConnected: boolean = useSelector(isUserConnectedSelector)
  const currentMarket: Market = useSelector(currentMarketSelector)

  let currentMarketIsAllowed: boolean = true
  if (rest.onlyFor) {
    currentMarketIsAllowed = rest.onlyFor.includes(currentMarket)
  }

  const redirectRedux = useSelector(redirectSelector)

  // ??
  if (authState?.isAuthenticated === null) {
    return null
  }

  // Loading...
  if (!authState) {
    return (
      <div data-testid="fetchingUserProfile">
        <p>private route / Fetching user profile...</p>
      </div>
    )
  }

  // Redirect to login route
  if (redirectRedux || !authState?.isAuthenticated) {
    dispatch(setUserConnected(false))
    return <Redirect data-testid="redirect" to="/login" />
  }

  // Redirect to home route if there is some specific access restriction.
  if (rest.isNotAccessible) {
    return <Redirect data-testid="redirectToHome" to="/" />
  }

  /**
   * If user is authenticated, getUserMe()
   */
  const connect = (isAuthenticated: boolean, isReduxUserConnected: boolean) => {
    if (isAuthenticated && !isReduxUserConnected) {
      dispatch(getUserMe())
    }
  }

  // connect user && redirect to right layout if authenticated
  connect(authState?.isAuthenticated, isUserConnected)

  // If layout is only for a list of markets, redirection to comptage product page
  if (!currentMarketIsAllowed) {
    return <Redirect to={COMPTAGE_PRODUITS} />
  }

  // Everything is ok
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!rest.layoutless) {
          return (
            <DefaultLayout>
              <Component {...props} />
            </DefaultLayout>
          )
        }

        return (
          <BlankLayout>
            <Component {...props} />
          </BlankLayout>
        )
      }}
    />
  )
}

export default PrivateLayoutRoute
