const oktaprodAuthConf = {
  issuer: `${process.env.REACT_APP_OKTA_BASE_URL}`,
  clientId: `${process.env.REACT_APP_OKTA_PROD_CLIENTID}`,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: false,
}
const oktadevAuthConf = {
  issuer: `${process.env.REACT_APP_OKTA_BASE_URL}`,
  clientId: `${process.env.REACT_APP_OKTA_DEV_CLIENTID}`,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: false,
}

const calculeConf = () => {
  const hostname = window?.location?.hostname
  if (hostname === 'myadfactory.seloger.com') {
    return oktaprodAuthConf
  }
  return oktadevAuthConf
}
const oktaAuthConfig = calculeConf()

export { oktaAuthConfig }
