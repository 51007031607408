import { createSlice, current } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import { getCountByIdAsync } from '../../../services/countApi'
import {
  processCountAsync,
  recordCountForMasterDisplayAsync,
} from '../../../services/masterDisplayApi'

import { initialStateType } from '../../../types/masterDisplay.type'
import { SelectElementType } from '../../../types/select.type'
import { MAFProduct, PlaceBo } from '../../../utils/myAdfactoryApi/swaggerApi'

export const initialState: initialStateType = {
  canSave: false,
  data: {
    broadcastMediums: [
      {
        label: 'Se Loger',
        value: 'SELOGER',
        checked: false,
      },
      {
        label: 'Logic Immo',
        value: 'LOGICIMMO',
        checked: true,
      },
    ],
    projectTypes: [
      {
        label: 'Achat',
        value: 'ACHAT',
        checked: false,
      },
      {
        label: 'Location',
        value: 'LOCATION',
        checked: false,
      },
    ],
    periodicity: [
      {
        label: 'Récurrent',
        value: 'Récurrent',
        checked: true,
      },
    ],
  },
  errors: null,
  res: null,
  radius: 0,
  loading: 'idle',
  listToDisplay: [],
}

const cleanResToDisplayInProgressComponent = (state: initialStateType) => {
  if (state.res) state.res = null
}

const updateDataWithResponse = (state: initialStateType) => {
  // update data.locations with data from processCount result
  const data = state.data ? current(state.data) : {}
  if (state?.res?.countData?.locality)
    state.data = { ...data, locations: state.res.countData.locality }

  // update data.broadcastMediums
  if (state?.res?.countData?.masterDisplayReq?.broadcastMediums) {
    state.data.broadcastMediums = data?.broadcastMediums?.map((b: any) => {
      const updatedB = { ...b }
      if (
        state?.res?.countData?.masterDisplayReq?.broadcastMediums?.includes(
          b.value
        )
      ) {
        updatedB.checked = true
      } else {
        updatedB.checked = false
      }
      return updatedB
    })
  }

  // update data.projectTypes
  if (state?.res?.countData?.masterDisplayReq?.projectTypes) {
    state.data.projectTypes = data?.projectTypes?.map((p: any) => {
      const updatedP = { ...p }
      if (
        state?.res?.countData?.masterDisplayReq?.projectTypes?.includes(p.value)
      ) {
        updatedP.checked = true
      } else {
        updatedP.checked = false
      }
      return updatedP
    })
  }
}

const setConfigDataFromGetCount = (state: initialStateType) => {
  // Locations
  const locations = state?.res?.countData?.forecasts as PlaceBo[]

  // BroadcastMediums
  const selectedBroadcastMediums =
    state?.res?.countData?.masterDisplayReq?.broadcastMediums
  const broadcastMediums = state?.data?.broadcastMediums?.map(
    (b: SelectElementType) => {
      const updatedB = { ...b }
      updatedB.checked =
        selectedBroadcastMediums && selectedBroadcastMediums.includes(b.value)
      return updatedB
    }
  )

  // ProjectTypes
  const selectedProjectTypes =
    state?.res?.countData?.masterDisplayReq?.projectTypes
  const projectTypes = state?.data?.projectTypes?.map(
    (p: SelectElementType) => {
      const updatedP = { ...p }
      updatedP.checked =
        selectedProjectTypes && selectedProjectTypes.includes(p.value)
      return updatedP
    }
  )

  state.data = {
    ...state.data,
    broadcastMediums,
    locations,
    projectTypes,
  }
}

// Slice
const masterDisplaySlice = createSlice({
  name: 'masterDisplay',
  initialState,
  reducers: {
    cleanState() {
      return initialState
    },
    setCanSave(state, action) {
      state.canSave = action.payload
    },
    setData(state, action) {
      state.data = action.payload
      cleanResToDisplayInProgressComponent(state)
    },
    setRes(state, action) {
      state.res = action.payload
    },
    setRadius(state, action) {
      state.radius = action.payload.radius
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(getCountByIdAsync.pending, (state: initialStateType) => {
        state.loading = 'pending'
      })
      .addCase(
        getCountByIdAsync.fulfilled,
        (state: initialStateType, action: any) => {
          // getCountByIdAsync is used by all the products. We need to check to product name from BO
          if (action.payload?.mafProduct === MAFProduct.MasterDisplay) {
            state.loading = 'idle'
            state.res = action.payload
            state.errors = null
            setConfigDataFromGetCount(state)
          }
        }
      )
      .addCase(
        getCountByIdAsync.rejected,
        (state: initialStateType, action) => {
          state.loading = 'idle'
          state.res = null
          state.errors = action.error
        }
      )
      .addCase(processCountAsync.pending, (state: initialStateType) => {
        state.loading = 'pending'
        state.canSave = false
        state.errors = null
        state.res = {
          ...state.res,
          countData: {},
        }
      })
      .addCase(processCountAsync.fulfilled, (state, action: any) => {
        state.loading = 'idle'
        state.canSave = true

        const postalCode = action.meta.arg?.postalCodes?.toString() ?? ''
        const localityByZipCode = action.payload.locality
        const localityName = localityByZipCode?.find(
          (el: any) => el.code === postalCode
        )?.name

        if (action.meta.arg?.radius && action.meta.arg?.radius > 0) {
          state.listToDisplay = [
            {
              id: '',
              code: postalCode,
              name: localityName ?? '',
            },
          ]
        } else {
          state.listToDisplay = action.payload.locality.map((el: any) => {
            return { id: el.code, code: el.code, name: el.name }
          })
        }

        // Set state.res
        const res = state.res ? current(state.res) : {}
        state.res = { ...res, countData: action.payload }

        updateDataWithResponse(state)
      })
      .addCase(processCountAsync.rejected, (state, action) => {
        state.loading = 'idle'
        state.res = null
        state.errors = action.error
      })
      .addCase(recordCountForMasterDisplayAsync.fulfilled, (state) => {
        state.canSave = false
      })
  },
})

// Selectors
type MasterDisplaySliceName = (typeof masterDisplaySlice)['name']
export type MasterDisplayStateType = Record<MasterDisplaySliceName, any>

export const masterDisplayDataSelector = (state: MasterDisplayStateType) =>
  state.masterDisplay.data
export const masterDisplayResSelector = (state: MasterDisplayStateType) =>
  state.masterDisplay.res
export const masterDisplayErrorsSelector = (state: MasterDisplayStateType) =>
  state.masterDisplay.errors
export const masterDisplayLoadingSelector = (state: MasterDisplayStateType) =>
  state.masterDisplay.loading
export const masterDisplayCanSaveSelector = (state: MasterDisplayStateType) =>
  state.masterDisplay.canSave
export const masterDisplayRadiusSelector = (state: MasterDisplayStateType) =>
  state.masterDisplay.radius
export const masterDisplayListToDisplaySelector = (
  state: MasterDisplayStateType
) => state.masterDisplay.listToDisplay

// Actions
export const { cleanState, setCanSave, setData, setRes, setRadius } =
  masterDisplaySlice.actions

// The reducer
export default masterDisplaySlice.reducer
