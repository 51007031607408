import { createAsyncThunk } from '@reduxjs/toolkit'
import { AllCountsParams, MyCounts } from '../types/counts'
import { getMyAdFactoryClass } from '../utils/myAdfactoryApi/getMyAdFactory.utils'
import { Market } from '../utils/myAdfactoryApi/swaggerApi'

export const getMyCounts = createAsyncThunk(
  'Count/getMyCounts',
  async (
    {
      page,
      itemsPerPage,
      productName,
      market = Market.Ancien,
      clientName,
      status,
      order = 'CreatedDate',
      orderDirection = 'desc',
      searchCount,
    }: MyCounts,
    getState
  ) => {
    const clientId = undefined

    const response = await getMyAdFactoryClass(getState).getMyCounts(
      page,
      itemsPerPage,
      clientId,
      productName,
      market,
      clientName,
      status,
      order,
      orderDirection,
      searchCount
    )

    return response
  }
)

export const getAllCounts = createAsyncThunk(
  'Count/getAllCounts',
  // eslint-disable-next-line no-unused-vars
  async (
    {
      page,
      itemsPerPage,
      clientId,
      productName,
      market,
      clientName,
      order,
      orderDirection,
      searchCount,
    }: AllCountsParams,
    getState
  ) => {
    const status = undefined

    /* eslint no-underscore-dangle: 0 */
    const response = await getMyAdFactoryClass(getState).getAllCounts(
      page,
      itemsPerPage,
      clientId,
      productName,
      market,
      clientName,
      status,
      order,
      orderDirection,
      searchCount
    )
    return response
  }
)

export const getCountByIdAsync = createAsyncThunk(
  'Count/getCountById',
  async (id: string, getState) => {
    const response = await getMyAdFactoryClass(getState).guid(id)
    return response
  }
)

export const deleteCount = createAsyncThunk(
  'Count/DeleteCount',
  async (id: number, getState) => {
    const response = await getMyAdFactoryClass(getState).countsDELETE(id)
    return response
  }
)
