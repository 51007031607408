import { createSlice, current } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import { getCountByIdAsync } from '../../../services/countApi'
import {
  getSelectableValuesAsync,
  processCountForPerformanceAsync,
  recordCountForPerformanceAsync,
} from '../../../services/performanceApi'

import {
  formatCityTags,
  formatCityTagsWithRadius,
  setInputTargetingsValue,
} from '../../../utils/format'
import { PERFORMANCE_PRODUCTS_FORMATS } from '../../../utils/variables'

import {
  PerformanceMarketsType,
  initialStateType,
} from '../../../types/performance.type'
import {
  MAFProduct,
  PerformanceForecastResult,
} from '../../../utils/myAdfactoryApi/swaggerApi'

export const initialState: initialStateType = {
  canSave: false,
  countNameSaved: null,
  errors: null,
  data: null,
  loading: 'idle',
  res: null,
  radius: 0,
  listToDisplay: [],
}

const initDefaultConfig = (state: initialStateType) => {
  // SellersNew Broadcast Mediums are checked by default
  if (state?.data?.targetings) {
    state.data.targetings.Sellers = {
      ...state.data.targetings.Sellers,
      checked: [false, false],
    }
    state.data.targetings.SellersNew = {
      ...state.data.targetings.SellersNew,
      checked: [true, true],
    }
    state.data.inputTargetingsValue = setInputTargetingsValue(
      state.data.targetings
    )
  }

  // By default, periodicity is OneShot in Config
  if (state.data) {
    state.data.periodicity = 'OneShot'
  }
}

const setConfigDataFromGetCount = (state: initialStateType) => {
  const data = state.data && current(state.data)
  // Targetings
  const targetingsFromDataArray =
    data?.targetings && Object.entries(data.targetings)
  const targetingsFromDisplayReqArray =
    state.res?.countData?.displayReq?.targetings

  const updatedTargetings = targetingsFromDataArray?.map((targeting: any) => {
    const updated = [...targeting]
    const filtered = targetingsFromDisplayReqArray?.filter(
      (t) => t.distributionType === updated[0]
    )
    const filteredBroadcasts = filtered?.[0]?.broadcastMediums
    // Reset default checked statement before setting with values from BO
    const checkedArray = [...updated[1].checked].map(() => false)

    filteredBroadcasts?.forEach((fb: string) => {
      if (updated[1].broadcastMediums.includes(fb)) {
        const i = updated[1].broadcastMediums.indexOf(fb)
        checkedArray[i] = true
      }
    })

    updated[1] = { ...updated[1], checked: checkedArray }
    return updated
  })

  // Locations
  const locations = state?.res?.countData.locality

  // Dates
  const dates = []
  if (state?.res?.countData.displayReq?.startDate)
    dates.push(state.res.countData.displayReq.startDate)
  if (state?.res?.countData.displayReq?.endDate)
    dates.push(state.res.countData.displayReq.endDate)

  state.data = {
    ...state.data,
    targetings: updatedTargetings && Object.fromEntries(updatedTargetings),
    locations,
    dates,
  }
  if (state.data) {
    state.data.inputTargetingsValue = setInputTargetingsValue(
      state.data.targetings
    )
  }
  defineLogosToDisplayInSummaryCard(state)
}

const defineDefaultForecastsForPendingProcessCount = (
  market: PerformanceMarketsType,
  state: initialStateType
) => {
  const defaultForecastSplitByPosition = Object.keys(
    PERFORMANCE_PRODUCTS_FORMATS
  )?.map((row: string) => {
    return {
      code: row,
      name: row,
      available: 0,
      budget: undefined, // important!
      purchased: 0,
      sold: 0,
      total: 0,
    }
  })
  state.res = {
    ...state.res,
    countData: {
      forecastSplitByPosition: defaultForecastSplitByPosition,
      mafProduct: MAFProduct.DisplayPerformance,
      totalImpPurchased: 0,
      totalBudget: 0,
    },
  }
}

const setForecastsPurchasedAndBudgetValues = (state: initialStateType) => {
  if (state.res) {
    const { forecastSplitByPosition, cpm } = state.res.countData
    const updatedForecastSplitByPosition = forecastSplitByPosition?.map(
      (fsp: PerformanceForecastResult) => {
        const updatedFsp = { ...fsp }
        const cpmFsp = cpm && updatedFsp?.code ? cpm[updatedFsp.code] : 0
        updatedFsp.purchased = updatedFsp?.click ?? 0
        updatedFsp.budget = updatedFsp.click
          ? Math.round(updatedFsp.click * cpmFsp)
          : 0
        return updatedFsp
      }
    )

    state.res.countData = {
      ...state.res.countData,
      forecastSplitByPosition: updatedForecastSplitByPosition,
    }
  }
}

const cleanResToDisplayInProgressComponent = (state: initialStateType) => {
  if (state.res) {
    state.res.countData = {}
  }
}

const defineLogosToDisplayInSummaryCard = (state: initialStateType) => {
  if (state.data) {
    const logosArray =
      state.data.targetings &&
      Object.values(state.data.targetings)?.map((targeting: any) =>
        targeting.checked?.map((c: boolean, index: number) => {
          if (c) return targeting.broadcastMediums[index]
          return null
        })
      )
    state.data.logos = logosArray?.flat()?.filter((l: string) => l)
  }
}

// Slice
const performanceSlice = createSlice({
  name: 'performance',
  initialState,
  reducers: {
    cleanState() {
      return initialState
    },
    setCanSave(state, action) {
      state.canSave = action.payload
    },
    setData(state, action) {
      state.data = action.payload
      if (state.data) {
        state.data.inputTargetingsValue = setInputTargetingsValue(
          state.data.targetings
        )
      }

      cleanResToDisplayInProgressComponent(state)
      defineLogosToDisplayInSummaryCard(state)
    },
    setRes(state, action) {
      state.res = action.payload
    },
    setRadius(state, action) {
      state.radius = action.payload.radius
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(getCountByIdAsync.pending, (state: initialStateType) => {
        state.loading = 'pending'
      })
      .addCase(
        getCountByIdAsync.fulfilled,
        (state: initialStateType, action: any) => {
          // getCountByIdAsync is used by all the products. We need to check to product name from BO
          if (action.payload?.mafProduct === MAFProduct.DisplayPerformance) {
            state.loading = 'idle'
            state.res = action.payload
            state.countNameSaved = action.payload.countData.countName ?? null
            state.errors = null
            setConfigDataFromGetCount(state)
          }
          if (state.res) {
            state.radius = action.payload.countData.displayReq?.radius

            const postalCode =
              action.payload.countData.displayReq?.postalCodes[0]
            const localityByZipCode =
              action.payload.countData.localityNameByZipCode
            if (state.radius > 0) {
              state.listToDisplay = formatCityTagsWithRadius(
                postalCode,
                localityByZipCode[postalCode]
              )
            } else {
              state.listToDisplay = formatCityTags(localityByZipCode ?? {})
            }
          }
        }
      )
      .addCase(
        getCountByIdAsync.rejected,
        (state: initialStateType, action) => {
          state.loading = 'idle'
          state.res = null
          state.countNameSaved = null
          state.errors = action.error
        }
      )
      .addCase(getSelectableValuesAsync.pending, (state: initialStateType) => {
        state.loading = 'pending'
        state.data = null
      })
      .addCase(
        getSelectableValuesAsync.fulfilled,
        (state: initialStateType, action) => {
          const payload = action?.payload
          state.loading = 'idle'
          state.data = {}
          state.data.targetings = {}
          state.data.targetings = { ...payload?.targetings }
          initDefaultConfig(state)
        }
      )
      .addCase(
        getSelectableValuesAsync.rejected,
        (state: initialStateType, action) => {
          state.loading = 'idle'
          state.data = null
          state.errors = action.error
        }
      )
      .addCase(
        processCountForPerformanceAsync.pending,
        (state: initialStateType, action: any) => {
          const { market } = action.meta.arg
          state.loading = 'pending'
          state.canSave = false
          state.errors = null
          if (market) {
            defineDefaultForecastsForPendingProcessCount(market, state)
          }
        }
      )
      .addCase(processCountForPerformanceAsync.fulfilled, (state, action) => {
        state.loading = 'idle'
        state.canSave = true
        const res = state.res ? current(state.res) : {}
        state.res = { ...res, countData: action.payload }

        const postalCode = action.meta.arg?.postalCodes?.toString() ?? ''
        const localityByZipCode = action.payload?.localityNameByZipCode

        if (action.meta.arg?.radius && action.meta.arg?.radius > 0) {
          state.listToDisplay = formatCityTagsWithRadius(
            postalCode,
            localityByZipCode?.[postalCode]
          )
        } else {
          state.listToDisplay = formatCityTags(localityByZipCode ?? {})
        }
        setForecastsPurchasedAndBudgetValues(state)

        // update data.locations with data from processCount result
        const data = state.data ? current(state.data) : {}
        if (state?.res?.countData?.locality)
          state.data = { ...data, locations: state.res.countData.locality }
      })
      .addCase(processCountForPerformanceAsync.rejected, (state, action) => {
        state.loading = 'idle'
        state.res = null
        state.errors = action.error
      })
      .addCase(recordCountForPerformanceAsync.fulfilled, (state) => {
        state.canSave = false
      })
  },
})

// Selectors
type PerformanceSliceName = (typeof performanceSlice)['name']
export type PerformanceStateType = Record<PerformanceSliceName, any>

export const performanceDataSelector = (state: PerformanceStateType) =>
  state.performance.data
export const performanceResSelector = (state: PerformanceStateType) =>
  state.performance.res
export const performanceErrorsSelector = (state: PerformanceStateType) =>
  state.performance.errors
export const performanceLoadingSelector = (state: PerformanceStateType) =>
  state.performance.loading
export const performanceCanSaveSelector = (state: PerformanceStateType) =>
  state.performance.canSave
export const performanceCountNameSavedSelector = (
  state: PerformanceStateType
) => state.performance.countNameSaved
export const performanceRadiusSelector = (state: PerformanceStateType) =>
  state.performance.radius
export const performanceListToDisplaySelector = (state: PerformanceStateType) =>
  state.performance.listToDisplay

// Actions
export const { cleanState, setCanSave, setData, setRes, setRadius } =
  performanceSlice.actions

// The reducer
export default performanceSlice.reducer
