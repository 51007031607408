import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ImgBlock, InputWrapper, SpanCoverage, SpanMax, StrongBudget, TableStyled, ThBlue, TotalAndBudget } from '../common/styled'

import Loader from '../../Loader/Loader'

import { displayCanSaveSelector, setCanSave } from '../../../redux/reducers/Display/display.reducer'

import { getLogoBroadcastMedium, formatNumbers } from '../../../utils/tsUtils'
import { ForecastResult } from '../../../utils/myAdfactoryApi/swaggerApi'

type PrintByBroadcastProps = {
  forecastResult: ForecastResult[],
  cpms?: { [key: string]: number },
  isProposal?: boolean,
  loading: string,
  totalBudget: number,
  totalOffer: number,
  onChange?: (data: any) => void,
}

export const defineMaximumBudget = (data: any, cpm: number = 0) => {
  if (data.available) {
    return Math.round((cpm * data.available) / 1000)
  }
  return 0
}

export const defineAvailableImp = (fr: ForecastResult) => Math.round(fr?.available ?? 0)
export const definePurchasedImp = (fr: ForecastResult) => Math.round(fr?.purchased ?? 0)
export const defineCpm = (fr: ForecastResult, cpms: { [key: string]: number } | undefined) => {
  if (fr.code && cpms && cpms[fr.code]) {
    return cpms[fr.code]
  }
  return 0
}

const InputBudget = ({ data, onChange, cpm }: { data: any, onChange: (data: any) => void, cpm: number }): React.ReactElement | null => {
  const dispatch = useDispatch()
  const canSave = useSelector(displayCanSaveSelector)

  const [budgetValue, setBudgetValue] = useState(data.budget)

  const maximumBudget: number = defineMaximumBudget(data, cpm)
  const isDisabled = maximumBudget === 0

  const handleChange = (event: any) => {
    if (!canSave) dispatch(setCanSave(true))

    const valueWithoutSpacing = event.target.value.replace(/\D+/g, '')
    if (!Number.isNaN(valueWithoutSpacing)) {
      if (valueWithoutSpacing === '') {
        return setBudgetValue(0)
      }

      if (valueWithoutSpacing > maximumBudget) {
        return false
      }

      return setBudgetValue(parseInt(valueWithoutSpacing))
    }
    return false
  }

  /**
   * WHEN PURCHASED INPUT IS USED, SET BUDGET INPUT WITH NEW VALUE FROM DATA
   */
  useEffect(() => {
    setBudgetValue(data.budget)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.purchased])

  /**
   * IF BUGDET INPUT IS USED, CALCULATE THE NEW PURCHASED VALUE OF DATA AND UPDATE
   */
  useEffect(() => {
    const broadcastData = {
      ...data,
      purchased: !Number.isNaN(Math.round((budgetValue / cpm) * 1000)) ? Math.round((budgetValue / cpm) * 1000) : 0,
      budget: budgetValue,
    }
    onChange(broadcastData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetValue])

  return (
    <div className={isDisabled ? 'isDisabled' : ''}>
      <InputWrapper>
        <input
          disabled={isDisabled}
          type="text"
          value={formatNumbers(budgetValue)}
          onChange={handleChange}
        />
        <span>€</span>
      </InputWrapper>
      <SpanMax>Max. {formatNumbers(maximumBudget)}€</SpanMax>
    </div>
  )
}

const InputPrint = ({ data, onChange, cpm }: { data: any, onChange: (data: any) => void, cpm: number }): React.ReactElement | null => {
  const dispatch = useDispatch()
  const canSave = useSelector(displayCanSaveSelector)

  const [printValue, setPrintValue] = useState(Math.min(data.purchased, data.available))

  const isDisabled = data.available === 0

  const handleChange = (event: any) => {
    if (!canSave) dispatch(setCanSave(true))

    const valueWithoutSpacing = event.target.value.replace(/\D+/g, '')
    if (!Number.isNaN(valueWithoutSpacing)) {
      if (valueWithoutSpacing === '') {
        return setPrintValue(0)
      }

      if (valueWithoutSpacing > data.available) {
        return false
      }

      return setPrintValue(parseInt(valueWithoutSpacing))
    }
    return false
  }

  /**
   * WHEN BUDGET INPUT IS USED, SET PUSCHASED INPUT WITH NEW VALUE FROM DATA
   */
  useEffect(() => {
    setPrintValue(Math.min(data.purchased, data.available))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.budget])

  /**
   * IF PUSCHASED INPUT IS USED, CALCULATE THE NEW BUDGET VALUE OF DATA AND UPDATE
   */
  useEffect(() => {
    const broadcastData = {
      ...data,
      purchased: printValue,
      budget: !Number.isNaN(Math.round((printValue / 1000) * cpm)) ? Math.round((printValue / 1000) * cpm) : 0,
    }
    onChange(broadcastData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printValue])

  return (
    <div className={isDisabled ? 'isDisabled' : ''}>
      <InputWrapper>
        <input
          disabled={isDisabled}
          type="text"
          value={formatNumbers(printValue)}
          onChange={handleChange}
        />
        <span>Imp.</span>
      </InputWrapper>
      <SpanCoverage>Soit {(Math.round(((printValue ?? 0) / (data.total ?? 0)) * 100) || 0)}% de couverture</SpanCoverage>
    </div>
  )
}

const PrintsByBroadcastTable = ({
  forecastResult,
  cpms,
  isProposal = false,
  loading,
  totalBudget,
  totalOffer,
  onChange = (() => true),
}: PrintByBroadcastProps): React.ReactElement => {
  const isPending = loading === 'pending'

  return (
    <TableStyled data-testid="PrintsByBroadcastTable">
      <thead>
        <tr>
          <th scope="col">
            Contexte de diffusion
          </th>

          {!isProposal && <th scope="col">Déjà vendues <span>(sur la période)</span></th>}
          {!isProposal && <th scope="col">Disponibles <span>(sur la période)</span></th>}
          <ThBlue scope="col">Impressions proposées <span>(sur la période)</span></ThBlue>
          <ThBlue scope="col">Budgets <span>(hors taxe)</span></ThBlue>
        </tr>
      </thead>
      <tbody>
        {forecastResult?.length > 0 && (
          forecastResult.map((fr: ForecastResult) => {
            const availableImp = defineAvailableImp(fr)
            const purchasedImp = definePurchasedImp(fr)
            const cpm = defineCpm(fr, cpms)

            return (
              <tr key={`row-${fr.code}`}>
                <td>
                  {fr.code && (
                    <ImgBlock>
                      <img src={getLogoBroadcastMedium(fr.code)} alt={fr.code} />
                    </ImgBlock>
                  )}
                </td>
                {!isProposal && (
                  <>
                    <td>
                      {isPending ? (
                        <Loader maxWidth={100} height={20} />
                      ) : (
                        <>{formatNumbers(fr.sold ?? 0)} Imp.</>
                      )}
                    </td>
                    <td>
                      {isPending ? (
                        <>
                          <Loader maxWidth={100} height={20} />
                          <SpanCoverage><Loader maxWidth={150} height={15} /></SpanCoverage>
                        </>
                      ) : (
                        <>
                          {formatNumbers(availableImp)} Imp.
                          {fr?.available && fr?.total ? <SpanCoverage>Soit {Math.round((fr.available / fr.total) * 100) || 0}% de couverture</SpanCoverage> : ''}
                        </>
                      )}
                    </td>
                  </>
                )}
                <td data-testid={`inputPrint-${fr.code}`}>
                  {isPending ? (
                    <>
                      <Loader maxWidth={300} height={35} style={{ marginBottom: '5px' }} />
                      <Loader maxWidth={120} height={20} />
                    </>
                  ) : (
                    <>
                      {!isProposal ? (
                        <InputPrint data={fr} onChange={onChange} cpm={cpm} />
                      ) : (
                        <StrongBudget>{formatNumbers(Math.min(purchasedImp, availableImp))} Imp.</StrongBudget>
                      )}
                    </>
                  )}
                </td>
                <td data-testid={`inputBudget-${fr.code}`}>
                  {(isPending || fr.budget === undefined) ? (
                    <>
                      <Loader maxWidth={300} height={35} style={{ marginBottom: '5px' }} />
                      <Loader maxWidth={80} height={20} />
                    </>
                  ) : (
                    <>
                      {!isProposal ? (
                        <InputBudget data={fr} onChange={onChange} cpm={cpm} />
                      ) : (
                        <StrongBudget>{fr.budget} €</StrongBudget>
                      )}
                    </>
                  )}
                </td>
              </tr>
            )
          })
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={!isProposal ? 3 : 1} style={{ textAlign: 'right' }}>
            <strong>Total du comptage :</strong>
          </td>
          <td>
            {isPending ? (
              <Loader maxWidth={80} height={20} />
            ) : (
              <TotalAndBudget $hideBudget={false}>
                <strong>{formatNumbers(totalOffer)} Imp.</strong>
              </TotalAndBudget>
            )}
          </td>
          <td>
            {isPending ? (
              <Loader maxWidth={80} height={20} style={{ marginLeft: 'auto' }} />
            ) : (
              <StrongBudget>{formatNumbers(totalBudget)} €</StrongBudget>
            )}
          </td>
        </tr>
      </tfoot>
    </TableStyled>
  )
}

export default PrintsByBroadcastTable
