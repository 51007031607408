import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import mapBackground from '../../../../assets/images/map_background.jpg'

import CountInProgress from '../../../Counts/CountInProgress/CountInProgress'
import VedettePlusSummaryCounts from '../VedettePlusSummaryCount/VedettePlusSummaryCount'
import PrintsByLocalitiesTableForVedettePlus from '../../../Tables/PrintsByLocalitiesTableForVedettePlus/PrintsByLocalitiesTableForVedettePlus'
import CountsFooter from '../../../Counts/CountsFooter/CountsFooter'
import Banner from '../../../Banner/Banner'

import {
  vedettePlusCanSaveSelector,
  vedettePlusCountNameSavedSelector,
  vedettePlusLoadingSelector,
  vedettePlusResSelector,
  vedettePlusExcludedLocalitiesSelector,
} from '../../../../redux/reducers/VedettePlus/vedettePlus.reducer'

import { PlaceBo } from '../../../../utils/myAdfactoryApi/swaggerApi'

const VedettePlusCount = (): React.ReactElement => {
  const res = useSelector(vedettePlusResSelector)
  const loading: string = useSelector(vedettePlusLoadingSelector)
  const canSave: boolean = useSelector(vedettePlusCanSaveSelector)
  const countNameSaved: string | null = useSelector(vedettePlusCountNameSavedSelector)
  const excludedLocalities = useSelector(vedettePlusExcludedLocalitiesSelector)

  if (!res || (res?.countData && Object.keys(res.countData).length === 0)) {
    return <CountInProgress dataTestId="vedettePlusCount" />
  }

  return (
    <VedettePlusCountWrapper data-testid="vedettePlusCount">
      <VedettePlusCountMap style={{ backgroundImage: `url(${mapBackground})` }} />
      <VedettePlusCountSummary>
        <VedettePlusCountTitle>Résumé du comptage</VedettePlusCountTitle>
        <VedettePlusCountSummaryBlock>
          <VedettePlusSummaryCounts />
        </VedettePlusCountSummaryBlock>
        {excludedLocalities?.length > 0 && (
          <Banner dataTestId="intensityUnavailable" style={{ marginTop: '2em', marginBottom: '2em' }}>
            <>
              <h6>Intensités non disponibles</h6>
              <p>Certaine localités sélectionnées ne sont pas disponible pour cette période.</p>
              <p>Ces localités ont été retirées :</p>
              {excludedLocalities?.length > 0 && (
                <ul>
                  {excludedLocalities.map((locality: PlaceBo) => (
                    <li key={`locality-unavailable-${locality?.id}`}>{locality?.name} ({locality?.code})</li>
                  ))}
                </ul>
              )}
            </>
          </Banner>
        )}
        {res?.countData?.forecasts?.length > 0
        && (
          <>
            <PrintsByLocalitiesTableForVedettePlus
              localitiesResult={res?.countData?.forecasts}
              loading={loading}
              totalBudget={res?.countData?.totalBudget ?? 0}
            />
            <CountsFooter
              canSave={canSave}
              countNameSaved={countNameSaved}
              loading={loading}
              res={res}
            />
          </>
        )}
      </VedettePlusCountSummary>
    </VedettePlusCountWrapper>
  )
}

const VedettePlusCountWrapper = styled.div`
  position: relative;
`

const VedettePlusCountMap = styled.div`
  position: absolute;
  left: 0;
  top: 13em;
  width: 100%;
  height: 30em;
  background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
  background-size: cover;
`

const VedettePlusCountSummary = styled.div`
  position: absolute;
  padding: 8.5em 2.5em 10em 2.5em;
  width: 100%;
`

const VedettePlusCountTitle = styled.p`
  font-size: 1.5em;
  font-weight: 700;
  color: ${(props) => props.theme.global.colorPalette.black};
`

const VedettePlusCountSummaryBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  height: 35em;
  margin-bottom: 6em;
`

export default VedettePlusCount
