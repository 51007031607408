/**
 * This custom hook is used to define the current base of the emailing product currently viewed by the user from the pathname
 * values: 'optin', 'alert' or ''
 */
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { BaseComptage } from '../utils/myAdfactoryApi/swaggerApi'

export const useEmailingBase = () => {
  const location = useLocation()

  const defineBase = useCallback(() => {
    if (location?.pathname.includes('/emailing/optin')) return BaseComptage.Optin
    if (location?.pathname.includes('/emailing/alert')) return BaseComptage.Alerte
    return BaseComptage.Optin
  }, [location])

  const emailingBase = defineBase()

  return {
    emailingBase,
  }
}
