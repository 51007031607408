/**
 * This custom hook is used to check, when editing a product using dates in configuration, whether it is in the past or in the future.
 * If in the past, the product must prevent a processCount using a date in the past.
 */
import { useRef } from 'react'
import { useMode } from './useMode'

import { isDateInPast } from '../utils/date'

import { ModesType } from '../types/Modes.type'

export const useControlDatePicker = (): { controlDateIfEditionMode: (startDate: string) => boolean, reInitDatePicker: () => void, needToReinitDatePicker: boolean } => {
  const { mode } = useMode()

  // Temp, for emailing before refacto
  // ---------
  const needToReinitDatePicker = useRef<boolean>(false)
  const reInitDatePicker = () => {
    needToReinitDatePicker.current = true
  }
  // ---------

  const controlDateIfEditionMode = (startDate: string): boolean => {
    if (mode === ModesType.Edit && startDate && isDateInPast(startDate)) return false
    return true
  }

  return {
    controlDateIfEditionMode,
    reInitDatePicker,
    needToReinitDatePicker: needToReinitDatePicker.current,
  }
}
