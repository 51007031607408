import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'

import { Popin, Button } from '@ui-library/core'
import { ReloadLined, AddBold } from '@ui-library/icons'

import RangeComponent from '../../../RangeComponent/RangeComponent'

import {
  emailingDataSelector,
  setData,
} from '../../../../redux/reducers/Emailing/emailing.reducer'

import { useMode } from '../../../../hooks/useMode'

import { ModesType } from '../../../../types/Modes.type'
import TypesProduct from './TypesProduct/TypesProduct'

const EmailingOptionalCriteria = (): React.ReactElement => {
  const dispatch = useDispatch()

  const { mode } = useMode()

  const data = useSelector(emailingDataSelector)

  const [isPopinOpen, setIsPopinOpen] = useState<boolean>(false)
  const [optionsIndicator, setOptionsIndicator] = useState <number>(0)
  const [typeOfProduct, setTypeOfProduct] = useState<string[]>([])
  const [isRangePriceUpdated, setIsRangePriceUpdated] = useState<boolean>(false)
  const [isRangeNbPiecesUpdated, setIsRangeNbPiecesUpdated] = useState<boolean>(false)
  const [isResetClicked, setIsResetClicked] = useState<boolean>(false)

  const closeModalCriterias = () => {
    setIsPopinOpen(false)
  }

  const resetOptions = () => {
    const updatedData = {
      ...data,
      options: {
        productTypes: [],
        minPrice: 1,
        maxPrice: 10000000,
        minNbPieces: 1,
        maxNbPieces: 5,
      },
    }

    dispatch(setData(updatedData))
    setOptionsIndicator(0)
    setIsResetClicked(true)
    setTypeOfProduct([])
  }

  const handleRangePriceUpdated = (value: boolean) => {
    setIsRangePriceUpdated(value)
  }

  const handleRangeNbPiecesUpdated = (value: boolean) => {
    setIsRangeNbPiecesUpdated(value)
  }

  // Indicator
  useEffect(() => {
    let acc = 0
    if (data?.options?.productTypes?.length > 0) acc++
    if (data?.options?.minPrice !== 1 || data?.options?.maxPrice !== 10000000) acc++
    if (data?.options?.minNbPieces !== 1 || data?.options?.maxNbPieces !== 5) acc++
    setOptionsIndicator(acc)
  }, [data.options])

  /**
   * If edition mode, set defaultValues for <TypesProduct>
   */
  useEffect(() => {
    if (mode === ModesType.Edit && data?.options?.productTypes) setTypeOfProduct(data.options.productTypes)
  }, [data, mode])

  return (
    <>
      <ButtonStyled
        $hideOptionsIndicator={optionsIndicator === 0}
        icon={AddBold}
        iconPosition="left"
        kind="primary"
        size="medium"
        tag="button"
        variant="text"
        onClick={() => { setIsPopinOpen(true) }}
      >
        Plus de critères <OptionsIndicator $hideOptionsIndicator={optionsIndicator === 0}>{optionsIndicator}</OptionsIndicator>
      </ButtonStyled>
      <Popin
        open={isPopinOpen}
        size="small"
        onClose={closeModalCriterias}
      >
        <PopinMoreCriteria>
          <h3> Plus de critères </h3>
          <TypesProduct
            typeOfProduct={typeOfProduct}
            settingProduct={setTypeOfProduct}
          />
          <RangeComponent
            mode="price"
            rangeTitle="Budget du projet immobilier"
            suiteTitle="(facultatif)"
            isRangeUpdated={isRangePriceUpdated}
            setIsRangeUpdated={handleRangePriceUpdated}
            isResetClicked={isResetClicked}
            setIsResetClicked={setIsResetClicked}
          />
          <RangeComponent
            mode="nbPiece"
            rangeTitle="Nombre de pièces"
            suiteTitle="(facultatif)"
            isRangeUpdated={isRangeNbPiecesUpdated}
            setIsRangeUpdated={handleRangeNbPiecesUpdated}
            isResetClicked={isResetClicked}
            setIsResetClicked={setIsResetClicked}
          />
          <PopinMoreCriteriaFooter>
            <PopinMoreCriteriaFooterBtns>
              <PopinMoreCriteriaFooterReset>
                <Button
                  icon={ReloadLined}
                  kind="primary"
                  size="medium"
                  tag="button"
                  variant="text"
                  onClick={resetOptions}
                >
                  Réinitialiser
                </Button>
              </PopinMoreCriteriaFooterReset>
              <div>
                <Button
                  kind="primary"
                  size="medium"
                  tag="button"
                  variant="normal"
                  onClick={closeModalCriterias}
                >
                  Valider
                </Button>
              </div>
            </PopinMoreCriteriaFooterBtns>
          </PopinMoreCriteriaFooter>
        </PopinMoreCriteria>
      </Popin>
    </>
  )
}

type ButtonStyledProps = {
  $hideOptionsIndicator?: boolean,
}

type OptionsIndicatorProps = {
  $hideOptionsIndicator?: boolean,
}

const ButtonStyled = styled(Button)<ButtonStyledProps>`
  padding-left: 0.75em;
  padding-right: 2.188em;
  height: 100%;
  background-color: ${(p) => p.theme.global.colorPalette.lighterSaphir};
  border: ${(props) => `1px solid ${props.theme.global.colorPalette.greyLight}`};
  box-sizing: border-box;
  border-radius: 4px;

  div {
    margin-right: 4px;
  }

  span {
    font-weight: normal;
    font-size: 1em;
    line-height: 1.375em;
  }

  &:hover {
    border: ${(props) => `1px solid ${props.theme.global.colorPalette.greyLight}`} !important;
  }

  ${(props: any) => props.$hideOptionsIndicator && css`
    background-color: transparent;
  `}
`

const OptionsIndicator = styled.span<OptionsIndicatorProps>`
  position: absolute;
  margin-left: 0.513em;
  margin-top: 0.213em;
  width: 1.375em;
  height: 1.375em;
  background-color: ${(props) => props.theme.global.colorPalette.saphir};
  color: ${(props) => props.theme.global.colorPalette.white};
  font-size: 0.875em !important;
  font-weight: 600 !important;
  border-radius: 0.75em;

  ${(props: any) => props.$hideOptionsIndicator && css`
    display: none;
  `}
`

const PopinMoreCriteria = styled.div`
  h3 {
    margin-bottom: 1em;
    font-size: 1.5em;
    line-height: 1.875em;
    font-weight: bold;
  }
`

const PopinMoreCriteriaFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3em;
  margin-left: -1.5em;
  width: calc(100% + 3em);
  box-shadow: 0px -1px 0px #DDD;
`

const PopinMoreCriteriaFooterBtns = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  width: 92%;
`

const PopinMoreCriteriaFooterReset = styled.div`
  display: flex;
  align-items: center;
`

export default EmailingOptionalCriteria
