import { createAsyncThunk } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import { hideNotification } from '../redux/reducers/Notifications/notifications.reducer'

import { getMyAdFactoryClass } from '../utils/myAdfactoryApi/getMyAdFactory.utils'
import {
  MasterDisplayReq,
  MasterDisplayResp,
} from '../utils/myAdfactoryApi/swaggerApi'

export const processCountAsync = createAsyncThunk(
  'masterDisplay/ProcessCount',
  async (
    {
      broadcastMediums,
      periodicity,
      postalCodes,
      projectTypes,
      market,
      radius,
    }: MasterDisplayReq,
    getState
  ) => {
    const body: MasterDisplayReq = {
      broadcastMediums,
      market,
      periodicity: periodicity,
      postalCodes,
      projectTypes,
      radius,
    }

    const response = await getMyAdFactoryClass(getState).processCount5(body)

    return response
  }
)

export const recordCountForMasterDisplayAsync = createAsyncThunk(
  'masterDisplay/RecordCount',
  async (
    {
      forecasts,
      mafProduct,
      masterDisplayReq,
      totalBudget,
      totalImpPurchased,
    }: MasterDisplayResp,
    getState
  ) => {
    const body: MasterDisplayResp = {
      forecasts,
      mafProduct,
      masterDisplayReq,
      totalBudget,
      totalImpPurchased,
    }
    let response = null
    response = await getMyAdFactoryClass(getState).recordCount5(body)

    setTimeout(() => {
      getState.dispatch(hideNotification())
    }, 5000)
    return response
  }
)
