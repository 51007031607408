import React from 'react'
import styled, { css } from 'styled-components'

import Icon from '@ui-library/core/Icon'
import SortLined from '@ui-library/icons/SortLined'
import SortUpLined from '@ui-library/icons/SortUpLined'

import type { TableHeadProps } from '../../../../types/Tables.type'

const TableHeadUsers = ({ setCol, col, setSortBy, sortBy, getData, currentPage }: TableHeadProps) => {
  const handleSort = (order: string) => {
    const newSortBy = { ...sortBy }
    // click on another column
    if (col !== order) {
      newSortBy[order] = 'desc'
      setSortBy(newSortBy)
      setCol(order)
      getData(currentPage, order, newSortBy[order])
      return
    }
    // click on the same column
    if (col === order) {
      if (sortBy[order] === 'desc') {
        newSortBy[order] = 'asc'
      } else {
        newSortBy[order] = 'desc'
      }
      setSortBy(newSortBy)
    } else {
      setCol(order)
    }
    getData(currentPage, order, newSortBy[order])
  }

  // Handle sort icon
  const arrowSortIcon = (column: string) => {
    // click on another column
    if (column !== col) return SortLined
    // click on the same column
    if (sortBy[column] === 'asc') return SortUpLined
    return SortLined
  }

  return (
    <thead data-testid="table.Header">
      <tr>
        <th>{}</th>
        <ThCellFix data-testid="lastName" $isBlue={col === 'lastName'} onClick={() => handleSort('lastName')}>
          Nom <IconStyled data-testid="lastNameIcon" $isBlue={col === 'lastName'} icon={arrowSortIcon('lastName')} size="16px" />
        </ThCellFix>
        <ThCellFix data-testid="firstName" $isBlue={col === 'firstName'} onClick={() => handleSort('firstName')}>
          Prénom <IconStyled data-testid="firstNameIcon" $isBlue={col === 'firstName'} icon={arrowSortIcon('firstName')} size="16px" />
        </ThCellFix>
        <ThBlue data-testid="email" $isBlue={col === 'email'} onClick={() => handleSort('email')}>
          Email <IconStyled data-testid="emailIcon" $isBlue={col === 'email'} icon={arrowSortIcon('email')} size="16px" />
        </ThBlue>
        <ThBlue data-testid="typeProfil" $isBlue={col === 'typeProfil'} onClick={() => handleSort('typeProfil')}>
          Métier <IconStyled data-testid="typeProfilIcon" $isBlue={col === 'typeProfil'} icon={arrowSortIcon('typeProfil')} size="16px" />
        </ThBlue>
        <ThCellFix data-testid="market" $isBlue={col === 'market'} onClick={() => handleSort('market')}>
          Marché <IconStyled data-testid="marketIcon" $isBlue={col === 'market'} icon={arrowSortIcon('market')} size="16px" />
        </ThCellFix>
      </tr>
    </thead>
  )
}

type ThCellFixProps = {
  $isBlue?: boolean,
}

type ThBlueProps = {
  $isBlue?: boolean,
}

type IconStyledProps = {
  $isBlue?: boolean,
}

const ThCellFix = styled.th<ThCellFixProps>`
  width: 12%;
  color: ${(p) => p.theme.global.colorPalette.black};

  ${(props: any) => props.$isBlue && css`
    color: ${(p) => p.theme.global.colorPalette.saphir};
    font-weight: 600;
  `}
`

const ThBlue = styled.th<ThBlueProps>`
  color: ${(p) => p.theme.global.colorPalette.black};

  ${(props: any) => props.$isBlue && css`
    color: ${(p) => p.theme.global.colorPalette.saphir};
    font-weight: 600;
  `}
`

const IconStyled = styled(Icon)<IconStyledProps>`
  position: relative;
  top: 1px;
  color: ${(p) => p.theme.global.colorPalette.greyLight};

  ${(props: any) => props.$isBlue && css`
    color: ${(p) => p.theme.global.colorPalette.saphir};
  `}
`

export default TableHeadUsers
