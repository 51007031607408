import { productNamesType } from '../types/productNames.types'
import { SelectElementType } from '../types/select.type'
import { TargetingsTypes } from '../types/targetings.type'
import { ForecastResult, PlaceBo } from './myAdfactoryApi/swaggerApi'

export const formatCityTags = (localityNameByZipCode: {
  [key: string]: string[]
}): PlaceBo[] => {
  if (!localityNameByZipCode) return []
  const localityObj = Object.entries(localityNameByZipCode)
  return localityObj
    .map((loc) => {
      const code = loc[0]
      const name = loc[1].length > 1 ? 'Toutes les villes' : loc[1][0]
      return { code, name, id: name }
    })
    .sort((a, b) => Number(a.code) - Number(b.code))
}

export const formatCityTagsWithRadius = (
  postalCode: string,
  cityName: string[] | undefined
): PlaceBo[] => {
  if (!cityName) return []
  const name = cityName?.length > 1 ? 'Toutes les villes' : cityName?.[0]
  return [
    {
      id: name,
      code: postalCode,
      name: name ?? '',
    },
  ]
}

export const formatZipCodeWithManyLocalityNames = (
  locations: PlaceBo[],
  localityNameByZipCode: { [key: string]: string[] } | undefined
) =>
  locations?.map((loc: ForecastResult) => {
    if (
      loc.code &&
      localityNameByZipCode &&
      localityNameByZipCode?.[loc.code]?.length > 1
    ) {
      return {
        ...loc,
        name: 'Toutes les villes',
        nameLight: 'Toutes les villes',
      }
    }
    return loc
  })

export const formatSelectableValues = (
  selectableValues: object | undefined,
  param: string
) => {
  const tab: SelectElementType[] = []
  if (selectableValues) {
    Object.entries(selectableValues).forEach(([key, value]) =>
      tab.push({ label: value[param], value: key, disabled: value.disabled })
    )
  }
  return tab
}

export const formatProductNames = (
  productName: keyof typeof productNamesType
): productNamesType => productNamesType?.[productName] ?? productName

export const setInputTargetingsValue = (targetings: TargetingsTypes) => {
  if (targetings && Object.entries(targetings).length === 0) return undefined

  const selectedTargets: string[] = []
  const targetingsArray = (targetings && Object.entries(targetings)) ?? []

  targetingsArray?.forEach((targeting: any) => {
    targeting?.[1].checked?.forEach((c: boolean, i: number) => {
      if (c) selectedTargets.push(targeting?.[1].broadcastMediums?.[i])
    })
  })

  if (selectedTargets.length === 0) return undefined

  const etc = selectedTargets.length > 0 ? '...' : ''
  const more =
    selectedTargets.length > 2 ? `+${selectedTargets.length - 2}` : ''
  return `${selectedTargets.join(',').substring(0, 16)}${etc} ${more}`
}
