import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Popin, TextArea } from '@ui-library/core'
import DispositifDisplayImage from '../../../../assets/images/expert360/DispositifDisplayImage.svg'
import cardOffersRetargeting from '../../../../assets/images/expert360/cardOffersRetargeting.svg'
import cardOffersBrandContent from '../../../../assets/images/expert360/cardOffersBrandContent.svg'

import Expert360DetailsPopin from '../Expert360DetailsPopin/Expert360DetailsPopin'
import CardInline from '../../../Cards/CardInline/CardInline'

import { formatNumbers } from '../../../../utils/tsUtils'
import { ClientIntensity } from '../../../../utils/myAdfactoryApi/swaggerApi'

type Expert360DetailsProps = {
  brandContentCommentary: string,
  isPrintOptionSelected: boolean,
  isProposal?: boolean,
  relativeInformations: '1 contenu / an' | 'Aucun contenu',
  selectedIntensity: ClientIntensity,
  selectedIntensityBudget: string,
  handleBrandContentCommentary?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
}

const DetailsButton = ({ selectedIntensity }: { selectedIntensity: ClientIntensity }): React.ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <ButtonStyled
        data-testid="xp360DetailsButton"
        kind="secondary"
        size="small"
        tag="button"
        variant="normal"
        onClick={() => setIsOpen(!isOpen)}
      >
        Afficher le détail estimatif
      </ButtonStyled>
      <PopinStyled
        size="large"
        testID="popinImpressionDetails"
        open={isOpen}
        onClose={() => setIsOpen(!isOpen)}
      >
        <Expert360DetailsPopin selectedIntensity={selectedIntensity} />
      </PopinStyled>
    </>
  )
}

const Expert360Details = ({
  brandContentCommentary,
  isPrintOptionSelected,
  isProposal = false,
  relativeInformations,
  selectedIntensity,
  selectedIntensityBudget,
  handleBrandContentCommentary = () => true,
}: Expert360DetailsProps): React.ReactElement => (
  <div data-testid="expert360Details">
    {!isProposal && <h4>Composantes de l'offre</h4>}
    <CardInline
      additionalButton={!isProposal ? <DetailsButton selectedIntensity={selectedIntensity} /> : null}
      image={DispositifDisplayImage}
      rightTitle={`${formatNumbers(selectedIntensity?.impressions)} impressions / mois`}
      rightDesc={`Soit une part de présence moyenne de ${selectedIntensity?.shareOfVoice}/10`}
      subTitleCard="(Bannières)"
      tags={['Notoriété', 'Display']}
      titleCard="Dispositif Display"
    >
      <>
        <p>Maximisez votre visibilité grâce à la diffusion de bannières publicitaires à votre image sur les pages de résultats des sites <strong>SeLoger</strong> & <strong>Logic-Immo</strong> (desktop & mobile)</p>
        <p><em>5 emplacements variables selon l'affichage</em></p>
      </>
    </CardInline>
    <CardInline
      image={cardOffersRetargeting}
      rightTitle={`${formatNumbers(selectedIntensity?.impressions)} impressions / mois`}
      subTitleCard="(Retargeting)"
      tags={['Notoriété', 'Extension', 'Performance', 'Mémorisation']}
      titleCard="Reciblage publicitaire"
    >
      <>
        <p>Exploitez la puissance de la data du Groupe SeLoger en reciblant les internautes ayant réalisé une recherche sur nos médias et soyez visible sur des 97% des sites français à forte notoriété tels que :</p>
        <p><strong>Le Monde, L'Equipe, Marmiton, Au féminin...</strong></p>
      </>
    </CardInline>
    {isPrintOptionSelected && (
      <CardInline
        image={cardOffersBrandContent}
        rightTitle={relativeInformations}
        tags={['Notoriété', 'Expertise', 'E-réputation', 'Référencement naturel']}
        titleCard="Brand Content"
      >
        <p>Affirmez votre expertise sur votre marché à travers une interview réalisée par un journaliste professionnel et diffusée sur les espaces édito de <strong>SeLoger</strong> et <strong>Logic-Immo</strong>.</p>
        {!isProposal ? (
          <div id="commentBrandContent">
            <TextArea
              label="Commentaires"
              value={brandContentCommentary ?? ''}
              maxCharacter={500}
              resize="vertical"
              onChange={handleBrandContentCommentary}
            />
          </div>
        ) : (
          <>
            {brandContentCommentary && (
              <Expert360DetailsPreviewComments>
                <small>Commentaires :</small>
                <p>{brandContentCommentary}</p>
              </Expert360DetailsPreviewComments>
            )}
          </>
        )}
      </CardInline>
    )}
    <TotalBudgetRecap><p>Budget total mensuel (HT)</p><span>{selectedIntensityBudget} €</span></TotalBudgetRecap>
  </div>
)

const ButtonStyled = styled(Button)`
  color: ${(props) => props.theme.global.colorPalette.black} !important;
  border-color: ${(props) => props.theme.global.colorPalette.black} !important;

  &:before {
    background: ${(props) => props.theme.global.colorPalette.white} !important;
  }

  &:hover, &:active, &:focus {
    color: ${(props) => props.theme.global.colorPalette.black} !important;
    border-color: ${(props) => props.theme.global.colorPalette.black} !important;

    &:before {
      background: ${(props) => props.theme.global.colorPalette.greyLighter} !important;
    }
  }
`

const PopinStyled = styled(Popin)`
  > div {
    max-width: 1150px;
    height: auto;
    padding-bottom: 2em;
  }
`

const TotalBudgetRecap = styled.div`
  display:flex;
  justify-content: flex-end;
  margin-top: 1.5em;

  p{
    font-size: 1.5em
  }

  span{
    margin-left: 0.3em;
    font-size: 1.5em;
    font-weight: 600;
  }
`

const Expert360DetailsPreviewComments = styled.div`
  margin: 1em 0 2em 0;
  font-style: italic;
  
    small, p {
      font-size: 1em;
      display: block;
    }

    small {
      font-weight: 600;
    }
  }
`

export default Expert360Details
