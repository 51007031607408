import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DocumentLined, CalendarLined, DesktopLined, SellRegular } from '@ui-library/icons'

import ResumeCard from '../../../ResumeCard/ResumeCard'

import { expert360DataSelector, expert360LoadingSelector, expert360MissingLocalitiesSelector, expert360ResSelector } from '../../../../redux/reducers/Expert360/expert360.reducer'

import { formatNumbers, isPrintOptionForIntensitySelected } from '../../../../utils/tsUtils'

import { BudgetInformationType, TableInformationType } from '../../../../types/ResumeCard.type'
import { productNamesType } from '../../../../types/productNames.types'
import { ForecastResult } from '../../../../utils/myAdfactoryApi/swaggerApi'
import { EXPERT_360_MINIMUM_BUDGET_TO_OFFER_PRINT_OPTION } from '../../../../utils/variables'
import { formatZipCodeWithManyLocalityNames } from '../../../../utils/format'

type Expert360SummaryCountProps = {
  isProposal?: boolean,
  selectedIntensityBudget?: string,
}

const Expert360SummaryCount = ({ isProposal = false, selectedIntensityBudget }: Expert360SummaryCountProps): React.ReactElement | null => {
  const loading = useSelector(expert360LoadingSelector)
  const data = useSelector(expert360DataSelector)
  const res = useSelector(expert360ResSelector)
  const missingLocalities = useSelector(expert360MissingLocalitiesSelector)

  const [totalOffersContent, setTotalOffersContent] = useState<any>([])

  const intensities = res?.countData?.client?.intensity ?? []
  const intensityId = res?.countData?.intensity ?? 2

  const defineIsPrintOptionIsSelected = () => {
    if (!isProposal) return isPrintOptionForIntensitySelected(intensities[intensityId - 1])

    const needToDisplayPrintOption = (intensityId === 4 && res?.countData?.print === 1) || (intensityId !== 4 && res?.countData?.totalBudget > EXPERT_360_MINIMUM_BUDGET_TO_OFFER_PRINT_OPTION)
    return isPrintOptionForIntensitySelected(null, needToDisplayPrintOption)
  }

  const locations: ForecastResult[] | undefined = formatZipCodeWithManyLocalityNames(data?.locations ?? [], res?.countData?.localityNameByZipCode ?? {})
  const projectTypes = data?.projectTypes?.filter((d: any) => d.checked).map((d: any) => d.label) ?? []
  const isPrintOptionSelected = defineIsPrintOptionIsSelected()

  const budgetInformation: BudgetInformationType = {
    totalBudget: isProposal && res?.countData?.totalBudget ? res.countData.totalBudget : selectedIntensityBudget,
    complementary: 'Budget total mensuel (HT)',
  }

  const tableInformation: TableInformationType = [
    {
      title: 'Média(s)',
      icon: DesktopLined,
      description: 'Desktop, tablette, mobile',
    },
    {
      title: 'Type de projet',
      icon: DocumentLined,
      description: projectTypes?.join(', ') ?? '',
    },
    {
      title: 'Périodicité',
      icon: CalendarLined,
      description: res?.countData?.xp360Req?.periodicity ?? data?.periodicity?.[0]?.value,
    },
    {
      title: 'Offres',
      icon: SellRegular,
      description: totalOffersContent?.[0] !== undefined && `Au total ${totalOffersContent?.[0].totalOffers} impressions\n${isPrintOptionSelected ? '+ 1 Brand Content' : ''}`,
    }
  ]

  useEffect(() => {
    const getTotalOffersContent = () => {
      if (isProposal && res && Object.keys(res).length > 0) {
        setTotalOffersContent([
          { totalOffers: formatNumbers(res.countData.totalImpPurchased * 2) }
        ])
      } else {
        const totalPrintsOffers = formatNumbers((intensities[intensityId - 1]?.impressions ?? 0) * 2)
        setTotalOffersContent([
          { totalOffers: totalPrintsOffers }
        ])
      }
      return totalOffersContent
    }
    getTotalOffersContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res])

  return (
    <ResumeCard
      testId="expert360ContentSummary"
      loading={loading}
      broadcastMediums={data?.broadcastMediums?.filter((d: any) => d.checked).map((d: any) => d.label) ?? []}
      budgetInformation={budgetInformation}
      productName={productNamesType.Expert360}
      tableInformation={tableInformation}
      locations={locations}
      shouldDisplayLocations={!missingLocalities}
      isProposal={isProposal}
    />
  )
}

export default Expert360SummaryCount
