import React from 'react'

import { ItemListLoadingStyled, LoaderItem } from '../../styled'

const ItemListLoader = (): React.ReactElement => (
  <div data-testid="itemlistLoader">
    <ItemListLoadingStyled>
      <LoaderItem />
    </ItemListLoadingStyled>
    <ItemListLoadingStyled>
      <LoaderItem />
    </ItemListLoadingStyled>
    <ItemListLoadingStyled>
      <LoaderItem />
    </ItemListLoadingStyled>
  </div>
)

export default ItemListLoader
