import React, { useEffect } from 'react'
import { PinRegular } from '@ui-library/icons'
import { ErrorBudget, IconStyled, InputWrapper, SpanCoverage, SpanMax, SpanShareOfVoice, StrongBudget, TableStyled, ThBlue, TotalAndBudget } from '../common/styled'
import { useInput } from '../../../hooks/useInput'
import Loader from '../../Loader/Loader'
import { capitalize, formatNumbers } from '../../../utils/tsUtils'
import { MASTER_DISPLAY_CPM } from '../../../utils/variables'
import { ForecastResult } from '../../../utils/myAdfactoryApi/swaggerApi'

type ForescastResultSplitByPostalCodes = ForecastResult & { offer?: number }

type PrintByLocalitiesProps = {
  budget: number,
  forescastResultSplitByPostalCodes: ForescastResultSplitByPostalCodes[],
  isProposal?: boolean,
  hideBudget?: boolean,
  shareOfVoice?: number | null,
  totalOffer: number,
  onBudgetChange?: (data: any) => void,
}

const InputBudget = ({ data, onBudgetChange }: { data: any, onBudgetChange: (data: any) => void }): React.ReactElement => {
  const { value: budgetValue, bindInput } = useInput(0)
  const maximumBudget: number = data.available ? Math.round((MASTER_DISPLAY_CPM * data.available) / 1000) : 0
  const showError: boolean = budgetValue > maximumBudget
  const isDisabled = maximumBudget === 0

  useEffect(() => {
    if (budgetValue <= Math.round(maximumBudget)) {
      const locationData = {
        name: data.name,
        code: data.code,
        sold: data.sold,
        total: data.total,
        available: data.available,
        purchased: (budgetValue / MASTER_DISPLAY_CPM) * 1000,
        budget: budgetValue,
      }
      onBudgetChange(locationData)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetValue])

  return (
    <div className={isDisabled ? 'isDisabled' : ''}>
      <SpanMax>Max. {formatNumbers(maximumBudget)}€</SpanMax>
      <InputWrapper>
        <input disabled={isDisabled} type="text" {...bindInput} />
        <span>€</span>
      </InputWrapper>
      {showError && <ErrorBudget data-testid="errorMsg">Merci de ne pas dépasser le budget maximum disponible</ErrorBudget>}
    </div>
  )
}

const PrintByLocalities = ({
  budget,
  forescastResultSplitByPostalCodes,
  isProposal = false,
  hideBudget = false,
  shareOfVoice = null,
  totalOffer,
  onBudgetChange = (() => true),
}: PrintByLocalitiesProps): React.ReactElement => (
  <TableStyled data-testid="PrintsByLocalitiesTable">
    <thead>
      <tr>
        <th scope="col">
          <IconStyled icon={PinRegular} size="18px" />
          Localités
        </th>
        {!isProposal && <th scope="col">Déjà vendues <span>(par mois)</span></th>}
        {!isProposal && <th scope="col">Disponibles <span>(par mois)</span></th>}
        <ThBlue scope="col">Impressions proposées <span>(par mois)</span></ThBlue>
        {!hideBudget && <ThBlue scope="col">Budgets <span>(hors taxe)</span></ThBlue>}
      </tr>
    </thead>
    <tbody>
      {forescastResultSplitByPostalCodes?.length > 0 && (
        forescastResultSplitByPostalCodes.map((fr: ForescastResultSplitByPostalCodes) => (
          <tr key={`row-${fr.code}`}>
            <td>{capitalize(fr.name)} ({fr.code})</td>
            {!isProposal && (
              <td>
                {fr.sold && fr.sold >= 0 ? (
                  <>{formatNumbers(fr.sold)} Imp.</>
                ) : (
                  <Loader maxWidth={100} height={20} />
                )}
              </td>
            )}
            {!isProposal && (
              <td>
                {(!hideBudget && ((fr?.available && fr?.available > 0) || fr?.available === 0)) || (hideBudget && fr?.available && fr?.offer && fr.available - fr.offer >= 0) ? (
                  <>
                    {hideBudget ? formatNumbers((fr?.available ?? 0) - (fr?.offer ?? 0)) : formatNumbers(fr.available)} Imp.
                    {(!hideBudget && fr?.available && fr?.total) ? <SpanCoverage>Soit {fr.available === 0 ? 0 : Math.round((fr.available / fr.total) * 100)}% de couverture</SpanCoverage> : ''}
                  </>
                ) : (
                  <>
                    <Loader maxWidth={100} height={20} />
                    {!hideBudget && <SpanCoverage><Loader maxWidth={150} height={15} /></SpanCoverage>}
                  </>
                )}
              </td>
            )}
            <td>
              {fr?.offer !== undefined && fr.offer >= 0 ? (
                <>
                  {formatNumbers(fr.offer)} Imp. { isProposal ? ' / mois' : ''}
                  {!hideBudget && <SpanCoverage>Soit {fr.offer === 0 ? 0 : Math.round((fr.offer / (fr.total ?? 0)) * 100)}% de couverture</SpanCoverage>}
                </>
              ) : (
                <>
                  <Loader maxWidth={100} height={20} />
                  {!hideBudget && <SpanCoverage><Loader maxWidth={150} height={15} /></SpanCoverage>}
                </>
              )}
            </td>
            {!hideBudget && (
              <td data-testid={`inputBudget-${fr.code}`} aria-label="BudgetInput">
                {!isProposal ? (
                  <InputBudget data={fr} onBudgetChange={onBudgetChange} />
                ) : (
                  <StrongBudget>{fr.budget} € <small>/ hors taxe</small></StrongBudget>
                )}
              </td>
            )}
          </tr>
        ))
      )}
    </tbody>
    <tfoot>
      <tr>
        <td colSpan={!isProposal ? 3 : 1} style={{ textAlign: 'right' }}>
          <strong>Total du comptage :</strong>
        </td>
        <td>
          <TotalAndBudget $hideBudget={hideBudget}>
            <strong>{formatNumbers(totalOffer)} Imp.</strong>
            {hideBudget && <strong style={{ textAlign: 'right' }} data-testid="budgetInPrintsTotalCell">{formatNumbers(budget)} €</strong>}
          </TotalAndBudget>
          {shareOfVoice && <SpanShareOfVoice>Soit une part de présence moyenne de {shareOfVoice}/10<br /><em>(à ce jour)</em></SpanShareOfVoice>}
        </td>
        {!hideBudget && <td><StrongBudget>{formatNumbers(budget)} €</StrongBudget></td>}
      </tr>
    </tfoot>
  </TableStyled>
)

export default PrintByLocalities
