import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import mapBackground from '../../../../assets/images/map_background.jpg'

import CountInProgress from '../../../Counts/CountInProgress/CountInProgress'
import MasterDisplaySummaryCount from '../MasterDisplaySummaryCount/MasterDisplaySummaryCount'
import ForecastBar from '../../../Bars/ForecastBar/ForecastBar'
import PrintsByLocalitiesTable from '../../../Tables/PrintsByLocalitiesTable/PrintsByLocalitiesTable'
import CountsFooter from '../../../Counts/CountsFooter/CountsFooter'

import { masterDisplayCanSaveSelector, masterDisplayLoadingSelector, masterDisplayResSelector } from '../../../../redux/reducers/MasterDisplay/masterDisplay.reducer'

import { useProductMasterDisplayCount } from '../../../../hooks/products/masterDisplay/useProductMasterDisplayCount'

import { ForecastResult } from '../../../../utils/myAdfactoryApi/swaggerApi'

const MasterDisplayCount = (): React.ReactElement => {
  const res = useSelector(masterDisplayResSelector)
  const loading: string = useSelector(masterDisplayLoadingSelector)
  const canSave: boolean = useSelector(masterDisplayCanSaveSelector)

  const {
    offers,
    forescastResultSplitByPostalCodes,
    handleBudgetChange,
  } = useProductMasterDisplayCount()

  if (!res) {
    return <CountInProgress dataTestId="masterDisplayCount" />
  }

  return (
    <MasterDisplayCountWrapper data-testid="masterDisplayCount">
      <MasterDisplayMaps style={{ backgroundImage: `url(${mapBackground})` }} />
      <MasterDisplayCountResult>
        <h3>Résumé du comptage</h3>
        <MasterDisplaySummary>
          <VolumeDetails>
            <VolumeTitle>Volume d'impression</VolumeTitle>
            <VolumeDesc>
              <p>Ces volumes prennent en compte les impressions disponibles et déjà vendues par mois.</p>
            </VolumeDesc>
            <ForecastBar
              available={res?.countData?.forecasts?.reduce((acc: number, obj: ForecastResult) => acc + parseInt(String(obj?.available)), 0)}
              loading={loading === 'pending'}
              offer={offers}
              sold={res?.countData?.forecasts?.reduce((acc: number, obj: ForecastResult) => acc + parseInt(String(obj?.sold)), 0)}
              total={res?.countData?.forecasts?.reduce((acc: number, obj: ForecastResult) => acc + parseInt(String(obj?.total)), 0)}
            />
          </VolumeDetails>
          {res && <MasterDisplaySummaryCount />}
        </MasterDisplaySummary>
        <MasterDisplayResults>
          <h2>Détails de la proposition</h2>
          <PrintsByLocalitiesTable
            budget={res?.countData?.totalBudget ?? 0}
            forescastResultSplitByPostalCodes={forescastResultSplitByPostalCodes}
            totalOffer={offers}
            onBudgetChange={(d) => handleBudgetChange(d)}
          />
        </MasterDisplayResults>
        <CountsFooter
          canSave={canSave}
          loading={loading}
          res={res}
        />
      </MasterDisplayCountResult>
    </MasterDisplayCountWrapper>
  )
}

const MasterDisplayCountWrapper = styled.div`
  position: relative;
`

const MasterDisplayMaps = styled.div`
  position: absolute;
  left: 0;
  top: 13.125em;
  width: 100%;
  height: 25em;
  background-color: ${(p) => p.theme.global.colorPalette.lighterSaphir};
  background-size: cover;
`

const MasterDisplayCountResult = styled.div`
  position: absolute;
  padding: 8.3em 2.5em 10em 2.5em;
  width: 100%;

  h3 {
    font-size: 2em;
    color: ${(p) => p.theme.global.colorPalette.black};
  }

  h4 {
    font-size: 1.125em;
    max-width: 71.1em;
    margin: auto;
    margin-top: 2.22em;
  }
`

const MasterDisplaySummary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  height: 35em;
`

const MasterDisplayResults = styled.div`
  margin: auto;
  margin-top: 5em;

  h2 {
    margin-bottom: 1.5em;
    font-size: 1.125em;
    font-weight: 700;
  }
`

const VolumeDetails = styled.div`
  margin-right: 1em;
  margin-bottom: 2.2em;
  padding: 1em;
  border-radius: .3em;
  background: rgba(255,255,255, .9);
`

const VolumeTitle = styled.h2`
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: .5em;
`

const VolumeDesc = styled.div`
  margin-bottom: 1em;
`

export default MasterDisplayCount
