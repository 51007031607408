import React from 'react'
import { Tag, Button } from '@ui-library/core'
import { Link } from 'react-router-dom'
import type { ProductCard as ProductCardType } from '../../../types/ProductsCard'

const ProductCard = ({
  cardCover,
  tag,
  title,
  description,
  available,
  pdfLink,
  productName,
  url,
  callbackRedirect,
} : ProductCardType): React.ReactElement => {
  const handleClick = () => {
    if (callbackRedirect) {
      callbackRedirect(url ?? '')
    }
  }

  return (
    <div className="productCard" data-testid={`${productName}Card`}>
      <div className="wrapperProductCard">
        <div
          className="cardCover"
          style={{ backgroundImage: `url(${cardCover})` }}
        />
        <div className="wrapperTags">
          {tag.map((elem) => (
            <div className="contentTag" key={elem}>
              <Tag label={elem} kind="inverse" />
            </div>
          ))}
        </div>
        <div className="wrapperDescription">
          <h3>{title}</h3>
          <div className="description">
            <p>{description}</p>
          </div>
        </div>
        <div className="wrapperCTA">
          {available && (
            <Button
              size="small"
              kind="secondary"
              variant="normal"
              tag="button"
              onClick={handleClick}
            >
              Sélectionner
            </Button>
          )}
          {pdfLink && pdfLink.length > 0 && (
            <div className="moreInformations">
              <Link to={{ pathname: pdfLink }} target="_blank">
                Voir le détail
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductCard
