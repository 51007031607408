import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Input from '../../../components/Input/Input'
import { userProfileSelector } from '../../../redux/reducers/UserConfig/userConfig.reducer'
import { jobList, marketList } from '../../../utils/variables'

const ProfileWrapper = styled.div`
  padding: 2em 2.5em 0 2.5em;

  h3 {
    font-size: 2em;
    font-weight: 700;
  }
`

const ProfileContent = styled.div`
  margin-top: 2em;
  display: flex;
  padding: 1.5em 12em 1.5em 1.5em;
  width: 100%;
  border: ${(props) =>
    `1px solid ${props.theme.global.colorPalette.greyLighterBis}`};
  border-radius: 0.75em;

  > p {
    width: 20%;
    max-width: 10em;
    font-size: 1.1em;
    font-weight: 700;
  }
`

const ProfileInfos = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  > div {
    width: calc(50% - 0.75em);

    &:not(:last-child) {
      margin-bottom: 1em;
    }

    &:nth-child(2n) {
      margin-left: 1.5em;
    }
  }
`

const ProfileHelp = styled.div`
  margin-top: 2em;
  padding: 1em;
  min-height: 3em;
  background-color: ${(props) => props.theme.global.colorPalette.pastelYellow};
  border-radius: 0.5em;
`

const UsersProfile = (): React.ReactElement => {
  // Selectors
  const userProfile = useSelector(userProfileSelector)

  // Variables
  const job = jobList.find(
    (elem: any) =>
      elem.value.toLowerCase() === userProfile?.typeProfil?.toLowerCase()
  )

  const markets: any = userProfile?.markets
    ?.map(
      (market: string) =>
        marketList.find((elem) => elem.value === market)?.label
    )
    .filter((r: string | undefined) => r)

  return (
    <ProfileWrapper>
      <h3>
        {userProfile.firstName} {userProfile.lastName}
      </h3>
      <ProfileContent>
        <p>Identité</p>
        <ProfileInfos>
          <Input
            noBorder={false}
            label="Prénom"
            content={userProfile?.firstName ?? ''}
          />
          <Input
            noBorder={false}
            label="Nom"
            content={userProfile?.lastName ?? ''}
          />
          <Input
            noBorder={false}
            label="Adresse mail"
            content={userProfile?.email ?? ''}
          />
          <Input noBorder={false} label="Métier" content={job?.label ?? ''} />
          <Input
            label="Marché"
            content={markets?.map((elem: string, key: number) =>
              key === 0 ? elem : `, ${elem}`
            )}
            noBorder
          />
        </ProfileInfos>
      </ProfileContent>
      <ProfileHelp>
        <p>
          Si vous souhaitez modifier vos informations, veuillez contacter le
          support à advertising@groupeseloger.com.
        </p>
      </ProfileHelp>
    </ProfileWrapper>
  )
}

export default UsersProfile
