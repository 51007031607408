import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import Banner from '../../../Banner/Banner'

import { expert360DataSelector, expert360ResSelector, setData } from '../../../../redux/reducers/Expert360/expert360.reducer'

import { PlaceBo } from '../../../../utils/myAdfactoryApi/swaggerApi'

type IntensityUnavailableProps = {
  insufficientIntensity: boolean,
  insufficientLocalities: Array<string>,
}

type LocalitiesUnavailableProps = {
  id: string,
  code: string,
  name: string,
}

const Expert360IntensityCardUnavailable = ({ insufficientIntensity, insufficientLocalities }: IntensityUnavailableProps): React.ReactElement | null => {
  const dispatch = useDispatch()
  const res = useSelector(expert360ResSelector)
  const data = useSelector(expert360DataSelector)

  const handleRemoveLocalities = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const updatedData = {
      ...data,
      locations: data?.locations?.filter((loc: PlaceBo) => !insufficientLocalities.includes(loc.code)),
    }
    dispatch(setData(updatedData))
  }

  /**
   * Insufficiant localities message
   */
  if (insufficientLocalities?.length > 0) {
    const localitiesInResult = res?.countData?.locality || []
    const localitiesToDisplay = localitiesInResult.map((lir: LocalitiesUnavailableProps) => {
      if (insufficientLocalities.includes(lir.code)) {
        return { id: lir.id, code: lir.code, name: lir.name }
      }
      return null
    })
    const localitiesToDisplayWithoutNulls = localitiesToDisplay.filter((n: any) => n)

    return (
      <Banner dataTestId="intensityUnavailable" style={{ marginTop: '2em' }}>
        <>
          <h6>Intensités non disponibles</h6>
          <p>Des localités sélectionnées ne sont pas disponibles pour toutes les intensités.</p>
          <p>Pour accéder à des intensités supérieures, les localités suivantes doivent être retirées :</p>
          {localitiesToDisplayWithoutNulls.length > 0 && (
            <ul>
              {localitiesToDisplayWithoutNulls?.map((locality: LocalitiesUnavailableProps) => (
                <li key={`locality-unavailable-${locality?.id}`}>{locality?.name} ({locality?.code})</li>
              ))}
            </ul>
          )}
          <ButtonRemoveLocalities type="button" onClick={handleRemoveLocalities}>
            Supprimer ces localités
          </ButtonRemoveLocalities>
        </>
      </Banner>
    )
  }

  /**
   * Insufficient intensities message
   */
  if (insufficientIntensity) {
    return (
      <Banner dataTestId="intensityUnavailable" style={{ marginTop: '2em' }}>
        <>
          <h6 className="intensityUnavailableTitle">Ciblage trop restreint</h6>
          <p>Les localités sélectionnées ne disposent pas d'un inventaire suffisant pour continuer.</p>
          <p>Pour élargir le ciblage, <a href="#locality">ajoutez d'autres localités</a>.</p>
        </>
      </Banner>
    )
  }

  return null
}

const ButtonRemoveLocalities = styled.button`
  background: transparent;
  border: 0;
  outline: none;
  margin: 10px 0 0;
  padding: 0;
  font-size: 1em;
  text-decoration: underline;
  cursor: pointer;

  &:hover, &:active, &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
  }
`

export default Expert360IntensityCardUnavailable
