import React, { useEffect, useState, useRef } from 'react'
import { Icon } from '@ui-library/core'
import { FillsLined, CheckLined } from '@ui-library/icons'
import { CSSTransition } from 'react-transition-group'

type CodeMafProps = {
  idMaf: number,
}

const CodeMaf = ({ idMaf }: CodeMafProps): React.ReactElement => {
  const [clickToCopy, setClickToCopy] = useState(false)
  const [triggerUseEffect, setTriggerUseEffect] = useState(false)
  const mafCode = idMaf ? `MAF-${idMaf}` : ''
  const refCheckLined = useRef(null)
  const refFillsLined = useRef(null)

  //---------------------------------------------------------------------
  // Update clickToCopy inside timer to display icon check and remove iconCopy

  useEffect(() => {
    setTriggerUseEffect(false)
    const timer = setTimeout(() => {
      setClickToCopy(false)
    }, 600)
    return () => clearTimeout(timer)
  }, [triggerUseEffect])

  //---------------------------------------------------------------------
  // Method call by clicking on the copy button

  const copyCodeMaf = () => {
    setClickToCopy(true)
    setTriggerUseEffect(true)
    navigator.clipboard.writeText(mafCode)
  }

  //---------------------------------------------------------------------
  // Return part

  return (
    <div className="codeMaf" data-testid="codeMafComponent">
      <div className="contentCodeMaf">
        <p data-testid="codeMaf">
          {mafCode}
        </p>
      </div>

      <div
        data-testid="copyPast"
        className="copyPast"
        onClick={() => {
          copyCodeMaf()
        }}
      >
        <CSSTransition
          nodeRef={refCheckLined}
          in={clickToCopy}
          timeout={400}
          classNames="iconValidate"
          appear
          unmountOnExit
        >
          <div data-testid="checkedIcon" className="wrapperIcon" ref={refCheckLined}>
            <Icon icon={CheckLined} />
          </div>
        </CSSTransition>
        <CSSTransition
          nodeRef={refFillsLined}
          in={!clickToCopy}
          timeout={400}
          classNames="iconCopy"
          appear
          unmountOnExit
        >
          <div className="wrapperIcon" ref={refFillsLined}>
            <Icon icon={FillsLined} />
          </div>
        </CSSTransition>
      </div>
    </div>
  )
}

export default CodeMaf
