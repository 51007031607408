import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import PerformanceHeader from '../../../components/Products/Performance/PerformanceHeader/PerformanceHeader'
import PerformanceCount from '../../../components/Products/Performance/PerformanceCount/PerformanceCount'

import { performanceErrorsSelector } from '../../../redux/reducers/Performance/performance.reducer'

import { useProductPerformanceData } from '../../../hooks/products/performance/useProductPerformanceData'

type PerformanceWrapperProps = {
  children: React.ReactNode,
  dataTestId: string,
}

const PerformanceWrapper = ({ children, dataTestId }: PerformanceWrapperProps): React.ReactElement => (
  <PerformanceWrapperStyled data-testid={dataTestId}>
    <PerformanceHeader />
    {children}
  </PerformanceWrapperStyled>
)

const PerformanceErrorComp = (): React.ReactElement => (
  <PerformanceWrapper dataTestId="performanceError">
    <PerformanceError>
      <p>Une erreur est survenue</p>
    </PerformanceError>
  </PerformanceWrapper>
)

const Performance = (): React.ReactElement => {
  const { id }: { id: string } = useParams()
  const performanceErrors: any = useSelector(performanceErrorsSelector)

  useProductPerformanceData(id)

  if (performanceErrors) {
    return <PerformanceErrorComp />
  }

  return (
    <PerformanceWrapper dataTestId="performance">
      <PerformanceCount />
    </PerformanceWrapper>
  )
}

// Styled component
const PerformanceWrapperStyled = styled.div`
  padding-top: 14em;
  background-color: ${(props) => props.theme.global.colorPalette.white};
`

const PerformanceError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

export default Performance
