/* eslint-disable no-lonely-if */
import React, { useEffect, useState } from 'react'

import latinize from 'latinize'
import Highlighter from 'react-highlight-words'

import { Icon } from '@ui-library/core'
import { PinFilled } from '@ui-library/icons'
import { AddItem, IconWrapper, ItemListStyled, ItemLocation, ItemLocationTag, ItemValue } from '../../styled'

import type { PlaceBo } from '../../../../utils/myAdfactoryApi/swaggerApi'

type ItemListProps = {
  item: PlaceBo,
  inputValue: string,
  myList: PlaceBo[],
  addItemsToMyList: (value: PlaceBo) => void,
}

const ItemList = ({
  item, inputValue, myList, addItemsToMyList,
}: ItemListProps): React.ReactElement => {
  const [alreadyInMyLocation, setAlreadyInMyLocation] = useState(false)

  useEffect(() => {
    if (myList.some((elem: PlaceBo) => typeof elem.id === 'string' && elem.id.startsWith('touteslesvilles') && item.code === elem.code)) {
      setAlreadyInMyLocation(true)
    } else if (myList.some((elem: PlaceBo) => elem.code === item.code)) {
      setAlreadyInMyLocation(true)
    } else {
      setAlreadyInMyLocation(false)
    }
  }, [myList, item])

  return (
    <ItemListStyled
      $isDisabled={alreadyInMyLocation}
      data-testid="locationResult"
      className={alreadyInMyLocation ? 'isDisabled' : ''}
      onClick={() => {
        !alreadyInMyLocation && addItemsToMyList(item)
      }}
    >
      <IconWrapper $isDisabled={alreadyInMyLocation}>
        <Icon icon={PinFilled} />
      </IconWrapper>
      <ItemValue data-testid="valueLocation">
        <p>
          {!alreadyInMyLocation ? (
            <ItemLocation>
              <Highlighter
                searchWords={[inputValue, inputValue.replace(/ /g, '-')]}
                autoEscape
                sanitize={latinize}
                textToHighlight={`${item.name} `}
              />
              <ItemLocationTag>
                <Highlighter
                  highlightClassName="highlightLocationTag"
                  searchWords={[inputValue, inputValue.replace(/ /g, '-')]}
                  autoEscape
                  sanitize={latinize}
                  textToHighlight={`${item.code} `}
                />
              </ItemLocationTag>
            </ItemLocation>
          ) : (
            <ItemLocation>
              {item.name}
              <ItemLocationTag>
                {item.code}
              </ItemLocationTag>
            </ItemLocation>
          )}
        </p>
      </ItemValue>
      <AddItem>
        <p>Ajouter</p>
      </AddItem>
    </ItemListStyled>
  )
}

export default ItemList
