import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Switch } from '@ui-library/core'

import Loader from '../../../Loader/Loader'

import { expert360LoadingSelector } from '../../../../redux/reducers/Expert360/expert360.reducer'

import { ClientIntensity } from '../../../../utils/myAdfactoryApi/swaggerApi'

type Expert360SwitchProps = {
  customIntensity: ClientIntensity,
  selectedIntensityId: number,
  onChange: (value: string) => void,
}

const Expert360Switch = ({ customIntensity, selectedIntensityId, onChange }: Expert360SwitchProps): React.ReactElement | null => {
  const loading = useSelector(expert360LoadingSelector)

  return (
    <Expert360SwitchStyled data-testid="expert360Switch">
      {loading !== 'pending' ? (
        <>
          {customIntensity?.available && (
            <Switch
              value={selectedIntensityId === 4 ? 'custom-offers' : 'our-offers'}
              onChange={onChange}
              choices={[
                { label: 'Nos offres', value: 'our-offers', key: 'our-offers' },
                { label: 'Offres sur-mesure', value: 'custom-offers', key: 'custom-offers' }
              ]}
            />
          )}
        </>
      ) : (
        <Loader maxWidth={300} height={40} />
      )}
    </Expert360SwitchStyled>
  )
}

const Expert360SwitchStyled = styled.div`
  max-width: 15em;
  margin-bottom: 1.5em;
`

export default Expert360Switch
