/**
 * This custom hook is used by exports page
 */
import Axios from 'axios'
import { useEffect, useState } from 'react'
import { ExportFilter } from '../utils/myAdfactoryApi/swaggerApi'

export const useExports = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [err, setErr] = useState<boolean>(false)
  const [success, setSuccess] = useState<string | null>(null)

  const handleClick = async (exportFilter: ExportFilter) => {
    setLoading(true)
    return Axios({
      url: `/Counts/GetExport?exportFilter=${exportFilter}`,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response: any) => {
        const today = new Date()
        const dateForExportName = today.toISOString().split('T')[0]
        const href = URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute(
          'download',
          `${exportFilter}-${dateForExportName}.csv`
        )
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
        setSuccess(
          `Votre fichier ${exportFilter}-${dateForExportName}.csv a été téléchargé avec succès`
        )
      })
      .catch((error: any) => {
        setErr(true)
        setSuccess(null)
        return error.response
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const timeout = setTimeout(() => setErr(false), 3000)
    return () => clearTimeout(timeout)
  }, [err])

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (success) {
      timeout = setTimeout(() => setSuccess(null), 5000)
    }
    return () => clearTimeout(timeout)
  }, [success])

  return {
    loading,
    err,
    success,
    handleClick,
  }
}
