import React from 'react'

interface ITitleTableProps {
  loading: string,
  col1: string,
  col2: string,
  col3: string,
  col4: string,
  showSL: boolean,
  showLI: boolean,
}

const TitleTable = ({ loading, col1, col2, col3, col4, showSL, showLI } : ITitleTableProps) => {
  if (loading === 'pending' || showSL || showLI) {
    return (
      <div className="wrapperTitleTable">
        <div>
          <p>{col1}</p>
        </div>
        <div>
          <p>{col2}</p>
        </div>
        <div className="blue">
          <p>{col3}</p>
        </div>
        <div className="blue">
          <p>{col4}</p>
        </div>
      </div>
    )
  }
  return null
}
export default TitleTable
