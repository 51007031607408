import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { Button, Icon } from '@ui-library/core'
import Banner from '@ui-library/core/Banner'
import { LoaderRegular } from '@ui-library/icons'

import AutocompleteMultiple from '../../../Autocomplete/AutocompleteMultiple/AutocompleteMultiple'
import ProgressBar from '../../../Bars/ProgressBar/ProgressBar'
import Select from '../../../Select/Select'

import {
  expert360DataSelector,
  expert360ListToDisplaySelector,
  expert360LoadingSelector,
  expert360RadiusSelector,
  expert360ResSelector,
  setData,
  setRadius,
} from '../../../../redux/reducers/Expert360/expert360.reducer'

import { useProductExpert360Header } from '../../../../hooks/products/expert360/useProductExpert360Header'

import { productNamesType } from '../../../../types/productNames.types'
import { SelectElementType } from '../../../../types/select.type'

const Xp360Banner = ({
  onClose,
}: {
  onClose: () => void
}): React.ReactElement => (
  <Expert360HeaderBanner>
    <Banner
      kind="important"
      text="Plusieurs localités sont actuellement fermées, retrouver la liste"
      href="https://docs.google.com/spreadsheets/d/1r1Bx1jcMdgL47S5zamB69XCsnzO-3c7CerkgfzkZPeU/edit#gid=0"
      onClose={onClose}
      linkText="ici"
    />
  </Expert360HeaderBanner>
)

const Expert360Header = (): React.ReactElement => {
  const dispatch = useDispatch()
  const data = useSelector(expert360DataSelector)
  const res = useSelector(expert360ResSelector)
  const loading = useSelector(expert360LoadingSelector)

  const [displayBanner, setDisplayBanner] = useState<boolean>(false)
  const listToDisplay = useSelector(expert360ListToDisplaySelector)
  const radius = useSelector(expert360RadiusSelector)

  if (!data.clientId) {
    try {
      const dataFromLocaleStorage = localStorage.getItem('data360')
      const data360 = dataFromLocaleStorage
        ? JSON.parse(dataFromLocaleStorage)
        : data

      dispatch(setData(data360))
    } catch (error) {
      dispatch(setData(data))
    }
  }
  const [reactivateEstimateButton, setReactivateEstimateButton] =
    useState(false)

  const {
    errorBroadcastMediums,
    errorProjectTypes,
    showErrorLocalities,
    handleBroadcastMediums,
    handleChangeLocations,
    handleProjectTypes,
    handleRadiusChange,
    handleProcess,
  } = useProductExpert360Header()

  const broadcastMediumsChecked =
    data?.broadcastMediums?.filter((b: SelectElementType) => b.checked)
      ?.length > 0
      ? data?.broadcastMediums?.filter((b: SelectElementType) => b.checked)
      : null
  const projectTypesChecked =
    data?.projectTypes?.filter((b: SelectElementType) => b.checked)?.length > 0
      ? data.projectTypes.filter((b: SelectElementType) => b.checked)
      : null

  return (
    <Expert360HeaderWrapper
      data-testid="expert360Header"
      $displayBannerVisible={displayBanner}
    >
      <ProgressBar />
      {displayBanner && <Xp360Banner onClose={() => setDisplayBanner(false)} />}
      <Expert360HeaderContainer>
        <h2>
          Configuration du comptage <span>{productNamesType.Expert360}</span>{' '}
          {data?.clientId && data?.clientName && (
            <span>
              ({data.clientId}, {data.clientName})
            </span>
          )}
        </h2>
        <FormWrapper>
          <FormContainer>
            <LocalitiesDiv data-testid="localitiesSelect">
              <AutocompleteMultiple
                catchmentZone={data?.catchmentZone ?? undefined}
                completingWord="localités"
                data-testid="AutocompleteExpert360"
                disabled={loading === 'pending'}
                error={showErrorLocalities}
                initDataLocality={listToDisplay ?? data?.locations ?? undefined}
                label="Localité(s) recherchée(s) par l'internaute"
                maximumItems={50}
                noResultFound="Aucun résultat ne correspond à votre recherche"
                otherSuggestionsText="Un autre lieu ?"
                placeholder="Ajouter une localité (ville, quartier, département...)"
                textError="Sélectionnez au moins une localité"
                titleList="Localité(s)"
                autocompleteCallback={(locations) =>
                  handleChangeLocations(locations)
                }
                persistRadius={(value: number) => {
                  dispatch(setRadius({ radius: value }))
                }}
                onRadiusChange={(_: boolean, isZero?: boolean) => {
                  handleRadiusChange(isZero)
                  setReactivateEstimateButton(true)
                }}
                radius={radius}
              />
            </LocalitiesDiv>
            <BroadcastMediumDiv>
              <Select
                disabled={loading === 'pending'}
                id="broadcastMedium"
                label="Support de diffusion"
                options={data?.broadcastMediums}
                msgError="Sélectionnez au moins une valeur"
                multiple
                placeholder="Ex: Se Loger"
                showError={errorBroadcastMediums}
                value={broadcastMediumsChecked}
                onChange={handleBroadcastMediums}
              />
            </BroadcastMediumDiv>
            <ProjectTypeDiv>
              <Select
                disabled={loading === 'pending'}
                id="projectType"
                options={data?.projectTypes}
                label="Type de projet"
                msgError="Sélectionnez au moins une valeur"
                multiple
                placeholder="Ex: Achat"
                showError={errorProjectTypes}
                value={projectTypesChecked}
                onChange={handleProjectTypes}
              />
            </ProjectTypeDiv>
            <PeriodicityDiv>
              <Select
                disabled
                label="Périodicité"
                msgError="Sélectionnez au moins une valeur"
                off
                options={data?.periodicity}
                placeholder="Ex: Récurrent"
                value={data?.periodicity}
                onChange={() => true}
              />
            </PeriodicityDiv>
            <EstimateButton>
              <Button
                tag="button"
                size="medium"
                kind="primary"
                variant="normal"
                onClick={() => {
                  handleProcess()
                  setReactivateEstimateButton(false)
                }}
                disabled={res && !reactivateEstimateButton}
              >
                {loading === 'pending' ? (
                  <Icon icon={LoaderRegular} style={{ display: 'flex' }} />
                ) : (
                  'Estimer'
                )}
              </Button>
            </EstimateButton>
          </FormContainer>
        </FormWrapper>
      </Expert360HeaderContainer>
    </Expert360HeaderWrapper>
  )
}

type Expert360HeaderWrapperProps = {
  $displayBannerVisible?: boolean
}

const Expert360HeaderWrapper = styled.div<Expert360HeaderWrapperProps>`
  position: absolute;
  top: 0;
  z-index: ${(props) => props.theme.global.myAdFactory.zIndex.form};
  height: 14em;
  width: 100%;
  background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};

  ${(props) =>
    props.$displayBannerVisible &&
    css`
      height: 21em;
    `}
`

const Expert360HeaderBanner = styled.div`
  margin: 2.5em 2.5em 0 2.5em;
  border-radius: 0.3em;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%);
`

const Expert360HeaderContainer = styled.div`
  margin: 2.5em 2.5em 0 2.5em;

  h2 {
    font-size: 1.15em;
    font-weight: 700;

    span {
      font-weight: normal;
    }
  }
`

const FormWrapper = styled.div`
  position: relative;
  height: 9.5em;
  margin-top: 1em;
  width: 100%;
  background-color: ${(props) => props.theme.global.colorPalette.white};
  border-radius: 0.3em;
  padding: 1em 1.5em 2.75em 1.5em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
`

const FormContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
`

const LocalitiesDiv = styled.div`
  width: 34%;
  margin-right: 2%;
`

const BroadcastMediumDiv = styled.div`
  width: 20%;
  margin-right: 2%;
`

const ProjectTypeDiv = styled.div`
  width: 20%;
  margin-right: 2%;
`

const PeriodicityDiv = styled.div`
  width: 20%;
`

const EstimateButton = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 10.75em;
  bottom: 0;
  transform: translateY(50%);

  button {
    width: 100%;
  }
`

export default Expert360Header
