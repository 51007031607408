/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
// test

import axios from 'axios'
import { OktaAuth } from '@okta/okta-auth-js'
import { oktaAuthConfig } from './okta'

const authClient = new OktaAuth(oktaAuthConfig)

const getToken = async () => {
  const { tokenManager } = authClient
  const idToken: any = await tokenManager.get('idToken')
  return idToken.idToken
}

let backendHost: string
const hostname = window?.location?.hostname
if (hostname === 'myadfactory.seloger.com') {
  backendHost = 'https://myadfactory-api.production.myadfactory.svc.groupe-seloger.com'
} else if (hostname === 'myadfactory-stage.dignp.com') {
  backendHost = 'https://myadfactory-api.staging.myadfactory.eks.dignp.com'
} else if (hostname === 'myadfactory-int1.dignp.com') {
  backendHost = 'https://myadfactory-api.integration.myadfactory.eks.dignp.com'
} else if (hostname === 'myadfactory-int2.dignp.com') {
  backendHost = 'https://myadfactory-api.integration.myadfactory.eks.dignp.com'
} else if (hostname === 'myadfactory-int3.dignp.com') {
  backendHost = 'https://myadfactory-api.integration.myadfactory.eks.dignp.com'
} else {
  backendHost = process.env.REACT_APP_BACKEND_HOST ?? 'https://myadfactory-api.staging.myadfactory.eks.dignp.com'
}
axios.interceptors.request.use(
  async (config) => {
    const token = await getToken()
    if (token) {
      config.baseURL = `${backendHost}/api`
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config
    if (error.response.status === 500 && !originalRequest._retry) {
      originalRequest._retry = true
      return axios(originalRequest)
    }

    // return Error object with Promise
    return Promise.reject(error)
  }
)
