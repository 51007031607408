import React, { useRef, useState } from 'react'
import { Tooltip } from '@ui-library/core'
import { marketList } from '../../../../../utils/variables'
import { UserBo } from '../../../../../utils/myAdfactoryApi/swaggerApi'

type UserMarketsCellProps = {
  user: UserBo;
}

export const UserMarketsCell: React.FC<UserMarketsCellProps> = ({ user }) => {
  const [styleTooltip, setStyleTooltip] = useState('none')
  const anchorRef = useRef(null)
  const { markets } = user
  const userMarkets = markets?.map((market) => marketList.find((element) => element.value === market)?.label)
  return (
    <>
      {
        userMarkets && userMarkets.length ? (
          <>
            <div
              data-testid="userMarketsText"
              ref={anchorRef}
              onMouseEnter={() => setStyleTooltip('block')}
              onMouseLeave={() => setStyleTooltip('none')}
            >
              {/* The first market + the markets array size minus 1 (ex: 'Ancien, +2' when we have [Ancien, Luxe, Construire]) */}
              {`${userMarkets[0]}${userMarkets.length > 1 ? `, +${userMarkets.length - 1}` : ''}`}
            </div>
            { userMarkets.length > 1 && (
              <Tooltip
                style={{
                  display: styleTooltip,
                  width: '16.5em',
                  textAlign: 'center',
                  pointerEvents: 'none',
                }}
                orientation="top"
                anchorRef={anchorRef}
              > {userMarkets.join(', ')}
              </Tooltip>
            )}
          </>
        ) : null
      }
    </>
  )
}
