import Paging from '@ui-library/core/Paging'
import { useSelector } from 'react-redux'
import { totalCountSelector } from '../../../redux/reducers/Page/Counts/pageCounts.reducer'
import { totalUsersSelector } from '../../../redux/reducers/Page/Users/pageUsers.reducer'

type FooterListProps = {
  currentPage: number
  onClick: (page: number) => void
  itemsPerPage: number
  mode: string
}

const FooterList = ({
  currentPage,
  onClick,
  itemsPerPage,
  mode,
}: FooterListProps) => {
  const handleCustomLabel = ({
    itemFrom,
    itemTo,
    totalCount,
  }: {
    itemFrom: number
    itemTo: number
    totalCount: number
  }) => (
    <div>
      Résultats {itemFrom} à {itemTo} sur {totalCount}
    </div>
  )

  let totalItems: any
  switch (mode) {
    case 'myCounts':
      totalItems = totalCountSelector
      break
    case 'allCounts':
      totalItems = totalCountSelector
      break
    case 'users':
      totalItems = totalUsersSelector
      break
    default:
      break
  }

  const totalCount: number = useSelector(totalItems)

  return (
    <div className="listsFooterWrapper" data-testid="listsFooter">
      {totalCount > 0 && (
        <Paging
          current={currentPage}
          totalCount={totalCount}
          itemCountPerPage={itemsPerPage}
          onClick={onClick}
          withSEO
          urlPattern="/pagination?p={page}"
          pageInfoCustomLabel={handleCustomLabel}
        />
      )}
    </div>
  )
}

export default FooterList
