import React from 'react'
import styled from 'styled-components'

import { getLogoBroadcastMedium } from '../../../utils/tsUtils'

import { BroadcastMedium } from '../../../utils/myAdfactoryApi/swaggerApi'

const LogosBroadcastMediums = ({ sites } : { sites: BroadcastMedium[] | undefined }): React.ReactElement | null => {
  if (!sites) return null

  return (
    <>
      {sites?.map((bm) => <ImgSite key={bm} style={{ backgroundImage: `url(${getLogoBroadcastMedium(bm)})` }} />)}
    </>
  )
}

const ImgSite = styled.div`
  width: 5.625em;
  background-repeat: no-repeat;
  background-position: center;
`

export default LogosBroadcastMediums
