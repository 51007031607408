import RangeBase from '@ui-library/core/RangeBase'
import { useState } from 'react'

type RadiusExtendLocalityProps = {
  persistRadiusInReducer?: (value: number) => void
  onRadiusChange?: (changed: boolean, isZero: boolean) => void
  radius: number
}

const RadiusExtendLocality = ({
  persistRadiusInReducer = () => null,
  onRadiusChange = () => null,
  radius,
}: RadiusExtendLocalityProps) => {
  const minRadiusValue = 0
  const maxRadiusValue = 50

  const [value, setValue] = useState(radius || minRadiusValue)

  const persistValue = (val: number) => {
    setValue(val)
    persistRadiusInReducer(val)
    onRadiusChange(true, val === 0)
  }

  /**
   * Handling value change, setting the RangeBase value to some specific gapped values (1, 5, 10, 20, 30, 40, 50)
   */
  const handleMinChange = (val: number) => {
    if (val > 1 && val <= 2) {
      return persistValue(1)
    }
    if (val > 2 && val <= 5) {
      return persistValue(5)
    }
    if (val > 5 && val <= 10) {
      return persistValue(10)
    }
    if (val > 10) {
      return persistValue(Math.ceil(val / 10) * 10)
    }
    return persistValue(minRadiusValue)
  }

  return (
    <div className="radiusExtendLocality" data-testid="radiusExtendLocality">
      <p>Dans un rayon de {value} km</p>
      <RangeBase
        currentMin={value}
        onMinChange={handleMinChange}
        max={maxRadiusValue}
      />
      <div className="space-between">
        <p>{minRadiusValue}</p>
        <p>{maxRadiusValue}</p>
      </div>
    </div>
  )
}

export default RadiusExtendLocality
