import React from 'react'
import styled from 'styled-components'

import countLoading from '../../../assets/images/bloc-calculette@2x.png'

type CountInProgressProps = {
  dataTestId: string,
}

const CountInProgress = ({ dataTestId }: CountInProgressProps): React.ReactElement => (
  <LoadingWrapper data-testid={dataTestId}>
    <LoadingCount>
      <img src={countLoading} alt="comptage en cours" />
      <p>Comptage en cours de configuration</p>
    </LoadingCount>
  </LoadingWrapper>
)

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 10em;
  margin-bottom: 3em;
`

const LoadingCount = styled.div`
  width: 18em;
  height: fit-content;
  text-align: center;

  p {
    margin-top: 1.1em;
    font-size: 1em;
    font-weight: 700;
    color: ${(props) => props.theme.global.colorPalette.lightSaphir};
  }

  img {
    height: 100%;
    width: 100%;
  }
`

export default CountInProgress
