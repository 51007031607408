import {
  CountBo,
  DisplayResp,
  PlaceBo,
} from '../utils/myAdfactoryApi/swaggerApi'
import { TargetingsTypes } from './targetings.type'

export enum DisplayMarketsType {
  Neuf = 'Neuf',
  Luxe = 'Luxe',
  Construire = 'Construire',
  BureauxEtCommerce = 'BureauxEtCommerce',
}

export type ResTypes =
  | (Omit<CountBo, 'countData'> & { countData: DisplayResp })
  | { countData: DisplayResp }

export type DataType = {
  dates?: Date[]
  inputTargetingsValue?: string
  isAllFrance?: boolean
  logos?: string[] | null
  locations?: PlaceBo[]
  periodicity?: string
  targetings?: TargetingsTypes
}

export type initialStateType = {
  canSave: boolean
  countNameSaved: string | null
  data: DataType | null
  errors: any
  loading: 'pending' | 'idle'
  res: ResTypes | null
  radius: number
  listToDisplay: PlaceBo[]
}
