import React from 'react'
import { PinRegular } from '@ui-library/icons'
import { FormatStyled, IconStyled, StrongBudget, TableStyled, ThBlue } from '../common/styled'
import Loader from '../../Loader/Loader'
import { ForecastResult } from '../../../utils/myAdfactoryApi/swaggerApi'
import { capitalize, formatNumbers } from '../../../utils/tsUtils'

type PrintsByLocalitiesTableForVedettePlusProps = {
  localitiesResult: any[],
  isProposal?: boolean,
  loading: string,
  totalBudget: number,
}

const PrintsByLocalitiesTableForVedettePlus = ({
  localitiesResult,
  isProposal = false,
  loading,
  totalBudget,
}: PrintsByLocalitiesTableForVedettePlusProps): React.ReactElement => {
  const isPending = loading === 'pending'

  return (
    <TableStyled data-testid="PrintsByLocalitiesTableForVedettePlus">
      <thead>
        <tr>
          <th scope="col">
            <IconStyled icon={PinRegular} size="18px" />
            Localités
          </th>
          {!isProposal && <th scope="col">Déjà vendues <span>(sur la période)</span></th>}
          {!isProposal && <th scope="col">Disponibles <span>(sur la période)</span></th>}
          <ThBlue scope="col">Impressions proposées <span>(sur la période)</span></ThBlue>
          <ThBlue scope="col">Budgets <span>(hors taxe)</span></ThBlue>
        </tr>
      </thead>
      <tbody>
        {localitiesResult?.length > 0 && (
          localitiesResult.map((locality: ForecastResult) => {
            // If table is used in a proposal page, we want to hide the "zero" lines
            if (isProposal && locality.budget === 0) {
              return null
            }

            return (
              <tr key={`row-${locality.code}`}>
                <td>
                  <FormatStyled>
                    {capitalize(locality.name)} ({locality.code})
                  </FormatStyled>
                </td>
                {!isProposal && (
                  <>
                    <td>
                      {isPending ? (
                        <Loader maxWidth={100} height={20} />
                      ) : (
                        <>{formatNumbers(locality.sold ?? 0)} %</>
                      )}
                    </td>
                    <td>
                      {isPending ? (
                        <Loader maxWidth={100} height={20} />
                      ) : (
                        <>
                          {formatNumbers(locality?.available ?? 0)} %
                        </>
                      )}
                    </td>
                  </>
                )}
                <td data-testid={`inputPrint-${locality.code}`}>
                  {isPending ? (
                    <Loader maxWidth={300} height={35} style={{ marginBottom: '5px' }} />
                  ) : (
                    <StrongBudget>25 %</StrongBudget>
                  )}
                </td>
                <td data-testid={`inputBudget-${locality.code}`}>
                  {(isPending || locality.budget === undefined) ? (
                    <Loader maxWidth={300} height={35} style={{ marginBottom: '5px' }} />
                  ) : (
                    <StrongBudget>{locality.budget} €</StrongBudget>
                  )}
                </td>
              </tr>
            )
          })
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={!isProposal ? 4 : 2} style={{ textAlign: 'right' }}>
            <strong>Total du comptage :</strong>
          </td>
          <td>
            {isPending ? (
              <Loader maxWidth={80} height={20} style={{ marginLeft: 'auto' }} />
            ) : (
              <StrongBudget>{formatNumbers(totalBudget)} €</StrongBudget>
            )}
          </td>
        </tr>
      </tfoot>
    </TableStyled>
  )
}

export default PrintsByLocalitiesTableForVedettePlus
