import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Icon } from '@ui-library/core/Icon'
import AppartmentLined from '@ui-library/icons/AppartmentLined'
import HouseLined from '@ui-library/icons/HouseLined'
import AreaLined from '@ui-library/icons/AreaLined'

import CardSelect from '../../../../Cards/CardSelect/CardSelect'

import { emailingDataSelector, setData } from '../../../../../redux/reducers/Emailing/emailing.reducer'

interface ITypeProducts {
  typeOfProduct : string [],
  settingProduct : React.Dispatch<React.SetStateAction<string []>>
}

const TypesProduct = ({ typeOfProduct, settingProduct } : ITypeProducts) => {
  const dispatch = useDispatch()

  const data = useSelector(emailingDataSelector)

  const availableProducts: string[] = data?.productType ? Object.values(data.productType) : []
  const AppartmentInfo = 'Unité d\'habitation, comportant un certain nombre de pièces et n\'occupant qu\'une partie d\'un immeuble collectif.'
  const HouseInfo = 'Bâtiment d\'habitation, individuel, destiné au logement d\'une famille.'
  const LoftInfo = 'Ancien local professionnel (entrepôt, atelier) transformé en logement et/ou studio d\'artiste.'

  const [styleInfosBubble, setStyleInfosBubble] = useState<Array<{ style: string}>>(
    availableProducts.map((item) => {
      return {
        style: 'none',
        name: item,
      }
    })
  )
  const selectTypeProduct = (selection : string) => {
    const newSelection = typeOfProduct.includes(selection)
      ? typeOfProduct.filter((cur: string) => cur !== selection)
      : [...typeOfProduct, selection]
    settingProduct(newSelection)
    const updatedData = {
      ...data,
      options: {
        ...data.options ?? {},
        productTypes: newSelection,
      },
    }
    dispatch(setData(updatedData))
  }

  const cardsProductIcon = (indexProduct : number) => {
    const iconsArray = [
      <Icon key={`cardsProductIcon-first-${indexProduct}`} size="20px" icon={AppartmentLined} />,
      <Icon key={`cardsProductIcon-second-${indexProduct}`} size="20px" icon={HouseLined} />,
      <Icon key={`cardsProductIcon-thirst-${indexProduct}`} size="20px" icon={AreaLined} />
    ]

    const infosArray = [
      AppartmentInfo,
      HouseInfo,
      LoftInfo
    ]

    return {
      icon: iconsArray[indexProduct],
      infos: infosArray[indexProduct],
      style: styleInfosBubble,
    }
  }

  const infosBubbleEnter = (indexStyle : number) => {
    const newStyleItems = [...styleInfosBubble]
    const newStyleItem = {
      ...styleInfosBubble[indexStyle],
      style: 'block',
    }
    newStyleItems[indexStyle] = newStyleItem
    setStyleInfosBubble(newStyleItems)
  }

  const infosBubbleLeave = (indexStyle : number) => {
    const newStyleItems = [...styleInfosBubble]
    const newStyleItem = {
      ...styleInfosBubble[indexStyle],
      style: 'none',
    }
    newStyleItems[indexStyle] = newStyleItem
    setStyleInfosBubble(newStyleItems)
  }

  return (
    <div data-testid="criteriaTypesProduct">
      <TypesProductTitle>Types de bien <span>(facultatif)</span></TypesProductTitle>
      <TypesProductWanted>Quel est le type de bien recherché par la cible ? </TypesProductWanted>
      <TypesProductCards>
        {availableProducts.map((name : string, index: number) => (
          <CardSelect
            type="product"
            key={name}
            iconCard={cardsProductIcon(index)?.icon}
            infoBubbleCard={cardsProductIcon(index)?.infos}
            label={name}
            isChecked={typeOfProduct.includes(name)}
            onClickCard={() => { selectTypeProduct(name) }}
            styleInfosBubble={styleInfosBubble[index]?.style}
            onMouseInfosEnter={() => { infosBubbleEnter(index) }}
            onMouseInfosLeave={() => { infosBubbleLeave(index) }}
          />
        ))}
      </TypesProductCards>
    </div>
  )
}

const TypesProductTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 0.875em;
`

const TypesProductWanted = styled.p`
  margin-bottom: 1em;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875em;
  color: ${(p) => p.theme.global.colorPalette.grey};
`

const TypesProductCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
`

export default TypesProduct
