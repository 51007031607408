import React, { VFC } from 'react'
import styled from 'styled-components'
import { Icon } from '@ui-library/core'
import { PrivateTownHouseRegular } from '@ui-library/icons'
import { MoreCriteriaInformationType } from '../../../types/ResumeCard.type'

interface ISummaryMoreCriteria {
  moreCriteriaInformation: MoreCriteriaInformationType
}

const SummaryMoreCriteria : VFC<ISummaryMoreCriteria> = ({ moreCriteriaInformation } : any) => {
  const optionsNotSelected = moreCriteriaInformation?.productTypes === '' && moreCriteriaInformation?.numberOfPieces === null && moreCriteriaInformation?.price === null

  return (
    <MoreCriteriaBlock className={optionsNotSelected ? 'isDisable' : ''}>
      <TitleBloc>
        <div className="icon">
          <Icon icon={PrivateTownHouseRegular} size="100%" />
        </div>
        <div className="title">Plus de critères</div>
      </TitleBloc>
      <Description>
        <p>{moreCriteriaInformation?.productTypes}</p>
        <p>{moreCriteriaInformation?.numberOfPieces}</p>
        <p>{moreCriteriaInformation?.price}</p>
      </Description>
    </MoreCriteriaBlock>
  )
}

const MoreCriteriaBlock = styled.div`
  display: flex;
  text-align: left !important;
  align-items: left !important;
  flex-direction: column;
  align-items: flex-start;
  grid-column: 1;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 2;
  
  &.isDisable {
    display: none;
  }
`
const TitleBloc = styled.div`
  width: 88%;
  display: flex;
  justify-content: flex-start;

  .title {
    font-size: 1.125em;
    font-family: ${(props) => props.theme.global.fontFamily[0]};
    font-weight: 700;
    color: ${(props) => props.theme.global.colorPalette.black};
    margin-bottom: 0.1em;
  }
`
const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 87%;
  text-align: left;
  font-size: 0.75em;
  font-family: ${(props) => props.theme.global.fontFamily[0]};
  color: ${(props) => props.theme.global.colorPalette.black};
`
export default SummaryMoreCriteria
