import React from 'react'

import CheckCircleFilled from '@ui-library/icons/CheckCircleFilled'
import CheckBold from '@ui-library/icons/CheckBold'

import { isPrintOptionForIntensitySelected, formatNumbers } from '../../../../utils/tsUtils'
import { EXPERT_360_MINIMUM_BUDGET_TO_OFFER_PRINT_OPTION } from '../../../../utils/variables'
import { BudgetDescriptionSpan, BudgetSpan, CheckOfferIcon, HeaderIntensity, IntensityInfoIcon, IntensityInfoUl, Expert360IntensityCardStyled } from '../styled'

export const generateIntensityInfos = (
  budget: number,
  impressions: number,
  available: boolean,
  shareOfVoice: number | undefined
) => {
  const impressionsFormat = formatNumbers(impressions)
  const lines = []
  lines[0] = `${impressionsFormat} impressions en Display`
  lines[1] = `Soit ${shareOfVoice}/10 de part de présence`
  lines[2] = `Retargeting ${impressionsFormat} impressions sur une sélection de sites Premium à forte notoriété`

  const isPrintOptionSelected = isPrintOptionForIntensitySelected(null, budget > EXPERT_360_MINIMUM_BUDGET_TO_OFFER_PRINT_OPTION)
  if (isPrintOptionSelected) {
    lines[3] = '1 contenu Brand Content Digital'
  }

  if (!available) lines[3] = 'contenu ici qui sera grisé'
  return lines
}

type Expert360IntensityCardProps = {
  available: boolean,
  budget: number,
  colorIntensity: string,
  id: number,
  impressions: number,
  isChecked: boolean,
  notAllowBrandContent: boolean,
  shareOfVoice: number | undefined,
  handleClick: (id: number) => void,
}

const Expert360IntensityCard = ({
  available,
  budget,
  colorIntensity,
  id,
  impressions,
  isChecked,
  notAllowBrandContent,
  shareOfVoice,
  handleClick,
}: Expert360IntensityCardProps): React.ReactElement => {
  const currentColor = ['Low', 'Medium', 'High'][id]
  const intensityInfos = generateIntensityInfos(budget, impressions, available, shareOfVoice)

  return (
    <Expert360IntensityCardStyled
      $available={available}
      $currentColor={currentColor}
      $isChecked={isChecked}
      $notAllowed={notAllowBrandContent}
      data-testid="intensityOffer"
      id={`intensity${currentColor}`}
      onClick={() => handleClick(id)}
    >
      <CheckOfferIcon
        $isChecked={isChecked}
        color={colorIntensity}
        icon={CheckCircleFilled}
        size="2.5em"
      />
      <HeaderIntensity>
        <BudgetSpan style={{ color: colorIntensity }} data-testid="headerIntensityBudget">{formatNumbers(budget)} €</BudgetSpan>
        <BudgetDescriptionSpan style={{ color: colorIntensity }}>mensuel</BudgetDescriptionSpan>
      </HeaderIntensity>
      <IntensityInfoUl data-testid="intensityInfo">
        {
          intensityInfos.map(
            (info, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={key}>
                { key !== 1 && <IntensityInfoIcon icon={CheckBold} color={colorIntensity} />}
                <span>{info}</span>
              </li>
            )
          )
        }
      </IntensityInfoUl>
    </Expert360IntensityCardStyled>
  )
}

export default Expert360IntensityCard
