import { Icon } from '@ui-library/core'
import styled, { css } from 'styled-components'

type TotalAndBudgetProps = {
  $hideBudget: boolean,
}

export const TableStyled = styled.table`
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;

  thead, tbody {
    width: 100%;

    tr {
      &:last-child {
        td {
          border-bottom: 1px solid ${(props) => props.theme.global.colorPalette.greyLight};
        }
      }
    }
    td {
      padding-left: 1%;
    }
    .availableBlock {
      display: flex;
    }
    .circleSold {
      display:inline-block;
      width: 0.8em;
      height: 0.8em;
      position: relative;
      border-radius: 50%;
      background-color: ${(props) => props.theme.global.myAdFactory.customColors.orange};
      right: 4%;
      top: 0.1em;
    }
    .circleAvailable {
      display:inline-block;
      width: 0.8em;
      height: 0.8em;
      position: relative;
      border-radius: 50%;
      background-color: ${(props) => props.theme.global.myAdFactory.customColors.green};
      right: 4%;
      top: 0.9em;
    }
    .productTitle {
      font-size: 1.3em;
      font-weight: 800;
    }
    .productName {
      display: flex;

      p {
        font-weight: 600;
      }

      svg {
        margin-top: 0.2em;
        margin-left: 0.3em;
        color: ${(props) => props.theme.global.colorPalette.saphir};
        cursor: pointer;
      }
    }
    .retargetingFeature {
      background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
      color: ${(props) => props.theme.global.colorPalette.saphir};
      padding: 0.2em 0.8em;
      margin-top: 0.5em;
      width: fit-content;
      border-radius: 0.3em;
    }

    td, th {
      width: 20%;
      border-top: 1px solid ${(props) => props.theme.global.colorPalette.greyLight};
      border-left: 1px solid ${(props) => props.theme.global.colorPalette.greyLight};

      &:last-child {
        border-right: 1px solid ${(props) => props.theme.global.colorPalette.darkerSaphir};
      }

      &:nth-child(4) {
        border-left: 1px solid ${(props) => props.theme.global.colorPalette.darkerSaphir};
      }
    }
  }

  thead {
    th {
      vertical-align: middle;

      &:first-child {
        border-top-left-radius: .3em;
        width: 25em;
      }

      &:last-child {
        border-top-right-radius: .3em;
      }
    }
  }

  tbody {
    tr {
      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: .3em;
          }
        }
      }
    }
    .isDisabled {
      opacity: 40%;
    }
  }

  tfoot {
    td {
      &:nth-child(2) {
        border-left: 1px solid ${(props) => props.theme.global.colorPalette.darkerSaphir};
        border-bottom: 1px solid ${(props) => props.theme.global.colorPalette.darkerSaphir};
        border-bottom-left-radius: .3em;
      }

      &:nth-child(3) {
        border-left: 1px solid ${(props) => props.theme.global.colorPalette.greyLight};
      }

      &:last-child {
        border-right: 1px solid ${(props) => props.theme.global.colorPalette.darkerSaphir};
        border-bottom: 1px solid ${(props) => props.theme.global.colorPalette.darkerSaphir};
        border-bottom-right-radius: .3em;
      }
    }
  }

  th, td {
    padding: .7em .5em;
    text-align: left;
  }

  th {
    font-weight: 700;
  }

  td {
    padding: 1.5em .5em;
    vertical-align: middle;
  }

`

export const IconStyled = styled(Icon)`
  display: inline-block;
  float: left;
  margin-right: .3em;
`

export const ThBlue = styled.th`
  background: ${(props) => props.theme.global.colorPalette.darkerSaphir};
  color: ${(props) => props.theme.global.colorPalette.white};
  border: 1px solid ${(props) => props.theme.global.colorPalette.darkerSaphir} !important;
`

export const TotalAndBudget = styled.div<TotalAndBudgetProps>`
  ${(props) => props.$hideBudget && css`
    display: flex;

    strong {
      width: 50%;
      flex: 0 0 50%;
    }
  `}
`

export const SpanShareOfVoice = styled.span`
  display: block;
  margin-top: .5em;
`

export const SpanCoverage = styled.span`
  display: block;
  margin-top: .5em;
  font-size: 0.85em;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

export const InputWrapper = styled.div`
  display: flex;
  height: 2.5em;

  input {
    border-radius: .3em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
    width: 100%;
    border: 1px solid ${(props) => props.theme.global.colorPalette.greyLight};
    padding: 0 1em;
    border-right: none;
    outline: none !important;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    height: 100%;
    border: 1px solid ${(props) => props.theme.global.colorPalette.greyLight};
    border-radius: .3em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
`

export const SpanMax = styled.span`
  display: block;
  margin-top: .5em;
  font-size: 0.85em;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

export const ErrorBudget = styled.span`
  display: block;
  margin-top: .5em;
  font-weight: 600;
  font-size: .85em;
  color: ${(props) => props.theme.global.colorPalette.error};
`

export const StrongBudget = styled.strong`
  display: block;
  text-align: right;
`

export const ImgBlock = styled.div`
  display: flex;
  align-items: center;
  max-height: 30px;
  overflow: hidden;

  img {
    background-size: cover;
  }
`

export const FormatStyled = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 1em;
  }
`

export const IconMoreInfo = styled.div`
  @media print {
    display: none;
  }
`

export const PopinContainer = styled.div`
  text-align: center;
  padding: 3em 3em 2em 3em;
  p {
    font-size: medium;
  }
`

export const PopinImages = styled.div`
  display: flex;
  .productImage{
    justify-items: center;
    display: grid;
    width: 50%;
  }
`

export const PopinTitle = styled.h3`
  font-weight: 700;
  margin-top: 1em;
  font-size: x-large;
  margin-bottom: 0.5em;
`

export const PopinDescription = styled.p`
  margin-bottom: 1.5em;
`
