import React from 'react'
import styled from 'styled-components'
import Tabs from '@ui-library/core/Tabs'

type DisplayTabsProps = {
  currentTab: string,
  handleChange: (value: string) => void,
}

const DisplayTabsWrapper = styled.div`
  margin-bottom: 2em;
`

const DisplayTabs = ({ currentTab, handleChange }: DisplayTabsProps): React.ReactElement => {
  const choices = [
    {
      label: 'Par format',
      id: 'format',
      ariaControls: 'formatTab',
    },
    {
      label: 'Par contexte de diffusion',
      id: 'context',
      ariaControls: 'contextTab',
    }
  ]

  return (
    <DisplayTabsWrapper data-testid="displayTabs">
      <Tabs
        choices={choices}
        kind="secondary"
        selected={currentTab}
        type="normal"
        onChange={(tab) => handleChange(tab.id)}
      />
    </DisplayTabsWrapper>
  )
}

export default DisplayTabs
