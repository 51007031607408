import React, { useEffect, useRef, useState } from 'react'
import { Button, InfoBubble } from '@ui-library/core'
import { PhotocopyRegular } from '@ui-library/icons'
import styled from 'styled-components'

type SummaryLocationsCopyLocalitiesProps = {
  zipCodes: string[],
  separator?: string,
}

const CopyLocalitiesWrapper = styled.div`
  margin-top: .5em;
  @media print {
    margin-top: 0;
    visibility: hidden;
  }
`

const InfoBubbleStyled = styled(InfoBubble)`
  h6 {
    font-weight: 700;
    font-size: 1.2em;
  }
`

const SummaryLocationsCopyLocalities = ({ zipCodes, separator = '|' }: SummaryLocationsCopyLocalitiesProps): React.ReactElement | null => {
  // Refs
  const btnRef = useRef(null)

  // State
  const [zipCodesCopied, setZipCodesCopied] = useState<boolean>(false)

  // Variables
  const zipCodesToCopy = zipCodes.join(separator)

  const handleClick = () => {
    if (zipCodes?.length > 0) {
      navigator.clipboard.writeText(zipCodesToCopy).then(() => setZipCodesCopied(true))
    }
  }

  const handleBubble = () => {
    setZipCodesCopied(false)
  }

  useEffect(() => {
    let timeOut: ReturnType<typeof setTimeout>

    if (zipCodesCopied) {
      timeOut = setTimeout(() => setZipCodesCopied(false), 2000)
    }

    return () => clearTimeout(timeOut)
  }, [zipCodesCopied])

  return (
    <CopyLocalitiesWrapper>
      <Button
        testID="copyLocalitiesBtn"
        icon={PhotocopyRegular}
        kind="primary"
        size="small"
        tag="button"
        variant="normal"
        onClick={handleClick}
        disabled={!zipCodes}
        ref={btnRef}
      >
        Copier les localités
      </Button>
      {zipCodesCopied && (
        <InfoBubbleStyled
          anchorRef={btnRef}
          orientation="right"
          type="Tooltip"
          onClick={handleBubble}
          onClickOutside={handleBubble}
          testID="confirmationLocalitiesCopied"
        >
          <h6>{zipCodes?.length} localité(s) copiée(s)</h6>
          <p>Collez-les dans votre outil de routage habituel</p>
        </InfoBubbleStyled>
      )}
    </CopyLocalitiesWrapper>
  )
}

export default SummaryLocationsCopyLocalities
