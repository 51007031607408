import React from 'react'

const ResultTableLoad = () => (
  <div className="tableResult">
    <div className="rowAlign">
      <span>Volume total:</span>
      <div className="firstBarLoading" />
    </div>
    <div className="wrapperTotalBudget">
      <div className="rowAlign">
        <span>Frais de production:</span>
        <div className="firstBarLoading" />
      </div>
      <div className="rowAlign">
        <span>Budget total:</span>
        <div className="secondBarLoading" />
      </div>
    </div>
  </div>
)
export default ResultTableLoad
