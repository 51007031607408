import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

type GlobalLayoutProps = {
  children: React.ReactNode | React.ReactNode[],
}

type MyGlobalLayoutProps = {
  $size: number,
}

const GlobalLayoutStyled = styled.div`
  font-size: ${({ $size }: MyGlobalLayoutProps) => `${$size}px`};
`

const GlobalLayout = ({ children }: GlobalLayoutProps): React.ReactElement => {
  const defaultFontSize = 16
  const history = useHistory()
  const [fontSize, setFontSize] = useState<number>(defaultFontSize)
  const ratio: number = 16 / 10

  //---------------------------------------------------------------------
  // set Font size according to window width
  const handleResize = useCallback(() => {
    const baseFontSize = defaultFontSize
    let newFontSize = baseFontSize

    if (window.innerWidth <= 768) {
      if (window.innerWidth / window.innerHeight < ratio) {
        newFontSize *= window.innerWidth / 320
      } else {
        newFontSize *= window.innerWidth / 480
      }
    } else if (window.innerWidth <= 1024) {
      newFontSize *= window.innerWidth / 1024
    } else {
      newFontSize = baseFontSize
    }

    setFontSize(newFontSize)
  }, [ratio])

  //---------------------------------------------------------------------
  // Add and remove eventListener on window resize
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize, false)

    return () => {
      window.removeEventListener('resize', handleResize, false)
    }
  }, [handleResize])

  //---------------------------------------------------------------------
  // Scroll to top at every history changes
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  return (
    <GlobalLayoutStyled $size={fontSize} data-testid="layout">
      {children}
    </GlobalLayoutStyled>
  )
}

export default GlobalLayout
