import React from 'react'
import styled from 'styled-components'
import { Button } from '@ui-library/core'
import { AddBold } from '@ui-library/icons'
import { respondTo } from '../../../theme/mixins/respondTo'

type HomepageHeaderProps = {
  userProfile: any,
  handleCreateNewCount: () => void,
}

const HomepageHeaderWrapper = styled.section`
    display: block;
    width: 100%;

    ${respondTo.md`
    display: flex;
    width: 100%;
    align-items: center;
    `}
`

const HomepageHeaderTitle = styled.div`
  ${respondTo.md`
    width: 70%;
    flex: 0 0 70%;
  `}

  h2 {
    color: ${(props) => props.theme.global.colorPalette.black};
    font-weight: 700;
    font-size: 2em;
    margin-bottom: .5em;

    ${respondTo.md`
      margin-bottom: 0;
    `}
  }
`

const HomepageHeaderNewCount = styled.div`
  ${respondTo.md`
    width: 30%;
    flex: 0 0 30%;
    text-align: right;
  `}
`

const HomepageHeader = ({ userProfile, handleCreateNewCount }: HomepageHeaderProps): React.ReactElement => (
  <HomepageHeaderWrapper data-testid="homepageHeader">
    <HomepageHeaderTitle>
      <h2>Bonjour {userProfile?.firstName},</h2>
    </HomepageHeaderTitle>
    <HomepageHeaderNewCount>
      <Button
        icon={AddBold}
        iconPosition="left"
        kind="primary"
        size="medium"
        tag="button"
        testID="addNewCountBtn"
        variant="normal"
        onClick={handleCreateNewCount}
      >
        Nouveau comptage
      </Button>
    </HomepageHeaderNewCount>
  </HomepageHeaderWrapper>
)

export default HomepageHeader
