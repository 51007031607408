import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { recordCountForExpert360Async } from '../../../services/expert360Api'
import { recordCountForMasterDisplayAsync } from '../../../services/masterDisplayApi'
import { recordCountAsync } from '../../../services/emailingApi'
import { recordCountForDisplayAsync } from '../../../services/displayApi'
import { recordCountForVedettePlusAsync } from '../../../services/vedettePlusApi'
import { recordCountForPerformanceAsync } from '../../../services/performanceApi'
import {
  successContentCreation,
  nbEmailsErrorContent,
  adjustableOfferErrorContent,
  budgetErrorContent,
  budgetInvalidErrorContent,
  networkErrorContent,
} from '../../constants/notification'

interface IcurrentNotificationsState {
  notificationVisible: boolean;
  notification: any;
}

export const initialState: IcurrentNotificationsState = {
  notificationVisible: false,
  notification: {},
}

// Slice
const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showNotification(state) {
      state.notificationVisible = true
    },
    hideNotification(state) {
      state.notificationVisible = false
    },
    setBudgetLow(state, action) {
      state.notification = budgetErrorContent(action.payload)
      state.notificationVisible = true
    },
    setNbEmailsInvalid(state) {
      state.notification = nbEmailsErrorContent
      state.notificationVisible = true
    },
    setBudgetInvalid(state) {
      state.notification = budgetInvalidErrorContent
      state.notificationVisible = true
    },
    setAdjustableOfferInvalid(state) {
      state.notification = adjustableOfferErrorContent
      state.notificationVisible = true
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        state.notificationVisible = false
        state.notification = {}
      })
      .addMatcher(isAnyOf(recordCountForExpert360Async.pending, recordCountForMasterDisplayAsync.pending, recordCountAsync.pending, recordCountForDisplayAsync.pending, recordCountForPerformanceAsync.pending, recordCountForVedettePlusAsync.pending), (state) => {
        state.notificationVisible = false
        state.notification = ''
      })
      .addMatcher(isAnyOf(recordCountForExpert360Async.rejected, recordCountForMasterDisplayAsync.rejected, recordCountAsync.rejected, recordCountForDisplayAsync.rejected, recordCountForPerformanceAsync.rejected, recordCountForVedettePlusAsync.rejected), (state) => {
        state.notification = networkErrorContent
        state.notificationVisible = true
      })
      .addMatcher(isAnyOf(recordCountForExpert360Async.fulfilled, recordCountForMasterDisplayAsync.fulfilled, recordCountAsync.fulfilled, recordCountForDisplayAsync.fulfilled, recordCountForPerformanceAsync.fulfilled, recordCountForVedettePlusAsync.fulfilled), (state) => {
        state.notificationVisible = true
        state.notification = successContentCreation
      })
  },
})

// Selectors
type NotificationsSliceName = (typeof notificationsSlice)['name']
export type notificationsStateType = Record<NotificationsSliceName, IcurrentNotificationsState>

export const notificationVisibleSelector = (state: notificationsStateType) => state.notifications.notificationVisible
export const notificationSelector = (state: notificationsStateType) => state.notifications.notification

// Actions
export const {
  showNotification,
  hideNotification,
  setBudgetLow,
  setBudgetInvalid,
  setNbEmailsInvalid,
  setAdjustableOfferInvalid,
} = notificationsSlice.actions

// The reducer
export default notificationsSlice.reducer
