import { Button, Icon } from '@ui-library/core'
import { CloseRegular } from '@ui-library/icons'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rowSelectedSelector } from '../../../redux/reducers/Page/Users/pageUsers.reducer'
import { addJobAndMarketToUserProfile } from '../../../redux/reducers/UserConfig/userConfig.reducer'
import { createOrUpdateUser } from '../../../services/userApi'
import { SelectElementType } from '../../../types/select.type'
import { UserInformation } from '../../../types/users'
import { Market, TypeProfil } from '../../../utils/myAdfactoryApi/swaggerApi'
import { customSortMarketList } from '../../../utils/tsUtils'
import { jobList, marketList } from '../../../utils/variables'
import Select from '../../Select/Select'
import Overlay from '../Overlay'

type OverlayEditUserProps = {
  closeOverlay: () => void
}
const OverlayEditUser = ({ closeOverlay }: OverlayEditUserProps) => {
  const dispatch = useDispatch()
  const [jobSelected, setJobSelected] = useState<SelectElementType | null>(null)
  const [marketsSelected, setMarketsSelected] = useState<SelectElementType[]>(
    []
  )

  const rowSelected = useSelector(rowSelectedSelector)

  const initial = `${rowSelected.userData?.firstName?.substring(
    0,
    1
  )}${rowSelected.userData?.lastName?.substring(0, 1)}`

  const handleJobChange = (elements: SelectElementType[]) => {
    if (elements.length > 0) {
      setJobSelected(elements[0])
    }
  }

  const handleMarketSelect = (elements: SelectElementType[]) => {
    elements.sort(customSortMarketList)
    setMarketsSelected(elements)
  }

  const preSelectedJob: SelectElementType | null =
    jobList.find(
      (element: SelectElementType) =>
        element.value.toLowerCase() ===
        rowSelected.userData?.typeProfil?.toLowerCase()
    ) ?? null

  const preSelectedMarkets = () => {
    const values = Array.from(rowSelected.userData.markets.values())
    const preSelectedValues: SelectElementType[] =
      values.map(
        (value) =>
          marketList.find(
            (element) => element.value === value
          ) as SelectElementType
      ) || []

    return preSelectedValues
  }

  const jobSelectedValue = () => {
    if (preSelectedJob && jobSelected === null) {
      return [preSelectedJob]
    }
    if (jobSelected) {
      return [jobSelected]
    }
    return null
  }

  const displayOrNotSelectedMarkets = () => {
    // Display the market selection menu if you select the "commerce" job and/or if the "commerce" job is already selected
    const commerceJob = jobList.find(
      (job: SelectElementType) => job.value === 'commerce'
    )?.value
    return (
      (preSelectedJob?.value === commerceJob && jobSelected === null) ||
      jobSelected?.value === commerceJob
    )
  }

  const processInformation = () => {
    const commerceJob = jobList.find(
      (job: SelectElementType) => job.value === 'commerce'
    )?.value
    const userInformation: UserInformation = {
      email: rowSelected.userData.email,
      lastName: rowSelected.userData.lastName,
      firstName: rowSelected.userData.firstName,
      typeProfil: jobSelected?.value as TypeProfil,
      markets:
        (preSelectedJob?.value !== commerceJob && jobSelected === null) ||
        (jobSelected?.value && jobSelected?.value !== commerceJob)
          ? (marketList.map((elem) => elem.value) as Market[])
          : (marketsSelected.map((elem) => elem.value) as Market[]),
      roles: rowSelected.userData.roles,
    }
    dispatch(createOrUpdateUser({ userInformation }))
    dispatch(addJobAndMarketToUserProfile(userInformation))

    closeOverlay()
  }

  return (
    <Overlay closeOverlay={() => closeOverlay()}>
      <div data-testid="overlayEditUser">
        <Button
          onClick={() => {
            closeOverlay()
          }}
          className="closeOverlay"
          kind="tertiary"
          size="medium"
          variant="text"
          tag="button"
        >
          <Icon icon={CloseRegular} />
        </Button>
        <div className="overlayFirstConnection">
          <div className="wrapperOverlayFirstConnection">
            <h2>Modifier le profil</h2>
            <div data-testid="overlayInfosUser" className="overlayHeader">
              <div className="tdWrapper circle">
                <div className="initial">{initial}</div>
              </div>
              <div className="overlayInfosUser">
                {rowSelected.userData.firstName} {rowSelected.userData.lastName}
                <div className="overlayInfosUserEmail">
                  {rowSelected.userData.email}
                </div>
              </div>
            </div>
            <hr />
            <div className="wrapperSelectJob" data-testid="selectJobEditUser">
              <Select
                options={jobList}
                label="Votre métier"
                placeholder="Ex : Commerce"
                onChange={handleJobChange}
                value={jobSelectedValue()}
              />

              {displayOrNotSelectedMarkets() && (
                <div
                  data-testid="selectMarketEditUser"
                  className="wrapperSelectMarket"
                >
                  <Select
                    options={marketList}
                    label="Marché(s)"
                    multiple
                    placeholder="Ancien, Neuf..."
                    onChange={(selected) => handleMarketSelect(selected)}
                    value={
                      marketsSelected.length > 0
                        ? marketsSelected
                        : preSelectedMarkets()
                    }
                  />
                </div>
              )}
              <div data-testid="saveEditUser" className="wrapperbuttonValidate">
                <Button
                  tag="button"
                  kind="primary"
                  size="medium"
                  variant="normal"
                  onClick={() => {
                    processInformation()
                  }}
                  disabled={
                    !(
                      (jobSelected !== null &&
                        jobSelected.value !== jobList[0].value) ||
                      marketsSelected.length !== 0
                    )
                  }
                >
                  Valider
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Overlay>
  )
}

export default OverlayEditUser
