import React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import styled from 'styled-components'
import { Button } from '@ui-library/core'
import Logo from '../../components/Logo/Logo'
import { getPersistor } from '../../redux/store/index'
import { respondTo } from '../../theme/mixins/respondTo'
import loginBackgroundSrc from '../../assets/images/login_background.jpg'

const LoginPage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
`

const LoginImage = styled.div`
  display: none;

  ${respondTo.sm`
    display: block;
    width: 57%;
    height: 100%;
    background-image: url(${loginBackgroundSrc});
    background-size: cover;
    background-position: center;
  `}
`

const LoginContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  ${respondTo.sm`
    width: 43%;
  `}
`

const LoginContent = styled.div`
  width: 100%;
  margin-right: 20%;
  margin-left: 20%;

  ${respondTo.sm`
    width: 56%;
  `}
`

const LoginContentText = styled.div`
  margin-top: 1.5em;
  margin-bottom: 2em;

  h2 {
    color: ${(props) => props.theme.global.colorPalette.greyDark};
    font-size: 1.2em;
    font-weight: 700;

    ${respondTo.sm`
      font-size: 2em;
    `}
  }
`

const Login = (): React.ReactElement => {
  const { oktaAuth } = useOktaAuth()

  const handleLogin = async () => {
    const persistor = getPersistor()
    persistor.purge()
    await oktaAuth.signInWithRedirect()
  }

  return (
    <LoginPage data-testid="loginPage">
      <LoginImage />
      <LoginContentWrapper>
        <LoginContent>
          <Logo />
          <LoginContentText>
            <h2 data-testid="loginPageTitle">
              Bienvenue<br />
              sur MyAdFactory
            </h2>
          </LoginContentText>
          <Button
            size="large"
            kind="primary"
            variant="normal"
            onClick={handleLogin}
            tag="a"
            testID="loginPageBtn"
          >
            Je me connecte
          </Button>
        </LoginContent>
      </LoginContentWrapper>
    </LoginPage>
  )
}

export default Login
