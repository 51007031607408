import React from 'react'
import { useSelector } from 'react-redux'
import { DocumentLined, CalendarLined, DesktopLined } from '@ui-library/icons'

import ResumeCard from '../../../ResumeCard/ResumeCard'

import { masterDisplayDataSelector, masterDisplayLoadingSelector, masterDisplayResSelector } from '../../../../redux/reducers/MasterDisplay/masterDisplay.reducer'

import { formatNumbers } from '../../../../utils/tsUtils'

import { BudgetInformationType, TableInformationType } from '../../../../types/ResumeCard.type'
import { productNamesType } from '../../../../types/productNames.types'
import { ForecastResult } from '../../../../utils/myAdfactoryApi/swaggerApi'
import { MASTER_DISPLAY_CPM } from '../../../../utils/variables'
import { formatZipCodeWithManyLocalityNames } from '../../../../utils/format'

type MasterDisplaySummaryCountProps = {
  isProposal?: boolean,
}

const MasterDisplaySummaryCount = ({ isProposal = false }: MasterDisplaySummaryCountProps): React.ReactElement | null => {
  const loading = useSelector(masterDisplayLoadingSelector)
  const data = useSelector(masterDisplayDataSelector)
  const res = useSelector(masterDisplayResSelector)

  const printsPerMonth = ((res?.countData?.totalBudget ?? 0) / MASTER_DISPLAY_CPM) * 1000
  const locations: ForecastResult[] | undefined = formatZipCodeWithManyLocalityNames(data?.locations ?? [], res?.countData?.localityNameByZipCode ?? {})
  const projectTypes = data?.projectTypes?.filter((d: any) => d.checked).map((d: any) => d.label) ?? []

  const budgetInformation: BudgetInformationType = {
    totalBudget: formatNumbers(res?.countData?.totalBudget ?? 0),
    complementary: `${formatNumbers(Math.round(printsPerMonth))} Impressions \n / mois sur l'année`,
  }

  const tableInformation: TableInformationType = [{
    title: 'Appareils',
    icon: DesktopLined,
    description: 'Desktop, tablette, mobile',
  },
  {
    title: 'Type de projet',
    icon: DocumentLined,
    description: projectTypes?.join(', ') ?? '',
  },
  {
    title: 'Périodicité',
    icon: CalendarLined,
    description: res?.countData?.masterDisplayReq?.periodicity ?? data?.periodicity?.[0]?.value,
  }]

  return (
    <ResumeCard
      testId="masterDisplayContentSummary"
      loading={loading}
      broadcastMediums={data?.broadcastMediums?.filter((d: any) => d.checked).map((d: any) => d.label) ?? []}
      budgetInformation={budgetInformation}
      productName={productNamesType.MasterDisplay}
      tableInformation={tableInformation}
      locations={locations}
      isProposal={isProposal}
    />
  )
}

export default MasterDisplaySummaryCount
