import { css } from 'styled-components'
import myAdFactory from '../myAdFactory'
import type { Breakpoints } from '../myAdFactory'

type RespondToFunction = {
  [Key in keyof Breakpoints]: (
    ...args: Parameters<typeof css>
  ) => ReturnType<typeof css>
}

// eslint-disable-next-line prefer-destructuring
const breakpoints: Breakpoints = myAdFactory.breakpoints

export const respondTo: RespondToFunction = Object.keys(breakpoints).reduce((accumulator: any, label) => {
  // eslint-disable-next-line no-param-reassign
  accumulator[label] = (...args: Parameters<typeof css>) => css`
    @media (min-width: ${breakpoints[label]}) {
      ${css(...args)}
    }
  `
  return accumulator
}, {} as RespondToFunction)
