import React from 'react'
import styled from 'styled-components'

import { Icon } from '@ui-library/core'
import { ChevronLeftBold } from '@ui-library/icons'

import { useHistory } from 'react-router-dom'

const ProgressBar = () => {
  const history = useHistory()

  const backStepConfiguration = () => {
    const pathPreviousStep = '/comptage/produits'
    history.push(pathPreviousStep)
  }

  return (
    <ProgressionBarSection data-testid="progressionBarSection">
      <ProgressionBarWrapper>
        <BackStepContainer>
          <BackStepButton data-testid="progressionBarBtn" type="button" onClick={backStepConfiguration}>
            <Icon icon={ChevronLeftBold} />
            <span>Retour à la liste des produits</span>
          </BackStepButton>
        </BackStepContainer>
      </ProgressionBarWrapper>
    </ProgressionBarSection>
  )
}

const ProgressionBarSection = styled.div`
  height: 3.5em;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.global.colorPalette.white};
`

const ProgressionBarWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 1%;
`

const BackStepContainer = styled.div`
  width: 41.3%;
  height: 3.5em;
  display: flex;
  align-items: center;
`

const BackStepButton = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  font-size: 0.85em;
  font-weight: 600;
  color: ${(props) => props.theme.global.colorPalette.saphir};

  span {
    margin-left: .4em;
  }
`

export default ProgressBar
