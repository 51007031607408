import React from 'react'
import styled from 'styled-components'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import OktaAuth from '@okta/okta-auth-js'

import { setUserConnected } from '../../../redux/reducers/UserConfig/userConfig.reducer'

import { getPersistor } from '../../../redux/store/index'

const handleLogout = async (oktaAuth: OktaAuth) => {
  const persistor = getPersistor()
  persistor.purge()
  await oktaAuth.revokeRefreshToken()
  oktaAuth.tokenManager.clear()
}

const ButtonStyled = styled.button`
  background: transparent;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100%;
  text-align: left;
  font-size: inherit;
`

const ButtonSignout = (): React.ReactElement => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { oktaAuth } = useOktaAuth()

  return (
    <ButtonStyled
      data-testid="headerButtonSignout"
      type="button"
      onClick={(e) => {
        e.preventDefault()
        dispatch(setUserConnected(false))
        handleLogout(oktaAuth)
        history.push('/login')
      }}
    >
      Déconnexion
    </ButtonStyled>
  )
}

export default ButtonSignout
