import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import mapBackground from '../../../../assets/images/map_background.jpg'

import CountInProgress from '../../../Counts/CountInProgress/CountInProgress'
import PerformanceSummaryCounts from '../PerformanceSummaryCount/PerformanceSummaryCount'
import ClicksByFormatTable from '../../../Tables/ClicksByFormatTable/ClicksByFormatTable'
import CountsFooter from '../../../Counts/CountsFooter/CountsFooter'

import { useProductPerformanceCount } from '../../../../hooks/products/performance/useProductPerformanceCount'

import {
  performanceCanSaveSelector,
  performanceCountNameSavedSelector,
  performanceLoadingSelector,
  performanceResSelector,
} from '../../../../redux/reducers/Performance/performance.reducer'

const PerformanceCount = (): React.ReactElement => {
  const {
    handleChangeInputs,
  } = useProductPerformanceCount()

  const res = useSelector(performanceResSelector)
  const loading: string = useSelector(performanceLoadingSelector)
  const canSave: boolean = useSelector(performanceCanSaveSelector)
  const countNameSaved: string | null = useSelector(performanceCountNameSavedSelector)

  if (!res || (res?.countData && Object.keys(res.countData).length === 0)) {
    return <CountInProgress dataTestId="performanceCount" />
  }

  return (
    <PerformanceCountWrapper data-testid="performanceCount">
      <PerformanceCountMap style={{ backgroundImage: `url(${mapBackground})` }} />
      <PerformanceCountSummary>
        <PerformanceCountTitle>Résumé du comptage</PerformanceCountTitle>
        <PerformanceCountSummaryBlock>
          <PerformanceSummaryCounts />
        </PerformanceCountSummaryBlock>
        <ClicksByFormatTable
          cpcs={res?.countData?.cpm ?? undefined}
          formatsResult={res?.countData?.forecastSplitByPosition ?? res?.countData?.forecastSplitByPosition ?? []}
          loading={loading}
          totalBudget={res?.countData?.totalBudget ?? 0}
          totalOffer={res?.countData?.totalImpPurchased ?? 0}
          onChange={handleChangeInputs}
        />
        <CountsFooter
          canSave={canSave}
          countNameSaved={countNameSaved}
          loading={loading}
          res={res}
        />
      </PerformanceCountSummary>
    </PerformanceCountWrapper>
  )
}

const PerformanceCountWrapper = styled.div`
  position: relative;
`

const PerformanceCountMap = styled.div`
  position: absolute;
  left: 0;
  top: 13em;
  width: 100%;
  height: 30em;
  background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
  background-size: cover;
`

const PerformanceCountSummary = styled.div`
  position: absolute;
  padding: 8.5em 2.5em 10em 2.5em;
  width: 100%;
`

const PerformanceCountTitle = styled.p`
  font-size: 1.5em;
  font-weight: 700;
  color: ${(props) => props.theme.global.colorPalette.black};
`

const PerformanceCountSummaryBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  height: 35em;
  margin-bottom: 6em;
`

export default PerformanceCount
