import { createSlice, current } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import { getCountByIdAsync } from '../../../services/countApi'
import {
  fetchCatchmentZoneClient,
  processCountAsync,
  recordCountForExpert360Async,
} from '../../../services/expert360Api'

import { ModesType } from '../../../types/Modes.type'
import { initialStateType } from '../../../types/expert360.type'
import { SelectElementType } from '../../../types/select.type'
import { formatCityTags, formatCityTagsWithRadius } from '../../../utils/format'
import { MAFProduct } from '../../../utils/myAdfactoryApi/swaggerApi'

export const initialState: initialStateType = {
  canSave: false,
  errors: null,
  data: {
    broadcastMediums: [
      {
        label: 'Se Loger',
        value: 'SELOGER',
        checked: true,
      },
      {
        label: 'Logic Immo',
        value: 'LOGICIMMO',
        checked: true,
      },
    ],
    projectTypes: [
      {
        label: 'Achat',
        value: 'ACHAT',
        checked: true,
      },
      {
        label: 'Location',
        value: 'LOCATION',
        checked: false,
      },
    ],
    periodicity: [
      {
        label: 'Récurrent',
        value: 'Récurrent',
        checked: true,
      },
    ],
  },
  initialIntensities: null,
  loading: 'idle',
  missingLocalities: null,
  res: null,
  radius: 0,
  listToDisplay: [],
}

const cleanResToDisplayInProgressComponent = (state: initialStateType) => {
  if (state.res) state.res = null
  if (state.missingLocalities) state.missingLocalities = null
}

const setConfigDataFromGetCount = (state: initialStateType) => {
  // Locations
  const locations = state?.res?.countData?.locality

  // Client id and clientName
  const clientId = state?.res?.countData?.client?.clientId
  const clientName = state?.res?.countData?.client?.clientName

  // BroadcastMediums
  const selectedBroadcastMediums =
    state?.res?.countData?.xp360Req?.broadcastMediums
  const broadcastMediums = state?.data?.broadcastMediums?.map(
    (b: SelectElementType) => {
      const updatedB = { ...b }
      updatedB.checked =
        selectedBroadcastMediums && selectedBroadcastMediums.includes(b.value)
      return updatedB
    }
  )

  // ProjectTypes
  const selectedProjectTypes = state?.res?.countData?.xp360Req?.projectTypes
  const projectTypes = state?.data?.projectTypes?.map(
    (p: SelectElementType) => {
      const updatedP = { ...p }
      updatedP.checked =
        selectedProjectTypes && selectedProjectTypes.includes(p.value)
      return updatedP
    }
  )

  state.data = {
    ...state.data,
    broadcastMediums,
    clientId,
    clientName,
    locations,
    projectTypes,
  }
}

const getDefaultSelectedIntensity = (state: initialStateType, action: any) => {
  if (
    !action?.meta?.arg?.previous &&
    action?.meta?.arg !== undefined &&
    action?.meta?.arg?.idMaf === undefined &&
    state?.res?.countData?.client?.intensity
  ) {
    if (state.res.countData.client.intensity?.[1]?.available)
      state.res.countData.intensity = 2
    else if (state.res.countData.client.intensity?.[0]?.available)
      state.res.countData.intensity = 1
    else if (state.res.countData.client.intensity?.[2]?.available)
      state.res.countData.intensity = 3
  }
}

const keepPreviousValuesFromGetCountById = (
  state: initialStateType,
  mode: ModesType,
  previous: any
) => {
  if (mode === ModesType.Edit && state.res && previous) {
    if (previous?.brandContentAdjustableOffer)
      state.res.countData.brandContentAdjustableOffer =
        previous.brandContentAdjustableOffer
    if (previous?.brandContentCommentary)
      state.res.countData.brandContentCommentary =
        previous.brandContentCommentary
    if (previous?.externalURL)
      state.res.countData.externalURL = previous.externalURL
    if (previous?.intensities && state.res.countData.client)
      state.res.countData.client.intensity = previous.intensities
    if (previous?.selectedIntensityId)
      state.res.countData.intensity = previous.selectedIntensityId
  }
}

const checkIfSelectedIntensityIsAlwayAvailable = (
  state: initialStateType,
  mode: ModesType | undefined
) => {
  if (mode === ModesType.Edit && state.res) {
    const intensities = state.res?.countData?.client?.intensity ?? []
    const selectedIntensityId = state.res?.countData?.intensity ?? 2
    const selectedIntensityIsStillAvailable =
      intensities?.[selectedIntensityId - 1]?.available ?? false
    if (!selectedIntensityIsStillAvailable) {
      const firstAvailable = intensities?.filter(
        (intensity) => intensity.available
      )
      state.res.countData.intensity = (firstAvailable?.[0]?.level ?? 0) + 1
    }
  }
}

const createFourthIntensityForAdjustableOffer = (
  state: initialStateType,
  action: any
) => {
  if (state?.res?.countData?.client?.intensity) {
    const customIntensityIsAvailable =
      state.res.countData.client.intensity.filter((i: any) => i.available)
        ?.length > 0
    state.res.countData.client.intensity.push({
      level: 3,
      available: customIntensityIsAvailable,
      prints: 0,
      impressions: action.payload.adjustableOffer.minAvailable,
      budget: action.payload.adjustableOffer.minPrice,
      shareOfVoice: action.payload.adjustableOffer.shareOfVoice,
    })
  }
}

const updateDataWithResponse = (state: initialStateType) => {
  // update data.locations with data from processCount result
  const data = state.data ? current(state.data) : {}
  if (state?.res?.countData?.locality)
    state.data = { ...data, locations: state.res.countData.locality }

  // update data.broadcastMediums
  if (state?.res?.countData?.xp360Req?.broadcastMediums) {
    data?.broadcastMediums?.map((b: any) => {
      const updatedB = { ...b }
      if (
        state?.res?.countData?.xp360Req?.broadcastMediums?.includes(b.value)
      ) {
        updatedB.checked = true
      } else {
        updatedB.checked = false
      }
      return updatedB
    })
  }

  // update data.projectTypes
  if (state?.res?.countData?.xp360Req?.projectTypes) {
    data?.projectTypes?.map((p: any) => {
      const updatedP = { ...p }
      if (state?.res?.countData?.xp360Req?.projectTypes?.includes(p.value)) {
        updatedP.checked = true
      } else {
        updatedP.checked = false
      }
      return updatedP
    })
  }
}

// Slice
const expert360Slice = createSlice({
  name: 'expert360',
  initialState,
  reducers: {
    cleanState() {
      return initialState
    },
    setCanSave(state, action) {
      state.canSave = action.payload
    },
    setData(state, action) {
      state.data = action.payload
      cleanResToDisplayInProgressComponent(state)
    },
    setRes(state, action) {
      state.res = action.payload
    },
    setRadius(state, action) {
      state.radius = action.payload.radius
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(getCountByIdAsync.pending, (state: initialStateType) => {
        state.loading = 'pending'
      })
      .addCase(
        getCountByIdAsync.fulfilled,
        (state: initialStateType, action: any) => {
          // getCountByIdAsync is used by all the products. We need to check to product name from BO
          if (action.payload?.mafProduct === MAFProduct.Expert360) {
            state.loading = 'idle'
            state.errors = null
            state.res = action.payload
            setConfigDataFromGetCount(state)
          }

          if (state.res) {
            state.radius = action.payload.countData.xp360Req?.radius
            const postalCode = action.payload.countData.xp360Req?.postalCodes[0]
            const localityByZipCode =
              action.payload.countData.localityNameByZipCode
            if (state.radius > 0) {
              state.listToDisplay = formatCityTagsWithRadius(
                postalCode,
                localityByZipCode[postalCode]
              )
            } else {
              state.listToDisplay = formatCityTags(localityByZipCode ?? {})
            }
          }
        }
      )
      .addCase(
        getCountByIdAsync.rejected,
        (state: initialStateType, action) => {
          state.loading = 'idle'
          state.res = null
          state.errors = action.error
        }
      )
      .addCase(processCountAsync.pending, (state: initialStateType) => {
        state.loading = 'pending'
        state.canSave = false
        state.errors = null
        state.res = {
          ...state.res,
          countData: {},
        }
      })
      .addCase(processCountAsync.fulfilled, (state, action: any) => {
        state.loading = 'idle'
        state.canSave = true

        if (Array.isArray(action.payload)) {
          state.missingLocalities = action.payload
        } else {
          state.missingLocalities = null

          const postalCode = action.meta.arg?.postalCodes[0]?.toString() ?? ''
          const localityByZipCode = action.payload?.localityNameByZipCode

          if (action.meta.arg?.radius && action.meta.arg?.radius > 0) {
            state.listToDisplay = formatCityTagsWithRadius(
              postalCode,
              localityByZipCode?.[postalCode]
            )
          } else {
            state.listToDisplay = formatCityTags(localityByZipCode ?? {})
          }

          // Copy of intensities sent by the back-office
          state.initialIntensities = action.payload.client.intensity

          // Set state.res
          const res = state.res ? current(state.res) : {}
          state.res = { ...res, countData: action.payload }

          createFourthIntensityForAdjustableOffer(state, action)
          getDefaultSelectedIntensity(state, action)
          updateDataWithResponse(state)

          // Edition only
          keepPreviousValuesFromGetCountById(
            state,
            action?.meta?.arg?.mode,
            action?.meta?.arg?.previous
          )
          checkIfSelectedIntensityIsAlwayAvailable(
            state,
            action?.meta?.arg?.mode
          )
        }
      })
      .addCase(processCountAsync.rejected, (state, action) => {
        state.loading = 'idle'
        state.res = null
        state.errors = action.error
      })
      .addCase(fetchCatchmentZoneClient.pending, (state) => {
        if (state?.data?.catchmentZone) state.data.catchmentZone = []
      })
      .addCase(fetchCatchmentZoneClient.fulfilled, (state, action: any) => {
        state.data.catchmentZone = action.payload
        localStorage.setItem('data360', JSON.stringify(current(state.data)))
      })
      .addCase(fetchCatchmentZoneClient.rejected, (state, action) => {
        if (state?.data?.catchmentZone) delete state.data.catchmentZone
        state.errors = action.error
        localStorage.removeItem('data360')
      })
      .addCase(recordCountForExpert360Async.fulfilled, (state) => {
        state.canSave = false
      })
  },
})

// Selectors
type Expert360SliceName = (typeof expert360Slice)['name']
export type Expert360StateType = Record<Expert360SliceName, initialStateType>

export const expert360CanSaveSelector = (state: Expert360StateType) =>
  state.expert360.canSave
export const expert360DataSelector = (state: Expert360StateType) =>
  state.expert360.data
export const expert360ResSelector = (state: Expert360StateType) =>
  state.expert360.res
export const expert360InitialIntensitiesSelector = (
  state: Expert360StateType
) => state.expert360.initialIntensities
export const expert360ErrorsSelector = (state: Expert360StateType) =>
  state.expert360.errors
export const expert360MissingLocalitiesSelector = (state: Expert360StateType) =>
  state.expert360.missingLocalities
export const expert360LoadingSelector = (state: Expert360StateType) =>
  state.expert360.loading
export const expert360RadiusSelector = (state: Expert360StateType) =>
  state.expert360.radius
export const expert360ListToDisplaySelector = (state: Expert360StateType) =>
  state.expert360.listToDisplay

// Actions
export const { cleanState, setCanSave, setData, setRes, setRadius } =
  expert360Slice.actions

// The reducer
export default expert360Slice.reducer
