import React from 'react'
import styled, { css } from 'styled-components'

type HamburgerIconProps = {
  dataTestid?: string | null,
  isOpen?: boolean,
  onClick?: Function,
}

type HamburgerIconStyledProps = {
  $isOpen: boolean,
}

const HamburgerIconStyled = styled.div<HamburgerIconStyledProps>`
  display: flex;
  flex-direction: column;
  width: 1.125em;
  user-select: none;
  cursor: pointer;

  span {
    margin-bottom: 0.2em;
    display: inline-block;
    width: 100%;
    background: ${(props) => props.theme.global.colorPalette.black};
    height: 0.1em;
    border-radius: 0.1em;
    transform-origin: 0.1em 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    &:first-child {
        transform-origin: 0% 0%;
    }

    &:last-child {
        margin: 0;
        transform-origin: 0% 100%;
    }

    ${(props: any) => props.$isOpen && css`
      transform: rotate(45deg) translate(-0em, -0.2em);

      &:nth-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      &:last-child {
        transform: rotate(-45deg) translate(0.1em, 0.1em);
      }
    `}
  }
`

const HamburgerIcon = ({ dataTestid = null, isOpen = false, onClick = (() => true) }: HamburgerIconProps): React.ReactElement => (
  <HamburgerIconStyled $isOpen={isOpen} data-testid={dataTestid || 'hamburgerIcon'} onClick={() => onClick()}>
    <span />
    <span />
    <span />
  </HamburgerIconStyled>
)

export default HamburgerIcon
