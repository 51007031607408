/**
 * This custom hook is used to get async data from BO
 * getSelectableValuesAsync() and/or getCountByIdAsync()
 */
import { useDispatch, useSelector } from 'react-redux'

import { useMode } from '../../useMode'
import { useEmailingBase } from '../../useEmailingBase'
import { useEffectOnce } from '../../useEffectOnce'

import { cleanState, setBase } from '../../../redux/reducers/Emailing/emailing.reducer'
import { currentMarketSelector } from '../../../redux/reducers/UserConfig/userConfig.reducer'

import { getSelectableValuesAsync } from '../../../services/emailingApi'
import { getCountByIdAsync } from '../../../services/countApi'

import { ModesType } from '../../../types/Modes.type'

export const useProductEmailingData = (id: string | undefined) => {
  const { mode } = useMode()
  const { emailingBase } = useEmailingBase()
  const dispatch: any = useDispatch()

  const currentMarket: string = useSelector(currentMarketSelector)

  // Async data
  useEffectOnce(() => {
    const onMount = () => {
      dispatch(setBase(emailingBase))
      if (id && mode !== ModesType.New) {
        dispatch(getSelectableValuesAsync(currentMarket)).then(() => {
          dispatch(getCountByIdAsync(id))
        })
      } else {
        dispatch(getSelectableValuesAsync(currentMarket))
      }
    }
    onMount()

    return () => {
      const onUnmount = () => {
        dispatch(cleanState())
      }
      onUnmount()
    }
  })
}
