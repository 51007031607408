import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { expert360LoadingSelector, expert360ResSelector, setRes } from '../../../../redux/reducers/Expert360/expert360.reducer'

import Expert360IntensityCardLoading from '../Expert360IntensityCardLoading/Expert360IntensityCardLoading'
import Expert360IntensityCard from '../Expert360IntensityCard/Expert360IntensityCard'
import Expert360IntensityCardUnavailable from '../Expert360IntensityCardUnavailable/Expert360IntensityCardUnavailable'

import { ClientIntensity } from '../../../../utils/myAdfactoryApi/swaggerApi'

const intensityInfo = [
  { id: 0, colorIntensity: '#09A3E9', notAllowBrandContent: false },
  { id: 1, colorIntensity: '#6F2ACF', notAllowBrandContent: false },
  { id: 2, colorIntensity: '#E00034', notAllowBrandContent: false }
]

const Expert360DefaultIntensities = (): React.ReactElement | null => {
  const dispatch = useDispatch()

  // Selectors
  const res = useSelector(expert360ResSelector)
  const loading: string = useSelector(expert360LoadingSelector)

  const intensityOffers = res?.countData?.client?.intensity ?? []
  const intensityIdSelectedId = res?.countData?.intensity ?? 2
  const insufficientShareOfVoice = res?.countData?.insufficientShareOfVoice

  const insufficientIntensity: boolean = intensityOffers?.length !== 0
    ? !intensityOffers?.[0]?.available
      || !intensityOffers?.[1]?.available
      || !intensityOffers?.[2]?.available
    : false

  const handleClick = (id: number) => {
    if (intensityOffers[id].available) {
      dispatch(setRes({
        ...res,
        countData: {
          ...res.countData,
          intensity: id + 1,
        },
      }))
    }
  }

  return (
    <>
      <Expert360DefaultIntensitiesWrapper>
        {loading === 'pending'
          ? intensityInfo.map((intensity) => (
            <Expert360IntensityCardLoading key={intensity.id} />
          ))
          : intensityOffers.map((intensity: ClientIntensity) => {
            if (intensity.level > 2) return null

            return (
              <Expert360IntensityCard
                key={`intensity-${intensity.level}`}
                id={intensity.level}
                impressions={intensity.impressions}
                budget={intensity.budget}
                handleClick={handleClick}
                isChecked={intensity.level === intensityIdSelectedId - 1}
                available={intensity.available}
                colorIntensity={intensityInfo[intensity.level].colorIntensity}
                notAllowBrandContent={intensityInfo[intensity.level].notAllowBrandContent}
                shareOfVoice={intensity.shareOfVoice}
              />
            )
          })}
      </Expert360DefaultIntensitiesWrapper>
      {loading !== 'pending' && <Expert360IntensityCardUnavailable insufficientIntensity={insufficientIntensity} insufficientLocalities={insufficientShareOfVoice} />}
    </>
  )
}

const Expert360DefaultIntensitiesWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export default Expert360DefaultIntensities
