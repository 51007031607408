import React from 'react'

type ModalRowWrapperProps = {
  left: number,
  top: number,
  children: React.ReactNode,
}

const ModalRowWrapper = ({ left, top, children }: ModalRowWrapperProps): React.ReactElement => (
  <div data-testid="ModalRowWrapper" className="modal" style={{ left: `${(left - 178 - 12) / 16}em`, top: `${(top - 57) / 16}em` }}>
    {children}
  </div>
)

export default ModalRowWrapper
