import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import ForecastBar from '../../../Bars/ForecastBar/ForecastBar'
import PrintsByLocalitiesTable from '../../../Tables/PrintsByLocalitiesTable/PrintsByLocalitiesTable'

import type { ClientIntensity } from '../../../../utils/myAdfactoryApi/swaggerApi'
import { expert360ResSelector } from '../../../../redux/reducers/Expert360/expert360.reducer'

type Expert360DetailsPopinProps = {
  selectedIntensity: ClientIntensity,
}

export const formatForecastResultSplitByPostalCodes = (forescastResultSplitByPostalCodes: any, selectedIntensityLevel: number) => (
  forescastResultSplitByPostalCodes?.map((item: any) => {
    const itemUpdated = { ...item }
    itemUpdated.offer = item[`impressionProposedForIntensity${selectedIntensityLevel}`]
    return itemUpdated
  })
)

const Expert360DetailsPopin = ({ selectedIntensity }: Expert360DetailsPopinProps): React.ReactElement => {
  // Selectors
  const res = useSelector(expert360ResSelector)

  // Variables
  const available = res?.countData?.totalAvailableForecast ?? 0
  const sold = res?.countData?.totalSoldForecast ?? 0
  const total = res?.countData?.totalForecast ?? 0
  const selectedIntensityLevel = selectedIntensity?.level ? selectedIntensity.level + 1 : 1
  const selectedIntensityImpressions = selectedIntensity?.impressions ?? 0
  const selectedIntensityBudget = selectedIntensity?.budget ?? 0
  const selectedIntensityShareOfVoice = selectedIntensity?.shareOfVoice ?? 0
  const forescastResultSplitByPostalCodes = res.countData.forescastResultSplitByPostalCodes ? Object.values(res.countData.forescastResultSplitByPostalCodes) : []

  const forescastResultSplitByPostalCodesFormatted = formatForecastResultSplitByPostalCodes(forescastResultSplitByPostalCodes, selectedIntensityLevel)

  return (
    <div data-testid="PrintDetailsXp360">
      <H1Styled>Exemple de répartition du volume d'impression</H1Styled>
      <ParagraphStyled>
        <p>La part de présence reste approximative, elle prend en compte les impressions disponibles et déjà vendues par mois en temps réel.</p>
        <p>Aucune modification dans la répartition par localité ne peut être apportée.</p>
      </ParagraphStyled>
      <PrintBarStyled>
        <ForecastBar
          available={available}
          loading={false}
          maxWidth={450}
          offer={selectedIntensityImpressions}
          sold={sold}
          total={total}
        />
      </PrintBarStyled>
      <H2Styled>Détails de la proposition</H2Styled>
      <PrintsByLocalitiesTable
        budget={selectedIntensityBudget}
        forescastResultSplitByPostalCodes={forescastResultSplitByPostalCodesFormatted}
        hideBudget
        shareOfVoice={selectedIntensityShareOfVoice}
        totalOffer={selectedIntensityImpressions}
      />
    </div>
  )
}

const H1Styled = styled.h1`
  font-size: 1.8em;
  font-weight: 700;
  margin-bottom: .5em;
`

const H2Styled = styled.h2`
  font-size: 1.3em;
  font-weight: 700;
  margin-top: 2em;
  margin-bottom: 1em;
`

const ParagraphStyled = styled.div`
  p {
    font-size: 1.2em;
  }
`

const PrintBarStyled = styled.div`
margin-top: 2em;
`

export default Expert360DetailsPopin
