import React, { useState } from 'react'
import { formatNumbers } from '../utils/tsUtils'

export const useInput = (initialValue: number | undefined) => {
  const [value, setValue] = useState<number>(initialValue ?? 0)

  return {
    value,
    setValue,
    reset: () => setValue(0),
    bindInput: {
      value: formatNumbers(value),
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!Number.isNaN(event.target.value.replace(/\D+/g, ''))) {
          event.target.value.replace(/\D+/g, '') === ''
            ? setValue(0)
            : setValue(parseInt(event.target.value.replace(/\D+/g, '')))
        }
      },
    },
  }
}
