import { createSlice, current } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'

import { getCountByIdAsync } from '../../../services/countApi'
import {
  getSelectableValuesAsync,
  processCountForDisplayAsync,
  recordCountForDisplayAsync,
} from '../../../services/displayApi'

import {
  formatCityTags,
  formatCityTagsWithRadius,
  setInputTargetingsValue,
} from '../../../utils/format'
import {
  DISPLAY_PRODUCTS,
  DISPLAY_PRODUCTS_FORMATS,
} from '../../../utils/variables'

import {
  DisplayMarketsType,
  initialStateType,
} from '../../../types/display.type'
import {
  ForecastResult,
  MAFProduct,
  Market,
} from '../../../utils/myAdfactoryApi/swaggerApi'

export const initialState: initialStateType = {
  canSave: false,
  countNameSaved: null,
  errors: null,
  data: null,
  loading: 'idle',
  res: null,
  radius: 0,
  listToDisplay: [],
}

const initDefaultConfigForMarketNeuf = (state: initialStateType) => {
  // SellersNew Broadcast Mediums are checked by default
  if (state?.data?.targetings) {
    state.data.targetings.Sellers = {
      ...state.data.targetings.Sellers,
      checked: [false, false],
    }
    state.data.targetings.SellersNew = {
      ...state.data.targetings.SellersNew,
      checked: [true, true],
    }
    state.data.inputTargetingsValue = setInputTargetingsValue(
      state.data.targetings
    )
  }

  // By default, periodicity is OneShot in Config
  if (state.data) {
    state.data.periodicity = 'OneShot'
  }
}

const initDefaultConfigForMarketConstruire = (state: initialStateType) => {
  // Everything is checked by default
  if (state?.data?.targetings) {
    state.data.targetings.Sellers = {
      ...state.data.targetings.Sellers,
      checked: [true, true],
    }
    state.data.targetings.ConstructionProjects = {
      ...state.data.targetings.ConstructionProjects,
      checked: [true],
    }
    state.data.inputTargetingsValue = setInputTargetingsValue(
      state.data.targetings
    )
  }

  // ConstructionProjects is modified: label is customized
  if (state?.data?.targetings?.ConstructionProjects) {
    state.data.targetings.ConstructionProjects = {
      ...state.data.targetings.ConstructionProjects,
      label:
        'Modèles de maisons, terrains avec maisons, terrains et projet de construction',
    }
  }
  // HouseModels, LandWithHouses and Lands are hidden
  if (state?.data?.targetings?.HouseModels)
    delete state.data.targetings.HouseModels
  if (state?.data?.targetings?.LandWithHouses)
    delete state.data.targetings.LandWithHouses
  if (state?.data?.targetings?.Lands) delete state.data.targetings.Lands

  // By default, periodicity is OneShot in Config
  if (state.data) {
    state.data.periodicity = 'OneShot'
  }
}

const initDefaultConfigForMarketBuCom = (state: initialStateType) => {
  if (state.data) {
    // By default, periodicity is Recurrent in Config
    state.data.periodicity = 'Récurrent'

    // Back-Office doesn't send targetings (???). FrontEnd need to create a static data.targetings
    delete state.data.targetings
    state.data.targetings = {
      Custom: {
        label: 'Custom',
        order: 1,
        broadcastMediums: ['SELOGER', 'SELOGERBUREAUXETCOMMERCE'],
        checked: [true, true],
      },
    }
  }
}

const initDefaultConfigForMarketLuxe = (state: initialStateType) => {
  if (state.data) {
    // By default, periodicity is Recurrent in Config
    state.data.periodicity = 'Récurrent'

    // Back-Office doesn't send right targetings (???). FrontEnd need to create a static data.targetings
    delete state.data.targetings
    state.data.targetings = {
      PrestigeSellers: {
        label: 'PrestigeSellers',
        order: 1,
        broadcastMediums: ['BELLESDEMEURES', 'LUXRESIDENCE'],
        checked: [true, true],
      },
    }
  }
}

const setConfigDataFromGetCount = (state: initialStateType) => {
  const data = state.data && current(state.data)
  // Targetings
  const targetingsFromDataArray =
    data?.targetings && Object.entries(data.targetings)
  const targetingsFromDisplayReqArray =
    state.res?.countData?.displayReq?.targetings

  const updatedTargetings = targetingsFromDataArray?.map((targeting: any) => {
    const updated = [...targeting]
    const filtered = targetingsFromDisplayReqArray?.filter(
      (t) => t.distributionType === updated[0]
    )
    const filteredBroadcasts = filtered?.[0]?.broadcastMediums
    // Reset default checked statement before setting with values from BO
    const checkedArray = [...updated[1].checked].map(() => false)

    filteredBroadcasts?.forEach((fb: string) => {
      if (updated[1].broadcastMediums.includes(fb)) {
        const i = updated[1].broadcastMediums.indexOf(fb)
        checkedArray[i] = true
      }
    })

    updated[1] = { ...updated[1], checked: checkedArray }
    return updated
  })

  // Locations
  const locations = state?.res?.countData.locality
  const isAllFrance = state?.res?.countData?.displayReq?.isAllFrance ?? false // for old counts without isAllFrance in displayReq

  // Periodicity
  let periodicity =
    state?.res?.countData?.displayReq?.periodicity ?? state?.data?.periodicity
  // Retrocomptabilité - Force periodicity to 'OneShot' for masterDisplayNeuf
  if (state?.res?.countData?.displayReq?.market === 'Neuf') {
    periodicity = 'OneShot'
  }

  // Dates
  const dates = []
  if (state?.res?.countData.displayReq?.startDate)
    dates.push(state.res.countData.displayReq.startDate)
  if (state?.res?.countData.displayReq?.endDate)
    dates.push(state.res.countData.displayReq.endDate)
  if (periodicity === 'Récurrent') {
    delete state?.data?.dates
  }

  state.data = {
    ...state.data,
    targetings: updatedTargetings && Object.fromEntries(updatedTargetings),
    locations,
    isAllFrance,
    dates,
    periodicity,
  }
  if (state.data) {
    state.data.inputTargetingsValue = setInputTargetingsValue(
      state.data.targetings
    )
  }
  defineLogosToDisplayInSummaryCard(state)
}

export const setMafProductValue = (
  currentMarket: DisplayMarketsType
): MAFProduct | undefined => {
  switch (currentMarket) {
    case DisplayMarketsType.Neuf:
      return MAFProduct.MasterDisplayNeuf
    case DisplayMarketsType.Luxe:
      return MAFProduct.DisplayLuxury
    case DisplayMarketsType.Construire:
      return MAFProduct.DisplayConstruire
    case DisplayMarketsType.BureauxEtCommerce:
      return MAFProduct.DisplayBureauxCommerce
    default:
      return undefined
  }
}

const defineDefaultForecastsForPendingProcessCount = (
  market: DisplayMarketsType,
  state: initialStateType
) => {
  const defaultForecasts = state.data?.logos?.map((row: string) => {
    return {
      code: row,
      name: row,
      available: 0,
      budget: undefined, // important!
      purchased: 0,
      sold: 0,
      total: 0,
    }
  })
  const defaultForecastSplitByPosition = Object.keys(
    DISPLAY_PRODUCTS_FORMATS[market]
  )?.map((row: string) => {
    return {
      code: row,
      name: row,
      available: 0,
      budget: undefined, // important!
      purchased: 0,
      sold: 0,
      total: 0,
    }
  })
  state.res = {
    ...state.res,
    countData: {
      forecasts: defaultForecasts,
      forecastSplitByPosition: defaultForecastSplitByPosition,
      mafProduct: setMafProductValue(market),
      totalImpPurchased: 0,
      totalBudget: 0,
    },
  }
}

const setForecastsPurchasedAndBudgetValues = (
  state: initialStateType,
  market: Market | undefined
) => {
  if (state.res && market !== Market.Luxe) {
    const { forecastSplitByPosition, forecasts, cpm } = state.res.countData
    const updatedForecastSplitByPosition = forecastSplitByPosition?.map(
      (fsp: ForecastResult) => {
        const updatedFsp = { ...fsp }
        const cpmFsp = cpm && updatedFsp?.code ? cpm[updatedFsp.code] : 0
        updatedFsp.purchased = updatedFsp?.available ?? 0
        updatedFsp.budget = updatedFsp.available
          ? Math.round(((cpmFsp ?? 0) * updatedFsp.available) / 1000)
          : 0
        return updatedFsp
      }
    )
    const updatedForecasts = forecasts?.map((f: ForecastResult) => {
      const updatedF = { ...f }
      const cpmF = cpm && updatedF?.code ? cpm[updatedF.code] : 0
      updatedF.purchased = updatedF?.available ?? 0
      updatedF.budget = updatedF.available
        ? Math.round(((cpmF ?? 0) * updatedF.available) / 1000)
        : 0
      return updatedF
    })
    state.res.countData = {
      ...state.res.countData,
      forecasts: updatedForecasts,
      forecastSplitByPosition: updatedForecastSplitByPosition,
    }
  }
}

const cleanResToDisplayInProgressComponent = (state: initialStateType) => {
  if (state.res) {
    state.res.countData = {}
  }
}

const defineLogosToDisplayInSummaryCard = (state: initialStateType) => {
  if (state.data) {
    const logosArray =
      state.data.targetings &&
      Object.values(state.data.targetings)?.map((targeting: any) =>
        targeting.checked?.map((c: boolean, index: number) => {
          if (c) return targeting.broadcastMediums[index]
          return null
        })
      )
    state.data.logos = logosArray?.flat()?.filter((l: string) => l)
  }
}

// Slice
const displaySlice = createSlice({
  name: 'display',
  initialState,
  reducers: {
    cleanState() {
      return initialState
    },
    setCanSave(state, action) {
      state.canSave = action.payload
    },
    setCurrentTab(state, action) {
      if (state?.res?.countData) {
        state.res.countData.saveBy = action.payload
      }
      state.canSave = true
    },
    setData(state, action) {
      state.data = action.payload
      if (state.data) {
        state.data.inputTargetingsValue = setInputTargetingsValue(
          state.data.targetings
        )
      }
      cleanResToDisplayInProgressComponent(state)
      defineLogosToDisplayInSummaryCard(state)
    },
    setRes(state, action) {
      state.res = action.payload
    },
    setRadius(state, action) {
      state.radius = action.payload.radius
    },
    resetListToDisplay(state) {
      state.listToDisplay = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(getCountByIdAsync.pending, (state: initialStateType) => {
        state.loading = 'pending'
      })
      .addCase(
        getCountByIdAsync.fulfilled,
        (state: initialStateType, action: any) => {
          // getCountByIdAsync is used by all the products. We need to check to product name from BO
          if (DISPLAY_PRODUCTS.includes(action.payload?.mafProduct)) {
            state.loading = 'idle'
            state.res = action.payload
            state.countNameSaved = action.payload.countData.countName ?? null
            state.errors = null
            setConfigDataFromGetCount(state)
          }

          if (state.res) {
            state.radius = action.payload.countData.displayReq?.radius
            const postalCode =
              action.payload.countData.displayReq?.postalCodes[0]
            const localityByZipCode =
              action.payload.countData.localityNameByZipCode
            if (state.radius > 0) {
              state.listToDisplay = formatCityTagsWithRadius(
                postalCode,
                localityByZipCode[postalCode]
              )
            } else {
              state.listToDisplay = formatCityTags(localityByZipCode ?? {})
            }
          }
        }
      )
      .addCase(
        getCountByIdAsync.rejected,
        (state: initialStateType, action) => {
          state.loading = 'idle'
          state.res = null
          state.countNameSaved = null
          state.errors = action.error
        }
      )
      .addCase(getSelectableValuesAsync.pending, (state: initialStateType) => {
        state.loading = 'pending'
        state.data = null
      })
      .addCase(
        getSelectableValuesAsync.fulfilled,
        (state: initialStateType, action) => {
          const payload = action?.payload
          state.loading = 'idle'
          state.data = {}
          state.data.targetings = {}
          state.data.targetings = { ...payload?.targetings }
          state.data.isAllFrance = false

          if (action?.meta?.arg === DisplayMarketsType.Neuf)
            initDefaultConfigForMarketNeuf(state)
          if (action?.meta?.arg === DisplayMarketsType.Construire)
            initDefaultConfigForMarketConstruire(state)
          if (action?.meta?.arg === DisplayMarketsType.BureauxEtCommerce)
            initDefaultConfigForMarketBuCom(state)
          if (action?.meta?.arg === DisplayMarketsType.Luxe)
            initDefaultConfigForMarketLuxe(state)
        }
      )
      .addCase(
        getSelectableValuesAsync.rejected,
        (state: initialStateType, action) => {
          state.loading = 'idle'
          state.data = null
          state.errors = action.error
        }
      )
      .addCase(
        processCountForDisplayAsync.pending,
        (state: initialStateType, action: any) => {
          const { market, isAllFrance } = action.meta.arg
          state.loading = 'pending'
          state.canSave = false
          state.errors = null
          if (market) {
            defineDefaultForecastsForPendingProcessCount(market, state)
          }
          if (isAllFrance) {
            const data = state.data ? current(state.data) : {}
            state.data = {
              ...data,
              locations: [{ id: '', code: '', name: 'Toute la France' }],
            }
          }
        }
      )
      .addCase(processCountForDisplayAsync.fulfilled, (state, action) => {
        const { market } = action.meta.arg
        state.loading = 'idle'
        state.canSave = true
        const res = state.res ? current(state.res) : {}
        state.res = { ...res, countData: action.payload }

        // By default, saveBy is set to format
        if (state.res) {
          state.res.countData.saveBy = 'format'
        }

        // update data.locations with data from processCount result
        const data = state.data ? current(state.data) : {}
        if (state?.res?.countData?.locality)
          state.data = { ...data, locations: state.res.countData.locality }

        const postalCode = action.meta.arg?.postalCodes?.toString() ?? ''
        const localityByZipCode = action.payload?.localityNameByZipCode

        if (action.meta.arg.radius && action.meta.arg.radius > 0) {
          state.listToDisplay = formatCityTagsWithRadius(
            postalCode,
            localityByZipCode?.[postalCode]
          )
        } else {
          state.listToDisplay = formatCityTags(localityByZipCode ?? {})
        }

        setForecastsPurchasedAndBudgetValues(state, market)
      })
      .addCase(processCountForDisplayAsync.rejected, (state, action) => {
        state.loading = 'idle'
        state.res = null
        state.errors = action.error
      })
      .addCase(recordCountForDisplayAsync.fulfilled, (state) => {
        state.canSave = false
      })
  },
})

// Selectors
type DisplaySliceName = (typeof displaySlice)['name']
export type DisplayStateType = Record<DisplaySliceName, any>

export const displayDataSelector = (state: DisplayStateType) =>
  state.display.data
export const displayResSelector = (state: DisplayStateType) => state.display.res
export const displayErrorsSelector = (state: DisplayStateType) =>
  state.display.errors
export const displayLoadingSelector = (state: DisplayStateType) =>
  state.display.loading
export const displayCanSaveSelector = (state: DisplayStateType) =>
  state.display.canSave
export const displayCountNameSavedSelector = (state: DisplayStateType) =>
  state.display.countNameSaved
export const currentTabSelector = (state: DisplayStateType) =>
  state.display.res?.countData?.saveBy ?? 'format'
export const displayRadiusSelector = (state: DisplayStateType) =>
  state.display.radius
export const displayListToDisplaySelector = (state: DisplayStateType) =>
  state.display.listToDisplay

// Actions
export const {
  cleanState,
  setCanSave,
  setData,
  setCurrentTab,
  setRes,
  setRadius,
  resetListToDisplay,
} = displaySlice.actions

// The reducer
export default displaySlice.reducer
