import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import Expert360Header from '../../../components/Products/Expert360/Expert360Header/Expert360Header'
import Expert360Count from '../../../components/Products/Expert360/Expert360Count/Expert360Count'

import { expert360ErrorsSelector } from '../../../redux/reducers/Expert360/expert360.reducer'

import { useProductExpert360Data } from '../../../hooks/products/expert360/useProductExpert360Data'

type Expert360WrapperProps = {
  children: React.ReactNode,
  dataTestId: string,
}

const Expert360Wrapper = ({ children, dataTestId }: Expert360WrapperProps): React.ReactElement => (
  <Expert360WrapperStyled data-testid={dataTestId}>
    <Expert360Header />
    {children}
  </Expert360WrapperStyled>
)

const Expert360ErrorComp = (): React.ReactElement => (
  <Expert360Wrapper dataTestId="expert360Error">
    <Expert360Error>
      <p>Une erreur est survenue</p>
    </Expert360Error>
  </Expert360Wrapper>
)

const Expert360 = (): React.ReactElement => {
  const { id }: { id: string } = useParams()
  const expert360Errors: any = useSelector(expert360ErrorsSelector)

  useProductExpert360Data(id)

  if (expert360Errors) {
    return <Expert360ErrorComp />
  }

  return (
    <Expert360Wrapper dataTestId="expert360">
      <Expert360Count />
    </Expert360Wrapper>
  )
}

// Styled component
const Expert360WrapperStyled = styled.div`
  padding-top: 14em;
  background-color: ${(props) => props.theme.global.colorPalette.white};
`

const Expert360Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

export default Expert360
