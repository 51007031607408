import React from 'react'
import { useSelector } from 'react-redux'

import { CalendarLined, UserRegular } from '@ui-library/icons'

import ResumeCard from '../../../ResumeCard/ResumeCard'

import { emailingDataSelector, emailingLoadingSelector, emailingResSelector } from '../../../../redux/reducers/Emailing/emailing.reducer'

import { useEmailingBase } from '../../../../hooks/useEmailingBase'

import { formatProductNames, formatZipCodeWithManyLocalityNames } from '../../../../utils/format'
import { formatDate } from '../../../../utils/date'
import { formatNumbers } from '../../../../utils/tsUtils'

import { productNamesType } from '../../../../types/productNames.types'
import { BudgetInformationType, MoreCriteriaInformationType, TableInformationType } from '../../../../types/ResumeCard.type'
import { BaseComptage, ForecastResult } from '../../../../utils/myAdfactoryApi/swaggerApi'

type EmailingSummaryCountProps = {
  isProposal?: boolean,
}

const EmailingSummaryCount = ({ isProposal = false }: EmailingSummaryCountProps): React.ReactElement => {
  const data = useSelector(emailingDataSelector)
  const res = useSelector(emailingResSelector)
  const loading = useSelector(emailingLoadingSelector)

  const { emailingBase } = useEmailingBase()

  const logos = data?.logos ?? []

  let productName = emailingBase === BaseComptage.Alerte ? productNamesType.EmailingAlerte : productNamesType.EmailingOptin
  if (res?.countData?.mafProduct) {
    productName = formatProductNames(res?.countData.mafProduct as keyof typeof productNamesType)
  }

  const locations: ForecastResult[] | undefined = formatZipCodeWithManyLocalityNames(data?.locations ?? [], res?.countData?.localityNameByZipCode ?? {})

  const periodicity: string = data.date ? formatDate(new Date(data.date)) : ''

  const projectType: string = data?.targetings && Object.values(data.targetings).map((t: any) => {
    if (t?.selected && t?.checked?.includes(true)) return t.label
    return null
  }).filter((t) => t).join(', ')

  const budgetInformation: BudgetInformationType = {
    totalBudget: formatNumbers(res?.countData?.totalBudget ?? 0),
    complementary: `${formatNumbers(res?.countData?.totalEmails ?? 0)} contacts`,
  }

  const tableInformation: TableInformationType = [{
    title: 'Ciblage(s)',
    icon: UserRegular,
    description: projectType,
  },
  {
    title: 'Date d\'envoi',
    icon: CalendarLined,
    description: periodicity,
  }]

  const moreCriteriaInformation: MoreCriteriaInformationType = {
    productTypes: data.options.productTypes ? data.options.productTypes?.join(', ') : null,
    numberOfPieces: (data.options.minNbPieces !== 1 || data.options.maxNbPieces !== 5) ? `${data.options.minNbPieces} à ${data.options.maxNbPieces} pièces` : null,
    price: (data.options.minPrice !== 1 || data.options.maxPrice !== 10000000) ? `Entre ${data.options?.minPrice?.toLocaleString('fr')} € et ${data.options?.maxPrice?.toLocaleString('fr')} € ` : null,
  }

  return (
    <ResumeCard
      testId="EmailingNeufContentSummary"
      loading={loading}
      broadcastMediums={logos}
      budgetInformation={budgetInformation}
      productName={productName}
      tableInformation={tableInformation}
      locations={locations}
      isProposal={isProposal}
      moreCriteriaInformation={moreCriteriaInformation}
    />
  )
}
export default EmailingSummaryCount
