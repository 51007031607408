import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import mapBackground from '../../../../assets/images/map_background.jpg'

import CountInProgress from '../../../Counts/CountInProgress/CountInProgress'
import EmailingSummaryCount from '../EmailingSummaryCount/EmailingSummaryCount'
import ContactsByBroadcastTable from '../../../Tables/ContactsByBroadcastTable/ContactsByBroadcastTable'
import CountsFooter from '../../../Counts/CountsFooter/CountsFooter'

import {
  emailingCanSaveSelector,
  emailingCountNameSavedSelector,
  emailingDataSelector,
  emailingLoadingSelector,
  emailingResSelector,
} from '../../../../redux/reducers/Emailing/emailing.reducer'

const EmailingCount = (): React.ReactElement => {
  const data = useSelector(emailingDataSelector)
  const res = useSelector(emailingResSelector)
  const loading: string = useSelector(emailingLoadingSelector)
  const canSave: boolean = useSelector(emailingCanSaveSelector)
  const countNameSaved: string | null = useSelector(emailingCountNameSavedSelector)

  if (!res || (res?.countData && Object.keys(res.countData).length === 0)) {
    return <CountInProgress dataTestId="emailingCount" />
  }

  return (
    <EmailingCountWrapper data-testid="emailingCount">
      <EmailingCountMap style={{ backgroundImage: `url(${mapBackground})` }} />
      <EmailingCountSummary>
        <EmailingCountTitle>Résumé du comptage</EmailingCountTitle>
        <EmailingCountSummaryBlock>
          <EmailingSummaryCount />
        </EmailingCountSummaryBlock>
        <ContactsByBroadcastTable countData={res?.countData ?? {}} loading={loading} logos={data?.logos} />
        <CountsFooter
          canSave={canSave}
          countNameSaved={countNameSaved}
          loading={loading}
          res={res}
        />
      </EmailingCountSummary>
    </EmailingCountWrapper>
  )
}

const EmailingCountWrapper = styled.div`
  position: relative;
`

const EmailingCountMap = styled.div`
  position: absolute;
  left: 0;
  top: 13em;
  width: 100%;
  height: 30em;
  background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
  background-size: cover;
`

const EmailingCountSummary = styled.div`
  position: absolute;
  padding: 8.5em 2.5em 10em 2.5em;
  width: 100%;
`

const EmailingCountTitle = styled.p`
  font-size: 1.5em;
  font-weight: 700;
  color: ${(props) => props.theme.global.colorPalette.black};
`

const EmailingCountSummaryBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  height: 35em;
  margin-bottom: 6em;
`

export default EmailingCount
