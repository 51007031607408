import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetAllUsers, UserInformation } from '../types/users'
import { getMyAdFactoryClass } from '../utils/myAdfactoryApi/getMyAdFactory.utils'

export const getUserMe = createAsyncThunk('Users/me', async (_, getState) => {
  const response = await getMyAdFactoryClass(getState).me()
  return response
})

export const getAllUsers = createAsyncThunk(
  'user/getAllUsers',
  async (
    {
      page,
      itemsPerPage,
      order = 'lastName',
      orderDirection = 'asc',
      searchUser,
    }: GetAllUsers,
    getState
  ) => {
    const response = await getMyAdFactoryClass(getState).getAllUsers(
      page,
      itemsPerPage,
      order,
      orderDirection,
      searchUser
    )
    return response
  }
)

export const createOrUpdateUser = createAsyncThunk(
  'Users/CreateOrUpdateUser',
  async (
    { userInformation }: { userInformation: UserInformation },
    getState
  ) => {
    const response =
      await getMyAdFactoryClass(getState).createOrUpdateUser(userInformation)
    return response
  }
)
