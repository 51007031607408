import { expert360ResSelector, Expert360StateType } from '../reducers/Expert360/expert360.reducer'
import { masterDisplayResSelector, MasterDisplayStateType } from '../reducers/MasterDisplay/masterDisplay.reducer'
import { emailingResSelector, EmailingStateType } from '../reducers/Emailing/emailing.reducer'
import { displayResSelector, DisplayStateType } from '../reducers/Display/display.reducer'
import { performanceResSelector, PerformanceStateType } from '../reducers/Performance/performance.reducer'
import { vedettePlusResSelector, VedettePlusStateType } from '../reducers/VedettePlus/vedettePlus.reducer'

import {
  setNbEmailsInvalid,
  hideNotification,
  setBudgetLow,
  setBudgetInvalid,
  setAdjustableOfferInvalid,
} from '../reducers/Notifications/notifications.reducer'

import { recordCountAsync } from '../../services/emailingApi'
import { recordCountForExpert360Async } from '../../services/expert360Api'
import { recordCountForMasterDisplayAsync } from '../../services/masterDisplayApi'
import { recordCountForDisplayAsync } from '../../services/displayApi'
import { recordCountForVedettePlusAsync } from '../../services/vedettePlusApi'
import { recordCountForPerformanceAsync } from '../../services/performanceApi'

import { EXPERT_360_MINIMUM_BUDGET_ADJUSTABLE_OFFER } from '../../utils/variables'

import { EmailingResp, ForecastResult, DisplayResp, VedettePlusResp, PerformanceDisplayResp, ClientIntensity, Xp360Resp, MasterDisplayResp } from '../../utils/myAdfactoryApi/swaggerApi'
import { ResTypes } from '../../types/expert360.type'
import { ModesType } from '../../types/Modes.type'

const defineIfCustomIntensityValuesAreOkForXp360 = (res: ResTypes | null): boolean => {
  if (!res) return true

  const intensities = res?.countData?.client?.intensity ?? []
  const customIntensity = intensities.filter((i: ClientIntensity) => i.level === 3)
  const intensityId = res?.countData?.intensity ?? 2
  const isCustomIntensity = intensityId === 3
  const adjustableOffer = res?.countData?.adjustableOffer

  const minBudgetValue = intensities?.[0]?.available ? intensities?.[0]?.budget : EXPERT_360_MINIMUM_BUDGET_ADJUSTABLE_OFFER

  const hasError = customIntensity && customIntensity?.length > 0 && isCustomIntensity && adjustableOffer?.maxPrice && adjustableOffer.maxAvailable
    && (customIntensity[0].budget < minBudgetValue
      || customIntensity[0].budget > adjustableOffer.maxPrice
      || customIntensity[0].impressions > adjustableOffer.maxAvailable)

  return Boolean(hasError)
}

export const saveCountExpert360 = (mode: ModesType = ModesType.New) => (dispatch:any, getState: () => Expert360StateType) => {
  const res = expert360ResSelector(getState()) as any
  const countData: Xp360Resp | undefined = res?.countData
  const mafId = res?.mafId ?? null
  const intensities = countData?.client?.intensity ?? []
  const intensityId = countData?.intensity ?? 2
  const isCustomIntensity = intensityId === 3
  const hasError = defineIfCustomIntensityValuesAreOkForXp360(res)
  let showAlert = false

  if (isCustomIntensity && hasError) {
    showAlert = true
  }

  let response = null

  if (showAlert) {
    dispatch(setAdjustableOfferInvalid())
    setTimeout(() => {
      dispatch(hideNotification())
    }, 5000)
  } else {
    const updatedCountData = {
      ...countData,
      displayBudget: intensityId >= 0 ? intensities[intensityId - 1]?.budget : 0,
      externalURL: countData?.externalURL ?? false,
      print: intensityId >= 0 ? intensities[intensityId - 1]?.prints : 0,
      retargetingBudget: intensityId >= 0 ? (intensities[intensityId - 1]?.budget ?? 0) * 0.05 : 0,
      totalBudget: intensityId >= 0 ? intensities[intensityId - 1]?.budget : 0,
      totalImpPurchased: intensityId >= 0 ? intensities[intensityId - 1]?.impressions : 0,
    }
    response = dispatch(recordCountForExpert360Async({ ...updatedCountData, mode, mafId }))
  }

  return Promise.resolve(response)
}

export const saveCountMasterDisplay = () => (dispatch:any, getState: () => MasterDisplayStateType) => {
  const res = masterDisplayResSelector(getState())
  const countData: MasterDisplayResp | undefined = res?.countData
  const minBudget = 150
  let showAlert = false

  if (countData?.totalBudget === 0 || (countData?.totalBudget && countData.totalBudget < minBudget)) {
    showAlert = true
  }

  let response = null

  if (showAlert) {
    dispatch(setBudgetLow(minBudget))
    setTimeout(() => {
      dispatch(hideNotification())
    }, 5000)
  } else {
    const savedData = {
      forecasts: res?.countData?.forecasts ?? [],
      mafProduct: res?.countData?.mafProduct,
      masterDisplayReq: res?.countData?.masterDisplayReq ?? null,
      totalBudget: res?.countData?.totalBudget ?? 0,
      totalImpPurchased: res?.countData?.totalImpPurchased ?? 0,
    }
    response = dispatch(recordCountForMasterDisplayAsync(savedData))
  }

  return Promise.resolve(response)
}

export const saveCountEmailing = (countName: string, mode: ModesType = ModesType.New) => (dispatch:any, getState: () => EmailingStateType) => {
  const res = emailingResSelector(getState())
  const countData: EmailingResp = res?.countData
  const mafId = res?.mafId ?? null

  let showAlert = false
  if (!countData.bySiteGroup?.LogicImmo
    && countData.bySiteGroup?.SeLoger?.selectedEmails === 0) showAlert = true
  if (!countData.bySiteGroup?.SeLoger
    && countData.bySiteGroup?.LogicImmo?.selectedEmails === 0) showAlert = true
  // SeLoger && LogicImmo
  if (countData.bySiteGroup?.SeLoger?.selectedEmails === 0
    && countData.bySiteGroup?.SeLoger?.respondedBroadcastMediums?.length === 0
    && countData.bySiteGroup?.LogicImmo?.selectedEmails === 0
    && countData.bySiteGroup?.LogicImmo?.respondedBroadcastMediums?.length === 0) showAlert = true
  // SeLoger
  if (countData.bySiteGroup?.SeLoger?.selectedEmails === 0
    && countData.bySiteGroup?.SeLoger.respondedBroadcastMediums?.length! > 0) showAlert = true
  if (countData.bySiteGroup?.SeLoger?.selectedEmails! > countData.bySiteGroup?.SeLoger?.emailsAvailable!) showAlert = true
  // LogicImmo
  if (countData.bySiteGroup?.LogicImmo?.selectedEmails === 0
    && countData.bySiteGroup?.LogicImmo.respondedBroadcastMediums?.length! > 0) showAlert = true
  if (countData.bySiteGroup?.LogicImmo?.selectedEmails! > countData.bySiteGroup?.LogicImmo?.emailsAvailable!) showAlert = true

  let response = null

  if (showAlert) {
    dispatch(setNbEmailsInvalid())
    setTimeout(() => {
      dispatch(hideNotification())
    }, 5000)
  } else {
    response = dispatch(recordCountAsync({ ...countData, countName, mode, mafId }))
  }

  return Promise.resolve(response)
}

export const saveCountDisplay = (countName: string, mode: ModesType = ModesType.New) => (dispatch:any, getState: () => DisplayStateType) => {
  const res = displayResSelector(getState())
  const countData: DisplayResp = res?.countData
  const mafId = res?.mafId ?? null
  const minBudget = 0
  let showAlert = false

  // By format, total can't be equal to zero
  if (countData.saveBy === 'format' && countData.totalBudget === minBudget) {
    showAlert = true
  }

  // By context, a row can't be equal to zero
  if (countData.saveBy === 'context') {
    countData?.forecasts?.forEach((forecast: ForecastResult) => {
      if (forecast.available && forecast.available > 0 && forecast.purchased === 0) {
        showAlert = true
      }
    })
  }

  let response = null

  if (showAlert && countData.saveBy === 'format') {
    dispatch(setBudgetLow(minBudget))
    setTimeout(() => {
      dispatch(hideNotification())
    }, 5000)
  } else if (showAlert && countData.saveBy === 'context') {
    dispatch(setBudgetInvalid())
    setTimeout(() => {
      dispatch(hideNotification())
    }, 5000)
  } else {
    response = dispatch(recordCountForDisplayAsync({ ...countData, countName, mode, mafId }))
  }

  return Promise.resolve(response)
}

export const saveCountPerformance = (countName: string, mode: ModesType = ModesType.New) => (dispatch:any, getState: () => PerformanceStateType) => {
  const res = performanceResSelector(getState())
  const countData: PerformanceDisplayResp = res?.countData
  const mafId = res?.mafId ?? null
  const minBudget = 0
  let showAlert = false

  // By format, total can't be equal to zero
  if (countData.totalBudget === minBudget) {
    showAlert = true
  }

  let response = null

  if (showAlert) {
    dispatch(setBudgetLow(minBudget))
    setTimeout(() => {
      dispatch(hideNotification())
    }, 5000)
  } else {
    response = dispatch(recordCountForPerformanceAsync({ ...countData, countName, mode, mafId }))
  }

  return Promise.resolve(response)
}

export const saveCountVedettePlus = (countName: string, mode: ModesType = ModesType.New) => (dispatch:any, getState: () => VedettePlusStateType) => {
  const res = vedettePlusResSelector(getState())
  const countData: VedettePlusResp = res?.countData
  const mafId = res?.mafId ?? null
  const minBudget = 0
  let showAlert = false

  countData?.forecasts?.forEach((forecast: ForecastResult) => {
    if (forecast.available && forecast.available > 0 && forecast.budget === minBudget) {
      showAlert = true
    }
  })

  let response = null

  if (showAlert) {
    dispatch(setBudgetInvalid())
    setTimeout(() => {
      dispatch(hideNotification())
    }, 5000)
  } else {
    response = dispatch(recordCountForVedettePlusAsync({ ...countData, countName, mode, mafId }))
  }

  return Promise.resolve(response)
}
