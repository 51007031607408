import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import DisplayHeaderNeuf from './DisplayHeaderNeuf/DisplayHeaderNeuf'
import DisplayHeaderConstruire from './DisplayHeaderConstruire/DisplayHeaderConstruire'
import DisplayHeaderBureauxEtCommerce from './DisplayHeaderBureauxEtCommerce/DisplayHeaderBureauxEtCommerce'
import DisplayHeaderLuxury from './DisplayHeaderLuxury/DisplayHeaderLuxury'

import { currentMarketSelector } from '../../../../redux/reducers/UserConfig/userConfig.reducer'

import { Market } from '../../../../utils/myAdfactoryApi/swaggerApi'
import { DisplayMarketsType } from '../../../../types/display.type'
import { productNamesType } from '../../../../types/productNames.types'

const DisplayHeader = (): React.ReactElement | null => {
  const currentMarket: any = useSelector(currentMarketSelector)

  return (
    <DisplayHeaderWrapper data-testid={`displayHeader${currentMarket}`}>
      <DisplayHeaderContainer>
        <h2>Configuration du comptage <span>{currentMarket === Market.Luxe ? productNamesType.DisplayLuxury : productNamesType.MasterDisplayNeuf}</span></h2>
        <FormWrapper>
          <FormContainer>
            {currentMarket === DisplayMarketsType.Neuf && <DisplayHeaderNeuf />}
            {currentMarket === DisplayMarketsType.Construire && <DisplayHeaderConstruire />}
            {currentMarket === DisplayMarketsType.BureauxEtCommerce && <DisplayHeaderBureauxEtCommerce />}
            {currentMarket === DisplayMarketsType.Luxe && <DisplayHeaderLuxury />}
          </FormContainer>
        </FormWrapper>
      </DisplayHeaderContainer>
    </DisplayHeaderWrapper>
  )
}

// Styled component
const DisplayHeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: ${(props) => props.theme.global.myAdFactory.zIndex.form};
  height: 10em;
  width: 100%;
  background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
`

const DisplayHeaderContainer = styled.div`
  margin: 2.5em 2.5em 0 2.5em;

  h2 {
    font-size: 1.15em;
    font-weight: 700;

    span {
      font-weight: 400;
    }
  }
`

const FormWrapper = styled.div`
  position: relative;
  height: 9.5em;
  margin-top: 1em;
  width: 100%;
  background-color: ${(props) => props.theme.global.colorPalette.white};
  border-radius: .3em;
  padding: 1em 1.5em 2.75em 1.5em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
`

const FormContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
`

export default DisplayHeader
