import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from '@ui-library/core'
import { SizeRegular } from '@ui-library/icons'

import ProposalHeader from '../../../components/Header/HeaderProposal/HeaderProposal'
import Expert360SummaryCount from '../../../components/Products/Expert360/Expert360SummaryCount/Expert360SummaryCount'

import mapBackground from '../../../assets/images/map_background.jpg'
import pdfLink from '../../../assets/pdf/SPECS_MYADFACTORY_360.pdf'

import { expert360ErrorsSelector, expert360ResSelector } from '../../../redux/reducers/Expert360/expert360.reducer'

import { useProductExpert360Data } from '../../../hooks/products/expert360/useProductExpert360Data'

import Expert360Details from '../../../components/Products/Expert360/Expert360Details/Expert360Details'
import { useProductExpert360Count } from '../../../hooks/products/expert360/useProductExpert360Count'
import Logo from '../../../components/Logo/Logo'
import { formatDate } from '../../../utils/date'

const Expert360Proposal = (): React.ReactElement | null => {
  const { id }: { id: string } = useParams()

  const expert360Errors: any = useSelector(expert360ErrorsSelector)
  const res = useSelector(expert360ResSelector)
  // const currentMarket: any = useSelector(currentMarketSelector)

  useProductExpert360Data(id)

  const {
    selectedIntensity,
    selectedIntensityBudget,
    isPrintOptionSelected,
    relativeInformations,
  } = useProductExpert360Count()

  window.onbeforeprint = () => {
    document.title = `MAF-${res?.mafId}`
  }

  window.onafterprint = () => {
    document.title = 'MyAdFactory - Groupe SeLoger'
  }

  if (res && Object.keys(res).length === 0) return null
  if (res?.countData && Object.keys(res.countData).length === 0) return null

  if (expert360Errors) {
    return (
      <div data-testid="proposalExpert360Error">
        <ProposalWrapper>
          <ProposalExpert360Error>
            <p>Une erreur est survenue</p>
          </ProposalExpert360Error>
        </ProposalWrapper>
      </div>
    )
  }

  return (
    <div data-testid="proposalExpert360">
      <ProposalHeader clientId={res?.clientId} clientName={res?.clientName} mafId={res?.mafId} />
      <ProposalWrapper>
        <ProposalMap style={{ backgroundImage: `url(${mapBackground})` }} />
        <ProposalContainer>
          <ProposalSummaryBlock>
            <ProposalSummaryBlockText>
              <h1>Bonjour,</h1>
              <p>Vous trouverez ci-dessous le plan média répondant aux besoins évoqués ensemble. Nous sommes à votre écoute pour toute question complémentaire.</p>
            </ProposalSummaryBlockText>
            <Expert360SummaryCount isProposal />
          </ProposalSummaryBlock>
        </ProposalContainer>
        <ProposalContainer>
          <ProposalDescription>
            <p>Composantes de l'offre</p>
            <a href={pdfLink} target="_blank" rel="noreferrer">
              <ButtonSpec
                className="downloadSpec"
                icon={SizeRegular}
                kind="tertiary"
                size="medium"
                tag="button"
                variant="normal"
              >
                Télécharger les spécifications techniques
              </ButtonSpec>
            </a>
          </ProposalDescription>
          {res?.countData?.client?.intensity?.length !== 0 && res?.countData?.intensity >= 0 && (
            <Expert360Details
              brandContentCommentary={res?.countData?.brandContentCommentary}
              isPrintOptionSelected={isPrintOptionSelected}
              isProposal
              relativeInformations={relativeInformations()}
              selectedIntensity={selectedIntensity}
              selectedIntensityBudget={selectedIntensityBudget}
            />
          )}
          <ProposalGenerate>
            <p>Générez un PDF de la proposition commerciale pour la partager à votre client.</p>
            <Button
              testID="pdfButton"
              size="medium"
              kind="primary"
              tag="button"
              variant="normal"
              onClick={() => { window.print() }}
            >
              Télécharger le PDF
            </Button>
          </ProposalGenerate>
          <ProposalFooter>
            <p>Proposition réalisée le {formatDate(new Date(res?.createdDate))} par</p>
            <span><Logo /></span>
          </ProposalFooter>
        </ProposalContainer>
      </ProposalWrapper>
    </div>
  )
}

const ProposalWrapper = styled.div`
  position: relative;
`

const ProposalMap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 28em;
  background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
  background-size: cover;
`

const ProposalExpert360Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

const ProposalContainer = styled.div`
  padding: 2em 6em 1em 6em;

  h1 {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: .5em;
  }

  > p {
    margin-bottom: 4em;
  }

  @media print {
    h1 {
      font-size: 1.4em;
    }

    > p {
      margin-bottom: 2em;
    }
  }
`

const ProposalSummaryBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  height: 35em;
  margin-bottom: 3em;

  @media print {
    margin-bottom: 1em;
  }
`

const ProposalSummaryBlockText = styled.div`
  h1 {
    margin-top: 2em;
  }
`

const ProposalDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 1.125em;
  }

  a {
    text-decoration: none;
  }
`

const ButtonSpec = styled(Button)`
  border-color: ${(props) => props.theme.global.colorPalette.saphir};
  color: ${(props) => props.theme.global.colorPalette.saphir};

  &:hover {
    color: ${(props) => props.theme.global.colorPalette.white};
  }

  @media print {
    visibility: hidden;
  } 
`

const ProposalGenerate = styled.div`
  margin-top: 4em;
  text-align: center;

  p {
    font-size: 1.5em;
    margin-bottom: 2em;
  }

  button {
    display: block;
    margin: 0 auto;
    margin-bottom: 2em;
  }

  @media print {
    display: none;
  }
`

const ProposalFooter = styled.div`
  margin-top: 4em;
  text-align: center;
  color: ${(props) => props.theme.global.colorPalette.grey};

  > span {
    display: block;
    margin: 1em auto;
    max-width: 150px;
  }
`

export default Expert360Proposal
