import React from 'react'
import { Icon } from '@ui-library/core'
import ThreePointsRegular from '@ui-library/icons/ThreePointsRegular'
import { useDispatch } from 'react-redux'
import { jobList } from '../../../../utils/variables'
import { UserBo } from '../../../../utils/myAdfactoryApi/swaggerApi'
import { saveSelectedRow } from '../../../../redux/reducers/Page/Users/pageUsers.reducer'
import { UserMarketsCell } from './UserMarketsCell/UserMarketsCell'

export type TableRowUsersProps = {
  user: UserBo;
  setActionButtonsMenuOpen: (value: boolean) => void;
};

const TableRowUsers = ({
  user,
  setActionButtonsMenuOpen,
}: TableRowUsersProps): React.ReactElement => {
  const initial = `${user?.firstName?.substring(
    0,
    1
  )}${user?.lastName?.substring(0, 1)}`
  let label = ''
  const job = jobList.find(
    (elem: any) => user.typeProfil
      && elem.value.toLowerCase() === user.typeProfil.toLowerCase()
  )
  if (job?.label) {
    label = job.label
  }

  const dispatch = useDispatch()

  const handleClickUsers = (e: React.MouseEvent<HTMLElement>) => {
    setActionButtonsMenuOpen(true)
    dispatch(
      saveSelectedRow({
        x: e.clientX,
        y: e.clientY,
        user: user,
      })
    )
  }

  return (
    <tr>
      <td>
        <div className="tdWrapper circle">
          <div className="initial">{initial}</div>
        </div>
      </td>
      <td>
        <div className="tdWrapper">
          <div className="cellText">{user.lastName}</div>
          <span className="divider" />
        </div>
      </td>
      <td>
        <div className="tdWrapper">
          <div className="cellText">{user.firstName}</div>
          <span className="divider" />
        </div>
      </td>
      <td>
        <div className="tdWrapper">
          <div className="cellText">{user.email}</div>
          <span className="divider" />
        </div>
      </td>
      <td>
        <div className="tdWrapper">
          <div className="cellText">{label}</div>
          <span className="divider" />
        </div>
      </td>
      <td aria-label="markets">
        <div className="tdWrapper">
          <div className="cellText">
            <UserMarketsCell user={user} />
          </div>
        </div>
      </td>
      <td
        data-testid="clickUsers"
        onClick={handleClickUsers}
        aria-label="Actions"
      >
        <div data-testid="clickUsersDiv">
          <Icon
            icon={ThreePointsRegular}
            size="16px"
            className="threePointsVertical"
          />
        </div>
      </td>
    </tr>
  )
}
export default TableRowUsers
