export const networkErrorContent = {
  statut: 'error',
  title: 'Oups !',
  description: 'Un problème est survenu lors de l\'enregistrement',
}
export const budgetErrorContent = (value: number) => {
  return ({
    statut: 'error',
    title: 'Oups !',
    description: `Ce comptage ne peut pas être enregistré car le budget total doit être superieur à ${value}€`,
  })
}
export const budgetInvalidErrorContent = {
  statut: 'error',
  title: 'Oups !',
  description: 'Ce comptage ne peut pas être enregistré car aucun budget ne peut être égal à zéro',
}
export const successContentCreation = {
  statut: 'success',
  title: 'C\'est bon !',
  description: 'Ce comptage a bien été enregistré',
}
export const successContentEdition = {
  statut: 'success',
  title: 'C\'est bon !',
  description: 'Vos modifications ont bien été enregistrées',
}
export const nbEmailsErrorContent = {
  statut: 'error',
  title: 'Oups !',
  description: 'Ce comptage ne peut pas être enregistré car le nombre de contacts est incorrect',
}
export const adjustableOfferErrorContent = {
  statut: 'error',
  title: 'Oups !',
  description: 'Ce comptage ne peut pas être enregistré car un des champs retourne une erreur',
}
