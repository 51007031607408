import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { currentTabSelector, displayResSelector, setCurrentTab, setRes } from '../../../redux/reducers/Display/display.reducer'
import { currentMarketSelector } from '../../../redux/reducers/UserConfig/userConfig.reducer'

import { DisplayMarketsType } from '../../../types/display.type'
import { ForecastResult } from '../../../utils/myAdfactoryApi/swaggerApi'

export const useProductDisplayCount = () => {
  const dispatch: any = useDispatch()

  const res = useSelector(displayResSelector)
  const currentTab: string = useSelector(currentTabSelector)
  const currentMarket: any = useSelector(currentMarketSelector)

  const usedForecast = currentTab === 'context' ? (res?.countData?.forecasts ?? []) : (res?.countData?.forecastSplitByPosition ?? [])
  const displayTabs = (currentMarket === DisplayMarketsType.Neuf || currentMarket === DisplayMarketsType.Construire)

  const calculateTotalBudget = (forecasts: ForecastResult[]) => {
    let totalBudg = 0
    forecasts.forEach((forecast: ForecastResult) => {
      totalBudg += forecast.budget ?? 0
    })
    return totalBudg
  }

  const calculateTotalImpressions = (forecasts: ForecastResult[]) => {
    let totalImp = 0
    forecasts.forEach((forecast: ForecastResult) => {
      totalImp += forecast.purchased ?? 0
    })
    return totalImp
  }

  // const disabledRowLogicalsForLuxury = (forecast: ForecastResult, forecasts: any[]): ForecastResult[] => {
  //   const copyForecasts = [...forecasts]
  //   const copyForecastBillboard = copyForecasts.filter((c) => c.code === 'Billboard')
  //   const copyForecastNatif = copyForecasts.filter((c) => c.code === 'Natif')

  //   return copyForecasts.map((c) => {
  //     if (currentMarket === DisplayMarketsType.Luxe && c.code !== 'Masthead') {
  //       if (copyForecastBillboard?.[0]?.budget === 0 && copyForecastNatif?.[0]?.budget === 0) {
  //         return { ...c, isDisabled: false }
  //       }
  //       if (c.code === 'Billboard' && c.budget === 0) {
  //         return { ...c, isDisabled: true }
  //       }
  //       if (c.code === 'Natif' && c.budget === 0) {
  //         return { ...c, isDisabled: true }
  //       }
  //       return c
  //     }
  //     return c
  //   })
  // }

  const handleChangeTabs = (value: string) => {
    dispatch(setCurrentTab(value))
  }

  const handleChangeInputs = (d: ForecastResult) => {
    const copyForecast = [...usedForecast]
    const index = copyForecast.findIndex((forecast: ForecastResult) => forecast.code === d.code)

    // First update target forecast with the new values.
    if (copyForecast && index !== -1) copyForecast[index] = d

    // copyForecast = disabledRowLogicalsForLuxury(d, copyForecast)

    const totalBudget = calculateTotalBudget(copyForecast)
    const totalImpPurchased = calculateTotalImpressions(copyForecast)

    if (currentTab === 'context') {
      const updatedRes = {
        ...res,
        countData: {
          ...res.countData,
          forecasts: copyForecast,
          totalBudget,
          totalImpPurchased,
        },
      }
      dispatch(setRes(updatedRes))
    } else {
      const updatedRes = {
        ...res,
        countData: {
          ...res.countData,
          forecastSplitByPosition: copyForecast,
          totalBudget,
          totalImpPurchased,
        },
      }
      dispatch(setRes(updatedRes))
    }
  }

  // Auto-update totals when forecasts are changing
  useEffect(() => {
    if (res?.countData && Object.keys(res.countData).length > 0) {
      const totalBudg = calculateTotalBudget(usedForecast)
      const totalImp = calculateTotalImpressions(usedForecast)
      const updatedRes = {
        ...res,
        countData: {
          ...res.countData,
          totalBudget: totalBudg,
          totalImpPurchased: totalImp,
        },
      }
      dispatch(setRes(updatedRes))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, dispatch])

  return {
    displayTabs,
    calculateTotalBudget,
    calculateTotalImpressions,
    handleChangeInputs,
    handleChangeTabs,
  }
}
