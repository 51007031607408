import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Icon } from '@ui-library/core'
import { LessBold } from '@ui-library/icons'
import styled from 'styled-components'
import HomepageHeader from '../../components/Homepage/HomepageHeader/HomepageHeader'
import HomepageBanner from '../../components/Homepage/HomepageBanner/HomepageBanner'
import HomepageContacts from '../../components/Homepage/HomepageContacts/HomepageContacts'
import { currentMarketSelector, userProfileSelector } from '../../redux/reducers/UserConfig/userConfig.reducer'
import { respondTo } from '../../theme/mixins/respondTo'

const HomepageWrapper = styled.section`
  height: 100%;
  width: 100%;
  padding: 1em;

  ${respondTo.sm`
    padding: 2.5em;
  `}
`

const HomepageTitle = styled.h3`
  margin-top: 2em;
  font-size: 1.5em;

  span {
    color: ${(props) => props.theme.global.colorPalette.black};
    font-weight: 700;
  }
`

const SeparationIcon = styled(Icon)`
  width: 1.5em;
  height: 2em;
  margin-bottom: 2em;
`

const Homepage = (): React.ReactElement => {
  // Selectors
  const userProfile = useSelector(userProfileSelector)
  const currentMarket = useSelector(currentMarketSelector)

  // States
  const [redirect, setRedirect] = useState(false)

  // Variables
  const { from } = { from: { pathname: '/comptage/produits' }}

  // Functions
  const createNewCount = () => {
    setRedirect(true)
  }

  if (redirect === true) {
    if (currentMarket === 'Neuf') {
      return <Redirect to={{ pathname: '/comptage/produits', state: { provenance: 'home' }}} />
    }
    return <Redirect to={from} />
  }

  return (
    <HomepageWrapper data-testid="homePage">
      <HomepageHeader userProfile={userProfile} handleCreateNewCount={createNewCount} />
      <HomepageBanner currentMarket={currentMarket} />
      {/* <HomepageTitle>
        Mon tableau <span> de bord</span>
      </HomepageTitle>
      <SeparationIcon icon={LessBold} className="separationIcon" />
      <Dashboard /> */}
      <HomepageTitle>
        Contacts <span>Principaux</span>
      </HomepageTitle>
      <SeparationIcon icon={LessBold} className="separationIcon" />
      <HomepageContacts />
    </HomepageWrapper>
  )
}

export default Homepage
