import React from 'react'
import styled from 'styled-components'
import { Icon } from '@ui-library/core'
import { NewsLined, PaperAircraftLined } from '@ui-library/icons'
import Banner from '../../Banner/Banner'
import { Market } from '../../../utils/myAdfactoryApi/swaggerApi'

type HomepageBannerProps = {
  currentMarket: Market,
}

const BannerContent = styled.div`
  display: flex;

  strong {
    font-size: 1.2em;
    display: block;
    margin-bottom: .3em;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }
`

const HomepageBanner = ({ currentMarket }: HomepageBannerProps): React.ReactElement | null => {
  if (currentMarket === Market.Ancien) {
    return (
      <Banner animation style={{ marginTop: '3em' }} dataTestId="homepageBanner">
        <BannerContent>
          <Icon icon={NewsLined} size="2.5em" color="#ffb75a" style={{ marginRight: '1em' }} />
          <div>
            <strong>Nouvelle application MyAdfactory</strong>
            <ul>
              <li>
                - Créez votre premier comptage sur le produit Expert 360 en cliquant sur "Nouveau Comptage"
              </li>
              <li>- En cas de RC client introuvable, adressez vous au marché de l'ancien: marion.pellegrinetti@groupeseloger.com</li>
            </ul>
          </div>
        </BannerContent>
      </Banner>
    )
  }

  if (currentMarket === Market.Neuf) {
    return (
      <Banner animation style={{ marginTop: '3em' }} dataTestId="homepageBanner">
        <BannerContent>
          <Icon icon={PaperAircraftLined} size="2.5em" color="#ffb75a" style={{ marginRight: '1em' }} />
          <div>
            <strong>Bonne nouvelle : le comptage Display est disponible sur votre nouveau MyAdFactory !</strong>
            <p>Vous pouvez désormais sélectionner les produits Vedette +, Display Impression & Performance.</p>
          </div>
        </BannerContent>
      </Banner>
    )
  }

  if (currentMarket === Market.Construire) {
    return (
      <Banner animation style={{ marginTop: '3em' }} dataTestId="homepageBanner">
        <BannerContent>
          <Icon icon={PaperAircraftLined} size="2.5em" color="#ffb75a" style={{ marginRight: '1em' }} />
          <div>
            <strong>Bonne nouvelle : le comptage Display est disponible sur votre nouveau MyAdFactory !</strong>
            <p>Vous pouvez désormais sélectionner le produit Display Impression.</p>
          </div>
        </BannerContent>
      </Banner>
    )
  }

  if (currentMarket === Market.Luxe) {
    return (
      <Banner animation style={{ marginTop: '3em' }} dataTestId="homepageBanner">
        <BannerContent>
          <Icon icon={PaperAircraftLined} size="2.5em" color="#ffb75a" style={{ marginRight: '1em' }} />
          <div>
            <strong>Bonne nouvelle : la nouvelle offre Display Luxury Duo est disponible sur votre nouveau MyAdFactory !</strong>
            <p>Vous pouvez désormais réaliser vos comptages sur les sites Lux Residence & Belles Demeures.</p>
          </div>
        </BannerContent>
      </Banner>
    )
  }

  return null
}

export default HomepageBanner
