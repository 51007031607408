import styled, { css } from 'styled-components'
import { Icon } from '@ui-library/core'

type Expert360IntensityCardStyledProps = {
  $available?: boolean,
  $currentColor?: string,
  $isChecked?: boolean,
  $notAllowed?: boolean,
}

type CheckOfferIconProps = {
  $isChecked?: boolean,
}

export const BudgetSpan = styled.span`
  font-weight: bold;
  font-size: 1.5em;
`

export const BudgetDescriptionSpan = styled.span`
  font-size: 1em;
  margin-bottom: 1em;
`

export const IntensityInfoUl = styled.ul`
  li {
    display: flex;
    font-weight: 400;
    color: ${(p) => p.theme.global.colorPalette.black};
    margin-bottom: 0.5em;
    justify-content: flex-start;
  }

  li:nth-child(2) {
    margin-left: 1.875em;
    font-size: 0.875em;
  }

  span {
    font-size: 1em;
    width: 100%;
  }
`

export const IntensityInfoIcon = styled(Icon)`
  width: 2em;
  height: 1em;
  margin-top: 0.1em;
`

export const Expert360IntensityCardStyled = styled.div<Expert360IntensityCardStyledProps>`
  margin-right: 1.5em;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0.8em;
  padding: 1em 1em 1.5em 1em;
  border: 1px solid transparent;
  position: relative;
  flex-grow: 1;

  @keyframes lazyLoadIntensityCustom {
    0% {
      background-position: -15em 0;
    }

    100% {
      background-position: 15em 0;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 16px 0px #00000029;
  }

  &:last-child {
    margin-right: 0;
  }

  ${(props: any) => props.$available && props.$currentColor === 'Low' && css`
    &:hover {
      border: 1px solid #09a3e9;
    }
  `}

  ${(props: any) => props.$available && props.$currentColor === 'Medium' && css`
    &:hover {
      border: 1px solid #6f2acf;
    }
  `}

  ${(props: any) => props.$available && props.$currentColor === 'High' && css`
    &:hover {
      border: 1px solid #e00034;
    }
  `}

  ${(props: any) => props.$available && props.$isChecked && props.$currentColor === 'Low' && css`
    border: 1px solid #09a3e9;
  `}

  ${(props: any) => props.$available && props.$isChecked && props.$currentColor === 'Medium' && css`
    border: 1px solid #6f2acf;
  `}

  ${(props: any) => props.$available && props.$isChecked && props.$currentColor === 'High' && css`
    border: 1px solid #e00034;
  `}

  ${(props: any) => !props.$available && css`
    opacity: 0.25;

    &:hover {
      cursor: not-allowed;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    }
    
    ${BudgetSpan}, ${BudgetDescriptionSpan} {
      margin-top: 3px;
      background: ${(p) => p.theme.global.colorPalette.greyLighter};
      color: transparent !important;
      width: fit-content;
    }

    ${IntensityInfoUl} {
      span {
        background: ${(p) => p.theme.global.colorPalette.greyLighter};
        padding-left: 0.55em;
        color: transparent;
      }
    }
  `}

  ${(props: any) => props.$notAllowed && css`
    cursor: not-allowed !important;
  `}
`

export const CheckOfferIcon = styled(Icon)<CheckOfferIconProps>`
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.125em;
  height: 1.125em;

  ${(props: any) => props.$isChecked && css`
    visibility: visible;
  `}

  ${(props: any) => !props.$isChecked && css`
    visibility: hidden;
  `}
`

export const HeaderIntensity = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.4em;
`

export const BudgetLoadingSpan = styled.span`
  width: 80%;
  height: 1.375em;
  margin-bottom: 1em;
  border-radius: 0.25em;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: lazyLoadIntensityCustom;
  animation-timing-function: linear;
  background: ${(p) => p.theme.global.colorPalette.lighterSaphir};
  background-size: 8em 1.4em;
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.global.colorPalette.lighterSaphir} 0%,
    ${(props) => props.theme.global.colorPalette.cloud} 20%,
    ${(props) => props.theme.global.colorPalette.lighterSaphir} 40%,
    ${(props) => props.theme.global.colorPalette.lighterSaphir} 100%
  );
`

export const DescriptionLoading = styled.div`
  width: 100%;
  height: 1.675em;
  margin-bottom: 1em;
  border-radius: 0.25em;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: lazyLoadIntensityCustom;
  animation-timing-function: linear;
  background: ${(p) => p.theme.global.colorPalette.lighterSaphir};
  background-size: 8em 1.4em;
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.global.colorPalette.lighterSaphir} 0%,
    ${(props) => props.theme.global.colorPalette.cloud} 20%,
    ${(props) => props.theme.global.colorPalette.lighterSaphir} 40%,
    ${(props) => props.theme.global.colorPalette.lighterSaphir} 100%
  );
`
