import InputGroup from '@ui-library/core/InputGroup'
import MagnifyingRegular from '@ui-library/icons/MagnifyingRegular'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useDebounce from '../../../hooks/useDebounce'
import { tabListCountSelector } from '../../../redux/reducers/UserConfig/userConfig.reducer'

type SearchInputProps = {
  placeholder?: string
  handleChange: Function
}

const SearchInput = ({ placeholder, handleChange }: SearchInputProps) => {
  const [inputValue, setInputValue] = useState('')
  const tabListCount = useSelector(tabListCountSelector)

  // Clear search input when change tab list.
  useEffect(() => {
    setInputValue('')
  }, [tabListCount])

  // Fetch data with debounce method.
  const debouncedQuery = useDebounce(inputValue, 150)

  useEffect(() => {
    handleChange(debouncedQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery])

  const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setInputValue(value)
  }

  return (
    <div className="inputSearchListWrapper" data-testid="searchHeaderList">
      <InputGroup
        style={{ width: '30rem' }}
        data-testid="searchInput"
        placeholder={placeholder ?? ''}
        buttonIcon={MagnifyingRegular}
        value={inputValue}
        state="normal"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputChange(e)}
      />
    </div>
  )
}

export default SearchInput
