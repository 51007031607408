import React from 'react'
import { useSelector } from 'react-redux'
import { DocumentLined, CalendarLined, DesktopLined, FillsRegular } from '@ui-library/icons'

import ResumeCard from '../../../ResumeCard/ResumeCard'

import { displayDataSelector, displayLoadingSelector, displayResSelector } from '../../../../redux/reducers/Display/display.reducer'
import { currentMarketSelector } from '../../../../redux/reducers/UserConfig/userConfig.reducer'

import { formatNumbers } from '../../../../utils/tsUtils'
import { formatDate } from '../../../../utils/date'
import { formatProductNames, formatZipCodeWithManyLocalityNames } from '../../../../utils/format'

import { ForecastResult } from '../../../../utils/myAdfactoryApi/swaggerApi'
import { BudgetInformationType, TableInformationType } from '../../../../types/ResumeCard.type'
import { productNamesType } from '../../../../types/productNames.types'
import { DisplayMarketsType } from '../../../../types/display.type'

type SummaryCountsProps = {
  isProposal?: boolean,
}

const DisplaySummaryCounts = ({ isProposal = false }: SummaryCountsProps): React.ReactElement => {
  const currentMarket: any = useSelector(currentMarketSelector)
  const data = useSelector(displayDataSelector)
  const res = useSelector(displayResSelector)
  const loading = useSelector(displayLoadingSelector)

  const logos = data?.logos ?? []
  const productName: productNamesType = res?.countData?.mafProduct ? formatProductNames(res?.countData.mafProduct as keyof typeof productNamesType) : productNamesType.MasterDisplayNeuf

  const locations: ForecastResult[] | undefined = formatZipCodeWithManyLocalityNames(data?.locations ?? [], res?.countData?.localityNameByZipCode ?? {})

  let periodicity: string = ''
  if (data?.periodicity === 'Récurrent') {
    periodicity = 'Récurrent'
  } else if (data?.dates) {
    periodicity = `Du ${formatDate(new Date(data.dates[0]))} au ${formatDate(new Date(data.dates[1]))}`
  }

  let projectType: string = ''
  if (data?.targetings && currentMarket !== DisplayMarketsType.BureauxEtCommerce && currentMarket !== DisplayMarketsType.Luxe) {
    projectType = Object.values(data.targetings).map((t: any) => {
      if (t.checked.includes(true)) return t.label
      return null
    }).filter((t: any) => t).join(', ')
  }

  const budgetInformation: BudgetInformationType = {
    totalBudget: formatNumbers(res?.countData.totalBudget ?? 0),
    complementary: `pour ${formatNumbers(res?.countData.totalImpPurchased ?? 0)} impressions`,
  }
  const defineFormatTextInResumeCard = (): string => {
    switch (currentMarket) {
      case DisplayMarketsType.Luxe:
        return 'Masthead, Billboard et Natif inter-annonces'
      case DisplayMarketsType.BureauxEtCommerce:
        return 'Masthead, Natif, Grand Angle et Masthead+'
      default:
        return 'Masthead, Pavé et Natif'
    }
  }

  const formatToDisplay: string = defineFormatTextInResumeCard()

  const tableInformation: TableInformationType = [{
    title: 'Appareils',
    icon: DesktopLined,
    description: 'Desktop, tablette, mobile',
  },
  {
    title: 'Périodicité',
    icon: CalendarLined,
    description: periodicity,
  },
  {
    title: 'Format',
    icon: FillsRegular,
    description: formatToDisplay,
  }]

  if (projectType) {
    tableInformation.splice(1, 0, {
      title: 'Type de projet',
      icon: DocumentLined,
      description: projectType,
    })
  }

  return (
    <ResumeCard
      broadcastMediums={logos}
      budgetInformation={budgetInformation}
      isAllFrance={data?.isAllFrance}
      isProposal={isProposal}
      loading={loading}
      locations={locations}
      productName={productName}
      tableInformation={tableInformation}
      testId="displayContentSummary"
    />
  )
}

export default DisplaySummaryCounts
