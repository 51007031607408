import { createAsyncThunk } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import { hideNotification } from '../redux/reducers/Notifications/notifications.reducer'
import { ModesType } from '../types/Modes.type'
import { getMyAdFactoryClass } from '../utils/myAdfactoryApi/getMyAdFactory.utils'
import { DisplayReq, DisplayResp } from '../utils/myAdfactoryApi/swaggerApi'

/**
 * Marché du neuf
 */

// Get options for targetings selection
export const getSelectableValuesAsync = createAsyncThunk(
  'display/GetSelectableValues',
  async (currentMarket: string, getState) => {
    const response =
      await getMyAdFactoryClass(getState).getSelectableValues(currentMarket)
    return response
  }
)

// Process count
export const processCountForDisplayAsync = createAsyncThunk(
  'display/ProcessCount',
  async (
    {
      market,
      targetings,
      postalCodes,
      isAllFrance,
      projectTypes,
      startDate,
      endDate,
      periodicity,
      radius,
    }: DisplayReq,
    getState
  ) => {
    const body: DisplayReq = {
      market,
      targetings,
      postalCodes,
      isAllFrance,
      projectTypes,
      startDate,
      endDate,
      periodicity,
      radius,
    }
    const response = await getMyAdFactoryClass(getState).processCount(body)
    return response
  }
)

export const recordCountForDisplayAsync = createAsyncThunk(
  'display/RecordCount',
  async (
    {
      cpm,
      countName,
      displayReq,
      forecasts,
      forecastSplitByPosition,
      locality,
      localityNameByZipCode,
      mafId,
      mafProduct,
      mode,
      saveBy,
      totalBudget,
      totalImpPurchased,
    }: DisplayResp & { mode: ModesType; mafId: number },
    getState
  ) => {
    const body: DisplayResp = {
      cpm,
      countName,
      displayReq,
      forecasts,
      forecastSplitByPosition,
      locality,
      localityNameByZipCode,
      mafProduct,
      saveBy,
      totalBudget,
      totalImpPurchased,
    }
    let response = null
    if (mode === ModesType.Edit) {
      response = await getMyAdFactoryClass(getState).display(mafId, body)
    } else {
      response = await getMyAdFactoryClass(getState).recordCount(body)
    }

    setTimeout(() => {
      getState.dispatch(hideNotification())
    }, 5000)
    return response
  }
)
