import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Checkbox, Icon, TextField } from '@ui-library/core'
import { Label } from '@ui-library/core/lib/atoms/Label'
import CheckBold from '@ui-library/icons/CheckBold'

import { expert360ResSelector, setRes } from '../../../../redux/reducers/Expert360/expert360.reducer'

import {
  formatNumbers,
  applyBoostExternalUrl,
  applyBoostPrintOption,
  decreaseBoostExternalUrl,
  decreaseBoostPrintOption,
  isPrintOptionForIntensitySelected,
} from '../../../../utils/tsUtils'
import { EXPERT_360_MINIMUM_BUDGET_ADJUSTABLE_OFFER } from '../../../../utils/variables'
import { ClientIntensity } from '../../../../utils/myAdfactoryApi/swaggerApi'

type Expert360CustomIntensityProps = {
  customIntensity: ClientIntensity,
}

/**
 * Update share of attendance when impressions number is changed.
 */
export const calculateNewPdp = (newImpressions: number, totalForecast: number | null): number => {
  let pdpValue = 0
  if (totalForecast && totalForecast >= 0) {
    const shareOfVoice = (newImpressions / totalForecast) * 100
    const pdpPercentage = Math.round(shareOfVoice * 3)

    if (pdpPercentage > 0 && pdpPercentage <= 15) {
      pdpValue = 1
    } else if (pdpPercentage >= 16 && pdpPercentage <= 25) {
      pdpValue = 2
    } else if (pdpPercentage >= 26 && pdpPercentage <= 35) {
      pdpValue = 3
    } else if (pdpPercentage >= 36 && pdpPercentage <= 45) {
      pdpValue = 4
    } else if (pdpPercentage >= 46 && pdpPercentage <= 55) {
      pdpValue = 5
    } else if (pdpPercentage >= 56 && pdpPercentage <= 65) {
      pdpValue = 6
    } else if (pdpPercentage >= 66 && pdpPercentage <= 75) {
      pdpValue = 7
    } else if (pdpPercentage >= 76 && pdpPercentage <= 85) {
      pdpValue = 8
    } else if (pdpPercentage >= 86 && pdpPercentage <= 95) {
      pdpValue = 9
    } else if (pdpPercentage > 95) {
      pdpValue = 10
    }
  }
  return pdpValue
}

const Expert360CustomIntensity = ({ customIntensity }: Expert360CustomIntensityProps) : React.ReactElement => {
  const dispatch = useDispatch()

  const res = useSelector(expert360ResSelector)

  const isFirstLoad = useRef(false)

  const isPrintOptionSelected: boolean = isPrintOptionForIntensitySelected(customIntensity)

  const [brandContentChecked, setBrandContentChecked] = useState(isPrintOptionSelected)
  const [defaultValueBudget, setDefaultValueBudget] = useState(EXPERT_360_MINIMUM_BUDGET_ADJUSTABLE_OFFER)

  const intensities = res?.countData?.client?.intensity ?? []

  /**
   * Prints Option Checkbox.
   * If checked, budget is boosted (budget + 68euros), and intensity prints is equal to 1.
   * Warning: apply this boost from initialBudget (without externalUrl option boost for example). In oder, (initialBudget + 68euros) + 30%
   */
  const handleBrandCheckbox = () => {
    const updatedIntensities = [...intensities].map((i: ClientIntensity) => {
      const newItem = { ...i }
      if (newItem.level === 3) {
        // In order to respect the order of calculations
        let initialBudget: number = newItem.budget
        initialBudget = res?.countData?.externalURL ? decreaseBoostExternalUrl(initialBudget) : initialBudget
        const appliedPrintOption = brandContentChecked ? decreaseBoostPrintOption(initialBudget) : applyBoostPrintOption(initialBudget)
        const appliedExternalUrlOption = res?.countData?.externalURL ? applyBoostExternalUrl(appliedPrintOption) : appliedPrintOption
        // budget always need to be > 0
        newItem.budget = appliedExternalUrlOption > 0 ? appliedExternalUrlOption : 0
        // prints is updated
        newItem.prints = brandContentChecked ? 0 : 1
      }
      return newItem
    })
    setBrandContentChecked(!brandContentChecked)
    dispatch(setRes({
      ...res,
      countData: {
        ...res.countData,
        client: {
          ...res.countData.client,
          intensity: updatedIntensities,
        },
      },
    }))
  }

  /**
   * Budget onChange.
   * When budget changes, need to update impressions and shareOfVoice from initial Budget (without options boosts) from cpmAvg given by adjustableOffer object.
   */
  const handleBudgetChange = (value: string) => {
    const valueNumber: number = parseInt(value.replace(/\s/g, ''), 10) || 0

    // In order to calculate right values for impressions and shareOfVoice, we need to have initial Budget without boosts
    let valueNumberWithoutBoost: number = res?.countData.externalURL ? decreaseBoostExternalUrl(valueNumber) : valueNumber
    valueNumberWithoutBoost = brandContentChecked ? decreaseBoostPrintOption(valueNumberWithoutBoost) : valueNumberWithoutBoost

    // Updating
    const updatedIntensities = [...intensities].map((i: ClientIntensity) => {
      const newItem = { ...i }
      if (newItem.level === 3) {
        newItem.budget = valueNumber
        // Impression always need to be > 0
        const cpmAvg = res?.countData?.adjustableOffer?.cpmAvg ?? 0
        newItem.impressions = (valueNumberWithoutBoost / cpmAvg) * 1000 > 0 ? Math.round((valueNumberWithoutBoost / cpmAvg) * 1000) : 0
        newItem.shareOfVoice = calculateNewPdp(newItem.impressions, res?.countData?.totalForecast)
      }
      return newItem
    })
    dispatch(setRes({
      ...res,
      countData: {
        ...res.countData,
        client: {
          ...res.countData.client,
          intensity: updatedIntensities,
        },
      },
    }))
  }

  /**
   * Budget Input State
   * If budget is higher than maxPrice or if budget is lower than 150, error
   */
  const handleBudgetState = () => {
    if (customIntensity?.budget > res?.countData?.adjustableOffer?.maxPrice || customIntensity?.budget < defaultValueBudget) {
      return 'error'
    }

    return 'normal'
  }

  /**
   * Budget Input Error Messages
   */
  const handleBudgetErrorMessage = () => {
    if (customIntensity?.budget > res?.countData?.adjustableOffer?.maxPrice) {
      return `Merci de ne pas dépasser le budget maximum de ${formatNumbers(res?.countData?.adjustableOffer?.maxPrice)}€`
    }

    if (customIntensity?.budget < defaultValueBudget) {
      return `Merci de ne pas passer sous le budget minimum de ${defaultValueBudget}€`
    }

    return ''
  }

  /**
   * Impressions onChange.
   * When Impressions changes, need to update budget and shareOfVoice
   */
  const handlePrintsChange = (value: string) => {
    const valueNumber: number = parseInt(value.replace(/\s/g, ''), 10) || 0

    const updatedIntensities = [...intensities].map((i: ClientIntensity) => {
      const newItem = { ...i }
      if (newItem.level === 3) {
        newItem.impressions = valueNumber
        newItem.shareOfVoice = calculateNewPdp(valueNumber, res?.countData?.totalForecast)
        // We need to reapply Boosts after impressions changes to have the right budget
        const initialBudget = Math.round(valueNumber * ((res?.countData?.adjustableOffer?.cpmAvg ?? 0) / 1000))
        let applyBoostToInitialBudget: number = brandContentChecked ? applyBoostPrintOption(initialBudget) : initialBudget
        applyBoostToInitialBudget = res?.countData?.externalURL ? applyBoostExternalUrl(applyBoostToInitialBudget) : applyBoostToInitialBudget
        newItem.budget = applyBoostToInitialBudget
      }
      return newItem
    })
    dispatch(setRes({
      ...res,
      countData: {
        ...res.countData,
        client: {
          ...res.countData.client,
          intensity: updatedIntensities,
        },
      },
    }))
  }

  /**
   * Impressions Input State
   * If budget is higher than maxPrice or if budget is lower than defaultValueBudget, error
   */
  const handlePrintsState = () => {
    if (customIntensity?.impressions > res?.countData?.adjustableOffer?.maxAvailable) {
      return 'error'
    }

    return 'normal'
  }

  /**
   * Budget Input Error Messages
   */
  const handlePrintsErrorMessage = () => {
    if (customIntensity?.impressions > res?.countData?.adjustableOffer?.maxAvailable) {
      return `Merci de ne pas dépasser le nombre d'impression maximum de ${formatNumbers(res?.countData?.adjustableOffer?.maxAvailable)}`
    }

    return ''
  }

  /**
   * First load only, budget is set to defaultValueBudget. Calculate impressions from this default budget
   */
  useEffect(() => {
    // apply boosts when is first load
    if (!isFirstLoad.current && customIntensity) {
      // Default budget. If intensity 1 is > 150 (is available), get the initial budget (without boost externalUrl). Else 150.
      let defaultBudget = EXPERT_360_MINIMUM_BUDGET_ADJUSTABLE_OFFER
      if (intensities[0]?.available) {
        defaultBudget = res?.countData?.externalURL ? decreaseBoostExternalUrl(intensities[0]?.budget) : intensities[0]?.budget
      }
      setDefaultValueBudget(defaultBudget)
      // From initial budget, we apply possible boosts before setting budget input value
      let firstValueForBudget = brandContentChecked ? applyBoostPrintOption(defaultBudget) : defaultBudget
      firstValueForBudget = res?.countData?.externalURL ? applyBoostExternalUrl(firstValueForBudget) : firstValueForBudget
      handleBudgetChange(String(firstValueForBudget))
      isFirstLoad.current = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customIntensity])

  return (
    <Expert360CustomIntensityStyled data-testid="customIntensity">
      <Expert360CustomIntensityInputs>
        <Label
          helperText="La proposition minimum tient compte d'une part de présence minimale assurant une certaine visibilité sur les localités choisies"
          label="Budget"
        >
          <Expert360CustomIntensityInputContainer>
            <TextField
              testID="budgetInput"
              iconColor="#2B2B2B"
              value={formatNumbers(customIntensity?.budget)}
              onChange={(event : React.ChangeEvent<HTMLInputElement>) => handleBudgetChange(event.target.value)}
              errorMessage={handleBudgetErrorMessage()}
              state={handleBudgetState()}
            />
            <Expert360CustomIntensitySuffix>€ / mois</Expert360CustomIntensitySuffix>
          </Expert360CustomIntensityInputContainer>
        </Label>
        <Label
          label="Nombre d'impressions"
        >
          <Expert360CustomIntensityInputContainer>
            <TextField
              testID="printInput"
              iconColor="#2B2B2B"
              value={formatNumbers(customIntensity?.impressions)}
              onChange={(event : React.ChangeEvent<HTMLInputElement>) => handlePrintsChange(event.target.value)}
              errorMessage={handlePrintsErrorMessage()}
              state={handlePrintsState()}
            />
            <Expert360CustomIntensitySuffix>impressions / mois</Expert360CustomIntensitySuffix>
          </Expert360CustomIntensityInputContainer>
        </Label>
        <Checkbox
          checked={brandContentChecked}
          label="Brand content"
          size="medium"
          variant="normal"
          onChange={handleBrandCheckbox}
        />
        <Expert360CustomIntensityBrandContentCheckbox>1 contenu par an</Expert360CustomIntensityBrandContentCheckbox>
      </Expert360CustomIntensityInputs>
      <Expert360CustomIntensityEstimation>
        <h1>Résultat estimé</h1>
        <hr />
        <Expert360CustomIntensityEstimationHeader>
          <Expert360CustomIntensityEstimationBudget>{`${formatNumbers(customIntensity?.budget)} €`}</Expert360CustomIntensityEstimationBudget>
          <Expert360CustomIntensityEstimationDescription>mensuel</Expert360CustomIntensityEstimationDescription>
        </Expert360CustomIntensityEstimationHeader>
        <Expert360CustomIntensityEstimationInfo>
          <li>
            <Expert360CustomIntensityEstimationInfoIcon icon={CheckBold} color="red" />
            <div>
              <span><b>{`${formatNumbers(customIntensity?.impressions)} impressions`}</b> en Display</span>
              <p>{`Soit une part de présence moyenne de ${customIntensity?.shareOfVoice}`}/10</p>
            </div>
          </li>
          <li>
            <Expert360CustomIntensityEstimationInfoIcon icon={CheckBold} color="red" />
            <span><b>{`${formatNumbers(customIntensity?.impressions)} impressions`}</b> en Retargeting</span>
          </li>
          {brandContentChecked ? (
            <li>
              <Expert360CustomIntensityEstimationInfoIcon icon={CheckBold} color="red" />
              <span><b>1 contenu</b> Brand Content</span>
            </li>
          ) : null}
        </Expert360CustomIntensityEstimationInfo>
      </Expert360CustomIntensityEstimation>
    </Expert360CustomIntensityStyled>
  )
}

const Expert360CustomIntensityStyled = styled.div`
  display: flex;
  width: 100%;
`

const Expert360CustomIntensityInputs = styled.div`
  flex: 0 0 calc(55% - 2em);
  width: calc(55% - 2em);
  margin-right: 2em;
`

const Expert360CustomIntensityBrandContentCheckbox = styled.small`
  margin-left: 1.7rem;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

const Expert360CustomIntensityInputContainer = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  padding-bottom: 1rem;
`

const Expert360CustomIntensitySuffix = styled.span`
  position: absolute;
  top: 0.6rem;
  right: 0.8rem;
`

const Expert360CustomIntensityEstimation = styled.div`
  flex: 0 0 45%;
  width: 45%;
  padding: 1em 1em 1.5em 1em;
  border-radius: 0.4em;
  background-color: ${(props) => props.theme.global.colorPalette.pastelYellow};

  h1 {
    margin-left: 0.4em;
  }

  hr {
    border: ${(props) => `0.01em solid ${props.theme.global.colorPalette.golden}`};
    margin-left: 0.4em;
  }
`

const Expert360CustomIntensityEstimationBudget = styled.div`
  font-weight: bold;
  font-size: 1.5em;
`

const Expert360CustomIntensityEstimationDescription = styled.span`
  font-size: 1em;
  margin-bottom: 1em;
`

const Expert360CustomIntensityEstimationHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.4em;
`

const Expert360CustomIntensityEstimationInfo = styled.ul`
  li {
    display: flex;
    font-weight: 400;
    color: ${(props) => props.theme.global.colorPalette.black};
    margin-bottom: 0.5em;
    justify-content: flex-start;
  }

  span {
    font-size: 1em;
    width: 100%;
  }
`

const Expert360CustomIntensityEstimationInfoIcon = styled(Icon)`
  width: 2em;
  height: 1em;
  margin-top: 0.1em;
`

export default Expert360CustomIntensity
