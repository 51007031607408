import React from 'react'
import styled from 'styled-components'
import { Icon } from '@ui-library/core'

import { PRODUCTS_BROADCAST_MEDIUM } from '../../utils/variables'
import { respondTo } from '../../theme/mixins/respondTo'

import { BudgetInformationType, LabelValueType, MoreCriteriaInformationType, TableInformationType } from '../../types/ResumeCard.type'

import Loader from '../Loader/Loader'
import SummaryLocations from './SummaryLocations/SummaryLocations'
import SummaryMoreCriteria from './SummaryMoreCriteria/SummaryMoreCriteria'

import { ForecastResult } from '../../utils/myAdfactoryApi/swaggerApi'
import { productNamesType } from '../../types/productNames.types'

type ResumeCardType = {
  testId: string,
  loading: string,
  broadcastMediums: string[] | LabelValueType[],
  budgetInformation: BudgetInformationType,
  productName: productNamesType,
  tableInformation: TableInformationType,
  locations: ForecastResult[] | undefined,
  shouldDisplayLocations?: boolean,
  isAllFrance?: boolean,
  isProposal?: boolean,
  moreCriteriaInformation?: MoreCriteriaInformationType,
}

const ResumeCard = ({
  testId,
  loading,
  broadcastMediums,
  budgetInformation,
  productName,
  tableInformation,
  locations,
  shouldDisplayLocations = true,
  isAllFrance = false,
  isProposal = false,
  moreCriteriaInformation,
}: ResumeCardType): React.ReactElement => (
  <ContentSummary data-testid={testId}>
    <BroadcastMediumLogos>
      {broadcastMediums?.map((elem: any, key: number) => {
        const elemFormatted = elem.value === undefined ? elem : elem.value
        return (
          <Medium
            // eslint-disable-next-line react/no-array-index-key
            key={key}
            style={{
              backgroundImage: `url(${PRODUCTS_BROADCAST_MEDIUM.find((element: any) => element.value === elemFormatted.toUpperCase().replace(' ', ''))?.logo})`,
            }}
          />
        )
      })}
    </BroadcastMediumLogos>
    <>
      <BudgetInformation>
        {loading === 'pending'
          ? (
            <LoadingBarWrapper>
              <Loader maxWidth={50} height={15} style={{ marginBottom: '5px' }} />
              <Loader maxWidth={80} height={15} />
            </LoadingBarWrapper>
          )
          : (
            <>
              <Budget>
                {budgetInformation.totalBudget}
                €
              </Budget>
              {budgetInformation.complementary && (
                <Complementary>
                  {budgetInformation.complementary}
                </Complementary>
              )}
            </>
          )}
      </BudgetInformation>
      <ProductName>
        {productName}
      </ProductName>
      <TableInformation
        style={{ gridTemplateColumns: `repeat(${tableInformation.length}, 1fr)` }}
      >
        {tableInformation.map((element: any, index: number) => (
          <div
            className={`child${index + 1}`}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <TitleBloc>
              <div className="icon">
                <Icon icon={element.icon} size="100%" />
              </div>
              <div className="title">{element.title}</div>
            </TitleBloc>
            {element.title === 'Offres' && loading === 'pending'
              ? (
                <LoadingBarWrapper>
                  <Loader maxWidth={120} height={20} />
                </LoadingBarWrapper>
              )
              : (
                <div className="description">
                  {element.description}
                </div>
              )}
          </div>
        ))}
        {moreCriteriaInformation && (
          <SummaryMoreCriteria
            moreCriteriaInformation={moreCriteriaInformation}
          />
        )}
      </TableInformation>
      {shouldDisplayLocations && (
        <SummaryLocations
          isAllFrance={isAllFrance}
          isProposal={isProposal}
          loading={loading}
          locations={locations}
          productName={productName}
        />
      )}
    </>
  </ContentSummary>
)

const ContentSummary = styled.div`
  position: relative;
  padding: 1.1em 1.375em;
  border-radius: 0.3em;
  width: 50%;
  background: ${(props) => props.theme.global.colorPalette.white};
  box-shadow: 0 0.1em 0.5em 0 rgba(0, 0, 0, 0.16);
  top: 3em;
`
const LoadingBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 3.4em;
  width: 150px;
`

const BroadcastMediumLogos = styled.div`
  display: flex;
  position: absolute;
  top: -1.5em;
`

const Medium = styled.div`
  &:first-child {
    margin-left: 0;
  }
  background-size: 90%;
  margin-left: 1em;
  background-color: ${(props) => props.theme.global.colorPalette.white};
  background-repeat: no-repeat;
  background-position: center;
  height: 3em;
  width: 3em;
  border-radius: 0.3em;
  box-shadow: 0 0.125em 0.5em 0 rgba(0, 0, 0, 0.16);

  ${respondTo.xlg`
    width: 4.5em;
    height: 4.5em;
  `}
`

const BudgetInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 3.4em;
`
const Budget = styled.div`
  font-size: 1.125em;
  font-family: ${(props) => props.theme.global.fontFamily[0]};
  font-weight: 700;
  color: ${(props) => props.theme.global.colorPalette.black};
`

const Complementary = styled.div`
  font-size: 0.75em;
  white-space: pre-line;
  text-align: right;
  font-family: ${(props) => props.theme.global.fontFamily[0]};
  color: ${(props) => props.theme.global.colorPalette.black};
`

const ProductName = styled.div`
  margin-top: 0.3em;
  font-family: ${(props) => props.theme.global.fontFamily[0]};
  font-weight: 700;
  color: ${(props) => props.theme.global.colorPalette.black};
  font-size: 1.5em;
`

const TableInformation = styled.div`
  margin-top: 1.5em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(4.25em, auto);
  border-bottom: 1px solid ${(props) => props.theme.global.myAdFactory.customColors.greyTable};
  border-left: 1px solid ${(props) => props.theme.global.myAdFactory.customColors.greyTable};
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;

  > div {
    border-top: 1px solid ${(props) => props.theme.global.myAdFactory.customColors.greyTable};
    border-right: 1px solid ${(props) => props.theme.global.myAdFactory.customColors.greyTable};
    padding: 0.5em;
    padding-left: 1.8em;

    .icon {
      margin-right: 0.5em;
      width: 1.2em;
    }
  }

  .child1 {
    position: relative;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    grid-column: 1;
    grid-row: 1;
    background-color: ${(props) => props.theme.global.colorPalette.white};

    .description {
      width: 85%;
      text-align: left;
      font-size: 0.75em;
      font-family: ${(props) => props.theme.global.fontFamily[0]};
      color: ${(props) => props.theme.global.colorPalette.black};
    }
  }
  .child2 {
    display: flex;
    flex-direction: column;
    grid-column: 2;
    grid-row: 1;
    background-color: ${(props) => props.theme.global.colorPalette.white};

    .description {
      overflow: hidden;
      width: 83%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: left;
      font-size: 0.75em;
      font-family: ${(props) => props.theme.global.fontFamily[0]};
      color: ${(props) => props.theme.global.colorPalette.black};
    }
  }
  .child3 {
    display: flex;
    flex-direction: column;
    grid-column: 3;
    grid-row: 1;
    background-color: ${(props) => props.theme.global.colorPalette.white};

    .description {
      width: 87%;
      text-align: left;
      font-size: 0.75em;
      font-family: ${(props) => props.theme.global.fontFamily[0]};
      color: ${(props) => props.theme.global.colorPalette.black};
    }
  }

  .child4 {
    display: flex;
    flex-direction: column;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    grid-column: 4;
    grid-row: 1;
    background-color: ${(props) => props.theme.global.colorPalette.white};

    .description {
      width: 85%;
      white-space: pre-wrap;
      text-align: left;
      font-size: 0.75em;
      font-family: ${(props) => props.theme.global.fontFamily[0]};
      color: ${(props) => props.theme.global.colorPalette.black};
    }
  }
`
const TitleBloc = styled.div`
  display: flex;
  text-align: center;
  width: 93%;

  .title {
    font-size: 0.875em;
    font-family: ${(props) => props.theme.global.fontFamily[0]};
    font-weight: 700;
    color: ${(props) => props.theme.global.colorPalette.black};
    margin-bottom: 0.1em;

    ${respondTo.xlg`
      font-size: 1em;
      text-align: left;
    `}
  }
`

export default ResumeCard
