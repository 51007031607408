import { createAsyncThunk } from '@reduxjs/toolkit'

import { getMyAdFactoryClass } from '../utils/myAdfactoryApi/getMyAdFactory.utils'

// Asynchronous thunk
// {{base_url}}/places

type PlacesLocalityProps = {
  locality: string
}

export const getPlacesLocality = createAsyncThunk(
  'placesApi/getPlacesLocality',
  async ({ locality }: PlacesLocalityProps, getState) => {
    const response = await getMyAdFactoryClass(getState).places(locality)
    return response
  }
)
