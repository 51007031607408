import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import {
  HouseCardRegular,
  CalculatorRegular,
  UserRegular,
} from '@ui-library/icons'
import { useHistory } from 'react-router-dom'
import { respondTo } from '../../theme/mixins/respondTo'
import Select from '../../components/Select/Select'
import NavigationMenuButton from '../../components/Nav/NavMenuButton'
import { addCurrentMarket, currentMarketSelector, userProfileSelector } from '../../redux/reducers/UserConfig/userConfig.reducer'
import { marketList, ADMIN } from '../../utils/variables'
import type { Market } from '../../utils/myAdfactoryApi/swaggerApi'
import { COMPTAGE_PRODUITS } from '../../routes.constants'
import { SelectElementType } from '../../types/select.type'

type NavProps = {
  menuIsOpen: boolean,
}

type NavWrapperProps = {
  $menuIsOpen: boolean,
}

const NavWrapper = styled.div<NavWrapperProps>`
  position: fixed;
  top: 0;
  left: -16em;
  width: 16em;
  height: 100%;
  background: ${(props) => props.theme.global.colorPalette.darkerSaphir};
  overflow-y: auto;
  transition: margin-left 0.4s;
  
  ${respondTo.sm`
    top: auto;
    left: auto;
  `}

  ${(props: any) => props.$menuIsOpen && css`
    margin-left: 16em;

    ${respondTo.sm`
      margin-left: auto;
    `}
  `}
`

const BarGradient = styled.div`
  height: 0.25em;
  background: linear-gradient(90deg, #e00034, #6f2acf, #09a3e9);
`

const NavCurrentMarket = styled.div`
  position: relative;
  min-height: 2.5em;
  margin: 1.25em 1em 0.5em;
`

const StyledNav = styled.div`
  margin-left: 1em;

  ul {
    width: 100%;
  }
`

const Nav = ({ menuIsOpen }: NavProps): React.ReactElement => {
  const dispatch = useDispatch()
  const history = useHistory()
  const userProfile = useSelector(userProfileSelector)
  const currentMarket = useSelector(currentMarketSelector)

  //-----------------------------------------------------------------------
  // Boolean variable to detect current page
  const counts = window.location.pathname.includes('comptage')
  const home = window.location.pathname === '/accueil'
  const users = window.location.pathname === '/users'

  /**
   * Filter select option with only markets associated to the user
   */
  let options: SelectElementType[] = []
  let defaultValue: SelectElementType[] = []
  if (userProfile?.markets && userProfile.markets.length > 0) {
    options = marketList.filter((obj) => userProfile?.markets?.includes(obj.value as Market))
    defaultValue = marketList.filter((m) => m.value === currentMarket)
  }

  const handleChange = (elem: SelectElementType[]) => {
    dispatch(addCurrentMarket(elem[0].value))
    const currentPathname = window.location.pathname
    // Redirect to products list if current page is count creation or edit when changing current market.
    if (currentPathname.includes('/saisie') || currentPathname.includes('/new') || currentPathname.includes('/edit')) {
      history.push(COMPTAGE_PRODUITS)
    }
  }

  return (
    <NavWrapper $menuIsOpen={menuIsOpen} data-testid="navigationMenu" className={menuIsOpen ? 'menuIsOpen' : ''}>
      <BarGradient />
      <NavCurrentMarket>
        {Object.keys(userProfile).length !== 0 && (
          <Select
            options={options}
            theme="secondary"
            value={defaultValue}
            onChange={handleChange}
            off={options.length <= 1}
          />
        )}
      </NavCurrentMarket>
      <StyledNav data-testid="navSection">
        <ul>
          <NavigationMenuButton
            current={home}
            link="/accueil"
            text="Accueil"
            icon={HouseCardRegular}
          />
          <NavigationMenuButton
            current={counts}
            link="/comptage"
            text="Comptages"
            icon={CalculatorRegular}
          />
          {userProfile.roles && userProfile.roles[0] === ADMIN && (
            <NavigationMenuButton
              current={users}
              link="/users"
              text="Utilisateurs"
              icon={UserRegular}
            />
          )}
        </ul>
      </StyledNav>
    </NavWrapper>
  )
}

export default Nav
