import React from 'react'
import Banner from '@ui-library/core/Banner'

import TitleTable from './TitleTable/TitleTable'
import SiteTable from './SiteTable/SiteTable'
import SiteTableLoad from './SiteTable/SiteTableLoad'
import ResultTable from './ResultTable/ResultTable'

import { useEmailingBase } from '../../../hooks/useEmailingBase'

import { CustomEmailingResp } from '../../../types/emailing.type'
import { BaseComptage, BroadcastMedium } from '../../../utils/myAdfactoryApi/swaggerApi'

type ContactsByBroadcastTableProps = {
  countData: CustomEmailingResp,
  loading?: string,
  logos?: BroadcastMedium[],
  isProposal?: boolean,
}

const ContactsByBroadcastTable = ({ countData = {}, loading = 'idle', logos = [], isProposal = false } : ContactsByBroadcastTableProps): React.ReactElement => {
  const { emailingBase } = useEmailingBase()

  const infoBySite = countData && Object.entries(countData).find((e) => e[0] === 'bySiteGroup')

  let duo : boolean = false
  let showSL: boolean = false
  let showLI: boolean = false

  if (infoBySite?.[1]?.SeLoger) {
    showSL = infoBySite[1].SeLoger.respondedBroadcastMediums && infoBySite[1].SeLoger.respondedBroadcastMediums.length > 0
  }
  if (infoBySite?.[1]?.LogicImmo) {
    showLI = emailingBase === BaseComptage.Optin && infoBySite[1].LogicImmo.respondedBroadcastMediums && infoBySite[1].LogicImmo.respondedBroadcastMediums.length > 0
  }
  if (emailingBase === BaseComptage.Alerte) {
    showLI = false
  }
  if (infoBySite?.[1]?.SeLoger && infoBySite?.[1]?.LogicImmo) {
    duo = true
  }

  return (
    <div className="tableCountsEmail" data-testid="tableCountsEmail">
      <div className="tableCountsTitle">
        <p>Détails de la proposition</p>
      </div>
      <div className="wrapperTable">
        <TitleTable
          loading={loading}
          col1="Contexte de diffusion"
          col2="Disponible"
          col3="Nombre de contacts"
          col4="Budget"
          showSL={showSL}
          showLI={showLI}
        />
        <div className="wrapperSiteTable">
          { loading === 'pending'
          && logos
          && (logos.includes(BroadcastMedium.SELOGER) || logos.includes(BroadcastMedium.SELOGERNEUF))
          && <SiteTableLoad ligne="seloger" sites={logos} /> }

          { loading === 'pending'
          && logos
          && (logos.includes(BroadcastMedium.LOGICIMMO) || logos.includes(BroadcastMedium.LOGICIMMONEUF))
          && <SiteTableLoad ligne="logicimmo" sites={logos} /> }

          {/* Composant chargé avec données API */}
          { showSL && loading !== 'pending' && infoBySite && infoBySite[1] && infoBySite[1].SeLoger && <SiteTable duo={duo} baseComptage={emailingBase} infoBySite={infoBySite[1].SeLoger} isProposal={isProposal} /> }
          { showLI && loading !== 'pending' && infoBySite && infoBySite[1] && infoBySite[1].LogicImmo && <SiteTable duo={duo} baseComptage={emailingBase} infoBySite={infoBySite[1].LogicImmo} isProposal={isProposal} /> }
        </div>
        <ResultTable
          loading={loading}
          totalEmails={countData.totalEmails ?? 0}
          productionFee={countData.productionFee}
          totalBudget={countData.totalBudget ?? 0}
          showSL={showSL}
          showLI={showLI}
        />
        {(!showSL && !showLI) && loading !== 'pending' && !isProposal && (
          <Banner
            href="#"
            kind="standard"
            linkText="Veuillez étendre votre comptage à d'autre(s) ciblage(s) et/ou localité(s)."
            text="Les critères sélectionnés ne disposent pas d'un inventaire suffisant. "
          />
        )}
      </div>
    </div>
  )
}

export default ContactsByBroadcastTable
