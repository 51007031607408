import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LogosBroadcastMediums from '../../../Logo/LogosBroadcastMediums/LogosBroadcastMediums'
import { ErrorNbMails, SiteTableBroadcasts, SiteTableBudget, SiteTableNbMails, SiteTableNbMailsInput, SiteTableNbMailsText, SiteTableRow } from './styled'

import { emailingCanSaveSelector, emailingResSelector, setCanSave, setRes } from '../../../../redux/reducers/Emailing/emailing.reducer'

import { useInput } from '../../../../hooks/useInput'

import { formatNumbers, proposalSelectedEmails, proposalTotalBudget } from '../../../../utils/tsUtils'

import { RespBySiteGroup, BaseComptage, SiteGroup } from '../../../../utils/myAdfactoryApi/swaggerApi'

const calculeBudget = (site:SiteGroup | undefined, baseComptage: BaseComptage, nbMailsValue: number, duo: boolean): number => {
  switch (site) {
    case 'SeLoger':
      if (baseComptage === 'Optin') {
        // Optin
        return nbMailsValue <= 1000 ? 260 : Math.round(260 + 0.29 * (nbMailsValue - 1000))
      }
      if (baseComptage === 'Alerte') {
        // Alerte
        return nbMailsValue <= 1000 ? 210 : Math.round(210 + 0.23 * (nbMailsValue - 1000))
      }
      break
    case 'LogicImmo':
      if (baseComptage === 'Optin') {
        if (nbMailsValue <= 1000) {
          return 0
        }
        return duo ? Math.round(300 + 0.32 * (nbMailsValue - 1000)) : Math.round(0.32 * (nbMailsValue - 1000))
      }
      break
    default:
      return 0
  }
  return 0
}

const SiteTable = ({ infoBySite, baseComptage, duo, isProposal = false }: { infoBySite: RespBySiteGroup, baseComptage: BaseComptage, duo: boolean, isProposal: boolean }): React.ReactElement => {
  const dispatch = useDispatch()

  const { value: nbMailsValue, bindInput } = useInput(infoBySite.selectedEmails)

  const res = useSelector(emailingResSelector)
  const canSave = useSelector(emailingCanSaveSelector)

  const canReactivateSaveButton = useRef<boolean>(false)

  const budget: number = calculeBudget(infoBySite.siteGroup, baseComptage, nbMailsValue, duo)
  const formattedBudget : string = formatNumbers(budget)
  const error = infoBySite.emailsAvailable && nbMailsValue > infoBySite.emailsAvailable

  useEffect(() => {
    if (!canSave && canReactivateSaveButton.current) dispatch(setCanSave(true))
    if (infoBySite.siteGroup) {
      const updatedRes = {
        ...res,
        countData: {
          ...res.countData,
          bySiteGroup: {
            ...res.countData.bySiteGroup,
            [infoBySite.siteGroup]: {
              ...res.countData.bySiteGroup[infoBySite.siteGroup],
              selectedEmails: nbMailsValue,
              selectedBudget: budget,
            },
          },
        },
      }
      const totalUpdatedRes = {
        ...updatedRes,
        countData: {
          ...updatedRes.countData,
          totalBudget: proposalTotalBudget(updatedRes.countData),
          totalEmails: proposalSelectedEmails(updatedRes.countData),
        },
      }
      dispatch(setRes(totalUpdatedRes))
      canReactivateSaveButton.current = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nbMailsValue])

  return (
    <SiteTableRow>
      <SiteTableBroadcasts>
        <LogosBroadcastMediums sites={infoBySite.respondedBroadcastMediums} />
      </SiteTableBroadcasts>
      <div>
        <p>
          {infoBySite.emailsAvailable} contacts
        </p>
        <p>
          <span>Budget max: {infoBySite.budgetMax}€</span>
        </p>
      </div>
      <SiteTableNbMails $isNotEditable={res}>
        {res?.countData && !isProposal ? (
          <SiteTableNbMailsInput $error={Boolean(error)}>
            <input type="text" {...bindInput} />
            <span>Contacts</span>
          </SiteTableNbMailsInput>
        ) : (
          <SiteTableNbMailsText $isNotEditable={res}>
            {infoBySite.selectedEmails}
            <span>contacts</span>
          </SiteTableNbMailsText>
        )}
        {error && (
          <ErrorNbMails className="errorNbMails">
            Max atteint
          </ErrorNbMails>
        )}
      </SiteTableNbMails>
      <SiteTableBudget $isNotEditable={res} $error={Boolean(error)}>
        <p>
          {formattedBudget} €
        </p>
      </SiteTableBudget>
    </SiteTableRow>
  )
}

export default SiteTable
