import React from 'react'
import { useSelector } from 'react-redux'
import { CalendarLined, DesktopLined, FillsRegular } from '@ui-library/icons'

import ResumeCard from '../../../ResumeCard/ResumeCard'

import { vedettePlusDataSelector, vedettePlusLoadingSelector, vedettePlusResSelector } from '../../../../redux/reducers/VedettePlus/vedettePlus.reducer'

import { formatNumbers } from '../../../../utils/tsUtils'
import { formatDate } from '../../../../utils/date'
import { formatZipCodeWithManyLocalityNames } from '../../../../utils/format'

import { ForecastResult } from '../../../../utils/myAdfactoryApi/swaggerApi'
import { BudgetInformationType, TableInformationType } from '../../../../types/ResumeCard.type'
import { productNamesType } from '../../../../types/productNames.types'

type SummaryCountsProps = {
  isProposal?: boolean,
}

const VedettePlusSummaryCounts = ({ isProposal = false }: SummaryCountsProps): React.ReactElement => {
  const data = useSelector(vedettePlusDataSelector)
  const res = useSelector(vedettePlusResSelector)
  const loading = useSelector(vedettePlusLoadingSelector)

  const logos = data?.logos ?? []
  const productName = productNamesType.VedettePlus

  const locations: ForecastResult[] | undefined = formatZipCodeWithManyLocalityNames(data?.locations ?? [], res?.countData?.localityNameByZipCode ?? {})

  const periodicity: string = data && data?.dates?.length > 0 && `Du ${formatDate(new Date(data.dates[0]))} au ${formatDate(new Date(data.dates[1]))}`

  const budgetInformation: BudgetInformationType = {
    totalBudget: formatNumbers(res?.countData.totalBudget ?? 0),
  }

  const formatToDisplay: string = 'Vedette +'

  const tableInformation: TableInformationType = [{
    title: 'Appareils',
    icon: DesktopLined,
    description: 'Desktop, tablette, mobile',
  },
  {
    title: 'Périodicité',
    icon: CalendarLined,
    description: periodicity,
  },
  {
    title: 'Format',
    icon: FillsRegular,
    description: formatToDisplay,
  }]

  return (
    <ResumeCard
      testId="vedettePlusContentSummary"
      loading={loading}
      broadcastMediums={logos}
      budgetInformation={budgetInformation}
      productName={productName}
      tableInformation={tableInformation}
      locations={locations}
      isProposal={isProposal}
    />
  )
}

export default VedettePlusSummaryCounts
