import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Icon } from '@ui-library/core'
import { ChevronLeftBold } from '@ui-library/icons'

import logo from '../../../assets/images/logo_groupe.svg'

import CodeMaf from '../../Input/CodeMaf/CodeMaf'

import { respondTo } from '../../../theme/mixins/respondTo'

type ProposalHeaderProps= {
  clientId?: string | null,
  clientName?: string | null,
  mafId: number,
}

const HeaderProposal = ({ clientId = null, clientName = null, mafId }: ProposalHeaderProps): React.ReactElement => {
  const history = useHistory()

  const backStepConfiguration = () => {
    history.push('/comptage')
  }

  return (
    <HeaderProposalStyled data-testid="headerProposal">
      <BackStepButton type="button" onClick={backStepConfiguration}>
        <Icon icon={ChevronLeftBold} />
        <span>Retour</span>
      </BackStepButton>
      <LogoDiv>
        <img src={logo} alt="Logo" />
      </LogoDiv>
      <HeaderProposalInfos>
        {clientId && <RcClient>{clientId}<RcName>, {clientName}</RcName></RcClient>}
        <div className="wrapperCodeMaf">
          <CodeMaf idMaf={mafId} />
        </div>
      </HeaderProposalInfos>
    </HeaderProposalStyled>
  )
}

const HeaderProposalStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  height: 5em;
  padding: 0 1.25em;
`

const BackStepButton = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  font-size: 0.875em;
  font-weight: 600;
  color: ${(p) => p.theme.global.colorPalette.saphir};

  span {
    margin-left: 0.4em;
  }

  @media print {
    visibility: hidden;
  }
`

const LogoDiv = styled.div`
  position: absolute;
  left: 50%;
  justify-content: center;
  transform: translateX(-50%);
`

const HeaderProposalInfos = styled.div`
  display: flex;
  align-items: center;
`

const RcClient = styled.div`
  margin-right: 0.5em;
`

const RcName = styled.span`
  display: none;

  ${respondTo.lg`
    display: inline;
  `}
`

export default HeaderProposal
