import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox } from '@ui-library/core'

import Loader from '../../../Loader/Loader'

import { expert360InitialIntensitiesSelector, expert360LoadingSelector, expert360ResSelector, setRes } from '../../../../redux/reducers/Expert360/expert360.reducer'

import { applyBoostExternalUrl, decreaseBoostExternalUrl } from '../../../../utils/tsUtils'
import type { ClientIntensity } from '../../../../utils/myAdfactoryApi/swaggerApi'

type Expert360ExternalUrlProps = {
  isProposal?: boolean,
}

export const reinitBudgetOfIntensities = (int: ClientIntensity[], initialIntensities: ClientIntensity) => {
  if (JSON.stringify(int) === JSON.stringify(initialIntensities)) {
    return int
  }

  return (
    int.map((i: any) => {
      const newIntensity = { ...i }
      newIntensity.budget = decreaseBoostExternalUrl(i.budget)
      return newIntensity
    })
  )
}

export const boostBudgetOfIntensities = (int: ClientIntensity[]) => (
  int.map((i: any) => {
    const newIntensity = { ...i }
    newIntensity.budget = applyBoostExternalUrl(i.budget)
    return newIntensity
  })
)

const Expert360ExternalUrl = ({ isProposal = false }: Expert360ExternalUrlProps): React.ReactElement | null => {
  const dispatch = useDispatch()

  // Selectors
  const loading = useSelector(expert360LoadingSelector)
  const res = useSelector(expert360ResSelector)
  const initialIntensities = useSelector(expert360InitialIntensitiesSelector)

  const intensities = res?.countData?.client?.intensity ?? []
  const intensitiesAvailable: ClientIntensity[] = intensities.filter((i: ClientIntensity) => i.available) ?? []
  const enableCheckbox: boolean = intensitiesAvailable?.length > 0

  const firstRender = useRef<boolean>(true)

  const handleChange = () => {
    dispatch(setRes({
      ...res,
      countData: {
        ...res.countData,
        externalURL: !res.countData.externalURL,
      },
    }))
  }

  useEffect(() => {
    if (!firstRender.current) {
      const newIntensities = res?.countData?.externalURL ? boostBudgetOfIntensities(intensities) : reinitBudgetOfIntensities(intensities, initialIntensities)
      dispatch(setRes({
        ...res,
        countData: {
          ...res.countData,
          client: {
            ...res.countData.client,
            intensity: newIntensities,
          },
        },
      }))
    }
    firstRender.current = false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res?.countData?.externalURL])

  if (isProposal && !res?.countData?.externalURL) {
    return null
  }

  return (
    <div data-testid="externalUrlCheckbox">
      {loading === 'pending' ? (
        <small><Loader maxWidth={450} height={18} /></small>
      ) : (
        <small>Par défaut, les encarts publicitaires redirigeront vers la fiche agence du client.</small>
      )}
      {loading === 'pending' ? (
        <small><Loader maxWidth={600} height={25} /></small>
      ) : (
        <Checkbox
          variant="normal"
          size="medium"
          label="Changer la redirection des encarts publicitaires vers une URL personnalisée (budget +30%)"
          checked={res?.countData?.externalURL ?? false}
          onChange={handleChange}
          disabled={!enableCheckbox}
        />
      )}
    </div>
  )
}

export default Expert360ExternalUrl
