export type Breakpoints = {
  [key: string]: string
}

const myAdFactory = {
  breakpoints: {
    xs: '481px',
    sm: '769px',
    md: '1025px',
    lg: '1281px',
    xlg: '1441px',
    xxlg: '2561px',
  },
  navigation: {
    width: '16em',
    hoverColor: '#1b2672',
  },
  zIndex: {
    form: '10',
    dropdown: '20',
    header: '30',
    footer: '30',
    max: '1000',
  },
  customColors: {
    greyTable: '#cccccc',
    orange: '#f5a623',
    green: '#32cd32',
  },
}

export default myAdFactory
