import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import OverlayRcClient from '../../components/Overlay/OverlayRcClient/OverlayRcClient'
import ProductCardContainer from '../../components/ProductCards/ProductCardsContainer/ProductCardContainer'
import ProductCard from '../../components/ProductCards/ProductCard/ProductCard'
import { currentMarketSelector, onProductionSelector } from '../../redux/reducers/UserConfig/userConfig.reducer'
import { productsCounts } from '../../utils/variables'
import type { ProductCard as ProductCardType } from '../../types/ProductsCard'
import { Market } from '../../utils/myAdfactoryApi/swaggerApi'

const ProductCardsPage = (): React.ReactElement => {
  const history = useHistory()
  const currentMarket: Market = useSelector(currentMarketSelector)
  const onProduction = useSelector(onProductionSelector)

  const [column, setColumn] = useState <number>(1)
  const [overlayRcClient, setOverlayRcClient] = useState <boolean>(false)
  const [urlName, setUrlName] = useState <string>('')

  //---------------------------------------------------------------------
  // set a number of column to the grid, according to window width

  const handleResize = useCallback(() => {
    if (window.innerWidth <= 768) {
      setColumn(1)
    } else if (window.innerWidth <= 1200 && window.innerWidth > 768) {
      setColumn(2)
    } else if (window.innerWidth <= 1400 && window.innerWidth > 1200) {
      setColumn(3)
    } else if (window.innerWidth <= 1700 && window.innerWidth > 1400) {
      setColumn(4)
    } else {
      setColumn(5)
    }
  }, [])

  //---------------------------------------------------------------------
  // Add and remove eventListener on window resize

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize, false)
    return function cleanup() {
      window.removeEventListener('resize', handleResize, false)
    }
  }, [handleResize])

  //---------------------------------------------------------------------
  // Close Method fired by overlay

  const closeOverlay = () => {
    setOverlayRcClient(false)
  }

  //---------------------------------------------------------------------
  // Action after clicking on product's button / Redirect or display Overlay

  const callbackRedirect = (url : string) => {
    if (url.includes('/count/expert360')) {
      setUrlName(url)
      setOverlayRcClient(true)
    } else {
      history.push(url)
    }
  }

  const comingSoonVisible = (currentMarket === Market.BureauxEtCommerce) && onProduction

  //---------------------------------------------------------------------
  // Return part
  return (
    <div className="wrapperProductsList">
      {overlayRcClient && (
        <OverlayRcClient
          url={urlName}
          closeOverlay={() => closeOverlay()}
        />
      )}
      {!comingSoonVisible && (
        <div className="contentListProducts">
          <h2>Pour quelle offre souhaitez-vous effectuer un comptage ?</h2>
        </div>
      )}
      <ProductCardContainer column={column}>
        {(currentMarket !== 'BureauxEtCommerce' || !onProduction) && productsCounts[currentMarket]?.map((product: ProductCardType) => (
          <li key={product.title}>
            <ProductCard
              tag={product.tag}
              cardCover={product.cardCover}
              title={product.title}
              description={product.description}
              pdfLink={product.pdfLink}
              available={product.available}
              productName={product.productName}
              url={product.url}
              callbackRedirect={(url : string) => {
                callbackRedirect(url)
              }}
            />
          </li>
        ))}
      </ProductCardContainer>
      {comingSoonVisible && (
        <div className="comingSoon">
          <p>Coming soon</p>
        </div>
      )}
    </div>
  )
}

export default ProductCardsPage
