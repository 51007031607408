import { Button, Checkbox, Icon } from '@ui-library/core'
import { LoaderRegular } from '@ui-library/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import AutocompleteMultiple from '../../../../Autocomplete/AutocompleteMultiple/AutocompleteMultiple'
import DatePicker from '../../../../DatePicker/DatePicker'
import Select from '../../../../Select/Select'

import {
  displayDataSelector,
  displayListToDisplaySelector,
  displayLoadingSelector,
  displayRadiusSelector,
  displayResSelector,
  setRadius,
} from '../../../../../redux/reducers/Display/display.reducer'

import { useProductDisplayHeader } from '../../../../../hooks/products/display/useProductDisplayHeader'

import { respondTo } from '../../../../../theme/mixins/respondTo'
import { PERIODICITY_OPTIONS } from '../../../../../utils/variables'

const DisplayHeaderLuxury = (): React.ReactElement => {
  const dispatch = useDispatch()

  const data = useSelector(displayDataSelector)
  const res = useSelector(displayResSelector)
  const loading = useSelector(displayLoadingSelector)
  const listToDisplay = useSelector(displayListToDisplaySelector)
  const radius = useSelector(displayRadiusSelector)

  const [reactivateEstimateButton, setReactivateEstimateButton] =
    React.useState(false)

  const {
    needToForceUpdateLocalitiesInput,
    showErrorLocalities,
    showErrorDatePicker,
    handleChangeLocations,
    handleChangeDates,
    handleCheckIsAllFrance,
    handlePeriodicityChange,
    handleProcess,
    toggleListBroadcastMedium,
    handleRadiusChange,
  } = useProductDisplayHeader()

  return (
    <>
      <LocalitiesDiv
        onClick={() => toggleListBroadcastMedium(true)}
        data-testid="localitiesSelect"
      >
        <AutocompleteMultiple
          catchmentZone={undefined}
          completingWord="localités"
          data-testid="AutocompleteDisplay"
          disabled={loading === 'pending'}
          error={showErrorLocalities}
          forceUpdate={needToForceUpdateLocalitiesInput}
          label="Localité(s) recherchée(s)"
          maximumItems={50}
          noResultFound="Aucun résultat ne correspond à votre recherche"
          otherSuggestionsText="Un autre lieu ?"
          placeholder="Ajouter une localité (ville, quartier, département...)"
          textError="Sélectionnez au moins une localité"
          titleList="Localité(s)"
          autocompleteCallback={(locations) => handleChangeLocations(locations)}
          persistRadius={(value) => {
            dispatch(setRadius({ radius: value }))
          }}
          onRadiusChange={(_: boolean, isZero?: boolean) => {
            handleRadiusChange(isZero)
            setReactivateEstimateButton(true)
          }}
          radius={radius}
          initDataLocality={listToDisplay ?? data?.locations ?? undefined}
        />
        <Checkbox
          label="Toute la France"
          size="small"
          variant="normal"
          checked={data?.isAllFrance ?? false}
          onChange={handleCheckIsAllFrance}
        />
      </LocalitiesDiv>
      <PeriodicityDiv>
        <Select
          disabled={loading === 'pending'}
          options={PERIODICITY_OPTIONS}
          label="Périodicité"
          placeholder=""
          onChange={handlePeriodicityChange}
          value={PERIODICITY_OPTIONS?.filter(
            (po: any) => po.value === data?.periodicity
          )}
        />
      </PeriodicityDiv>
      {data?.periodicity === 'OneShot' && (
        <DatepickerDiv onClick={() => toggleListBroadcastMedium(true)}>
          <LabelField>Dates</LabelField>
          <DatepickerContainer>
            <DatePicker
              action={(dates: any) => handleChangeDates(dates)}
              defaultValue={data?.dates}
              showErrorDatePicker={showErrorDatePicker}
              modeSelectRange
              resetDate={!data?.dates}
            />
          </DatepickerContainer>
        </DatepickerDiv>
      )}
      <EstimateButton onClick={() => toggleListBroadcastMedium(true)}>
        <Button
          tag="button"
          size="medium"
          kind="primary"
          variant="normal"
          onClick={() => {
            handleProcess()
            setReactivateEstimateButton(false)
          }}
          disabled={
            res?.countData &&
            Object.keys(res.countData).length > 0 &&
            !reactivateEstimateButton
          }
        >
          {loading === 'pending' ? (
            <Icon icon={LoaderRegular} style={{ display: 'flex' }} />
          ) : (
            'Estimer'
          )}
        </Button>
      </EstimateButton>
    </>
  )
}

const LocalitiesDiv = styled.div`
  margin-right: 2%;

  ${respondTo.md`
    width: 31%;
  `}

  ${respondTo.lg`
    width: 36%;
  `}
`

const PeriodicityDiv = styled.div`
  position: relative;
  margin-right: 2%;

  ${respondTo.md`
    width: 33%;
  `}

  ${respondTo.lg`
    width: 22%;
  `}
`

const DatepickerDiv = styled.div`
  position: relative;

  ${respondTo.md`
    width: 31%;
  `}

  ${respondTo.lg`
    width: 25%;
  `}

  input {
    font-weight: 600;
  }

  [data-testid='gsl.uilib.TextField.message'] {
    margin-top: 0.4em;
    font-size: 0.85em;
    font-weight: 600;
  }
`

const DatepickerContainer = styled.div`
  width: 100%;
  height: 39%;
  margin-right: 1.5em;

  p {
    display: inline-block;
    margin-top: 0.25em;
    color: ${(props) => props.theme.global.colorPalette.error};
    line-height: 1.12em;
  }
`

const LabelField = styled.span`
  display: block;
  margin-bottom: 0.3em;
  font-size: 0.85em;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const EstimateButton = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 10.75em;
  bottom: 0;
  transform: translateY(50%);

  button {
    width: 100%;
  }
`

export default DisplayHeaderLuxury
