import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import mapBackground from '../../../../assets/images/map_background.jpg'

import Loader from '../../../Loader/Loader'
import Banner from '../../../Banner/Banner'
import CountInProgress from '../../../Counts/CountInProgress/CountInProgress'
import Expert360SummaryCount from '../Expert360SummaryCount/Expert360SummaryCount'
import Expert360Switch from '../Expert360Switch/Expert360Switch'
import Expert360ExternalUrl from '../Expert360ExternalUrl/Expert360ExternalUrl'
import Expert360DefaultIntensities from '../Expert360DefaultIntensities/Expert360DefaultIntensities'
import Expert360Details from '../Expert360Details/Expert360Details'
import Expert360CustomIntensity from '../Expert360CustomIntensity/Expert360CustomIntensity'
import CountsFooter from '../../../Counts/CountsFooter/CountsFooter'

import { expert360CanSaveSelector, expert360LoadingSelector, expert360MissingLocalitiesSelector, expert360ResSelector } from '../../../../redux/reducers/Expert360/expert360.reducer'

import { useProductExpert360Count } from '../../../../hooks/products/expert360/useProductExpert360Count'

type Expert360CountMissingLocalitiesProps = {
  missingLocalities: string[],
}
const Expert360CountMissingLocalities = ({ missingLocalities }: Expert360CountMissingLocalitiesProps) => (
  <Banner dataTestId="missingLocalitiesError" style={{ marginTop: '4em' }}>
    <>
      <h6>Localités non disponibles</h6>
      <p>Certaine localités sélectionnées ne sont pas disponible pour le moment.</p>
      <p>Pour réaliser le comptage veuillez les retirer :</p>
      {missingLocalities.length > 0 && (
        <ul>
          {missingLocalities?.map((zipcode: string) => (
            <li key={`missing-locality-${zipcode}`}>{zipcode}</li>
          ))}
        </ul>
      )}
    </>
  </Banner>
)

const Expert360Count = (): React.ReactElement => {
  const res = useSelector(expert360ResSelector)
  const loading: string = useSelector(expert360LoadingSelector)
  const missingLocalities = useSelector(expert360MissingLocalitiesSelector)
  const canSave: boolean = useSelector(expert360CanSaveSelector)

  const {
    customIntensity,
    selectedIntensity,
    selectedIntensityBudget,
    isPrintOptionSelected,
    relativeInformations,
    handleChangeOfferType,
    handleBrandContentCommentary,
  } = useProductExpert360Count()

  if (!res) {
    return <CountInProgress dataTestId="expert360Count" />
  }

  return (
    <Expert360CountWrapper data-testid="expert360Count">
      <Expert360Maps style={{ backgroundImage: `url(${mapBackground})` }} />
      <Expert360CountResult>
        <h3>Résumé du comptage</h3>
        <Expert360Summary>
          {res && <Expert360SummaryCount selectedIntensityBudget={selectedIntensityBudget} />}
        </Expert360Summary>
        <Expert360Results>
          <Expert360ChooseIntensity>
            {missingLocalities?.length > 0 && <Expert360CountMissingLocalities missingLocalities={missingLocalities} />}
            {!missingLocalities && (
              <>
                <Expert360ChooseIntensityTitle>Choisissez l'intensité de votre offre</Expert360ChooseIntensityTitle>
                <Expert360Switch selectedIntensityId={res?.countData?.intensity} customIntensity={customIntensity} onChange={(value) => handleChangeOfferType(value)} />
                <Expert360ExternalCheckbox>
                  {loading !== 'pending' ? (
                    <Expert360ExternalUrl />
                  ) : (
                    <Loader maxWidth={500} height={25} />
                  )}
                </Expert360ExternalCheckbox>
                {res?.countData?.intensity !== 4 && (
                  <>
                    <Expert360DefaultIntensities />
                    {res?.countData?.client?.intensity?.length !== 0 && res?.countData?.intensity >= 0 && (
                      <Expert360Details
                        brandContentCommentary={res?.countData?.brandContentCommentary}
                        isPrintOptionSelected={isPrintOptionSelected}
                        relativeInformations={relativeInformations()}
                        selectedIntensity={selectedIntensity}
                        selectedIntensityBudget={selectedIntensityBudget}
                        handleBrandContentCommentary={handleBrandContentCommentary}
                      />
                    )}
                  </>
                )}
                {res?.countData?.intensity === 4 && loading !== 'pending' && <Expert360CustomIntensity customIntensity={customIntensity} />}
              </>
            )}
          </Expert360ChooseIntensity>
        </Expert360Results>
        {!missingLocalities && (
          <CountsFooter
            canSave={canSave}
            loading={loading}
            res={res}
          />
        )}
      </Expert360CountResult>
    </Expert360CountWrapper>
  )
}

const Expert360CountWrapper = styled.div`
  position: relative;
`

const Expert360Maps = styled.div`
  position: absolute;
  left: 0;
  top: 13.125em;
  width: 100%;
  height: 25em;
  background-color: ${(p) => p.theme.global.colorPalette.lighterSaphir};
  background-size: cover;
`

const Expert360CountResult = styled.div`
  position: absolute;
  padding: 8.3em 2.5em 10em 2.5em;
  width: 100%;

  h3 {
    font-size: 2em;
    color: ${(p) => p.theme.global.colorPalette.black};
  }

  h4 {
    font-size: 1.125em;
    max-width: 71.1em;
    margin: auto;
    margin-top: 2.22em;
  }
`

const Expert360Summary = styled.div`
  height: 35em;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`

const Expert360Results = styled.div`
  margin: auto;
  max-width: 80em;
`

const Expert360ChooseIntensity = styled.div`
  margin: auto;
  max-width: 80em;
`

const Expert360ChooseIntensityTitle = styled.p`
  margin-bottom: 1em;
  margin-top: 3em;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.5em;
  color: ${(p) => p.theme.global.colorPalette.black};
`

const Expert360ExternalCheckbox = styled.div`
  margin-bottom: 1.5em;

  small {
    display: block;
    margin-bottom: 1em;
    font-size: .9em;
  }
`

export default Expert360Count
