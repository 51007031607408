/**
 * DATE HELPERS
 */
export const isDateInPast = (date: string): boolean => {
  const inputDate = new Date(date)
  const currentDate = new Date()
  return inputDate < currentDate
}

export const formatDate = (d: Date | undefined) => {
  if (d === undefined) return ''
  const month = `${d.getMonth() + 1}`.padStart(2, '0')
  const year = d.getFullYear()
  const day = d.getDate().toString().padStart(2, '0')
  return `${day}/${month}/${year}`
}
