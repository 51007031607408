import React from 'react'

interface IProductCardContainer {
  children : React.ReactNode,
  column : number
}

const ProductCardContainer = ({ children, column } : IProductCardContainer) => (

  <ul
    className="wrapperCard"
    style={{ gridTemplateColumns: `repeat(${column}, 1fr)` }}
  >
    {children}
  </ul>

)

export default ProductCardContainer
