import React, { useRef, useState } from 'react'
import { Toggle, useClickOutside } from '@ui-library/core'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import HamburgerIcon from '../Icons/Hamburger/Hamburger'
import Logo from '../Logo/Logo'
import { onProductionSelector, setOnProduction, userProfileSelector } from '../../redux/reducers/UserConfig/userConfig.reducer'
import { respondTo } from '../../theme/mixins/respondTo'
import DropdownUserAccount from '../Dropdowns/DropDownUserAccount/DropdownUserAccount'

type HeaderProps = {
  menuIsOpen: boolean,
  clickOnMenu: () => void,
}

type HeaderWrapperProps = {
  $isOpen: boolean,
}

const HeaderWrapper = styled.div<HeaderWrapperProps>`
  position: fixed;
  left: 0;
  z-index: ${(props) => props.theme.global.myAdFactory.zIndex.header};
  display: flex;
  align-items: center;
  width: 100%;
  height: 3em;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.global.colorPalette.white};
  transition: left 0.4s;

  ${respondTo.sm`
    transition: none;
    height: 5em;
  `}

  ${(props: any) => props.$isOpen && css`
    left: ${props.theme.global.myAdFactory.navigation.width};

    ${respondTo.sm`
      left: 0;
    `}
  `}
`

const HeaderMenuIcon = styled.div`
  position: absolute;
  left: 1em;

  ${respondTo.sm`
    display: none;
  `}
`
const HeaderLogo = styled.div`
  width: 50%;
  height: 100%;
  margin: auto;

  img {
    marging: 0 auto;
    padding: .5em 0 .3em;
    height: 100%;
  }

  ${respondTo.sm`
    margin: 0; 
    width: ${(props) => props.theme.global.myAdFactory.navigation.width};

    img {
      max-width: 9.5em;
      display: block;
      margin: auto;
    }
  `}
`

const HeaderUserAccount = styled.div`
  position: absolute;
  right: 1em;

  ${respondTo.sm`
    right: 2.5em;
  `}
`

const HeaderToggleProd = styled.div`
  display: none;

  ${respondTo.sm`
    display: flex;
    align-items: center;

    span {
      display: inline-block;
      margin-right: .5em;
    }
  `} 
`

const Header = ({ menuIsOpen, clickOnMenu }: HeaderProps): React.ReactElement => {
  const dispatch = useDispatch()
  const userProfile = useSelector(userProfileSelector)
  const onProduction = useSelector(onProductionSelector)

  const [accountOpen, setAccountOpen] = useState(false)
  const dropDownUserRef = useRef(null)

  // Toogle simulation production
  const hostname = window?.location?.hostname
  const showToggle = hostname !== 'myadfactory.seloger.com'

  //-----------------------------------------------------------------------
  // function to close the drop-down menu outside of it according to a state
  const closeDropDownUserAccount = () => {
    setAccountOpen(false)
  }

  useClickOutside([dropDownUserRef], closeDropDownUserAccount)

  return (
    <HeaderWrapper data-testid="header" $isOpen={menuIsOpen}>
      <HeaderMenuIcon>
        <HamburgerIcon
          dataTestid="headerMenuMobileButton"
          isOpen={menuIsOpen}
          onClick={() => clickOnMenu()}
        />
      </HeaderMenuIcon>
      <HeaderLogo>
        <Logo to="/accueil" />
      </HeaderLogo>
      {showToggle && (
        <HeaderToggleProd data-testid="headerToggle">
          <span>Production</span>
          <Toggle size="small" checked={onProduction} onChange={(value) => dispatch(setOnProduction(value))} />
        </HeaderToggleProd>
      )}
      <HeaderUserAccount ref={dropDownUserRef}>
        <DropdownUserAccount
          firstname={userProfile.firstName ?? ''}
          isOpen={accountOpen}
          onClickEvent={() => { setAccountOpen(!accountOpen) }}
        />
      </HeaderUserAccount>
    </HeaderWrapper>
  )
}

export default Header
