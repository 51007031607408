import {
  PRODUCTS_BROADCAST_MEDIUM,
  EXPERT_360_BOOST_PERCENT_FOR_EXTERNAL_URL_OPTION,
  EXPERT_360_MINIMUM_BUDGET_TO_OFFER_PRINT_OPTION,
  EXPERT_360_PRICE_PRINT_OPTION,
  marketList,
} from './variables'
import { BroadcastMedium as BroadcastMediumType } from '../types/BroadCastMedium.type'
import { ClientIntensity, EmailingResp } from './myAdfactoryApi/swaggerApi'
import { SelectElementType } from '../types/select.type'

export const getLogoBroadcastMedium: (bcm: string) => string | undefined = (bcm:string) => {
  const svgCard = PRODUCTS_BROADCAST_MEDIUM.find((medium: BroadcastMediumType) => medium.label.toUpperCase().replace(' ', '') === bcm.replace(' ', ''))?.logo
  return svgCard
}

export const proposalTotalBudget = (proposalData : EmailingResp): number => {
  const sites = Object.keys(proposalData?.bySiteGroup ?? {})
  const budgetBySites = sites.reduce((acc, item) => acc + (proposalData.bySiteGroup?.[item]?.selectedBudget ?? 0), 0)
  const totalBudget = (proposalData?.productionFee ?? 0) + budgetBySites
  return totalBudget
}

export const proposalSelectedEmails = (proposalData : EmailingResp): number => {
  const sites = Object.keys(proposalData?.bySiteGroup ?? {})
  const totalEmailsBySites = sites.reduce((acc, item) => acc + (proposalData.bySiteGroup?.[item]?.selectedEmails ?? 0), 0)
  return totalEmailsBySites
}

/**
 * EXPERT360 HELPERS
 */
export const isPrintOptionForIntensitySelected = (intensity: ClientIntensity | null = null, force: boolean = false): boolean => {
  // Fixed Intensities cases
  if (!force && intensity && intensity.level < 3 && intensity.budget > EXPERT_360_MINIMUM_BUDGET_TO_OFFER_PRINT_OPTION) {
    return true
  }
  // Custom Intensity case, prints === 1 if checkbox value is true
  if (!force && intensity && intensity.level === 3 && intensity.prints === 1) {
    return true
  }
  return force
}

export const decreaseBoostExternalUrl = (budget: number) => Math.round(budget / (EXPERT_360_BOOST_PERCENT_FOR_EXTERNAL_URL_OPTION + 1))
export const applyBoostExternalUrl = (budget: number) => Math.round(budget + (budget * EXPERT_360_BOOST_PERCENT_FOR_EXTERNAL_URL_OPTION))

export const decreaseBoostPrintOption = (budget: number) => budget - EXPERT_360_PRICE_PRINT_OPTION
export const applyBoostPrintOption = (budget: number) => budget + EXPERT_360_PRICE_PRINT_OPTION

export const formatNumbers = (x: number | undefined) => {
  if (x !== undefined) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }
  return ''
}

export const capitalize = (word: string | undefined) => {
  if (word === undefined) return ''
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const buildStringBroadCastMedium = (tabStrings: string[], limit: number = 12) => {
  let acc = ''
  let i = 0
  let nbChar = limit
  while (nbChar > 0 && i <= tabStrings.length - 1) {
    if (i === 0) acc = `${tabStrings[i].slice(0, nbChar)}`
    else {
      acc = `${acc},${tabStrings[i].slice(0, nbChar)}`
    }
    nbChar -= tabStrings[i].length
    i++
  }
  if (tabStrings.length - i === 0) {
    acc += '...'
  } else {
    acc += `...+${tabStrings.length - i}`
  }
  return acc
}

const marketListLabels = marketList.map((m) => m.label)
export const customSortMarketList = (a: SelectElementType, b: SelectElementType) => {
  const indexA = marketListLabels.indexOf(a.label)
  const indexB = marketListLabels.indexOf(b.label)

  if (indexA === -1) {
    return 1
  }
  if (indexB === -1) {
    return -1
  }
  return indexA - indexB
}
