import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { userProfileSelector } from '../../redux/reducers/UserConfig/userConfig.reducer'
import OverlayFirstConnection from '../../components/Overlay/OverlayFirstConnection/OverlayFirstConnection'
import Header from '../../components/Header/Header'
import Nav from '../Nav/Nav'
import { respondTo } from '../../theme/mixins/respondTo'

type PrivateLayoutProps = {
  children: React.ReactElement,
}

type PageContentProps = {
  $menuOpen: boolean,
  theme: any,
}

const PageWrapperContent = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  margin-top: 3em;
  height: calc(100% - 3em);

  ${respondTo.sm`
    margin-top: 5em;
    height: calc(100% - 5em);
  `}
`

const PageContent: any = styled.div`
  position: absolute;
  left: ${(props: PageContentProps) => {
  if (props.$menuOpen) return props.theme.global.myAdFactory.navigation.width
  return 0
}};
  margin-left: 0;
  flex-grow: 1;
  width: 100%;
  transition: left 0.4s;

  ${respondTo.sm`
    margin-left: ${(props) => props.theme.global.myAdFactory.navigation.width};
    width: calc(100% - ${(props) => props.theme.global.myAdFactory.navigation.width});
    transition: none;
  `}

  ${(props: any) => props.$menuOpen && css`
    ${respondTo.sm`
      margin-left: 0;
    `}
  `}
`

const DefaultLayout = ({ children }: PrivateLayoutProps): React.ReactElement => {
  const userProfile = useSelector(userProfileSelector)
  const [menuOpen, setMenuOpen] = useState(false)

  const clickOnMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <>
      {userProfile.typeProfil === null && <OverlayFirstConnection />}
      <div data-testid="defaultLayout">
        <Header clickOnMenu={clickOnMenu} menuIsOpen={menuOpen} />
        <PageWrapperContent>
          <Nav menuIsOpen={menuOpen} />
          <PageContent $menuOpen={menuOpen}>
            {children}
          </PageContent>
        </PageWrapperContent>
      </div>
    </>
  )
}

export default DefaultLayout
