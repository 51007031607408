import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  emailingBaseSelector,
  emailingDataSelector,
  emailingLoadingSelector,
  emailingRadiusSelector,
  setData,
} from '../../../redux/reducers/Emailing/emailing.reducer'
import { currentMarketSelector } from '../../../redux/reducers/UserConfig/userConfig.reducer'

import { useControlDatePicker } from '../../useControlDatePicker'
import { useMode } from '../../useMode'

import { processCountAsync } from '../../../services/emailingApi'
import { ModesType } from '../../../types/Modes.type'
import { SelectElementType } from '../../../types/select.type'
import { TargetingTypes } from '../../../types/targetings.type'
import { formatSelectableValues } from '../../../utils/format'
import { PlaceBo } from '../../../utils/myAdfactoryApi/swaggerApi'

type InputValidState = 'normal' | 'error' | 'success'

export const useProductEmailingHeader = () => {
  const dispatch = useDispatch()
  const { mode } = useMode()
  const { controlDateIfEditionMode } = useControlDatePicker()

  const data = useSelector(emailingDataSelector)
  const radius = useSelector(emailingRadiusSelector)
  const emailingBase = useSelector(emailingBaseSelector)
  const loading = useSelector(emailingLoadingSelector)
  const currentMarket = useSelector(currentMarketSelector)

  const [openListBroadcast, setOpenListBroadcast] = useState<boolean>(false)
  const [stateBroadcast, setStateBroadcast] =
    useState<InputValidState>('normal')
  const [showErrorDatePicker, setShowErrorDatePicker] = useState<boolean>(false)
  const [showErrorTargetings, setShowErrorTargetings] = useState<boolean>(false)
  const [showErrorLocalities, setShowErrorLocalities] = useState<boolean>(false)

  const projectOptions = formatSelectableValues(data?.targetings, 'label').map(
    (sv) => sv
  )

  const projectValues =
    data?.targetings &&
    Object.entries(data.targetings)
      .filter((t: any) => t[1].selected)
      .map((p: any) => {
        return {
          label: p?.[1]?.label,
          value: p?.[1]?.value,
          disabled: p?.[1]?.disabled,
        }
      })

  const getSelectedTargetings = () => {
    const dataTargetingsArray =
      data?.targetings && Object.entries(data.targetings)
    const filtered =
      dataTargetingsArray?.filter((t: any) => t?.[1]?.selected) ?? []
    return Object.fromEntries(filtered)
  }

  const handleChangeTargetings = (elems: SelectElementType[]) => {
    const removeDuplicatesInElems = () => {
      const uniqueElemsStringified: any = []
      elems.forEach((e: SelectElementType) => {
        const stringified = JSON.stringify(e)
        if (uniqueElemsStringified.indexOf(stringified) === -1) {
          uniqueElemsStringified.push(stringified)
        } else {
          const indexToRemove = uniqueElemsStringified.indexOf(stringified)
          uniqueElemsStringified.splice(indexToRemove, 1)
        }
      })
      return uniqueElemsStringified.map((e: any) => JSON.parse(e))
    }
    const resetSelectedValuesInTargetingsArray = () => {
      const updatedTargetingsArray =
        updatedTargetings &&
        Object.entries(updatedTargetings).map((t: any) => [
          t[0],
          {
            ...t[1],
            selected: false,
          },
        ])
      return Object.fromEntries(updatedTargetingsArray)
    }

    const uniqueElems = removeDuplicatesInElems()
    let updatedTargetings = { ...data?.targetings }
    updatedTargetings = resetSelectedValuesInTargetingsArray()

    uniqueElems.forEach((e: any) => {
      updatedTargetings[e.value] = {
        ...updatedTargetings[e.value],
        selected: true,
      }
    })

    dispatch(
      setData({
        ...data,
        targetings: updatedTargetings,
      })
    )
    setShowErrorTargetings(false)
    setStateBroadcast('normal')
  }

  const handleBroadcastMediumClick = (newData: TargetingTypes) => {
    const updatedData = {
      ...data,
      targetings: {
        ...data.targetings,
        ...newData,
      },
    }
    setStateBroadcast('normal')
    dispatch(setData(updatedData))
  }

  const handleChangeLocations = (locations: PlaceBo[]) => {
    const updatedData = { ...data, locations }
    setShowErrorLocalities(false)
    dispatch(setData(updatedData))
  }

  const handleChangeDate = (date: Date | null) => {
    const updatedData = { ...data, date }
    if (!date) delete updatedData.date
    if (updatedData && Object.keys(updatedData).length > 0)
      dispatch(setData(updatedData))
    setShowErrorDatePicker(false)
  }

  const handleRadiusChange = (radiusIsZero = false) => {
    const updatedData = { ...data }
    // If radius is 0, we need to remove all localities but the one selected
    // Somehow when the radius is 0, the localities are not updated
    radiusIsZero && (updatedData.locations = [updatedData.locations[0]])
    dispatch(setData(updatedData))
  }

  const handleConfigErrors = () => {
    const errors = {
      broadcasts: false,
      date: false,
      locations: false,
      targetings: false,
    }

    // Config need to have at least one targeting selected
    const targetingsArray = data?.targetings && Object.entries(data.targetings)
    const targetingsSelected =
      targetingsArray?.filter((ta: any) => ta?.[1].selected) ?? []
    if (targetingsSelected.length === 0) errors.targetings = true

    // Config can't have a selected targeting without at least one checked broadcast
    let aSelectedTargetingHasNotCheckedValues = false
    targetingsArray?.forEach((t: any) => {
      if (t?.[1]?.selected && !t?.[1]?.checked?.includes(true)) {
        aSelectedTargetingHasNotCheckedValues = true
      }
    })
    errors.broadcasts = aSelectedTargetingHasNotCheckedValues

    // Config need to have at least one location
    if (!data?.locations || data?.locations?.length === 0)
      errors.locations = true

    // Config need to have a sending date
    if (!data?.date) errors.date = true

    return errors
  }

  const handleErrors = (errors: any) => {
    if (errors.targetings) setShowErrorTargetings(true)
    if (errors.broadcasts) setStateBroadcast('error')
    if (errors.locations) setShowErrorLocalities(true)
    if (errors.date) setShowErrorDatePicker(true)

    if (
      errors.targetings ||
      errors.date ||
      errors.locations ||
      errors.broadcasts
    )
      return true
    return false
  }

  const handleProcess = () => {
    const configErrors = handleConfigErrors()
    const errors = handleErrors(configErrors)
    let controlDate = true

    if (mode === ModesType.Edit)
      controlDate = controlDateIfEditionMode(data?.date)

    // In edit mode, the date is in the past. You must therefore reset the date input and block the processCount
    if (!controlDate) handleChangeDate(null)

    // const format Targetings for api
    const formatTargetings = (targetings: any) => {
      const targetingsArray = Object.entries(targetings)
      const formattedTargetings = targetingsArray
        ?.filter((ta: any) => ta?.[1]?.selected)
        .map((ta: any) => {
          const checkedArray = ta?.[1].checked
          const checkedBroadcastMediums = checkedArray
            ?.map((c: any, index: number) => {
              if (c) return ta?.[1].broadcastMediums?.[index]
              return undefined
            })
            .filter((item: any) => item)

          return {
            distributionType: ta[0],
            broadcastMediums: checkedBroadcastMediums,
          }
        })
      return formattedTargetings
    }

    const productTypes =
      data?.options?.productTypes?.length > 0
        ? data.options.productTypes.map((pt: string) => {
            const updatedPt = pt
            if (updatedPt === 'Appartement') return 'Apartment'
            if (updatedPt === 'Maison') return 'House'
            return updatedPt
          })
        : []

    const price =
      data?.options?.minPrice !== 1 || data?.options?.maxPrice !== 10000000
        ? {
            min: data.options.minPrice,
            max: data.options.maxPrice,
          }
        : undefined

    const numberOfPieces =
      data?.options?.minNbPieces !== 1 || data?.options?.maxNbPieces !== 5
        ? {
            min: data.options.minNbPieces,
            max: data.options.maxNbPieces,
          }
        : undefined

    if (controlDate && !errors) {
      dispatch(
        processCountAsync({
          baseComptage: emailingBase,
          targetings: formatTargetings(data?.targetings) ?? undefined,
          dateSent: data?.date ?? undefined,
          locationZipCode:
            data?.locations?.map((locality: any) => locality.code) ?? undefined,
          market: currentMarket,
          productTypes,
          price,
          numberOfPieces,
          radius,
        })
      )
    }
  }

  const toggleListBroadcastMedium = (forceClose: boolean = false) => {
    if (forceClose) setOpenListBroadcast(false)
    else setOpenListBroadcast(!openListBroadcast)
  }

  return {
    loading,
    openListBroadcast,
    projectOptions,
    projectValues,
    showErrorDatePicker,
    showErrorLocalities,
    showErrorTargetings,
    stateBroadcast,
    getSelectedTargetings,
    handleBroadcastMediumClick,
    handleChangeDate,
    handleChangeLocations,
    handleChangeTargetings,
    handleProcess,
    toggleListBroadcastMedium,
    handleRadiusChange,
  }
}
