import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button } from '@ui-library/core'

import mapBackground from '../../../assets/images/map_background.jpg'
import pdfDisplayImpression from '../../../assets/pdf/pdf-display-impression.pdf'
import pdfSpecsDisplayLuxury from '../../../assets/pdf/pdf-specs-display-luxury.pdf'

import ProposalHeader from '../../../components/Header/HeaderProposal/HeaderProposal'
import DisplaySummaryCount from '../../../components/Products/Display/DisplaySummaryCount/DisplaySummaryCount'
import PrintsByBroadcastTable from '../../../components/Tables/PrintsByBroadcastTable/PrintsByBroadcastTable'
import Logo from '../../../components/Logo/Logo'
import PrintsByFormatTable from '../../../components/Tables/PrintsByFormatTable/PrintsByFormatTable'
import PrintsByFormatTableForLuxury from '../../../components/Tables/PrintsByFormatTableForLuxury/PrintsByFormatTableForLuxury'

import { currentMarketSelector } from '../../../redux/reducers/UserConfig/userConfig.reducer'
import { displayErrorsSelector, displayResSelector } from '../../../redux/reducers/Display/display.reducer'

import { useProductDisplayData } from '../../../hooks/products/display/useProductDisplayData'
import { formatDate } from '../../../utils/date'
import { respondTo } from '../../../theme/mixins/respondTo'
import { DisplayMarketsType } from '../../../types/display.type'

const DisplayProposal = (): React.ReactElement | null => {
  const { id }: { id: string } = useParams()

  const displayErrors: any = useSelector(displayErrorsSelector)
  const res = useSelector(displayResSelector)
  const currentMarket: any = useSelector(currentMarketSelector)

  const pdfDisplay = currentMarket === DisplayMarketsType.Neuf ? pdfDisplayImpression : pdfSpecsDisplayLuxury

  useProductDisplayData(id)

  window.onbeforeprint = () => {
    document.title = `MAF-${res?.mafId}`
  }

  window.onafterprint = () => {
    document.title = 'MyAdFactory - Groupe SeLoger'
  }

  if (res && Object.keys(res).length === 0) return null
  if (res?.countData && Object.keys(res.countData).length === 0) return null

  if (displayErrors) {
    return (
      <div data-testid="proposalDisplayError">
        <ProposalWrapper>
          <ProposalDisplayError>
            <p>Une erreur est survenue</p>
          </ProposalDisplayError>
        </ProposalWrapper>
      </div>
    )
  }

  return (
    <div data-testid="proposalDisplay">
      <ProposalHeader mafId={res?.mafId} />
      <ProposalWrapper>
        <ProposalMap style={{ backgroundImage: `url(${mapBackground})` }} />
        <ProposalContainer>
          <ProposalSummaryBlock>
            <DisplaySummaryCount isProposal />
          </ProposalSummaryBlock>
        </ProposalContainer>
        <ProposalContainer>
          <h1>Bonjour,</h1>
          <p>Vous trouverez ci-dessous le plan média répondant aux besoins évoqués ensemble. Nous sommes à votre écoute pour toute question complémentaire.</p>
          <ProposalResult>
            {res?.countData?.saveBy === 'context' && currentMarket !== DisplayMarketsType.Luxe && (
              <PrintsByBroadcastTable
                forecastResult={res?.countData?.forecasts ?? []}
                isProposal
                loading="idle"
                totalBudget={res?.countData?.totalBudget ?? 0}
                totalOffer={res?.countData?.totalImpPurchased ?? 0}
              />
            )}
            {res?.countData?.saveBy === 'format' && currentMarket !== DisplayMarketsType.Luxe && (
              <PrintsByFormatTable
                cpms={res?.countData?.cpm ?? undefined}
                currentMarket={currentMarket}
                formatsResult={res?.countData?.forecastSplitByPosition ?? []}
                isProposal
                loading="idle"
                totalBudget={res?.countData?.totalBudget ?? 0}
                totalOffer={res?.countData?.totalImpPurchased ?? 0}
              />
            )}
            {res?.countData?.saveBy === 'format' && currentMarket === DisplayMarketsType.Luxe && (
              <PrintsByFormatTableForLuxury
                cpms={undefined}
                currentMarket={currentMarket}
                formatsResult={res?.countData?.forecastSplitByPosition ?? []}
                isProposal
                loading="idle"
                totalBudget={res?.countData?.totalBudget ?? 0}
                totalOffer={res?.countData?.totalImpPurchased ?? 0}
              />
            )}
            <ProposalGenerate>
              <p>Générez un PDF de la proposition commerciale pour le partager à votre client. Vous pouvez également télécharger les spécifications techniques des différents formats d'affichage publicitaire.</p>
              <Button
                testID="pdfButton"
                size="medium"
                kind="primary"
                tag="button"
                variant="normal"
                onClick={() => { window.print() }}
              >
                Télécharger le PDF
              </Button>
              {[DisplayMarketsType.Neuf, DisplayMarketsType.Luxe].includes(currentMarket) && (
                <ButtonSpecs
                  testID="specsButton"
                  size="small"
                  kind="tertiary"
                  tag="button"
                  variant="normal"
                  onClick={() => { window.open(pdfDisplay) }}
                >
                  Télécharger les spécifications techniques
                </ButtonSpecs>
              )}
            </ProposalGenerate>
            <ProposalFooter>
              <p>Proposition réalisée le {formatDate(new Date(res?.createdDate))} par</p>
              <span><Logo /></span>
            </ProposalFooter>
          </ProposalResult>
        </ProposalContainer>
      </ProposalWrapper>
    </div>
  )
}

const ProposalWrapper = styled.div`
  position: relative;
`

const ProposalMap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 30em;
  background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
  background-size: cover;
`

const ProposalSummaryBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  height: 35em;
  margin-bottom: 3em;

  @media print {
    margin-bottom: 1em;
  }
`

const ProposalContainer = styled.div`
  padding: 0em 6em 2.5em 6em;

  ${respondTo.lg`
    padding: 0em 10em 2.5em 10em;
  `}

  h1 {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: .5em;
  }

  > p {
    margin-bottom: 4em;
  }

  @media print {
    h1 {
      font-size: 1.4em;
    }

    > p {
      margin-bottom: 2em;
    }
  }
`

const ProposalDisplayError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

const ProposalResult = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`

const ProposalGenerate = styled.div`
  margin-top: 4em;
  text-align: center;

  p {
    font-size: 1.2em;
    margin-bottom: 2em;
  }

  button {
    display: block;
    margin: 0 auto;
    margin-bottom: 2em;
  }

  @media print {
    display: none;
  }
`

const ButtonSpecs = styled(Button)`
  border: ${(props) => `1px solid ${props.theme.global.colorPalette.black}`};
`

const ProposalFooter = styled.div`
  margin-top: 4em;
  text-align: center;
  color: ${(props) => props.theme.global.colorPalette.grey};

  > span {
    display: block;
    margin: 1em auto;
    max-width: 150px;
  }
`

export default DisplayProposal
