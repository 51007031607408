import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logoImg from '../../assets/images/logo_blue.svg'

type LogoProps = {
  to?: string | null,
}

const ImgStyled = styled.img`
  width: 100%;
`

const Logo = ({ to = null }: LogoProps): React.ReactElement => {
  if (!to) {
    return <ImgStyled src={logoImg} alt="Logo MyAdFactory" />
  }

  return (
    <Link to={to}>
      <ImgStyled src={logoImg} alt="Logo MyAdFactory" />
    </Link>
  )
}

export default Logo
