import React from 'react'
import { Tag } from '@ui-library/core'
import styled from 'styled-components'
import { respondTo } from '../../../theme/mixins/respondTo'

type CardInlineProps = {
  additionalButton?: React.ReactElement | null,
  children: React.ReactNode,
  image: string,
  rightTitle: string,
  rightDesc?: string,
  subTitleCard?: string | null,
  tags: string[],
  titleCard: string,
}

const CardInlineWrapper = styled.div`
  display: flex;
  margin: 1em auto 0;
  padding: 1em;
  box-shadow: 0 0.1em 0.5em 0 rgba(0, 0, 0, .15);
  border-radius: .3em;
`

const CardInlineImage = styled.div`
  width: 12em;  
  height: 12em;
  background-size: cover;
  flex-shrink: 0;
`

const CardInlineContent = styled.div`
  flex-grow: 1;
  margin-left: 1.5em;
`

const CardInlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 2.5em;
`

const CardInlineContentLeft = styled.div`
  width: 60%;
  flex: 0 0 60%;

  ${respondTo.md`
    width: 70%;
    flex: 0 0 70%;
  `}

  ${respondTo.xlg`
    width: 75%;
    flex: 0 0 75%;
  `}
`

const CardInlineContentRight = styled.div`
  width: 40%;
  flex: 0 0 40%;

  ${respondTo.md`
    width: 30%;
    flex: 0 0 30%;
  `}

  ${respondTo.xlg`
    width: 25%;
    flex: 0 0 25%;
  `}
`

const CardInlineTags = styled.div`
  margin-bottom: 1.5em;
`

const CardInlineTitle = styled.div`
  margin-top: 0.65em;
  margin-bottom: 0.5em;

  span {
    font-weight: 400;
  }

  p {
    font-size: 1.5em;
    font-weight: 700;
    color: ${(props) => props.theme.global.colorPalette.black};
  }
`

const CardInlineDesc = styled.div`
  font-size: 1em;

  p {
    margin-bottom: .5em;
  }

  ul {
    padding-left: 1.8em;
    margin-top: .2em;

    li {
      list-style-type: disc;
    }
  }

  #commentBrandContent {
    margin: 1em 0 2em 0;

    textarea {
      width: 100% !important;
    }
  }
`

const CardInlineInfos = styled.div`
  display: flex;
  white-space: pre-wrap;
  text-align: right;
  flex-direction: column;

  > p {
    font-weight: 700;
    color: ${(props) => props.theme.global.colorPalette.saphir};
  }

  > span {
    display: block;
    max-width: 200px;
    margin-top: .5em;
    margin-left: auto;
    font-size: .9em;
    color: ${(props) => props.theme.global.colorPalette.black};
  }
`

const CardInlineAdditionalButton = styled.div`
  margin-top: 1em;
`

const CardInline = ({
  children,
  additionalButton = null,
  image,
  rightTitle,
  rightDesc = undefined,
  subTitleCard = null,
  tags,
  titleCard,
}: CardInlineProps): React.ReactElement => (
  <CardInlineWrapper data-testid="cardInline">
    <CardInlineImage data-testid="cardInlineImage" style={{ backgroundImage: `url(${image})` }} />
    <CardInlineContent>
      <CardInlineContainer>
        <CardInlineContentLeft>
          <CardInlineTags>
            {tags?.map((tag) => (
              <Tag label={tag} kind="inverse" key={tag} className="tags" />
            ))}
          </CardInlineTags>
          <CardInlineTitle>
            <p>{titleCard} {subTitleCard ? <span>{subTitleCard}</span> : ''}</p>
          </CardInlineTitle>
          <CardInlineDesc>
            {children}
          </CardInlineDesc>
        </CardInlineContentLeft>
        <CardInlineContentRight>
          <CardInlineInfos>
            {rightTitle && <p>{rightTitle}</p>}
            {rightDesc && <span>{rightDesc}</span>}
            {additionalButton && <CardInlineAdditionalButton>{additionalButton}</CardInlineAdditionalButton>}
          </CardInlineInfos>
        </CardInlineContentRight>
      </CardInlineContainer>
    </CardInlineContent>
  </CardInlineWrapper>
)

export default CardInline
