import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Tooltip } from '@ui-library/core'
import { formatNumbers } from '../../../utils/tsUtils'
import Loader from '../../Loader/Loader'

type PrintBarProps = {
  available: number,
  loading: boolean,
  maxWidth?: number,
  offer: number,
  sold: number,
  total: number,
}

type ForecastBarWrapperProps = {
  $maxWidth: number,
}

type ForecastBarLegendColorProps = {
  $color: string,
}

const ForecastBarWrapper = styled.div<ForecastBarWrapperProps>`
  position: relative;

  ${(props) => props.$maxWidth && css`
    max-width: ${props.$maxWidth}px;
  `}
`

const ForecastBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 1.5em;
  border-radius: 1.5em;
  overflow: hidden;
`

const ForecastBarSold = styled.div`
  position: relative;
  height: 100%;
  background-color: ${(props) => props.theme.global.colorPalette.lightCandy};
`
const ForecastBarOffer = styled.div`
  position: relative;
  height: 100%;
  background-color: ${(props) => props.theme.global.colorPalette.darkerSaphir};
`

const ForecastBarAvailable = styled.div`
  position: relative;
  height: 100%;
  background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
`

const ForecastBarLegend = styled.div`
  margin-top: 1em;
  display: flex;
  color: ${(props) => props.theme.global.colorPalette.black};
`

const ForecastBarLegendItem = styled.div`
  margin-right: 2em;
  display: flex;
  align-items: center;
`

const ForecastBarLegendColor = styled.div<ForecastBarLegendColorProps>`
  margin-right: .5em;
  height: .5em;
  width: .5em;
  border-radius: .5em;

  ${(props) => props.$color === 'sold' && css`
    background-color: ${props.theme.global.colorPalette.lightCandy};
  `}

  ${(props) => props.$color === 'offer' && css`
    background-color: ${props.theme.global.colorPalette.darkerSaphir};
  `}

  ${(props) => props.$color === 'available' && css`
    background-color: ${props.theme.global.colorPalette.lighterSaphir};
  `}
`

const ForecastBarLegendText = styled.div`
  margin-left: .2em;
  font-size: .9em;
`

const ForecastBar = ({ available, loading, maxWidth = 1000, offer, sold, total }: PrintBarProps): React.ReactElement => {
  // Refs
  const soldRef: React.RefObject<HTMLDivElement> = useRef(null)
  const offerRef: React.RefObject<HTMLDivElement> = useRef(null)
  const availableRef: React.RefObject<HTMLDivElement> = useRef(null)

  // States
  const [infosTooltip, setInfosTooltip] = useState<{ ref: React.RefObject<HTMLDivElement>, value?: number, percentage?: number }>({ ref: soldRef })
  const [tooltipIsDisplayed, setTooltipIsDisplayed] = useState<boolean>(false)

  // Variables
  const soldPercent = (sold * 100) / total
  const offerPercent = (offer * 100) / total
  const availablePercent = ((available - offer) * 100) / total

  // Functions
  const tooltipEnter = (ref: React.RefObject<HTMLDivElement>, value: number, percentage: number) => {
    setInfosTooltip({ ref, value, percentage })
    setTooltipIsDisplayed(true)
  }

  const tooltipLeave = () => {
    setTooltipIsDisplayed(false)
  }

  if (loading) {
    return <Loader maxWidth={maxWidth} height={25} />
  }

  return (
    <>
      <Tooltip
        anchorRef={infosTooltip.ref}
        testID="ForecastBarTooltip"
        orientation="top"
        style={{ display: tooltipIsDisplayed ? 'block' : 'none', pointerEvents: 'none', zIndex: 1000 }}
      >
        <p>
          {formatNumbers(Math.round(infosTooltip.value!))} impressions
          <br />
          Soit {infosTooltip.percentage!}% de couverture
        </p>
      </Tooltip>

      <ForecastBarWrapper $maxWidth={maxWidth} data-testid="forecastBar">
        <ForecastBarContent>
          <ForecastBarSold
            data-testid="ForecastBarSold"
            ref={soldRef}
            style={{ width: `${soldPercent}%` }}
            onMouseEnter={() => { tooltipEnter(soldRef, sold, Math.round(soldPercent)) }}
            onMouseLeave={() => { tooltipLeave() }}
          />
          <ForecastBarOffer
            data-testid="ForecastBarOffer"
            ref={offerRef}
            style={{ width: `${offerPercent}%` }}
            onMouseEnter={() => { tooltipEnter(offerRef, offer, Math.round(offerPercent)) }}
            onMouseLeave={() => { tooltipLeave() }}
          />
          <ForecastBarAvailable
            data-testid="ForecastBarAvailable"
            ref={availableRef}
            style={{ width: `${availablePercent}%` }}
            onMouseEnter={() => { tooltipEnter(availableRef, available - offer, Math.round(availablePercent)) }}
            onMouseLeave={() => { tooltipLeave() }}
          />
        </ForecastBarContent>
      </ForecastBarWrapper>
      <ForecastBarLegend>
        <ForecastBarLegendItem>
          <ForecastBarLegendColor $color="sold" />
          <ForecastBarLegendText>
            Déjà vendues
          </ForecastBarLegendText>
        </ForecastBarLegendItem>
        <ForecastBarLegendItem>
          <ForecastBarLegendColor $color="offer" />
          <ForecastBarLegendText>
            Proposées
          </ForecastBarLegendText>
        </ForecastBarLegendItem>
        <ForecastBarLegendItem>
          <ForecastBarLegendColor $color="available" />
          <ForecastBarLegendText>
            Disponibles
          </ForecastBarLegendText>
        </ForecastBarLegendItem>
      </ForecastBarLegend>
    </>
  )
}

export default ForecastBar
