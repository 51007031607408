import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { expert360ResSelector, setRes } from '../../../redux/reducers/Expert360/expert360.reducer'

import { formatNumbers, isPrintOptionForIntensitySelected } from '../../../utils/tsUtils'

export const useProductExpert360Count = () => {
  const dispatch: any = useDispatch()

  const res = useSelector(expert360ResSelector)

  const intensities = res?.countData?.client?.intensity ?? []
  const intensityId = res?.countData?.intensity ?? 2
  const customIntensity = intensities[3] ?? undefined

  const selectedIntensity = intensities.length > 0 && intensityId > 0 ? intensities[intensityId - 1] : null

  const selectedIntensityBudget = intensities.length > 0 && intensityId > 0
    ? formatNumbers(intensities?.[intensityId - 1]?.budget ?? 0)
    : '0'

  const isPrintOptionSelected: boolean = intensityId > 0 ? isPrintOptionForIntensitySelected(intensities[intensityId - 1]) : false

  const relativeInformations = () => {
    if (isPrintOptionSelected) return '1 contenu / an'
    return 'Aucun contenu'
  }

  const handleChangeOfferType = (value: string) => {
    if (value === 'custom-offers') {
      dispatch(setRes({
        ...res,
        countData: {
          ...res.countData,
          intensity: 4,
        },
      }))
    } else {
      dispatch(setRes({
        ...res,
        countData: {
          ...res.countData,
          intensity: res?.countData?.client?.intensity?.[1]?.available ? 2 : 3,
        },
      }))
    }
  }

  const handleBrandContentCommentary = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setRes({
      ...res,
      countData: {
        ...res.countData,
        brandContentCommentary: event.target.value,
      },
    }))
  }

  return {
    customIntensity,
    selectedIntensity,
    selectedIntensityBudget,
    isPrintOptionSelected,
    relativeInformations,
    handleChangeOfferType,
    handleBrandContentCommentary,
  }
}
