import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import VedettePlusHeader from '../../../components/Products/VedettePlus/VedettePlusHeader/VedettePlusHeader'
import VedettePlusCount from '../../../components/Products/VedettePlus/VedettePlusCount/VedettePlusCount'

import { vedettePlusErrorsSelector } from '../../../redux/reducers/VedettePlus/vedettePlus.reducer'

import { useProductVedettePlusData } from '../../../hooks/products/vedettePlus/useProductVedettePlusData'

type VedettePlusWrapperProps = {
  children: React.ReactNode,
  dataTestId: string,
}

const VedettePlusWrapper = ({ children, dataTestId }: VedettePlusWrapperProps): React.ReactElement => (
  <VedettePlusWrapperStyled data-testid={dataTestId}>
    <VedettePlusHeader />
    {children}
  </VedettePlusWrapperStyled>
)

const VedettePlusErrorComp = (): React.ReactElement => (
  <VedettePlusWrapper dataTestId="vedettePlusError">
    <VedettePlusError>
      <p>Une erreur est survenue</p>
    </VedettePlusError>
  </VedettePlusWrapper>
)

const VedettePlus = (): React.ReactElement => {
  const { id }: { id: string } = useParams()
  const vedettePlusErrors: any = useSelector(vedettePlusErrorsSelector)

  useProductVedettePlusData(id)

  if (vedettePlusErrors) {
    return <VedettePlusErrorComp />
  }

  return (
    <VedettePlusWrapper dataTestId="vedettePlus">
      <VedettePlusCount />
    </VedettePlusWrapper>
  )
}

// Styled component
const VedettePlusWrapperStyled = styled.div`
  padding-top: 14em;
  background-color: ${(props) => props.theme.global.colorPalette.white};
`

const VedettePlusError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

export default VedettePlus
