import React from 'react'

import CardSelect from '../Cards/CardSelect/CardSelect'

import { getLogoBroadcastMedium } from '../../utils/tsUtils'

import { TargetingTypes } from '../../types/targetings.type'
import { BaseComptage } from '../../utils/myAdfactoryApi/swaggerApi'

type BroadcastMediumProps = {
  atLeastOneBaseMsg?: string,
  emailingBase?: BaseComptage,
  info: string,
  targetRequiredText: string,
  targetings: TargetingTypes,
  onClick: (newData: TargetingTypes) => void,
  closeDropdown: () => void,
}

export const handleClickCardSelect = (
  targetings: any,
  market: string,
  index: number,
  onClick: (newData: TargetingTypes) => void
) => {
  const targetingsOriginal = Object.fromEntries(targetings)
  const updatedCheckedArray = targetingsOriginal?.[market]?.checked ? [...targetingsOriginal[market].checked] : []

  // If only 1 broadcast for a label, never check/unchecked the card
  if (updatedCheckedArray.length > 1) {
    updatedCheckedArray[index] = !updatedCheckedArray[index]
  }

  const newTargetings = {
    ...targetingsOriginal,
    [market]: {
      ...targetingsOriginal?.[market],
      checked: updatedCheckedArray
      ,
    },
  }

  onClick(newTargetings)
}

export const mapTargetings = (
  targetings: any,
  emailingBase: BaseComptage | undefined,
  atLeastOneBaseMsg: string,
  onClick: (newData: TargetingTypes) => void
) => (
  <>
    {targetings?.map((targeting: any) => {
      const displayEmailingAtLeastOneBaseMsg = emailingBase && targeting?.[1]?.selected && targeting?.[1]?.checked?.filter((t: any) => t).length === 0
      return (
        <div key={targeting?.[0]}>
          <div className="title">{targeting?.[1]?.label}</div>
          <div className="wrapLogoBroadcastMedium">
            {targeting?.[1]?.broadcastMediums?.map((medium: any, i: number) => {
              const forbiddenToUncheck = targeting?.[1]?.broadcastMediums?.length <= 1

              return (
                <CardSelect
                  data-testid={`imgCardSelect${medium}`}
                  forbiddenToUncheck={forbiddenToUncheck}
                  type="broadcast"
                  key={`cardSelect${medium}`}
                  imgCard={getLogoBroadcastMedium(medium)}
                  isChecked={targeting?.[1]?.checked?.[i]}
                  onClickCard={() => !forbiddenToUncheck && handleClickCardSelect(targetings, targeting?.[0], i, onClick)}
                  label=""
                />
              )
            })}
          </div>
          {displayEmailingAtLeastOneBaseMsg && (
            <div className="errorBroadcastMedium">
              {atLeastOneBaseMsg}
            </div>
          )}
        </div>
      )
    })}
  </>
)

const BroadcastMedium = ({
  atLeastOneBaseMsg,
  emailingBase,
  info,
  targetRequiredText,
  targetings,
  onClick,
  closeDropdown,
} : BroadcastMediumProps): React.ReactElement => {
  const targetingsArray = (targetings && Object.entries(targetings)) ?? []

  return (
    <div className="wrapperListBroadcastMedium">
      <div
        className="backgroundListBroadcastMedium"
        onClick={closeDropdown}
        aria-hidden="true"
      />
      <div data-testid="wrapperBroadcastMediumEmail" className="wrapperBroadcastMediumEmail">
        {targetingsArray.length > 0 && info && <span className="infoBroadcastMedium">{info}</span>}
        {targetingsArray.length === 0 && targetRequiredText && <span className="broadcastMediumEmpty">{targetRequiredText}</span>}
        {targetingsArray && (
          <div className="listBroadcastMedium">
            {mapTargetings(targetingsArray, emailingBase ?? undefined, atLeastOneBaseMsg ?? '', onClick)}
          </div>
        )}
      </div>
    </div>
  )
}

export default BroadcastMedium
