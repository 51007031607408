import { createSlice, current } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { createOrUpdateUser, getAllUsers } from '../../../../services/userApi'
import { UserBo } from '../../../../utils/myAdfactoryApi/swaggerApi'

interface IPageUsersState {
  loading: string
  error: any
  allUsersData: UserBo[]
  totalUsers: number
  rowSelected: {
    x: number
    y: number
    showPopin: boolean
    userData: UserBo[]
  }
  search?: string
}

export const initialState: IPageUsersState = {
  loading: 'idle',
  error: null,
  allUsersData: [],
  totalUsers: 0,
  rowSelected: {
    x: 0,
    y: 0,
    showPopin: false,
    userData: [],
  },
  search: '',
}

// Slice
const pageUsersSlice = createSlice({
  name: 'pageUsers',
  initialState,
  reducers: {
    setUsersSearch: (state, action) => {
      state.search = action.payload.search
    },
    saveSelectedRow(state, action) {
      state.rowSelected.x = action.payload.x
      state.rowSelected.y = action.payload.y
      state.rowSelected.userData = action.payload.user
    },
    setShowPopin(state, action) {
      state.rowSelected.showPopin = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        state.loading = 'idle'
        state.error = null
        state.allUsersData = []
        state.totalUsers = 0
        state.rowSelected = {
          x: 0,
          y: 0,
          showPopin: false,
          userData: [],
        }
      })
      .addCase(createOrUpdateUser.fulfilled, (state, action: any) => {
        const index: number = current(state.allUsersData).findIndex(
          (user: UserBo) => user.id === action.payload.id
        )
        state.allUsersData.splice(index, 1, action.payload)
      })
      .addCase(getAllUsers.pending, (state) => {
        state.loading = 'pending'
        state.allUsersData = []
        state.totalUsers = 0
      })
      .addCase(getAllUsers.fulfilled, (state, action: any) => {
        state.loading = 'idle'
        state.allUsersData = action.payload.userBo
        state.totalUsers = action.payload.total
      })
      .addCase(getAllUsers.rejected, (state, action: any) => {
        state.loading = 'idle'
        state.error = action.error
      })
  },
})

// Actions
export const { setUsersSearch, saveSelectedRow, setShowPopin } =
  pageUsersSlice.actions

// Selectors
type PageUsersSliceName = (typeof pageUsersSlice)['name']
export type PageUsersStateType = Record<PageUsersSliceName, IPageUsersState>

export const usersDataSelector = (state: PageUsersStateType) =>
  state.pageUsers.allUsersData
export const totalUsersSelector = (state: PageUsersStateType) =>
  state.pageUsers.totalUsers
export const loadingApiSelector = (state: PageUsersStateType) =>
  state.pageUsers.loading
export const rowSelectedSelector = (state: PageUsersStateType) =>
  state.pageUsers.rowSelected
export const userSearchSelector = (state: PageUsersStateType) =>
  state.pageUsers.search

// The reducer
export default pageUsersSlice.reducer
