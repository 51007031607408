import React, { useState, VFC } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Popin } from '@ui-library/core'

import { userProfileSelector } from '../../../redux/reducers/UserConfig/userConfig.reducer'

import SummaryLocationsCopyLocalities from './SummaryLocationsCopyLocalities'

import { ILocality } from '../../../types/Locality.type'
import { productNamesType } from '../../../types/productNames.types'

interface ISummaryLocations {
  isAllFrance?: boolean,
  isProposal?: boolean,
  loading: string,
  locations: any,
  productName?: productNamesType,
}

const SummaryLocations : VFC<ISummaryLocations> = ({ locations, isAllFrance, isProposal, loading, productName } : any) => {
  // Selectors
  const { typeProfil } = useSelector(userProfileSelector)

  // State
  const [isPopinOpen, setIsPopinOpen] = useState<boolean>(false)

  const otherLocalities: ILocality[] = []

  const isEmailingProduct: boolean = productName && productName.includes('Emailing')
  const separator = isEmailingProduct ? '|' : ','
  const locationsLength = locations?.length ?? 0

  const localitiesBoxClosed = () => {
    setIsPopinOpen(false)
  }

  // Display only 14 first localities.
  const visibleLocations: ILocality[] = []
  locations?.forEach((location: ILocality, index: number) => {
    if (index >= 14) {
      otherLocalities.push(location)
    } else {
      visibleLocations.push(location)
    }
  })

  return (
    <Locations data-testid="summaryLocations" style={{ marginTop: '1.5em' }}>
      <LocationTitle>
        Localité(s) recherché(es) par la cible
      </LocationTitle>
      <ContentLocation>
        {isAllFrance && <div><span>Toute la France</span></div>}
        {!isAllFrance && visibleLocations?.map((location: any, key: number) => (
        // eslint-disable-next-line react/no-array-index-key
          <div key={key}>
            <span>{`${location.name} ${location.code ? `(${location.code})` : ''}`}</span>
          </div>
        ))}
        {!isAllFrance && locations?.length > 14 && (
          <>
            <p className="otherLocalities" onClick={() => { setIsPopinOpen(true) }} aria-hidden="true">
              {locationsLength > 14 && `+ ${locationsLength - 14} autres localités`}
            </p>
            <Popin
              className="localitiesBox"
              size="medium"
              open={loading === 'pending' ? false : isPopinOpen}
              onClose={localitiesBoxClosed}
            >
              <h3>Autres localité(s) recherché par la cible</h3>
              <div className="localitiesBoxContent">
                {otherLocalities?.map((location: ILocality) => (
                  <div key={location.code}>
                    <span>{`${location.name} (${location.code})`}</span>
                  </div>
                ))}
              </div>
            </Popin>
          </>
        )}
      </ContentLocation>
      {isProposal && typeProfil === 'AdfactoryOperations' && <SummaryLocationsCopyLocalities separator={separator} zipCodes={locations.map((location: any) => location.code)} />}
    </Locations>
  )
}

const Locations = styled.div`
  margin-top: 1.5em;
`
const LocationTitle = styled.div`
  font-size: 1.125em;
  color: ${(props) => props.theme.global.colorPalette.black};
  font-family: ${(props) => props.theme.global.fontFamily[0]};
  font-weight: 700;
`
const ContentLocation = styled.div`
  max-height: 14.8em;
  overflow: auto;
  margin-top: 0.8em;
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
    padding: 0 0.5em 0.1em 0.5em;
    color: ${(props) => props.theme.global.colorPalette.saphir};
    border-radius: 0.3em;
    span {
      margin: 0;
      font-size: 0.875em;
      font-weight: 600;
    }
  }
  .otherLocalities {
    font-size: 0.875em;
    font-family: ${(props) => props.theme.global.fontFamily[0]};;
    font-weight: 600;
    padding: 0.063em 0.5em;
    cursor: pointer;
    color: ${(props) => props.theme.global.colorPalette.saphir};
    text-decoration-line: underline;
  }
`

export default SummaryLocations
