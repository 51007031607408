import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Button, Icon, TextField } from '@ui-library/core'
import {
  ChevronDownLined,
  ChevronUpLined,
  LoaderRegular,
} from '@ui-library/icons'

import AutocompleteMultiple from '../../../Autocomplete/AutocompleteMultiple/AutocompleteMultiple'
import BroadcastMedium from '../../../BroadcastMedium/BroadcastMedium'
import DatePicker from '../../../DatePicker/DatePicker'
import Select from '../../../Select/Select'
import EmailingOptionalCriteria from '../EmailingOptionalCriteria/EmailingOptionalCriteria'

import {
  emailingDataSelector,
  emailingListToDisplaySelector,
  emailingRadiusSelector,
  emailingResSelector,
  setRadius,
} from '../../../../redux/reducers/Emailing/emailing.reducer'
import { currentMarketSelector } from '../../../../redux/reducers/UserConfig/userConfig.reducer'

import { useProductEmailingHeader } from '../../../../hooks/products/emailing/useProductEmailingHeader'
import { useEmailingBase } from '../../../../hooks/useEmailingBase'

import { productNamesType } from '../../../../types/productNames.types'

const EmailingHeader = (): React.ReactElement | null => {
  const dispatch = useDispatch()

  const { emailingBase } = useEmailingBase()

  const currentMarket: any = useSelector(currentMarketSelector)
  const data = useSelector(emailingDataSelector)
  const res = useSelector(emailingResSelector)
  const listToDisplay = useSelector(emailingListToDisplaySelector)
  const radius = useSelector(emailingRadiusSelector)

  const [reactivateEstimateButton, setReactivateEstimateButton] =
    React.useState(false)

  const {
    loading,
    openListBroadcast,
    projectOptions,
    projectValues,
    showErrorDatePicker,
    showErrorLocalities,
    showErrorTargetings,
    stateBroadcast,
    getSelectedTargetings,
    handleBroadcastMediumClick,
    handleChangeDate,
    handleChangeLocations,
    handleChangeTargetings,
    handleProcess,
    toggleListBroadcastMedium,
    handleRadiusChange,
  } = useProductEmailingHeader()

  return (
    <EmailingHeaderWrapper data-testid={`emailingHeader${currentMarket}`}>
      <EmailingHeaderContainer>
        <h2>
          Configuration du comptage{' '}
          <span>
            {emailingBase === 'Optin'
              ? productNamesType.EmailingOptin
              : productNamesType.EmailingAlerte}
          </span>
        </h2>
        <FormWrapper>
          <FormContainer>
            <TargetingsDiv data-testid="targetingsSelect">
              <LabelField>Ciblage(s)</LabelField>
              <Select
                disabled={loading === 'pending'}
                options={projectOptions}
                multiple
                placeholder="Ex: Acheteurs, Inv..."
                onChange={handleChangeTargetings}
                value={projectValues?.length > 0 ? projectValues : null}
                showError={showErrorTargetings}
                msgError="Sélectionnez au moins une valeur"
              />
            </TargetingsDiv>
            <BroadcastDiv data-testid="broadcastSelect">
              <LabelField>Contexte(s) de diffusion</LabelField>
              <span
                onClick={() => toggleListBroadcastMedium()}
                aria-hidden="true"
              >
                <TextField
                  disabled={loading === 'pending'}
                  errorMessage="Sélectionnez au moins une valeur"
                  iconColor="#929292"
                  onChange={() => true}
                  placeholder="Ex: SeLoger,Logic..."
                  state={stateBroadcast}
                  suffix={openListBroadcast ? ChevronUpLined : ChevronDownLined}
                  value={data?.inputTargetingsValue ?? ''}
                />
              </span>
              {openListBroadcast && loading === 'idle' && (
                <BroadcastMedium
                  atLeastOneBaseMsg="Vous devez sélectionner au moins une base de diffusion pour chaque type de cible(s)"
                  emailingBase={emailingBase}
                  info="Sélectionnez un ou plusieurs sites où vous souhaitez afficher le display."
                  targetRequiredText="Veuillez sélectionner au moins un ciblage"
                  closeDropdown={toggleListBroadcastMedium}
                  onClick={handleBroadcastMediumClick}
                  targetings={getSelectedTargetings() ?? null}
                />
              )}
            </BroadcastDiv>
            <LocalitiesDiv
              onClick={() => toggleListBroadcastMedium(true)}
              data-testid="localitiesSelect"
            >
              <AutocompleteMultiple
                maximumItems={50}
                catchmentZone={undefined}
                label="Localité(s) recherchée(s)"
                placeholder="Ajouter une localité (ville, quartier, département...)"
                completingWord="localités"
                titleList="Localité(s)"
                otherSuggestionsText="Un autre lieu ?"
                autocompleteCallback={(locations) =>
                  handleChangeLocations(locations)
                }
                persistRadius={(value) => {
                  dispatch(setRadius({ radius: value }))
                }}
                onRadiusChange={(_: boolean, isZero?: boolean) => {
                  handleRadiusChange(isZero)
                  setReactivateEstimateButton(true)
                }}
                radius={radius}
                error={showErrorLocalities}
                noResultFound="Aucun résultat ne correspond à votre recherche"
                textError="Sélectionnez au moins une localité"
                initDataLocality={listToDisplay ?? data?.locations ?? undefined}
                data-testid="AutocompleteDisplay"
                disabled={loading === 'pending'}
              />
            </LocalitiesDiv>
            <DatepickerDiv onClick={() => toggleListBroadcastMedium(true)}>
              <LabelField>Date d'envoi</LabelField>
              <DatepickerContainer>
                <DatePicker
                  action={(date: any) => handleChangeDate(date)}
                  defaultValue={data?.date}
                  showErrorDatePicker={showErrorDatePicker}
                  modeSelectRange={false}
                  resetDate={!data?.date}
                />
              </DatepickerContainer>
            </DatepickerDiv>
            <OptionalCriteriaDiv
              onClick={() => toggleListBroadcastMedium(true)}
            >
              <OptionalCriteriaContainer>
                <EmailingOptionalCriteria />
              </OptionalCriteriaContainer>
            </OptionalCriteriaDiv>
            <EstimateButton onClick={() => toggleListBroadcastMedium(true)}>
              <Button
                tag="button"
                size="medium"
                kind="primary"
                variant="normal"
                onClick={() => {
                  handleProcess()
                  setReactivateEstimateButton(false)
                }}
                disabled={
                  res?.countData &&
                  Object.keys(res.countData).length > 0 &&
                  !reactivateEstimateButton
                }
              >
                {loading === 'pending' ? (
                  <Icon icon={LoaderRegular} style={{ display: 'flex' }} />
                ) : (
                  'Estimer'
                )}
              </Button>
            </EstimateButton>
          </FormContainer>
        </FormWrapper>
      </EmailingHeaderContainer>
    </EmailingHeaderWrapper>
  )
}

// Styled component
const EmailingHeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: ${(props) => props.theme.global.myAdFactory.zIndex.form};
  height: 10em;
  width: 100%;
  background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
`

const EmailingHeaderContainer = styled.div`
  margin: 2.5em 2.5em 0 2.5em;

  h2 {
    font-size: 1.15em;
    font-weight: 700;

    span {
      font-weight: 400;
    }
  }
`

const FormWrapper = styled.div`
  position: relative;
  height: 9.5em;
  margin-top: 1em;
  width: 100%;
  background-color: ${(props) => props.theme.global.colorPalette.white};
  border-radius: 0.3em;
  padding: 1em 1.5em 2.75em 1.5em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
`

const FormContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
`
const TargetingsDiv = styled.div`
  position: relative;
  width: 18%;
  margin-right: 2%;

  input {
    font-weight: 600;

    &:disabled {
      cursor: wait;
      background: transparent;
    }
  }

  [data-testid='msgError'] {
    margin-top: 0.4em;
    font-size: 0.85em;
    font-weight: 600;
  }
`

const BroadcastDiv = styled.div`
  position: relative;
  width: 20%;
  margin-right: 2%;

  input {
    font-weight: 600;

    &:disabled {
      cursor: wait;
      background: transparent;
    }
  }

  [data-testid='gsl.uilib.TextField.message'] {
    margin-top: 0.4em;
    font-size: 0.85em;
    font-weight: 600;
  }

  [data-testid='gsl.uilib.TextField.input'] {
    &:disabled {
      background: transparent;
      border-color: ${(props) => props.theme.global.colorPalette.greyLight};
      cursor: wait;
    }
  }
`

const LocalitiesDiv = styled.div`
  margin-right: 2%;
  width: 23%;
`

const DatepickerDiv = styled.div`
  position: relative;
  width: 15%;
  margin-right: 2%;

  input {
    font-weight: 600;
  }

  [data-testid='gsl.uilib.TextField.message'] {
    margin-top: 0.4em;
    font-size: 0.85em;
    font-weight: 600;
  }
`

const OptionalCriteriaDiv = styled.div`
  position: relative;
  width: 16%;

  input {
    font-weight: 600;
  }

  [data-testid='gsl.uilib.TextField.message'] {
    margin-top: 0.4em;
    font-size: 0.85em;
    font-weight: 600;
  }
`

const OptionalCriteriaContainer = styled.div`
  margin-top  1.3em;
  height: 2.5em;
`

const DatepickerContainer = styled.div`
  width: 100%;
  height: 39%;
  margin-right: 1.5em;

  p {
    display: inline-block;
    margin-top: 0.25em;
    color: ${(props) => props.theme.global.colorPalette.error};
    line-height: 1.12em;
  }
`

const LabelField = styled.span`
  display: block;
  margin-bottom: 0.3em;
  font-size: 0.85em;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const EstimateButton = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 10.75em;
  bottom: 0;
  transform: translateY(50%);

  button {
    width: 100%;
  }
`

export default EmailingHeader
