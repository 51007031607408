import { Button, Icon } from '@ui-library/core'
import { LoaderRegular } from '@ui-library/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import AutocompleteMultiple from '../../../Autocomplete/AutocompleteMultiple/AutocompleteMultiple'
import DatePicker from '../../../DatePicker/DatePicker'

import {
  setRadius,
  vedettePlusDataSelector,
  vedettePlusListToDisplaySelector,
  vedettePlusLoadingSelector,
  vedettePlusRadiusSelector,
  vedettePlusResSelector,
} from '../../../../redux/reducers/VedettePlus/vedettePlus.reducer'

import { useProductVedettePlusHeader } from '../../../../hooks/products/vedettePlus/useProductVedettePlusHeader'

import { respondTo } from '../../../../theme/mixins/respondTo'
import { productNamesType } from '../../../../types/productNames.types'

const VedettePlusHeader = (): React.ReactElement => {
  const dispatch = useDispatch()

  const data = useSelector(vedettePlusDataSelector)
  const res = useSelector(vedettePlusResSelector)
  const loading = useSelector(vedettePlusLoadingSelector)
  const listToDisplay = useSelector(vedettePlusListToDisplaySelector)
  const radius = useSelector(vedettePlusRadiusSelector)

  const [reactivateEstimateButton, setReactivateEstimateButton] =
    React.useState(false)

  const {
    showErrorLocalities,
    showErrorDatePicker,
    handleChangeLocations,
    handleChangeDates,
    handleRadiusChange,
    handleProcess,
  } = useProductVedettePlusHeader()

  return (
    <VedettePlusHeaderWrapper data-testid="vedettePlusHeader">
      <VedettePlusHeaderContainer>
        <h2>
          Configuration du comptage <span>{productNamesType.VedettePlus}</span>
        </h2>
        <FormWrapper>
          <FormContainer>
            <LocalitiesDiv data-testid="localitiesSelect">
              <AutocompleteMultiple
                maximumItems={50}
                catchmentZone={undefined}
                label="Localité(s) recherchée(s)"
                placeholder="Ajouter une localité (ville, quartier, département...)"
                completingWord="localités"
                titleList="Localité(s)"
                otherSuggestionsText="Un autre lieu ?"
                autocompleteCallback={(locations) =>
                  handleChangeLocations(locations)
                }
                persistRadius={(value: number) =>
                  dispatch(setRadius({ radius: value }))
                }
                onRadiusChange={(_: boolean, isZero?: boolean) => {
                  handleRadiusChange(isZero)
                  setReactivateEstimateButton(true)
                }}
                radius={radius}
                error={showErrorLocalities}
                noResultFound="Aucun résultat ne correspond à votre recherche"
                textError="Sélectionnez au moins une localité"
                initDataLocality={listToDisplay ?? data?.locations ?? undefined}
                data-testid="AutocompleteDisplay"
                disabled={loading === 'pending'}
              />
            </LocalitiesDiv>
            <DatepickerDiv>
              <LabelField>Périodicité</LabelField>
              <DatepickerContainer>
                <DatePicker
                  action={(dates: any) => handleChangeDates(dates)}
                  defaultValue={data?.dates}
                  showErrorDatePicker={showErrorDatePicker}
                  modeSelectRange
                  resetDate={!data?.dates}
                />
              </DatepickerContainer>
            </DatepickerDiv>
            <EstimateButton>
              <Button
                tag="button"
                size="medium"
                kind="primary"
                variant="normal"
                onClick={() => {
                  handleProcess()
                  setReactivateEstimateButton(false)
                }}
                disabled={
                  res?.countData &&
                  Object.keys(res.countData).length > 0 &&
                  !reactivateEstimateButton
                }
              >
                {loading === 'pending' ? (
                  <Icon icon={LoaderRegular} style={{ display: 'flex' }} />
                ) : (
                  'Estimer'
                )}
              </Button>
            </EstimateButton>
          </FormContainer>
        </FormWrapper>
      </VedettePlusHeaderContainer>
    </VedettePlusHeaderWrapper>
  )
}

const VedettePlusHeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: ${(props) => props.theme.global.myAdFactory.zIndex.form};
  height: 10em;
  width: 100%;
  background-color: ${(props) => props.theme.global.colorPalette.lighterSaphir};
`

const VedettePlusHeaderContainer = styled.div`
  margin: 2.5em 2.5em 0 2.5em;

  h2 {
    font-size: 1.15em;
    font-weight: 700;

    span {
      font-weight: 400;
    }
  }
`

const FormWrapper = styled.div`
  position: relative;
  height: 9.5em;
  margin-top: 1em;
  width: 100%;
  background-color: ${(props) => props.theme.global.colorPalette.white};
  border-radius: 0.3em;
  padding: 1em 1.5em 2.75em 1.5em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
`

const FormContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
`

const LocalitiesDiv = styled.div`
  margin-right: 2%;

  ${respondTo.md`
    width: 33%;
  `}

  ${respondTo.lg`
    width: 45%;
  `}
`

const DatepickerDiv = styled.div`
  position: relative;

  ${respondTo.md`
    width: 31%;
  `}

  ${respondTo.lg`
    width: 25%;
  `}

  input {
    font-weight: 600;
  }

  [data-testid='gsl.uilib.TextField.message'] {
    margin-top: 0.4em;
    font-size: 0.85em;
    font-weight: 600;
  }
`

const DatepickerContainer = styled.div`
  width: 100%;
  height: 39%;
  margin-right: 1.5em;

  p {
    display: inline-block;
    margin-top: 0.25em;
    color: ${(props) => props.theme.global.colorPalette.error};
    line-height: 1.12em;
  }
`

const LabelField = styled.span`
  display: block;
  margin-bottom: 0.3em;
  font-size: 0.85em;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const EstimateButton = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 10.75em;
  bottom: 0;
  transform: translateY(50%);

  button {
    width: 100%;
  }
`

export default VedettePlusHeader
