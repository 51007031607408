import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@ui-library/core'
import { AccountRegular, ChevronDownLined } from '@ui-library/icons'
import styled, { css } from 'styled-components'
import { respondTo } from '../../../theme/mixins/respondTo'
import ButtonSignout from '../../Buttons/ButtonSignout/ButtonSignout'

type DropdownUserAccountProps = {
  firstname: string,
  isOpen: boolean,
  onClickEvent: Function,
}

type DropdownUserAccountSelectProps = {
  $accountIsOpen: boolean,
}

type DropdownUserAccountListProps = {
  $accountIsOpen: boolean,
}

const DropdownUserAccountStyled = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

const DropdownUserAccountSelect = styled.div<DropdownUserAccountSelectProps>`
  strong, span {
    display: none;
  }

  ${respondTo.sm`
    border: ${(props) => `1px solid ${props.theme.global.colorPalette.grey}`};
    border-radius: 2.5em;
    padding: .5em 1.3em;
    display: flex;
    align-items: center;

    strong, span {
      display: block;
      margin-left: .5em;
      font-weight: 600;

      svg {
        transition: transform .3s;
      }
    }
  `}

  ${(props: any) => props.$accountIsOpen && css`
    color: ${props.theme.global.colorPalette.saphir};
    border-color: ${props.theme.global.colorPalette.saphir} !important;

    span {
      svg {
        transform: rotate(180deg);
        margin-top  .05em;
        transition: transform .3s;
      }
    }
  `}
`

const DropdownUserAccountList = styled.div<DropdownUserAccountListProps>`
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0em;
  background: ${(props) => props.theme.global.colorPalette.white};
  width: auto;
  box-shadow: 0 0.1em 0.5em 0 rgba(0, 0, 0, 0.16);
  border-radius: 0.3em;
  overflow: hidden;

  ${respondTo.sm`
    width: 100%;
    right: auto;
    margin-top: 0.5em;
  `}

  ul {
    width: 100%;
    user-select: none;

    li {
      height: 2.5em;
      cursor: pointer;

      &:hover {
        background-color: ${(props) => props.theme.global.colorPalette.secondaryLighter};
      }

      span {
        font-weight: 500;
      }

      a, button {
        padding: 0.75em;
        display: block;
        text-decoration: none;
        color: ${(props) => props.theme.global.colorPalette.black};
      }
    }
  }

  ${(props: any) => !props.$accountIsOpen && css`
    display: none;
  `}
`

const DropdownUserAccount = ({ firstname = '', isOpen = false, onClickEvent = () => true }: DropdownUserAccountProps): React.ReactElement => (
  <DropdownUserAccountStyled data-testid="dropdownUserAccount" onClick={() => onClickEvent()}>
    <DropdownUserAccountSelect $accountIsOpen={isOpen}>
      <Icon icon={AccountRegular} size="1.5em" />
      <strong>{firstname}</strong>
      <span><Icon icon={ChevronDownLined} size="1em" style={{ marginLeft: '2em' }} /></span>
    </DropdownUserAccountSelect>
    <DropdownUserAccountList $accountIsOpen={isOpen}>
      <ul>
        <li>
          <Link to="/profile">
            <span>Mon Profil</span>
          </Link>
        </li>
        <li><ButtonSignout /></li>
      </ul>
    </DropdownUserAccountList>
  </DropdownUserAccountStyled>
)

export default DropdownUserAccount
