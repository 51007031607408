import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useControlDatePicker } from '../../useControlDatePicker'
import { useMode } from '../../useMode'

import {
  displayDataSelector,
  displayListToDisplaySelector,
  displayRadiusSelector,
  resetListToDisplay,
  setData,
} from '../../../redux/reducers/Display/display.reducer'
import { currentMarketSelector } from '../../../redux/reducers/UserConfig/userConfig.reducer'

import { processCountForDisplayAsync } from '../../../services/displayApi'

import { ModesType } from '../../../types/Modes.type'
import { DisplayMarketsType } from '../../../types/display.type'
import { SelectElementType } from '../../../types/select.type'
import { TargetingTypes } from '../../../types/targetings.type'
import { PlaceBo } from '../../../utils/myAdfactoryApi/swaggerApi'

type InputValidState = 'normal' | 'error' | 'success'

export const useProductDisplayHeader = () => {
  const { mode } = useMode()
  const dispatch = useDispatch()

  const data = useSelector(displayDataSelector)
  const listToDisplay = useSelector(displayListToDisplaySelector)
  const radius = useSelector(displayRadiusSelector)
  const currentMarket: any = useSelector(currentMarketSelector)

  const { controlDateIfEditionMode } = useControlDatePicker()

  const [openListBroadcast, setOpenListBroadcast] = useState<boolean>(false)
  const [stateBroadcast, setStateBroadcast] =
    useState<InputValidState>('normal')
  const [showErrorLocalities, setShowErrorLocalities] = useState<boolean>(false)
  const [showErrorDatePicker, setShowErrorDatePicker] = useState<boolean>(false)
  const [
    needToForceUpdateLocalitiesInput,
    setNeedToForceUpdateLocalitiesInput,
  ] = useState<boolean>(false)

  const toggleListBroadcastMedium = (forceClose: boolean = false) => {
    if (forceClose) setOpenListBroadcast(false)
    else setOpenListBroadcast(!openListBroadcast)
  }

  const handleBroadcastMediumClick = (newData: TargetingTypes) => {
    const updatedData = { ...data, targetings: newData }
    setStateBroadcast('normal')
    dispatch(setData(updatedData))
  }

  const handleChangeLocations = (locations: PlaceBo[]) => {
    const updatedData = { ...data, locations, isAllFrance: false }
    setShowErrorLocalities(false)
    dispatch(setData(updatedData))
  }

  const handleCheckIsAllFrance = () => {
    const isChecked = data?.isAllFrance ?? false
    const updatedData = { ...data, isAllFrance: !isChecked }
    delete updatedData.locations

    dispatch(setData(updatedData))
    dispatch(resetListToDisplay(listToDisplay))
    setShowErrorLocalities(false)
    setNeedToForceUpdateLocalitiesInput(!needToForceUpdateLocalitiesInput)
  }

  const handlePeriodicityChange = (elem: SelectElementType[]): void => {
    const updatedData = {
      ...data,
      periodicity: elem?.[0]?.value,
    }
    delete updatedData.dates
    dispatch(setData(updatedData))
  }

  const handleRadiusChange = (radiusIsZero = false) => {
    const updatedData = { ...data }
    // If radius is 0, we need to remove all localities but the one selected
    // Somehow when the radius is 0, the localities are not updated
    radiusIsZero && (updatedData.locations = [updatedData.locations[0]])
    dispatch(setData(updatedData))
  }

  const handleChangeDates = (dates: Date[] | null) => {
    const updatedData = { ...data, dates }
    if (!dates || dates?.length === 0) delete updatedData.dates
    if (updatedData && Object.keys(updatedData).length > 0)
      dispatch(setData(updatedData))
    setShowErrorDatePicker(false)
  }

  const handleConfigErrors = () => {
    const errors = {
      dates: false,
      locations: false,
      targetings: false,
    }

    // Config need to have at least one targeting selected
    const targetingsArray = data?.targetings && Object.entries(data.targetings)
    let targetingsNotEmpty = false
    targetingsArray.forEach((ta: any) => {
      if (ta?.[1].checked?.includes(true)) targetingsNotEmpty = true
    })
    errors.targetings = !targetingsNotEmpty

    // Config need to have at least one location
    if (
      (!data?.locations || data?.locations?.length === 0) &&
      !data?.isAllFrance
    )
      errors.locations = true

    // Config need to have some dates in periodicity different to recurrent
    if (
      data?.periodicity !== 'Récurrent' &&
      (!data?.dates || data?.dates?.length === 0)
    )
      errors.dates = true

    return errors
  }

  const handleErrors = (errors: any) => {
    if (errors.targetings) setStateBroadcast('error')
    if (errors.dates) setShowErrorDatePicker(true)
    if (errors.locations) setShowErrorLocalities(true)

    if (errors.targetings || errors.dates || errors.locations) return true
    return false
  }

  const handleProcess = () => {
    const configErrors = handleConfigErrors()
    const errors = handleErrors(configErrors)
    let controlDate = true

    if (mode === ModesType.Edit)
      controlDate = controlDateIfEditionMode(data?.dates?.[0])

    // In edit mode, the date is in the past. You must therefore reset the date input and block the processCount
    if (!controlDate) handleChangeDates(null)

    // const format Targetings for api
    const formatTargetings = (targetings: any) => {
      const targetingsArray = Object.entries(targetings)
      let formattedTargetings = targetingsArray?.map((ta: any) => {
        const checkedArray = ta?.[1].checked
        const checkedBroadcastMediums = checkedArray
          ?.map((c: any, index: number) => {
            if (c) return ta?.[1].broadcastMediums?.[index]
            return undefined
          })
          .filter((item: any) => item)

        return {
          distributionType: ta[0],
          broadcastMediums: checkedBroadcastMediums,
        }
      })

      // CONSTRUIRE
      // We removed unused targetings HouseModels, LandWithHouses and Lands in display.reducer.
      // Need to send these targetings in the ProcessCount anyway.
      if (currentMarket === DisplayMarketsType.Construire) {
        formattedTargetings = [
          ...formattedTargetings,
          {
            distributionType: 'HouseModels',
            broadcastMediums: ['SELOGERCONSTRUIRE'],
          },
          {
            distributionType: 'LandWithHouses',
            broadcastMediums: ['SELOGERCONSTRUIRE'],
          },
          {
            distributionType: 'Lands',
            broadcastMediums: ['SELOGERCONSTRUIRE'],
          },
        ]
      }

      return formattedTargetings
    }

    if (controlDate && !errors) {
      dispatch(
        processCountForDisplayAsync({
          market: currentMarket,
          targetings: formatTargetings(data?.targetings) ?? undefined,
          postalCodes:
            data?.locations?.map((locality: any) => locality.code) ?? undefined,
          isAllFrance: data?.isAllFrance ?? false,
          projectTypes: ['ACHAT'],
          startDate: data?.dates?.[0] ? new Date(data?.dates?.[0]) : undefined,
          endDate: data?.dates?.[1] ? new Date(data?.dates?.[1]) : undefined,
          periodicity: data?.periodicity ?? 'Récurrent',
          radius,
        })
      )
    }
  }

  return {
    needToForceUpdateLocalitiesInput,
    openListBroadcast,
    showErrorLocalities,
    showErrorDatePicker,
    stateBroadcast,
    toggleListBroadcastMedium,
    handleConfigErrors,
    handleBroadcastMediumClick,
    handleChangeLocations,
    handleChangeDates,
    handleCheckIsAllFrance,
    handleErrors,
    handlePeriodicityChange,
    handleProcess,
    handleRadiusChange,
  }
}
