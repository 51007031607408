import React from 'react'
import styled from 'styled-components'

import { Button } from '@ui-library/core'

import { useExports } from '../../hooks/useExports'
import { ExportFilter } from '../../utils/myAdfactoryApi/swaggerApi'

const Exports = (): React.ReactElement => {
  const { loading, err, success, handleClick } = useExports()
  return (
    <ExportContainer>
      <h1>Exports</h1>
      <ExportButtons>
        <Button
          disabled={loading}
          size="medium"
          kind="primary"
          variant="normal"
          tag="button"
          onClick={() => handleClick(ExportFilter.NeufConstruire)}
        >
          Neuf / Construire
        </Button>
        <Button
          disabled={loading}
          size="medium"
          kind="primary"
          variant="normal"
          tag="button"
          onClick={() => handleClick(ExportFilter.Expert360)}
        >
          Expert360
        </Button>
        <Button
          disabled={loading}
          size="medium"
          kind="primary"
          variant="normal"
          tag="button"
          onClick={() => handleClick(ExportFilter.LuxeBucom)}
        >
          Luxe / BuCom
        </Button>
      </ExportButtons>
      {loading && <LoadingSpan>Loading...</LoadingSpan>}
      {!loading && err && <ErrorSpan>Une erreur est survenue</ErrorSpan>}
      {!loading && success && <SuccessSpan>{success}</SuccessSpan>}
    </ExportContainer>
  )
}

const ExportContainer = styled.div`
  text-align: center;
  padding: 1em;

  h1 {
    font-weight: 700;
    font-size: 3em;
  }
`

const ExportButtons = styled.div`
  margin-top: 2em;

  button {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
`

const LoadingSpan = styled.span`
  margin-top: 1em;
  display: block;
  color: grey;
`

const ErrorSpan = styled.span`
  margin-top: 1em;
  display: block;
  color: red;
`

const SuccessSpan = styled.span`
  margin-top: 1em;
  display: block;
  color: green;
  font-weight: 700;
`

export default Exports
