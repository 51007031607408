import { createAsyncThunk } from '@reduxjs/toolkit'
import { hideNotification } from '../redux/reducers/Notifications/notifications.reducer'
import { ModesType } from '../types/Modes.type'
import { getMyAdFactoryClass } from '../utils/myAdfactoryApi/getMyAdFactory.utils'
// import { ModesType } from '../types/Modes.type'
import {
  VedettePlusReq,
  VedettePlusResp,
} from '../utils/myAdfactoryApi/swaggerApi'

// Process count
export const processCountForVedettePlusAsync = createAsyncThunk(
  'vedettePlus/ProcessCount',
  async (
    {
      market,
      targetings,
      postalCodes,
      projectTypes,
      startDate,
      endDate,
      periodicity,
      radius,
    }: VedettePlusReq,
    getState
  ) => {
    const body: VedettePlusReq = {
      market,
      targetings,
      postalCodes,
      projectTypes,
      startDate,
      endDate,
      periodicity,
      radius,
    }
    const response = await getMyAdFactoryClass(getState).processCount6(body)
    return response
  }
)

export const recordCountForVedettePlusAsync = createAsyncThunk(
  'vedettePlus/RecordCount',
  async (
    {
      countName,
      vedettePlusReq,
      forecasts,
      locality,
      localityNameByZipCode,
      mafProduct,
      mafId,
      mode,
      totalBudget,
    }: VedettePlusResp & { mode: ModesType; mafId: number },
    getState
  ) => {
    const body: VedettePlusResp = {
      countName,
      vedettePlusReq,
      forecasts,
      locality,
      localityNameByZipCode,
      mafProduct,
      totalBudget,
    }
    let response = null
    if (mode === ModesType.Edit) {
      response = await getMyAdFactoryClass(getState).vedettePlus(mafId, body)
    } else {
      response = await getMyAdFactoryClass(getState).recordCount6(body)
    }

    setTimeout(() => {
      getState.dispatch(hideNotification())
    }, 5000)
    return response
  }
)
