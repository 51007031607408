import React from 'react'
import { Icon } from '@ui-library/core'
import { EmailLined } from '@ui-library/icons'
import styled from 'styled-components'

type CardContactsProps = {
  title: string,
  text: React.ReactNode,
  contact: string,
}

const CardContactsWrapper = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 100%;
  border-radius: .3em;
  padding: 2em 1em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .15);
  background-color: ${(props) => props.theme.global.colorPalette.white};

  h6 {
    font-size: 1.15em;
    margin-bottom: 1.5em;
    font-weight: 700;
    color: ${(props) => props.theme.global.colorPalette.black};
  }
`

const CardContactEmail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5em;

  a {
    color: ${(props) => props.theme.global.colorPalette.saphir};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const CardContactIcon = styled(Icon)`
  margin-top: .2em;
  margin-right: .3em;
  color: ${(props) => props.theme.global.colorPalette.saphir};
`

const CardContacts = ({ title, text, contact }: CardContactsProps): React.ReactElement => (
  <CardContactsWrapper data-testid="cardContacts">
    <div>
      <h6>{title}</h6>
      {text}
      <CardContactEmail>
        <CardContactIcon icon={EmailLined} />
        <a href={`https://mail.google.com/mail/?view=cm&source=mailto&to=${contact}`} target="_blank" rel="noreferrer">{contact}</a>
      </CardContactEmail>
    </div>
  </CardContactsWrapper>
)

export default CardContacts
