import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { performanceResSelector, setRes } from '../../../redux/reducers/Performance/performance.reducer'

import { ForecastResult } from '../../../utils/myAdfactoryApi/swaggerApi'

export const useProductPerformanceCount = () => {
  const dispatch: any = useDispatch()

  const res = useSelector(performanceResSelector)

  const usedForecast = res?.countData?.forecastSplitByPosition ?? []

  const calculateTotalBudget = (forecasts: ForecastResult[]) => {
    let totalBudg = 0
    forecasts.forEach((forecast: ForecastResult) => {
      totalBudg += forecast.budget ?? 0
    })
    return totalBudg
  }

  const calculateTotalPurchased = (forecasts: ForecastResult[]) => {
    let totalPurchased = 0
    forecasts.forEach((forecast: ForecastResult) => {
      totalPurchased += forecast.purchased ?? 0
    })
    return totalPurchased
  }

  const handleChangeInputs = (d: ForecastResult) => {
    const copyForecast = [...usedForecast]
    const index = copyForecast.findIndex((forecast: ForecastResult) => forecast.code === d.code)

    // First update target forecast with the new values.
    if (copyForecast && index !== -1) copyForecast[index] = d

    const totalBudget = calculateTotalBudget(copyForecast)
    const totalImpPurchased = calculateTotalPurchased(copyForecast)

    const updatedRes = {
      ...res,
      countData: {
        ...res.countData,
        forecastSplitByPosition: copyForecast,
        totalBudget,
        totalImpPurchased,
      },
    }
    dispatch(setRes(updatedRes))
  }

  // Auto-update totals when forecasts are changing
  useEffect(() => {
    if (res?.countData && Object.keys(res.countData).length > 0) {
      const totalBudg = calculateTotalBudget(usedForecast)
      const totalPurchased = calculateTotalPurchased(usedForecast)
      const updatedRes = {
        ...res,
        countData: {
          ...res.countData,
          totalBudget: totalBudg,
          totalImpPurchased: totalPurchased,
        },
      }
      dispatch(setRes(updatedRes))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return {
    calculateTotalBudget,
    calculateTotalPurchased,
    handleChangeInputs,
  }
}
