import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import type { Persistor } from 'redux-persist/lib/types'
import { capitalize } from '../../utils/tsUtils'
import rootReducer from '../reducers/index'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userConfig'],
  migrate: (state: any) => {
    // 06/21/2023 Migrations - Now, userConfig.currentMarket is capitalized. If user is already connected, with persistent currentMarket in lowercase, we need to capitalize the value.
    const newState = { ...state }
    if (Object.keys(newState).length > 0) {
      newState.userConfig.currentMarket = capitalize(
        state?.userConfig?.currentMarket
      )
    }
    return Promise.resolve(newState)
  },
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

let persistor: Persistor

export const getStoreAndPersistor = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  })
  persistor = persistStore(store)
  return { store, persistor }
}

export const getPersistor = () => persistor
